import { Component, OnInit } from '@angular/core';
import {AvatarTypeEnum} from "datnek-ds";

@Component({
  selector: 'nk-shared-following-menu-mobile',
  templateUrl: './following-menu-mobile.component.html',
  styleUrls: ['./following-menu-mobile.component.scss'],
})
export class FollowingMenuMobileComponent implements OnInit {
  avatarEnum = AvatarTypeEnum;
  constructor() {}

  ngOnInit(): void {}
}
