<main class="content delenscio-ads">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
        <div class="datnek-ds-card bg-white">
          <div class="p-3">Page de redirection API Google/Yahoo/Outlook</div>
        </div>
      </div>
    </div>
  </div>
</main>
