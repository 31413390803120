import { Event } from '../events/event.model';
import { Post } from '../posts/post.model';
import {TrackModel} from "datnek-core";

export class MediaNetwork extends TrackModel {
  Name?: string;
  Hashname?: string;
  Extension?: string;
  Size?: number;
  SubDir?: string;
  Description?: string;
  GroupCode?: string;

  PresentationEventId?: string;
  CoverPictureEventId?: string;
  PublicationId?: string;

  presentationEvent?: Event;
  CoverPictureEvent?: Event;
  Publication?: Post;

  constructor(
    Id?: string | null,
    CreatedAt?: Date,
    UpdatedAt?: Date,
    Name?: string,
    Hashname?: string,
    Extension?: string,
    Size?: number,
    SubDir?: string | null,
    Description?: string | null,
    GroupCode?: string | null,
  ) {
    super(Id as string, CreatedAt, UpdatedAt);
    this.Name = Name;
    this.Hashname = Hashname;
    this.Extension = Extension;
    this.Size = Size;
    this.SubDir = SubDir as string;
    this.GroupCode = GroupCode as string;
    this.Description = Description as string;
  }
}
