
import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BaseHttpService, Environment, LoaderService} from "datnek-core";
import {MediaNetwork} from "../../../models";

@Injectable({
  providedIn: 'root',
})
export class MediaNetworksService extends BaseHttpService<MediaNetwork, MediaNetwork> {
  constructor(http: HttpClient,
              loaderService: LoaderService,
              @Inject(Environment.Provider.API) private api: Environment.Api) {
    super(http, loaderService);
    this.setUrl(`${this.api.network}/Medias`);
  }
}
