export const HOME_ROUTES = {
  HOME: 'home',
  HOME_APP: '',
  HOME_PAGE: 'homepage',
  HELP_DEV: 'help-dev',
  TEST_MODAL: 'test-modal',
  UI_BOX: 'ui-box',
  ADD_ARTICLE: 'add-publication',
  CONTACTS: 'contacts',
  EVENTS: 'events',
  SINGLE_EVENTS: 'events/single-events',
  SINGLE_POST_PAGE: 'page-post-id',
  CHECKOUT: 'payment-checkout',
  SINGLE_ARTICLE: 'single-publication',
  LIST_ARTICLES: 'list-articles',
  SAVED_MESSAGES: 'saved-messages',
  NOTIFICATIONS: 'notifications',
  CHALLENGE_STATS: 'challenge-stats',
  RESTRICTED_GROUP: 'restricted-group',
  SINGLE_GROUP: 'single-group',
  SINGLE_POST: 'post-id',
  GROUP_DISCUSSION: 'group-discussion',
  GROUP_HASHTAGS: 'group-hashtags',
  HOME_HASHTAGS: 'home-hashtags',
  SINGLE_HASHTAGS: 'single-hashtag',
  GROUP_USERS: 'group-users',
  GROUP_MULTIMEDIA: 'group-multimedia',
  GROUP_SETTINGS: 'group-settings',
  HOME_GROUP_SETTINGS: 'home-group-settings',
  CUSTOMIZE_GROUP_SETTINGS: 'customize',
  SECURITY_GROUP_SETTINGS: 'security',
  ADMIN_GROUP_SETTINGS: 'admins',
  RULES_GROUP_SETTINGS: 'rules',
  QUIZZ_GROUP_SETTINGS: 'quizz',
  GROUP_STATS: 'group-stats',
  HOME_GROUP_STATS: 'home-group-stats',
  ADHESION_LIST_GROUP: 'adhesion-list',
  VALIDATION_USER: 'validation-user',
  POST_PENDING: 'post-pending',
  POST_SIGNALIZATION: 'post-signalization',
  POST_MODERATION: 'post-moderation',
  API_GOOGLE_YAHOO: 'api-google-yahoo',
  BLANK: 'blank',
  SAVED_CATEGORY: 'saved-category-messages',
};
