<nk-shared-card-user-box
  resumeTitle="Penting gak Penting yang oentung Posting"
  name="John Doe"
  [status]="avatarEnum.person"
></nk-shared-card-user-box>
<nk-shared-invitation-box titleCard="Invitations"></nk-shared-invitation-box>

<!--TOP JOBS-->
<nk-shared-top-jobs></nk-shared-top-jobs>

<div class="py-4"></div>
