import {Component, Input, OnInit} from '@angular/core';
import {Overlay} from '@angular/cdk/overlay';

import {
  DialogService,
  DialogSizeEnum,
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogTypeEnum} from 'datnek-ds';
import { DialogAddGroupComponent } from '@del-shared';

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  groupName: string;
  relations: number;
  sector: string;
}

@Component({
  selector: 'nk-shared-groups-menu-mobile',
  templateUrl: './groups-menu-mobile.component.html',
  styleUrls: ['./groups-menu-mobile.component.scss'],
})
export class GroupsMenuMobileComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  dataUsers: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'JOBS - EMPLOYMENT',
      relations: 4,
      sector: 'Génie civil',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'JOBS - EMPLOYMENT',
      relations: 18,
      sector: 'Technologie',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'JOBS - EMPLOYMENT',
      relations: 0,
      sector: 'Agroalimentaire',
    },
  ];

  /**
   * Les type de suggestion
   */
  @Input()
  suggestionType: any[] = ['invitation', 'suggestion', 'groups'];

  constructor(private overlay: Overlay, private modalService: DialogService) {}

  ngOnInit(): void {}

  addNewGroupDialog() {
    const ref = this.modalService.open(DialogAddGroupComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Créer un nouveau groupe`,
        size: DialogSizeEnum.small,
      },
    });
  }
}
