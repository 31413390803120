import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Inject, Injectable} from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';

import { ArticlesActions } from './articles.actions';
import {Environment, ListData, ListDataInterface} from "datnek-core";
import {Article, ArticleService, MediaNetworksService} from '@del-shared';
import {ToastService} from "datnek-ds";

interface ArticleStateModel {
  articles: ListDataInterface<Article>;
  loadingArticlesByLazy: boolean;
  loadingSave: boolean;
  loadingRemove: boolean;
  error: any;
}

//const USER_STATE_TOKEN = template StateToken<UserStateModel>('user');

@State<ArticleStateModel>({
  name: 'article',
  defaults: {
    articles: new ListData<Article>(),
    loadingArticlesByLazy: false,
    loadingSave: false,
    loadingRemove: false,
    error: null,
  },
})
@Injectable()
export class ArticleState {
  constructor(
    private articleService: ArticleService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private mediaNetworksService: MediaNetworksService,
    @Inject(Environment.Provider.ENVIRONMENT) private environment: Environment.Env
  ) {}

  @Action(ArticlesActions.setArticleOfCurrentUser)
  async setArticleOfCurrentUser(
    ctx: StateContext<ArticleStateModel>,
    action: ArticlesActions.setArticleOfCurrentUser
  ) {
    ctx.patchState({ articles: { Data: action.data, Count: action.count as number } });
  }

  @Action(ArticlesActions.getLazyByResume)
  async getArticlesLazyByResume(
    ctx: StateContext<ArticleStateModel>,
    action: ArticlesActions.getLazyByResume
  ) {
    const state = ctx.getState();

    if (state.loadingArticlesByLazy) return;

    ctx.patchState({ loadingArticlesByLazy: true, error: null });

    try {
      const articleListResponse = await this.articleService
        .getByResumeLazy(action.resumeId, action.skip, action.take ?? this.environment.paginationTake.articles)
        .toPromise();

      const articles = articleListResponse?.Results?.sort(
        (a: Article, b: Article) => {
          return (
            new Date(b.UpdateAt as Date).getTime() - new Date(a.UpdateAt as Date).getTime()
          );
        }
      ) as Article[];


      const articles1: Article[] = state.articles.Data?.length
        ? [...state.articles.Data, ...articles]
        : articles;
      ctx.dispatch(
        new ArticlesActions.setArticleOfCurrentUser(
          articleListResponse?.Count,
          articles1
        )
      );
    } catch (error) {
      this.toastService.error(
        'Error',
        "Un problème est survenue lors  de la récupération la liste l'publication"
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingArticlesByLazy: false });
    }
  }

  @Action(ArticlesActions.create)
  async createArticle(
    ctx: StateContext<ArticleStateModel>,
    action: ArticlesActions.create
  ) {
    const state = ctx.getState();

    ctx.patchState({ loadingSave: true, error: null });

    try {
      const saved = await this.articleService
        .createData(action.article)
        .toPromise();
      if (!saved) throw new Error('Error');

      ctx.dispatch(
        new ArticlesActions.setArticleOfCurrentUser(state.articles.Count as number + 1, [
          saved,
          ...state.articles.Data as Article[],
        ])
      );

      this.toastService.success('Success', 'Article enregistrée avec succès');
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        `Un problème est survenue lors de l'enregistrement de l'article`
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingSave: false });
    }
  }

  @Action(ArticlesActions.update)
  async updateArticle(
    ctx: StateContext<ArticleStateModel>,
    action: ArticlesActions.update
  ) {
    const _articles = JSON.parse(JSON.stringify(ctx.getState().articles)) as ListDataInterface<Article>;

    ctx.patchState({ loadingSave: true, error: null });

    try {
      const saved = await this.articleService
        .editData(action.article, action.article.Id as string)
        .toPromise();
      if (!saved) throw new Error('Error');

      const foundIndex = _articles.Data?.findIndex((e) => e.Id === saved.Id) as number;

      const oldDocuments = _articles.Data?.find((e) => e.Id === saved.Id)?.Documents?.length
        ? _articles.Data[foundIndex].Documents
        : [];
      const newDocuments = saved.Documents.length
        ? [...saved.Documents, ...oldDocuments]
        : oldDocuments;

      _articles.Data?.splice(foundIndex, 1, {
        ...saved,
        Documents: newDocuments,
      });

      ctx.dispatch(
        new ArticlesActions.setArticleOfCurrentUser(
          _articles.Count as number,
          _articles.Data as Article[]
        )
      );

      this.toastService.success('Success', 'Article enregistrée avec succès');
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        `Un problème est survenue lors de la mise à jour de l'article`
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingSave: false });
    }
  }

  @Action(ArticlesActions.remove)
  async removeArticle(
    ctx: StateContext<ArticleStateModel>,
    action: ArticlesActions.remove
  ) {
    const _articles = JSON.parse(
      JSON.stringify(ctx.getState().articles.Data)
    ) as Article[];

    ctx.patchState({ loadingRemove: true, error: null });

    try {
      const saved = await this.articleService
        .deleteData(action.article.Id as string)
        .toPromise();
      if (!saved) throw new Error('Error to delete publication');

      const foundIndex = _articles.findIndex((e) => e.Id === saved.Id);

      _articles.splice(foundIndex, 1);
      ctx.dispatch(
        new ArticlesActions.setArticleOfCurrentUser(
          ctx.getState().articles.Count as number - 1,
          _articles
        )
      );

      this.toastService.success('Success', 'La Article a bien été supprimée');
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        'Un problème est survenue lors de la suppression'
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingRemove: false });
    }
  }

  @Action(ArticlesActions.removeMedia)
  async removeArticleMedia(
    ctx: StateContext<ArticleStateModel>,
    action: ArticlesActions.removeMedia
  ) {
    const _data = JSON.parse(
      JSON.stringify(ctx.getState().articles.Data)
    ) as Article[];

    ctx.patchState({ loadingRemove: true, error: null });

    try {
      const result = await this.mediaNetworksService
        .delete(action.mediaCv.Id as string)
        .toPromise();
      if (!result)
        throw new Error(
          'la suppression du media a rencontré une erreur, le resulat du save = ' +
            result
        );

      const foundIndex = _data.findIndex(
        (e) => e.Id === action.mediaCv.PublicationId
      );
      const indexMediaDeleted = _data[foundIndex].Documents.findIndex(
        (elt) => elt.Id === action.mediaCv.Id
      );

      _data[foundIndex].Documents.splice(indexMediaDeleted, 1);
      _data.splice(foundIndex, 1, _data[foundIndex]);

      ctx.dispatch(
        new ArticlesActions.setArticleOfCurrentUser(
          ctx.getState().articles.Count as number,
          _data
        )
      );

      this.toastService.success('Success', 'Le media a bien été supprimée');
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        'Un problème est survenue lors de la suppression du media'
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingRemove: false });
    }
  }
}
