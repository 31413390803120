import {ListData, ListDataInterface} from "datnek-core";
import {State} from "@ngxs/store";
import {Injectable} from "@angular/core";

interface ChallengeModel {
  challenges: ListDataInterface<any>;
  loadingchallengeLazy: boolean;
  loadingSave: boolean;
  loadingRemove: boolean;
  error: any;
}



@State<ChallengeModel>({
  name: 'challenges',
  defaults: {
    challenges: new ListData<any>(),
    loadingchallengeLazy: false,
    loadingSave: false,
    loadingRemove: false,
    error: null,
  },
})
@Injectable()
export class ChallengeState{

}
