<div
  class="chat-message-left"
  [class.small]="size === sizeEnum.small"
  *ngIf="!isLoading"
>
  <div class="avatar">
    <datnek-ds-avatar [size]="size" [border]="false"></datnek-ds-avatar>
  </div>
  <div class="message-box d-flex flex-column">
    <div
      class="flex-shrink-1 message rounded py-2 px-3 mb-3"
      *ngIf="message.length"
    >
      <div class="d-flex align-items-start position-relative">
        <div class="message-content">
          <div class="respond" *ngIf="response.length">
            <div class="name-poster display-4 mb-1">Vous</div>
            <span>{{ response | truncatetext: 65 }}</span>
          </div>
          {{ message }}
        </div>
        <div class="option-block">
          <div class="options">
            <div
              class="pointer"
              (datnekdsClickOrTouch)="openDropdown(actions, target1)"
              #target1
            >
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlinedotsVertical"
                [type]="colorEnum.unstyled"
                [size]="sizeEnum.small"
                class=""
              ></datnek-ds-button-icon>
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-inline-block dropdown-reactions"
        [ngClass]="{ isPopbox: 'is-popbox' }"
        *ngIf="reaction"
      >
        <div
          class="liked-icon"
          (datnekdsClickOrTouch)="openDropdown(reactions, target3)"
          #target3
        >
          <img src="/assets/icons/src/likes/like-color.svg" />
          <img src="/assets/icons/src/likes/clapping-hands-color.svg" />
          <span>3</span>
        </div>
      </div>
    </div>
    <!--IF GALLERY IMAGE-->
    <div
      class="message-image-box"
      *ngIf="
        videoGallery === true ||
        imageGallery === true ||
        documentGallery === true
      "
    >
      <datnek-ds-image-gallery
        [galleryItems]="galleryItems"
        *ngIf="imageGallery === true"
        [isLoading]="false"
      ></datnek-ds-image-gallery>
      <datnek-ds-video-gallery
        [galleryVideoItems]="videoItems"
        *ngIf="videoGallery === true"
        [isLoading]="false"
      ></datnek-ds-video-gallery>
      <datnek-ds-document-gallery
        *ngIf="documentGallery === true"
        [isLoading]="false"
      ></datnek-ds-document-gallery>
    </div>
    <!--IF SHARED CONTENT-->
    <div
      class="message-box d-flex flex-column"
      *ngIf="isArticle === true || isGroup === true || isEvent === true"
    >
      <div class="flex-shrink-1 message rounded py-2 px-3 mb-3">
        <div class="d-flex align-items-start position-relative">
          <div
            class="article-box pointer"
            (datnekdsClickOrTouch)="openArticle($event)"
            *ngIf="isArticle === true"
          >
            <div class="img">
              <img src="/assets/Img/AdobeStock_104857309-1140x650.jpg" alt="" />
            </div>
            <div class="desc p-3">
              <div class="display-1 mb-3">
                {{ articleTitle | truncatetext: 55 }}
              </div>
              <p class="short">
                {{ articleDesc | truncatetext: 150 }}
              </p>
            </div>
          </div>

          <div
            class="group-box pointer"
            (datnekdsClickOrTouch)="openGroup($event)"
            *ngIf="isGroup === true"
          >
            <div class="img">
              <img src="/assets/Img/group-placeholder-bg.jpg" alt="" />
            </div>
            <div class="desc p-3">
              <div class="infos-group d-flex mb-2">
                <span>Groupe public</span> <span class="point"></span>
                <span>92 membres</span>
              </div>
              <div class="display-1 mb-3">
                {{ groupName | truncatetext: 45 }}
              </div>
            </div>
          </div>
          <div class="option-block">
            <div class="options">
              <div
                class="pointer"
                (datnekdsClickOrTouch)="openDropdown(actions, target2)"
                #target2
              >
                <datnek-ds-button-icon
                  [Icon]="iconEnum.outlinedotsVertical"
                  [type]="colorEnum.unstyled"
                  [size]="sizeEnum.small"
                ></datnek-ds-button-icon>
              </div>
            </div>
          </div>
        </div>

        <!-- LIKE ICONS : ONLY ON MOBILE DEVICES-->
        <div
          class="liked-icon d-md-none"
          (datnekdsClickOrTouch)="menuLikeIcons = true"
        >
          <img src="/assets/icons/src/likes/like-color.svg" />
        </div>
        <!-- END LIKE ICONS : ONLY ON MOBILE DEVICES-->

        <div
          class="d-inline-block d-none d-md-block dropdown-reactions"
          *ngIf="reaction"
        >
          <div
            class="liked-icon d-none d-md-block"
            (datnekdsClickOrTouch)="openDropdown(reactions, target4)"
            #target4
          >
            <img src="/assets/icons/src/likes/like-color.svg" />
          </div>
        </div>
      </div>

      <div
        class="like-btn"
        (datnekdsClickOrTouch)="openDropdown(likesicons, origin3); showLikes()"
        #origin3
      >
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlineemojiHappy"
          [type]="colorEnum.ghost"
          [round]="true"
          [size]="sizeEnum.small"
          class="ml-2"
        ></datnek-ds-button-icon>
      </div>
    </div>

    <!--IF AUDIO-->
    <div class="message-audio" *ngIf="audio === true">
      <audio controls="">
        <source src="/assets/file_example_WAV_1MG.wav" type="audio/wav" />
      </audio>
    </div>
    <span class="text-muted small text-nowrap mt-2">{{ time }}</span>
    <div
      class="like-btn"
      (datnekdsClickOrTouch)="openDropdown(likesicons, origin4); showLikes()"
      #origin4
    >
      <datnek-ds-button-icon
        [Icon]="iconEnum.outlineemojiHappy"
        [type]="colorEnum.ghost"
        [round]="true"
        [size]="sizeEnum.small"
        class="ml-2"
      ></datnek-ds-button-icon>
    </div>
  </div>
</div>

<datnek-skeleton-chat-message-left
  *ngIf="isLoading"
></datnek-skeleton-chat-message-left>

<!-- TOAST MESSAGES -->
<ng-template #cp let-close="close"> Le message a été copié</ng-template>

<ng-template #register let-close="close">
  Le message a été enregistré</ng-template
>

<!-- DROPDOWNS -->
<ng-template #likesicons let-close="close">
  <div class="like-icons-drop">
    <nk-shared-likes-icons-popbox [likes]="likes"></nk-shared-likes-icons-popbox>
  </div>
</ng-template>

<ng-template #actions let-close="close">
  <div class="datnek-ds-card like-icons-drop dropdown-card">
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="showToast(cp); close()"
    >
      <datnek-ds-svg-icon [icon]="iconEnum.outlineduplicate"></datnek-ds-svg-icon
      >Copier
    </button>
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="openMessageToRespond(); close()"
    >
      <datnek-ds-svg-icon [icon]="iconEnum.outlinearrowLeft"></datnek-ds-svg-icon>
      Répondre
    </button>
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="sendMessage(); close()"
    >
      <datnek-ds-svg-icon [icon]="iconEnum.outlinearrowRight"></datnek-ds-svg-icon>
      Transférer
    </button>
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="addToCategory(); close()"
    >
      <datnek-ds-svg-icon [icon]="iconEnum.outlinebookmark"></datnek-ds-svg-icon>
      Enregistrer
    </button>
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="addSignalization(); close()"
    >
      <datnek-ds-svg-icon
        [icon]="iconEnum.outlineexclamationCircle"
      ></datnek-ds-svg-icon>
      Signaler un problème
    </button>
  </div>
</ng-template>

<ng-template #reactions let-close="close">
  <div class="datnek-ds-card dropdown-card chat-message-action">
    <p-tabView>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div class="head-react">Réactions <span>3</span></div>
        </ng-template>
        <p-scrollPanel [style]="{ width: '100%', height: '150px' }">
          <div class="list-react">
            <ng-container *ngIf="reaction">
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-between
                  w-100
                  p-2
                "
                *ngFor="let data of userData"
                (datnekdsClickOrTouch)="deleteReaction()"
              >
                <div class="d-flex align-items-center left">
                  <datnek-ds-avatar
                    [size]="sizeEnum.small"
                    [border]="false"
                    class="mr-2"
                    [type]="avatarEnum.person"
                  ></datnek-ds-avatar>
                  <div class="infos">
                    <div class="display-4 name mr-2">
                      {{ data.name | truncatetext: 25 }}
                    </div>
                    <span class="display-7">Cliquez pour Supprimer</span>
                  </div>
                </div>
                <div class="right">
                  <img src="{{ data.img }}" />
                </div>
              </div>
            </ng-container>
          </div>
        </p-scrollPanel>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div class="head-react">
            <img class="mr-1" src="/assets/icons/src/likes/like-color.svg" />
            2
          </div>
        </ng-template>
        <p-scrollPanel [style]="{ width: '100%', height: '150px' }">
          <div class="list-react">
            <ng-container *ngIf="reaction">
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-between
                  w-100
                  p-2
                "
                *ngFor="let data of userData1"
                (datnekdsClickOrTouch)="deleteReaction()"
              >
                <div class="d-flex align-items-center left">
                  <datnek-ds-avatar
                    [size]="sizeEnum.small"
                    [border]="false"
                    class="mr-2"
                    [type]="avatarEnum.person"
                  ></datnek-ds-avatar>
                  <div class="infos">
                    <div class="display-4 name mr-2">
                      {{ data.name | truncatetext: 25 }}
                    </div>
                    <span class="display-7">Cliquez pour Supprimer</span>
                  </div>
                </div>
                <div class="right">
                  <img src="{{ data.img }}" />
                </div>
              </div>
            </ng-container>
          </div>
        </p-scrollPanel>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div class="head-react">
            <img
              class="mr-1"
              src="/assets/icons/src/likes/clapping-hands-color.svg"
            />
            1
          </div>
        </ng-template>
        <p-scrollPanel [style]="{ width: '100%', height: '150px' }">
          <div class="list-react">
            <ng-container *ngIf="reaction">
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-between
                  w-100
                  p-2
                "
                *ngFor="let data of userData2"
                (datnekdsClickOrTouch)="deleteReaction()"
              >
                <div class="d-flex align-items-center left">
                  <datnek-ds-avatar
                    [size]="sizeEnum.small"
                    [border]="false"
                    class="mr-2"
                    [type]="avatarEnum.person"
                  ></datnek-ds-avatar>
                  <div class="infos">
                    <div class="display-4 name mr-2">
                      {{ data.name | truncatetext: 25 }}
                    </div>
                    <span class="display-7">Cliquez pour Supprimer</span>
                  </div>
                </div>
                <div class="right">
                  <img src="{{ data.img }}" />
                </div>
              </div>
            </ng-container>
          </div>
        </p-scrollPanel>
      </p-tabPanel>
    </p-tabView>
  </div>
</ng-template>
