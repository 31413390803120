import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BaseHttpService, Environment, LoaderService} from "datnek-core";
import {LocalizationNetwork} from "../../../models";

@Injectable({
  providedIn: 'root',
})
export class LocalizationsNetworksService extends BaseHttpService<LocalizationNetwork, LocalizationNetwork> {
  constructor(http: HttpClient,
              loaderService: LoaderService,
              @Inject(Environment.Provider.API) private api: Environment.Api) {
    super(http, loaderService);
    this.setUrl(`${this.api.network}/Localizations`);
  }
}
