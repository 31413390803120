import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  relations: number;
}

@Component({
  selector: 'nk-shared-invitation-box',
  templateUrl: './invitation-box.component.html',
  styleUrls: ['./invitation-box.component.scss'],
})
export class InvitationBoxComponent implements OnInit {
  dataUsers: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      relations: 4,
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      relations: 18,
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      relations: 0,
    },
  ];

  /**
   * Les Titre de la card
   */
  @Input()
  titleCard = '';

  isLoading = true;

  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  gotoInvitation(e: any) {
    e.preventDefault();
    this.router.navigate(['/request/invitaions']);
  }
}
