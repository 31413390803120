import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nk-shared-friends-menu-mobile',
  templateUrl: './friends-menu-mobile.component.html',
  styleUrls: ['./friends-menu-mobile.component.scss']
})
export class FriendsMenuMobileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
