import {Inject, Injectable} from '@angular/core';
import { FormControl } from '@angular/forms';
import  * as moment from 'moment';
import {Environment} from "datnek-core";
import {Media} from "../../../models";
import {HttpClient} from "@angular/common/http";
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(@Inject(Environment.Provider.API) private api: Environment.Api, private http: HttpClient){}

  blobToBase64(blob: any): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  getLang(){
    return this.http.get("/assets/data/delenscioLangs.json");
  }

  getActivities(){
    return this.http.get("/assets/data/ativitiesSectors.json");
  }

  getPosition(){
    return this.http.get("/assets/data/positionHelds.json");
  }

  getContractTypeMap(){
    return this.http.get("/assets/data/contractTypeMapArr.json");
  }

  getAvoidedCharactersInTitleField(){
    return this.http.get("/assets/data/avoidedCharactersInTitleField.json");
  }

  getDaysMap(){
    return this.http.get("/assets/data/daysMap.json");
  }
  getAcceptedMIME(){
      return this.http.get("/assets/data/acceptedMIME.json");
  }

  getFormationLevelMapArr(){
      return this.http.get("/assets/data/formationLevelMapArr.json");
  }

  getCountryList(){
      return this.http.get("/assets/data/countryList.json");
  }

  mediaApi(path: string): string {
    return `${this.api.medias.replace(/^\/|\/$/g, '')}/ ${path.replace(
      /^\/|\/$/g,
      ''
    )}`;
  }

  DateValidator(format = 'YYYY-MM-DD'): any {
    return (control: FormControl): { [p: string]: any } | null => {
      const val = moment(control.value, format, true);

      if (!val.isValid()) {
        return { invalidDate: true };
      }

      return null;
    };
  }

  getFileToBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  /***************************************************************************************************************************** */
  /************************************************ FOR FORMATING DATE ********************************************************** */
  formateDate(date: Date, format = 'YYYY-MM-DD'): string | null  {
    if (!date) {
      return null;
    }
    return moment(date).format(format);
  }

  getFormatedStringFromDays(numberOfDays: number, y = true, m = true, d = false) {
    const years = Math.floor(numberOfDays / 365);
    const months = Math.floor(numberOfDays / 30);
    //var months = Math.floor(numberOfDays % 365 / 30);
    const days = Math.floor((numberOfDays % 365) % 30);
    //console.log("****************",years,months,days )
    // var yearsDisplay = years > 0 ? years + (years == 1 ? " an " : " ans ") : "";
    // var monthsDisplay = months > 0 ? months + (months == 1 ? " moi " : " mois ") : "";
    // var daysDisplay = days > 0 ? days + (days == 1 ? " jr" : " jrs") : "";
    // return yearsDisplay +(m? monthsDisplay:'' )+(d? daysDisplay:'');
    const monthsDisplay =
      months > 0 ? months + (months == 1 ? ' moi ' : ' mois ') : 0;
    return monthsDisplay;
  }
  /************************************************ --END-- FOR FORMATING DATE ************************************************** */
  /***************************************************************************************************************************** */

  /***************************************************************************************************************************** */
  /************************************************ FOR MEDIAS UPLOAD ********************************************************** */
  getMediaImageFromNgDatNekFileUpload(
    filesAndExt: any,
    subDir: string
  ): Media[] {
    const medias: Media[] = filesAndExt.map((f: any) => {
      const m = new Media(
        null!,
        new Date(),
        null!,
        f.file.name,
        f.img?.substr(f.img?.indexOf(',') + 1),
        f.file.type,
        f.file.size,
        null!
      );
      m.SubDir = subDir;
      delete m.Id;
      return m;
    });

    return medias;
  }

  async getMediaPDFFromNgDatNekFileUpload(
    filesAndExt: any,
    subDir: string
  ): Promise<Media[]> {
    const medias: Media[] = [];

    for (const elt of filesAndExt) {
      const result_base64: any = await this.readFileAsDataURL(elt.file);

      const m = new Media(
        null!,
        new Date(),
        null!,
        elt.file.name,
        result_base64?.substr(result_base64?.indexOf(',') + 1),
        elt.file.type,
        elt.file.size,
        null!
      );
      delete m.Id;
      m.SubDir = subDir;

      medias.push(m);
    }

    return medias;
  }

  async readFileAsDataURL(file: File) {
    const result_base64 = await new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });

    return result_base64;
  }
  /************************************************ --END-- FOR MEDIAS UPLOAD ************************************************** */
  /***************************************************************************************************************************** */
}
