<div class="datnek-ds-dialog-body dialog-edit-contact">
  <div class="content-modal">
    <div class="content-text">
      <datnek-ds-upload-files
        title="Charger la photo votre contact"
        imgSize="Largeur minimum de 480 pixels, 16:9 recommandée"
        fileType="single"
        [accept]="accept"
        type="image"
        [smallHeight]="true"
      ></datnek-ds-upload-files>
    </div>
    <div class="form p-3">
      <div class="grid grid--gap16">
        <div class="grid__col_12 grid__col_sm6 mb-3">
          <datnek-ds-input-text
            [label]="'Noms *'"
            type="text"
            placeholder="Nom du contact"
          ></datnek-ds-input-text>
        </div>
        <div class="grid__col_12 grid__col_sm6 mb-3">
          <datnek-ds-input-text
            [label]="'Prénom(s) *'"
            type="text"
            placeholder="Prénom du contact"
          ></datnek-ds-input-text>
        </div>
      </div>
      <div class="grid grid--gap16">
        <div class="grid__col_12 grid__col_sm6 mb-3">
          <datnek-ds-input-text
            [label]="'Fonction *'"
            type="text"
            placeholder="Fonction/Poste occupé"
          ></datnek-ds-input-text>
        </div>
        <div class="grid__col_12 grid__col_sm6 mb-3">
          <datnek-ds-input-text
            [label]="'Entreprise *'"
            type="text"
            placeholder="L'entreprise du contact"
          ></datnek-ds-input-text>
        </div>
      </div>
      <div class="grid grid--gap16">
        <div class="grid__col_12">
          <div class="input-plus mb-3">
            <datnek-ds-input-text
              [label]="'Téléphone *'"
              type="text"
              placeholder="6 99 46 12 05"
            ></datnek-ds-input-text>
            <datnek-ds-button-icon
              [Icon]="iconEnum.outlineplusCircle"
              [type]="colorEnum.ghost"
              [round]="true"
              (datnekdsClickOrTouch)="addInput()"
            ></datnek-ds-button-icon>
            <div class="mt-3">
              <datnek-ds-input-text
                type="text"
                placeholder="6 99 46 12 05"
                *ngIf="input === true"
              ></datnek-ds-input-text>
            </div>
          </div>

          <div class="input-plus mb-3">
            <datnek-ds-input-text
              [label]="'Email *'"
              type="email"
              placeholder="email@gmail.com"
            ></datnek-ds-input-text>
            <datnek-ds-button-icon
              [Icon]="iconEnum.outlineplusCircle"
              [type]="colorEnum.ghost"
              [round]="true"
              (datnekdsClickOrTouch)="addInput1()"
            ></datnek-ds-button-icon>
            <div class="mt-3">
              <datnek-ds-input-text
                type="email"
                placeholder="email@gmail.com"
                *ngIf="input1 === true"
              ></datnek-ds-input-text>
            </div>
          </div>

          <div class="input-plus block border p-3 rounded">
            <div class="grid grid--gap16">
              <div class="grid__col_12">
                <datnek-ds-input-text
                  [label]="'Pays *'"
                  type="text"
                  placeholder="203 Rue du Bucher"
                ></datnek-ds-input-text>
              </div>
              <div class="grid__col_12 grid__col_sm6">
                <datnek-ds-input-text
                  [label]="'Adress postale *'"
                  type="text"
                  placeholder="203 Rue du Bucher"
                ></datnek-ds-input-text>
              </div>
              <div class="grid__col_12 grid__col_sm6">
                <datnek-ds-input-text
                  [label]="'Ville *'"
                  type="text"
                  placeholder="Strasbourg"
                ></datnek-ds-input-text>
              </div>
            </div>
            <datnek-ds-button-icon
              [Icon]="iconEnum.outlineplusCircle"
              [type]="colorEnum.ghost"
              [round]="true"
              (datnekdsClickOrTouch)="addInput2()"
            ></datnek-ds-button-icon>

            <hr *ngIf="input2 === true" />

            <div class="grid grid--gap16" *ngIf="input2 === true">
              <div class="grid__col_12">
                <datnek-ds-input-text
                  [label]="'Pays *'"
                  type="text"
                  placeholder="203 Rue du Bucher"
                ></datnek-ds-input-text>
              </div>
              <div class="grid__col_12 grid__col_sm6">
                <datnek-ds-input-text
                  [label]="'Adress postale *'"
                  type="text"
                  placeholder="203 Rue du Bucher"
                ></datnek-ds-input-text>
              </div>
              <div class="grid__col_12 grid__col_sm6">
                <datnek-ds-input-text
                  [label]="'Ville *'"
                  type="text"
                  placeholder="Strasbourg"
                ></datnek-ds-input-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="datnek-ds-dialog-footer">
    <datnek-ds-button
      [label]="'Annuler'"
      [size]="sizeEnum.large"
      [type]="colorEnum.secondary"
      class="mr-3"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
    <datnek-ds-button
      [label]="'Ajouter le contact'"
      [size]="sizeEnum.large"
      [type]="colorEnum.primary"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
  </div>
</div>
