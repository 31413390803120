import {Article, MediaNetwork} from '@del-shared';

export namespace ArticlesActions {
  export class setArticleOfCurrentUser {
    static readonly type = '[Cv Api] Set Articles of Current user';
    constructor(public count?: number, public data?: Article[]) {}
  }

  export class getLazyByResume {
    static readonly type = '[Cv Api] Get Articles Lazy Resume';
    constructor(
      public resumeId: string,
      public skip: number,
      public take: number| null = null
    ) {}
  }

  export class create {
    static readonly type = '[Cv Api] Create Article';
    constructor(public article: Article) {}
  }

  export class update {
    static readonly type = '[Cv Api] Update Article';
    constructor(public article: Article) {}
  }

  export class remove {
    static readonly type = '[Cv Api] Delete Article';
    constructor(public article: Article) {}
  }

  export class removeMedia {
    static readonly type = '[Cv Api] Delete Media of Article';
    constructor(public mediaCv: MediaNetwork) {}
  }
}
