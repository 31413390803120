import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UUID } from 'angular2-uuid';
import { MessageService } from 'primeng/api';
import {DatnekModalService, Media} from "@network-shared";
import {CropperService} from "datnek-ds";
import {TranslationService} from "@del-shared";

@Component({
  selector: 'network-help-dev',
  templateUrl: './help-dev.component.html',
  styleUrls: ['./help-dev.component.scss'],
  providers: [MessageService],
})
export class HelpDevComponent implements OnInit, OnDestroy {
  custormBrowser: string = UUID.UUID();
  profile = '';
  keyProfile = UUID.UUID();
  profile1 = '';
  keyProfile1 = UUID.UUID();
  media: Media = new Media();
  media1: Media = new Media();
  subscribeMedia = new Subscription();

  custormBrowserBackground: string = UUID.UUID();
  background = '';
  keyBackground = UUID.UUID();
  background1 = '';
  keyBackground1 = UUID.UUID();
  backgroundMedia: Media = new Media();
  backgroundMedia1: Media = new Media();

  uploadedFiles: any[] = [];

  constructor(
    private translationService: TranslationService,
    private cropperService: CropperService,
    private messageService: MessageService,
    public modalService: DatnekModalService
  ) {
    this.translationService.setLanguage();
  }

  ngOnInit(): void {
    // listerner the profile or background when changed
    this.subscribeMedia = this.cropperService.subjectMedia.subscribe(
      (cropperModel) => {
        if (cropperModel.Key === this.keyProfile) {
          this.media = cropperModel.Picture as any;
          this.profile = cropperModel.base64;
        }
        if (cropperModel.Key === this.keyProfile1) {
          this.media1 = cropperModel.Picture as any;
          this.profile1 = cropperModel.base64;
        }

        if (cropperModel.Key === this.keyBackground) {
          this.backgroundMedia = cropperModel.Picture as any;
          this.background = cropperModel.base64;
        }
        if (cropperModel.Key === this.keyBackground1) {
          this.backgroundMedia1 = cropperModel.Picture as any;
          this.background1 = cropperModel.base64;
        }
      }
    );
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  onChangeLanguage(lang: string): void {
    this.translationService.useLanguage(lang);
  }

  ngOnDestroy(): void {
    // destroy listerner
    this.subscribeMedia.unsubscribe();
  }

  onBrowser(event: MouseEvent): void {
    event.preventDefault();
    this.custormBrowser = UUID.UUID();
  }

  onBrowserBackground(event: MouseEvent): void {
    event.preventDefault();
    this.custormBrowserBackground = UUID.UUID();
  }

  onUpload(event: { files: any }) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    console.log(this.uploadedFiles);

    this.messageService.add({
      severity: 'info',
      summary: 'Success',
      detail: 'File Uploaded',
    });
  }
}
