<div class="suggestion-header display-1">LE CLUB DES EXPERTS ANGULARJS</div>
<!--<div class="search p-2 border-bottom">
  <datnek-ds-search-inputs
    placeholder="Rechercher dans les groupes"
  ></datnek-ds-search-inputs >
</div>-->
<del-shared-menu-sidebar
  [isSingleGroupID]="true"
  [menuLeft]="menuLeft"
  (onClick)="closeLeftSidebar()"
  class="mb-3"
></del-shared-menu-sidebar>

<!--<nk-shared-card-user-box
  name="LE CLUB DES EXPERTS ANGULARJS"
  [status]="avatarEnum.group"
></nk-shared-card-user-box>-->

<div class="datnek-ds-card">
  <div class="suggestion-header display-1 p-3 border-bottom">A propos</div>
  <div class="description p-3">
    <p class="">
      {{ readMore ? descriptionGroup : (descriptionGroup | slice: 0:150) }}
    </p>
    <datnek-ds-link
      href="javascript:;"
      label="Voir plus"
      *ngIf="!readMore"
      (datnekdsClickOrTouch)="readMore = true"
      class="d-block m-auto"
    ></datnek-ds-link>
    <datnek-ds-link
      href="javascript:;"
      label="Voir moins"
      *ngIf="readMore"
      (datnekdsClickOrTouch)="readMore = false"
      class="d-block m-auto"
    ></datnek-ds-link>
  </div>
  <div class="suggestion-content">
    <div class="sidebar-users p-3">
      <div class="display-2 mb-3">Personnes</div>
      <div class="sidebar-users-group">
        <datnek-ds-avatar [size]="sizeEnum.small"></datnek-ds-avatar>
        <datnek-ds-avatar [size]="sizeEnum.small"></datnek-ds-avatar>
        <datnek-ds-avatar [size]="sizeEnum.small"></datnek-ds-avatar>
        <datnek-ds-avatar [size]="sizeEnum.small"></datnek-ds-avatar>
        <datnek-ds-avatar [size]="sizeEnum.small"></datnek-ds-avatar>
        <datnek-ds-avatar [size]="sizeEnum.small"></datnek-ds-avatar>
        <datnek-ds-avatar [size]="sizeEnum.small"></datnek-ds-avatar>
        <div class="plus">+23</div>
      </div>
      <!--<p-tabView>
            <p-tabPanel header="Personnes">

            </p-tabPanel>
            <p-tabPanel header="Paramètres">
              <div class="sidebar-chat"></div>
            </p-tabPanel>
          </p-tabView>-->
    </div>
  </div>
  <div
    class="suggestion-footer text-center d-flex justify-content-center"
  ></div>
</div>

<div
  class="
    sidebar-footer
    d-flex
    justify-content-between
    align-items-center
    p-3
    border-top
  "
>
  <input
    type="hidden"
    [routerLink]="'/groups/single-group/group-settings'"
    routerLinkActive
    #settings="routerLinkActive"
  />
  <input
    type="hidden"
    [routerLink]="'/groups/single-group/group-stats'"
    routerLinkActive
    #stats="routerLinkActive"
  />
  <datnek-ds-button
    [type]="colorEnum.primary"
    label="Nouvelle publication"
    [size]="sizeEnum.large"
    (datnekdsClickOrTouch)="closeLeftSidebar(); newContentDialog()"
  ></datnek-ds-button>

  <div class="d-flex align-items-center">
    <datnek-ds-button-icon
      [Icon]="iconEnum.outlinecog"
      [type]="colorEnum.unstyled"
      [size]="sizeEnum.large"
      (datnekdsClickOrTouch)="closeLeftSidebar(); goToSettings()"
      class=""
      *ngIf="!settings.isActive"
    ></datnek-ds-button-icon>
    <datnek-ds-button-icon
      [colorIcon="iconEnum.outlinecolorCog"
      [type]="colorEnum.unstyled"
      [size]="sizeEnum.large"
      (datnekdsClickOrTouch)="closeLeftSidebar(); goToSettings()"
      class=""
      *ngIf="settings.isActive"
    ></datnek-ds-button-icon>
    <datnek-ds-button-icon
      [Icon]="iconEnum.outlinechartSquareBar"
      [type]="colorEnum.unstyled"
      [size]="sizeEnum.large"
      (datnekdsClickOrTouch)="closeLeftSidebar(); goToStats()"
      class=""
      *ngIf="!stats.isActive"
    ></datnek-ds-button-icon>
    <datnek-ds-button-icon
      [colorIcon]="iconEnum.outlinecolorchartSquareBar"
      [type]="colorEnum.unstyled"
      [size]="sizeEnum.large"
      (datnekdsClickOrTouch)="closeLeftSidebar(); goToStats()"
      class=""
      *ngIf="stats.isActive"
    ></datnek-ds-button-icon>
  </div>
</div>
