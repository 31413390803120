import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {
  AvatarTypeEnum,
  ControlDisplayEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  DialogAddDocumentComponent,
  DialogAddPicturesComponent,
  DialogAddVideoComponent,
  DialogAddVoiceComponent, PopupInterface
} from 'datnek-ds';

import { Overlay } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

import {
  DialogAddMemberComponent,
} from '../../dialogs';

const STYLES_DIALOG = {
  width: '100vw',
  height: '100vh',
  borderRadius: 0,
};

@Component({
  selector: 'nk-shared-chat-popup-box',
  templateUrl: './chat-popup-box.component.html',
  styleUrls: ['./chat-popup-box.component.scss'],
})
export class ChatPopupBoxComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  @Output() closePopupEvent = new EventEmitter<PopupInterface>();
  @Input() userChat!: PopupInterface;

  /**
   * Vérifie si le chatbox est sous forme de Sidebar
   */
  @Input()
  isSidebar = false;

  likes = false;
  icons = false;
  status = true;
  hide = false;
  Toolbar = false;
  showMessageToRespond = false;

  respond_message = `Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis maiores cumque inventore, molestias eaque sapiente`;

  constructor(
    public router: Router,
    private overlay: Overlay,
    private modalService: DialogService
  ) {}

  closePopup(): void {
    this.closePopupEvent.emit(this.userChat);
  }

  addPicture(): void {
    const ref = this.modalService.open(DialogAddPicturesComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter une image`,
        size: DialogSizeEnum.small,
      },
    });
  }

  addVideo(): void {
    const ref = this.modalService.open(DialogAddVideoComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter une Vidéo`,
        size: DialogSizeEnum.small,
      },
    });
  }

  addVoice(): void {
    const ref = this.modalService.open(DialogAddVoiceComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter un enregistrement audio`,
        size: DialogSizeEnum.small,
      },
    });
  }

  addDocument(): void {
    const ref = this.modalService.open(DialogAddDocumentComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter un document`,
        size: DialogSizeEnum.small,
      },
    });
  }

  addMember(): void {
    const ref = this.modalService.open(DialogAddMemberComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter un nouveau membre`,
        size: DialogSizeEnum.small,
      },
    });
  }

  openVideoCall(): void {
    this.router.navigate(['/chat/chat-call']);
  }

  ngOnInit(): void {}

  showLikes(): void {
    this.likes = !this.likes;
  }

  showIcons(): void {
    this.icons = !this.icons;
  }

  showStatus(): void {
    this.status = !this.status;
  }

  onHide(): void {
    this.hide = !this.hide;
  }

  showToolbar(): void {
    this.Toolbar = !this.Toolbar;
  }

  openMessageToRespond(): void {
    this.showMessageToRespond = !this.showMessageToRespond;
    //alert('RESPOND ' + this.showMessageToRespond);
  }
}
