<div class="datnek-ds-dialog-body dialog-add-calendar">
  <div class="content-modal p-3">
    <div class="content-text">
      <p class="">
        Souhaitez-vous vraiment participer au challenge? En cliquant sur Oui,
        nous enverrons vos informations aux organisateurs.
      </p>
    </div>
  </div>
</div>

<div class="datnek-ds-dialog-footer">
  <datnek-ds-button
    [label]="'Annuler'"
    [size]="sizeEnum.large"
    [type]="colorEnum.secondary"
    class="mr-3"
    (datnekdsClickOrTouch)="dialogRef.close()"
  ></datnek-ds-button>
  <datnek-ds-button
    [label]="'Oui'"
    [size]="sizeEnum.large"
    [type]="colorEnum.primary"
    (datnekdsClickOrTouch)="showToast(go); dialogRef.close()"
  ></datnek-ds-button>
</div>

<!--NOTIFS MESSAGES-->
<ng-template #go let-close="close">
  Les organisateurs ont été informé. Vous serez notifié une fois que les
  organisateurs auront validé.
</ng-template>
