<div class="datnek-ds-dialog-body dialog-contact-details">
  <div class="content-modal">
    <div
      class="
        content-head
        d-flex
        flex-column flex-md-row
        justify-content-between
        px-3
        py-4
      "
    >
      <div class="left d-flex">
        <datnek-ds-avatar
          [size]="sizeEnum.large"
          class="mr-3 d-none d-md-block"
        ></datnek-ds-avatar>
        <datnek-ds-avatar
          [size]="sizeEnum.medium"
          class="mr-3 d-md-none"
        ></datnek-ds-avatar>
        <div class="infos d-flex flex-column">
          <div
            class="
              name
              display-1
              mb-1
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <span class="d-flex align-items-center"
              ><span class="truncated">Etienne Henri Lotin Joseph</span>
              <img src="assets/icons/src/check-color.png" alt="" class="ml-3"
            /></span>
            <datnek-ds-svg-icon
              [icon]="iconEnum.outlinestar"
              class="mr-2"
              *ngIf="favorite === false"
              (datnekdsClickOrTouch)="showToast(fav); addFavorite()"
            ></datnek-ds-svg-icon>
            <datnek-ds-svg-icon-solid
              [icon]="iconEnum.solidstar"
              class="mr-2"
              *ngIf="favorite === true"
              (datnekdsClickOrTouch)="showToast(fav); addFavorite()"
            ></datnek-ds-svg-icon-solid>
          </div>

          <div class="display-4 dispo mb-1">
            Ouvert pour un emploi de Designer à partir du 25 sept 2022
          </div>

          <div class="society d-flex align-items-center">
            <datnek-ds-svg-icon
              [icon]="iconEnum.outlineofficeBuilding"
            ></datnek-ds-svg-icon>
            <div class="ml-2">Lotin Corp</div>
          </div>
          <div class="d-flex align-items-center">
            <datnek-ds-svg-icon [icon]="iconEnum.outlineinbox"></datnek-ds-svg-icon>
            <div class="ml-2">Envoyer un message</div>
          </div>
        </div>
      </div>

      <div
        class="actions d-none d-md-flex align-items-center justify-content-end"
      >
        <datnek-ds-button
          label="Modifier"
          [size]="sizeEnum.large"
          [type]="colorEnum.primary"
          (datnekdsClickOrTouch)="editContact()"
        ></datnek-ds-button>

        <div ngbDropdown #myDrop="ngbDropdown" class="d-inline-block">
          <div class="pointer" id="dropdownBasic2" ngbDropdownToggle>
            <datnek-ds-button-icon
              [Icon]="iconEnum.outlinedotsVertical"
              [type]="colorEnum.unstyled"
              [size]="sizeEnum.medium"
            ></datnek-ds-button-icon>
          </div>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
            <button
              ngbDropdownItem
              (datnekdsClickOrTouch)="showToast(mask); myDrop.close()"
            >
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlineeyeOff"
              ></datnek-ds-svg-icon>
              Masquer dans les contacts
            </button>
            <button
              ngbDropdownItem
              (datnekdsClickOrTouch)="deleteContact(); myDrop.close()"
            >
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlinetrash"
              ></datnek-ds-svg-icon>
              Supprimer
            </button>
          </div>
        </div>
      </div>
      <div
        class="actions-mobile d-flex d-md-none align-items-center gap-3 pt-3"
      >
        <datnek-ds-button
          [label]="'Masquer dans les contacts'"
          [size]="sizeEnum.large"
          [block]="true"
          [type]="colorEnum.secondary"
          [leftIcon]="iconEnum.outlineeyeOff"
          pTooltip="Masquer dans les contacts"
          tooltipPosition="top"
        ></datnek-ds-button>
        <datnek-ds-button
          [label]="'Supprimer'"
          [size]="sizeEnum.large"
          [block]="true"
          [type]="colorEnum.secondary"
          [leftIcon]="iconEnum.outlinetrash"
          (datnekdsClickOrTouch)="deleteContact()"
        ></datnek-ds-button>
      </div>
    </div>

    <div class="content-body p-3">
      <div class="grid grid--gap16">
        <div class="grid__col_12 grid__col_sm6">
          <div class="datnek-ds-card border coordonnees p-3">
            <div class="display-1 mb-3">Coordonnées</div>
            <div class="d-flex align-items-center mb-2">
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlinemail"
                class="mr-3"
              ></datnek-ds-svg-icon>
              <datnek-ds-link
                label="contact@lotincorp.biz"
                mailTo="contact@lotincorp.biz"
                href="javascript:;"
              ></datnek-ds-link>
            </div>
            <div class="d-flex align-items-center mb-2">
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlinelocationMarker"
                class="mr-3"
              ></datnek-ds-svg-icon>
              <datnek-ds-link
                label="403, Rue Drouot, Akwa Douala - Cameroon"
                href="javascript:;"
              ></datnek-ds-link>
            </div>
            <div class="d-flex align-items-center mb-2">
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlinelink"
                class="mr-3"
              ></datnek-ds-svg-icon>
              <datnek-ds-link
                href="javascript:;"
                label="https://lotincorp.biz"
                (datnekdsClickOrTouch)="link('https://lotincorp.biz')"
              ></datnek-ds-link>
            </div>
          </div>
        </div>
        <div class="grid__col_12 grid__col_sm6">
          <div class="datnek-ds-card border recent-messages p-3 mb-0">
            <div class="display-1 mb-3">Vos conversations récentes</div>

            <div class="messages">
              <p-scrollPanel [style]="{ width: '100%', height: '205px' }">
                <div class="item py-3">
                  <div class="mb-2">
                    {{ message1 | truncatetext: 40 }}
                  </div>
                  <div class="display-7 time">17/08/2019 01:04</div>
                </div>
                <div class="item py-3">
                  <div class="mb-2">
                    {{ message2 | truncatetext: 40 }}
                  </div>
                  <div class="display-7 time">17/08/2019 01:04</div>
                </div>
                <div class="item py-3">
                  <div class="mb-2">
                    {{ message3 | truncatetext: 40 }}
                  </div>
                  <div class="display-7 time">17/08/2019 01:04</div>
                </div>
              </p-scrollPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TOAST MESSAGE -->
<ng-template #mask let-close="close">
  Le contact a bien été masqué de la liste</ng-template
>
<ng-template #fav let-close="close">
  Le contact a été ajouté/supprimé vos favoris</ng-template
>
