<div class="datnek-ds-card top-jobs skeleton">
  <div class="header-top-jobs" *ngIf="!isLoading">
    <h2 class="display-1">Top Jobs</h2>
  </div>
  <div class="header-top-jobs" *ngIf="isLoading">
    <div class="line w-50 h15"></div>
  </div>
  <span class="display-5 text-uppercase p-3 d-flex" *ngIf="!isLoading"
    >Conseillé pour vous</span
  >

  <div class="jobs-list pb-3">
    <nk-shared-jobs-list [jobData]="dataJobs"></nk-shared-jobs-list>
  </div>

  <div class="footer-top-jobs text-center">
    <datnek-ds-link
      label="Voir plus"
      *ngIf="!isLoading"
      href="https://dev-jobs.delenscio.be/list-enterprise"
      target="_blank"
    ></datnek-ds-link>
    <div class="line w-50 h15 m-auto" *ngIf="isLoading"></div>
  </div>
</div>
