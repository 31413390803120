
import { Component, OnInit } from '@angular/core';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogService, DialogSizeEnum, DialogTypeEnum
} from 'datnek-ds';
import {DialogStatusPaiementComponent} from "@network-shared";

@Component({
  selector: 'network-paiement-checkout',
  templateUrl: './paiement-checkout.component.html',
  styleUrls: ['./paiement-checkout.component.scss'],
})
export class PaiementCheckoutComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  constructor(
    private modalService: DialogService
  ) {}

  ngOnInit(): void {}

  checkoutStatus() {
    const ref = this.modalService.open(DialogStatusPaiementComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Statut du paiement`,
        size: DialogSizeEnum.small,
      },
    });
  }
}
