import { Component, OnInit } from '@angular/core';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogRef
} from 'datnek-ds';

@Component({
  selector: 'nk-shared-dialog-chat-call-params',
  templateUrl: './dialog-chat-call-params.component.html',
  styleUrls: ['./dialog-chat-call-params.component.scss'],
})
export class DialogChatCallParamsComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  constructor(public dialogRef: DialogRef) {}

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }
}
