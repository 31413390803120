import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {BaseHttpService, Environment, ListResult, LoaderService} from "datnek-core";
import {Event as EventCompany} from "../../../models";
@Injectable({
  providedIn: 'root',
})
export class EventsService extends BaseHttpService<EventCompany, EventCompany> {
  constructor(http: HttpClient,
              loaderService: LoaderService,
              @Inject(Environment.Provider.API) private api: Environment.Api) {
    super(http, loaderService);
    this.setUrl(`${this.api.network}/Events`);
  }

  getByCompany(
    companyId: string,
    skip: number,
    take: number,
    globalLoading = false
  ): Observable<ListResult<EventCompany>> {
    return this.getLazyData(take, skip,`${this.rootURL}/Company/${companyId}`, globalLoading);
  }
}
