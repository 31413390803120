import { DialogShareToEmailComponent } from '../dialog-share-to-email/dialog-share-to-email.component';
import { DialogShareToUserComponent } from '../dialog-share-to-user/dialog-share-to-user.component';
import { DialogShareToGroupComponent } from '../dialog-share-to-group/dialog-share-to-group.component';
import { Component, OnInit, TemplateRef, Type } from '@angular/core';
import { Router } from '@angular/router';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';

@Component({
  selector: 'nk-shared-dialog-challenge-show-winner',
  templateUrl: './dialog-challenge-show-winner.component.html',
  styleUrls: ['./dialog-challenge-show-winner.component.scss'],
})
export class DialogChallengeShowWinnerComponent implements OnInit {
  isLoading = true;

  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  yourChallenges = false;

  openForJob = false;
  challenges = false;
  scores = [
    {
      libelle: 'Injection des dépendances',
      note: 6,
      total: 10,
    },
    {
      libelle: 'Gestion des API',
      note: 7,
      total: 20,
    },
    {
      libelle: 'Création des modèle',
      note: 11,
      total: 15,
    },
    {
      libelle: 'Refactoring',
      note: 8,
      total: 12,
    },
    {
      libelle: 'Directives',
      note: 18,
      total: 10,
    },
    {
      libelle: 'POO',
      note: 12,
      total: 20,
    },
    {
      libelle: 'Appels asynchrones',
      note: 15,
      total: 20,
    },
    {
      libelle: 'Niveau de language',
      note: 22,
      total: 30,
    },
  ];
  otherScores = [
    {
      challengeName: 'ReactJS skills',
      score: 7.3,
      jauge: 47,
      skills: [
        {
          libelle: 'Injection des dépendances',
          note: 6,
          total: 10,
        },
        {
          libelle: 'Gestion des API',
          note: 7,
          total: 20,
        },
        {
          libelle: 'Création des modèle',
          note: 11,
          total: 15,
        },
        {
          libelle: 'Refactoring',
          note: 8,
          total: 12,
        },
        {
          libelle: 'Directives',
          note: 18,
          total: 10,
        },
        {
          libelle: 'POO',
          note: 12,
          total: 20,
        },
        {
          libelle: 'Appels asynchrones',
          note: 15,
          total: 20,
        },
        {
          libelle: 'Niveau de language',
          note: 22,
          total: 30,
        },
      ],
    },
    {
      challengeName: 'AngularJS skills',
      score: 9.12,
      jauge: 17,
      skills: [
        {
          libelle: 'Injection des dépendances',
          note: 6,
          total: 10,
        },
        {
          libelle: 'Gestion des API',
          note: 7,
          total: 20,
        },
        {
          libelle: 'Création des modèle',
          note: 11,
          total: 15,
        },
        {
          libelle: 'Refactoring',
          note: 8,
          total: 12,
        },
        {
          libelle: 'Directives',
          note: 18,
          total: 10,
        },
        {
          libelle: 'POO',
          note: 12,
          total: 20,
        },
        {
          libelle: 'Appels asynchrones',
          note: 15,
          total: 20,
        },
        {
          libelle: 'Niveau de language',
          note: 22,
          total: 30,
        },
      ],
    },
    {
      challengeName: '#C',
      score: 7.4,
      jauge: 27,
      skills: [
        {
          libelle: 'Injection des dépendances',
          note: 6,
          total: 10,
        },
        {
          libelle: 'Gestion des API',
          note: 7,
          total: 20,
        },
        {
          libelle: 'Création des modèle',
          note: 11,
          total: 15,
        },
        {
          libelle: 'Refactoring',
          note: 8,
          total: 12,
        },
        {
          libelle: 'Directives',
          note: 18,
          total: 10,
        },
        {
          libelle: 'POO',
          note: 12,
          total: 20,
        },
        {
          libelle: 'Appels asynchrones',
          note: 15,
          total: 20,
        },
        {
          libelle: 'Niveau de language',
          note: 22,
          total: 30,
        },
      ],
    },
    {
      challengeName: 'Community Management',
      score: 6.9,
      jauge: 67,
      skills: [
        {
          libelle: 'Injection des dépendances',
          note: 6,
          total: 10,
        },
        {
          libelle: 'Gestion des API',
          note: 7,
          total: 20,
        },
        {
          libelle: 'Création des modèle',
          note: 11,
          total: 15,
        },
        {
          libelle: 'Refactoring',
          note: 8,
          total: 12,
        },
        {
          libelle: 'Directives',
          note: 18,
          total: 10,
        },
        {
          libelle: 'POO',
          note: 12,
          total: 20,
        },
        {
          libelle: 'Appels asynchrones',
          note: 15,
          total: 20,
        },
        {
          libelle: 'Niveau de language',
          note: 22,
          total: 30,
        },
      ],
    },
    {
      challengeName: 'Intégration Web',
      score: 6.9,
      jauge: 67,
      skills: [
        {
          libelle: 'Injection des dépendances',
          note: 6,
          total: 10,
        },
        {
          libelle: 'Gestion des API',
          note: 7,
          total: 20,
        },
        {
          libelle: 'Création des modèle',
          note: 11,
          total: 15,
        },
        {
          libelle: 'Refactoring',
          note: 8,
          total: 12,
        },
        {
          libelle: 'Directives',
          note: 18,
          total: 10,
        },
        {
          libelle: 'POO',
          note: 12,
          total: 20,
        },
        {
          libelle: 'Appels asynchrones',
          note: 15,
          total: 20,
        },
        {
          libelle: 'Niveau de language',
          note: 22,
          total: 30,
        },
      ],
    },
    {
      challengeName: 'Intégration Web',
      score: 6.9,
      jauge: 67,
      skills: [
        {
          libelle: 'Injection des dépendances',
          note: 6,
          total: 10,
        },
        {
          libelle: 'Gestion des API',
          note: 7,
          total: 20,
        },
        {
          libelle: 'Création des modèle',
          note: 11,
          total: 15,
        },
        {
          libelle: 'Refactoring',
          note: 8,
          total: 12,
        },
        {
          libelle: 'Directives',
          note: 18,
          total: 10,
        },
        {
          libelle: 'POO',
          note: 12,
          total: 20,
        },
        {
          libelle: 'Appels asynchrones',
          note: 15,
          total: 20,
        },
        {
          libelle: 'Niveau de language',
          note: 22,
          total: 30,
        },
      ],
    },
  ];

  constructor(
    private router: Router,
    public ref: DialogRef,
    private modalService: DialogService
  ) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  shareToGroup(): void {
    const ref = this.modalService.open(DialogShareToGroupComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Partager dans un groupe`,
        size: DialogSizeEnum.small,
      },
    });
  }

  shareToUser(): void {
    const ref = this.modalService.open(DialogShareToUserComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Partager à un utilisateur`,
        size: DialogSizeEnum.small,
      },
    });
  }

  shareToEmail(): void {
    const ref = this.modalService.open(DialogShareToEmailComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Modifier la photo de profil`,
        size: DialogSizeEnum.small,
      },
    });
  }

  goToProfile(e: any) {
    this.router.navigate(['/']);
  }

  goToFollowingPersons(e: any) {
    this.router.navigate(['/request/following']);
  }

  goToMyFollowers(e: any) {
    this.router.navigate(['/request/followers']);
  }

  showChallenges() {
    //this.challenges = !this.challenges;
    this.router.navigate(['/challenge/challenge-stats']);
    this.ref.close();
  }

  close() {
    this.ref.close();
  }

  link(url: string) {
    window.open(url, '_blank');
  }
}
