import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nk-shared-dialog-status-paiement',
  templateUrl: './dialog-status-paiement.component.html',
  styleUrls: ['./dialog-status-paiement.component.scss']
})
export class DialogStatusPaiementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
