<div class="network-accordion">
  <div
    class="title d-flex align-items-center justify-content-between"
    (click)="toggle.emit()"
  >
    <div class="display-3">{{ title }}</div>
    <datnek-ds-svg-icon
      [icon]="iconEnum.outlinechevronDown"
      *ngIf="!opened"
      class=""
    ></datnek-ds-svg-icon>
    <datnek-ds-svg-icon
      [icon]="iconEnum.outlinechevronUp"
      *ngIf="opened"
      class=""
    ></datnek-ds-svg-icon>
  </div>
  <div class="body" [ngClass]="{ hidden: !opened }">
    <ng-content></ng-content>
  </div>
  <div></div>
</div>
