<div class="container px-xl-4 pt-3 pt-lg-4">
  <section class="datnek-ds-content grid grid--gap16">
    <div
      class="
        d-none d-lg-block
        grid__col_12 grid__col_sm12 grid__col_md4 grid__col_lg3
      "
    >
      <div class="sticky-box">
        <div class="sticky-item">
          <nk-shared-card-user-box
            *ngIf="currentUser && currentResume"
            [resumeTitle]="currentResume.Title"
            [name]="getName()"
            [userName]="currentUser.Username"
            [status]="avatarEnum.person"
          ></nk-shared-card-user-box>
          <nk-shared-invitation-box
            titleCard="Invitations"
          ></nk-shared-invitation-box>
          <nk-shared-suggestion-box
            titleCard="Suggestion"
            [status]="avatarEnum.person"
          ></nk-shared-suggestion-box>
        </div>
      </div>
    </div>

    <div class="grid__col_12 grid__col_sm12 grid__col_md8 grid__col_lg6">
      <nk-shared-card-new-publication-box
        [status]="avatarEnum.person"
      ></nk-shared-card-new-publication-box>
      <nk-shared-card-challenge-box
        [challengeData]="challengeData"
        [challengeEnum]="challengeEnum.future"
        [fullChallenges]="true"
        [acceptedChallenges]="false"
        [listChallenges]="true"
      ></nk-shared-card-challenge-box>
      <nk-shared-card-challenge-box
        [challengeData]="challengeData"
        [challengeEnum]="challengeEnum.inProgress"
        [fullChallenges]="false"
        [acceptedChallenges]="true"
        [listChallenges]="true"
      ></nk-shared-card-challenge-box>
      <!--<nk-shared-challenge-winners-box></nk-shared-challenge-winners-box>-->
      <nk-shared-challenge-battle-box></nk-shared-challenge-battle-box>
      <network-card-publication-box
        publicationType="challenge"
        [status]="avatarEnum.person"
      ></network-card-publication-box>
      <network-card-publication-box
        [status]="avatarEnum.person"
      ></network-card-publication-box>
      <nk-shared-notification-blox
        title="Se souvenir du mot de passe ?"
        description="Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. "
      ></nk-shared-notification-blox>
      <network-card-publication-box
        publicationType="post"
        [status]="avatarEnum.person"
      ></network-card-publication-box>
      <network-card-publication-box
        [init]="dataImagesGallery"
        publicationType="images"
        [status]="avatarEnum.person"
      ></network-card-publication-box>
      <network-card-publication-box
        [videoData]="dataVideosGallery"
        publicationType="video"
        [status]="avatarEnum.person"
      ></network-card-publication-box>
      <network-card-publication-box
        publicationType="article"
        [status]="avatarEnum.person"
      ></network-card-publication-box>
      <network-card-publication-box
        publicationType="event"
        [status]="avatarEnum.person"
      ></network-card-publication-box>
      <network-card-publication-box
        publicationType="group"
        [status]="avatarEnum.person"
      ></network-card-publication-box>
      <network-card-publication-box
        [documentData]="dataDocument"
        publicationType="document"
        [status]="avatarEnum.person"
      ></network-card-publication-box>
    </div>
    <div
      class="
        d-none d-xl-block
        grid__col_12
        d-none d-xl-block
        grid__col_sm12 grid__col_lg3
      "
    >
      <nk-shared-card-challenge-box
        [challengeData]="challengeData"
        [challengeEnum]="challengeEnum.finished"
        [fullChallenges]="true"
        [acceptedChallenges]="true"
        [isSidebar]="true"
        [listChallenges]="true"
      ></nk-shared-card-challenge-box>
      <!--<nk-shared-challenge-winners-sidebar></nk-shared-challenge-winners-sidebar>
      <nk-shared-challenge-battle-sidebar></nk-shared-challenge-battle-sidebar>-->
      <nk-shared-top-jobs></nk-shared-top-jobs>
      <nk-shared-suggestion-box
        titleCard="Entreprises à suivre"
        [status]="avatarEnum.society"
      ></nk-shared-suggestion-box>
    </div>
  </section>
</div>
