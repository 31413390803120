import { HttpClient } from '@angular/common/http';

import { SHARED_LOCALES } from './locales';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import {assetUrl} from "datnek-ds";
import {Inject, Injectable} from "@angular/core";
import {AppEnum} from "../../enum";


// AoT requires an exported function for factories
// tslint:disable-next-line:typedef
export function TranslateCustomHttpLoaderFactory(http: HttpClient, trans:  TranslateCustomHttpLoaderFactoryService) {
  // console.log('custom', assetUrl('locales/'), LOCALES);
   console.log('app local',trans.appLocal);
  let locales = SHARED_LOCALES;

  locales = [...locales, ...trans.appLocal];
  return new MultiTranslateHttpLoader(http, locales);
}


@Injectable({
  providedIn: 'root',
})
export class TranslateCustomHttpLoaderFactoryService {

  public constructor( @Inject(AppEnum.FactoryName)  public appLocal:  { prefix: string, suffix: string, }[]) {
  }
}
