<div class="datnek-ds-dialog-body dialog-view-participants">
  <div class="content-modal">
    <div class="member-list">
      <nk-shared-suggestion-list
        [status]="avatarEnum.person"
        [userData]="dataUsers"
        [sendMessage]="true"
      ></nk-shared-suggestion-list>
    </div>
  </div>
</div>
