<div
  class="
    float-chat-button
    d-none d-md-flex
    justify-content-center
    align-items-center
    shadow-sm
  "
  (datnekdsClickOrTouch)="showStatus()"
>
  <datnek-ds-button-icon
    [size]="sizeEnum.large"
    [type]="colorEnum.unstyled"
    [Icon]="iconEnum.outlinechatbubbleLeft"
    [isLoading]="false"
  ></datnek-ds-button-icon>

  <!--IF HAVE NEW MESSAGE-->
  <span class="have-messages"></span>
</div>

<!--
<div class="d-flex flex-column d-md-none">
  <datnek-ds-svg-icon [Icon]="chat-alt" class="m-auto"></datnek-ds-svg-icon>
  <span class="m-auto">Messages</span>
</div>
<datnek-ds-button-icon
  [type]="colorEnum.ghost"
  [Icon]="iconEnum.outlinechatAlt2"
  [size]="sizeEnum.large"
  (datnekdsClickOrTouch)="showStatus()"
  class="d-md-none"
></datnek-ds-button-icon>-->

<!--CHAT LIST BOX-->

<div class="chat-list-box shadow-sm" [class.show]="status === true">
  <div
    class="
      header-chat-list
      d-flex
      align-items-center
      justify-content-between
      px-3
    "
  >
    <div triggers="click:blur">
      <datnek-ds-avatar
        [size]="sizeEnum.small"
        [border]="false"
        class="mr-2"
      ></datnek-ds-avatar>
      <span class="display-4" triggers="click:blur">Messagerie</span>
    </div>
    <div class="text-right">
      <datnek-ds-svg-icon-white
        [icon]="iconEnum.outlineWhitepencilAlt"
        class="mr-1 pointer"
        (datnekdsClickOrTouch)="goToChatMessage($event)"
      ></datnek-ds-svg-icon-white>
      <datnek-ds-svg-icon-white
        [icon]="iconEnum.outlineWhitechevronDown"
        (datnekdsClickOrTouch)="showStatus()"
        class="pointer"
      ></datnek-ds-svg-icon-white>
    </div>
  </div>
  <div class="searchbox p-2 bg-white">
    <datnek-ds-search-input
      placeholder="Rechercher un profil"
    ></datnek-ds-search-input >
  </div>
  <ng-container *ngIf="isLoading">
    <datnek-skeleton-suggestion-list
      *ngFor="let in of counter(8); let i = index"
    ></datnek-skeleton-suggestion-list>
  </ng-container>
  <p-scrollPanel
    [style]="{ width: '100%', height: '530px' }"
    *ngIf="!isLoading"
  >
    <div class="body-chat-list p-2">
      <nk-shared-user-chat-list
        [userData]="dataUsersChat"
        (openPopupEvent)="registerPopup($event)"
        class="d-none flex-column d-md-flex"
      ></nk-shared-user-chat-list>

      <nk-shared-user-chat-list
        [userData]="dataUsersChat"
        (onClick)="goToChatMessage($event)"
        class="d-flex flex-column d-md-none"
      ></nk-shared-user-chat-list>
    </div>
  </p-scrollPanel>
</div>

<nk-shared-chat-popup-box
  *ngFor="let item of popups"
  [userChat]="item"
  (closePopupEvent)="closePopup($event)"
></nk-shared-chat-popup-box>
