import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef
} from 'datnek-ds';

@Component({
  selector: 'nk-shared-comment-user-infos',
  templateUrl: './comment-user-infos.component.html',
  styleUrls: ['./comment-user-infos.component.scss'],
})
export class CommentUserInfosComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  follow = false;

  @Input()
  commentUserInfo = true;

  linkDescription = `Lorem ipsum dolor sit amet consectetur, adipisicing elit.
  Cum, cupiditate similique! Exercitationem modi dicta animi,
  voluptates repellat excepturi reiciendis`;
  descriptionPage = `Lorem ipsum dolor sit amet consectetur, adipisicing elit.
  Cum, cupiditate similique! Exercitationem modi dicta animi,
  voluptates repellat excepturi reiciendis`;

  constructor(private router: Router, public ref: DialogRef) {}

  ngOnInit(): void {}

  goToHome(e: any) {
    this.router.navigate(['/']);
  }

  goToChat(e: any) {
    this.router.navigate(['/chat/home']);
  }

  link(url: string) {
    window.open(url, '_blank');
  }
}
