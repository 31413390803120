<div class="datnek-ds-dialog-body dialog-add-category">
  <div class="content-header border-top border-bottom">
    <div class="search p-2 border-bottom">
      <datnek-ds-search-input
        placeholder="Rechercher dans les catégories"
      ></datnek-ds-search-input >
    </div>
    <datnek-ds-button
      label="Nouvelle catégorie"
      [leftIcon]="iconEnum.outlineplusCircle"
      [block]="true"
      [size]="sizeEnum.large"
      [type]="colorEnum.secondary"
      (datnekdsClickOrTouch)="addCategory()"
    ></datnek-ds-button>
    <div class="add-cat" *ngIf="addCat === true">
      <datnek-ds-input-text placeholder="Nouvelle catégorie"></datnek-ds-input-text>
      <div class="buttons gap-3 d-flex">
        <datnek-ds-button
          label="Ajouter"
          [type]="colorEnum.primary"
          [block]="true"
          class="datnek-ds-button"
        ></datnek-ds-button>
        <datnek-ds-button
          label="Annuler"
          [type]="colorEnum.secondary"
          (datnekdsClickOrTouch)="addCategory()"
          [block]="true"
          class="datnek-ds-button"
        ></datnek-ds-button>
      </div>
    </div>
  </div>
  <div class="content-modal">
    <datnek-ds-checkbox-group
      [items]="items"
      (onClick)="goToSavedMessages($event); ref.close()"
    ></datnek-ds-checkbox-group>
  </div>
</div>
<div class="datnek-ds-dialog-footer">
  <datnek-ds-button
    [label]="'Voir dans la liste'"
    [size]="sizeEnum.medium"
    [type]="colorEnum.primary"
    (datnekdsClickOrTouch)="ref.close(); goToSavedMessages($event)"
    *ngIf="nonOk"
    class="mr-3"
  ></datnek-ds-button>
  <datnek-ds-button
    [label]="'Ok'"
    [size]="sizeEnum.medium"
    [type]="colorEnum.primary"
    (datnekdsClickOrTouch)="showToast(saved); nonOk = true"
    *ngIf="!nonOk"
  ></datnek-ds-button>
  <datnek-ds-button
    [label]="'fermer'"
    [size]="sizeEnum.medium"
    [type]="colorEnum.secondary"
    (datnekdsClickOrTouch)="ref.close()"
    *ngIf="nonOk"
  ></datnek-ds-button>
</div>

<ng-template #saved let-close="close">
  L'publication a bien été sauvegardé dans la catégorie
  <a
    href="javascript:;"
    (datnekdsClickOrTouch)="goToSavedMessages($event); ref.close()"
    >Catégorie</a
  ></ng-template
>
