import { daysMap } from './constant';
import { CountryList } from './countryStore';
import {LocationHistoryClearPeriodEnum} from "datnek-sso";

export function cleanHtml(htmlText: string): string {
  return htmlText.replace(/<\/?[^>]+(>|$)/g, '');
}

export function getCountryNameFromCode(code: string): string {
  const countryItem = CountryList.find((elt) => elt.code === code);
  return `${countryItem?.name}`;
}

export function getDayValueFromCode(code: number | undefined): string {
  return daysMap?.find((elt) => elt.code  == code as number)?.value as string;
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

//  Check Extension
export function isImageExt(ext: string | any): boolean {
  return Boolean(['png', 'jpeg', 'jpg'].find((elt) => elt === ext));
}

export function isVideoExt(ext: string): boolean {
  return Boolean(['mp4'].find((elt) => elt === ext));
}

export function isPdfExt(ext: string): boolean {
  return Boolean(['pdf'].find((elt) => elt === ext));
}
// -- End -- Check Extension

// Delenscio Lang
export function showDelenscioLang(langCode: string, languagesList: any[]): string {
  const langObj = languagesList.find(
    (elt) => elt.code === langCode
  );
  return langObj ? langObj[langCode] : '';
}

// Delenscio Lang
export function getLocationHistoryDescription(
  lh: LocationHistoryClearPeriodEnum | null | undefined
): string {
  if (!lh) {
    return 'Ne pas Supprimer automatiquement';
  }
  lh = lh as LocationHistoryClearPeriodEnum;
  switch (lh) {
    case LocationHistoryClearPeriodEnum.MoreThanOneMonth:
      return "Supprimer automatiquement mes positions datant de plus d'un mois";

    case LocationHistoryClearPeriodEnum.MoreThanThreeMonths:
      return 'Supprimer automatiquement mes positions datant de plus de plus de 3 mois';

    case LocationHistoryClearPeriodEnum.MoreThanEighteenMonths:
      return 'Supprimer automatiquement mes positions datant de plus de plus de 18 mois';

    default:
      return '';
  }
}
