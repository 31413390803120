<div class="datnek-ds-dialog-body dialog-share-post-with-edit">
  <div class="content-modal">
    <div class="d-flex align-items-center p-3 border-bottom" style="gap: 16px">
      <div class="d-flex align-items-center flex-wrap">
        <span class="mr-3">Publier en tant que</span>
        <div class="d-flex align-items-center">
          <datnek-ds-image-text-dropdown
            [submenuData]="meSubmenu"
            [status]="avatarEnum.person"
            [label]="'John Doe'"
            class="mr-3"
          ></datnek-ds-image-text-dropdown>
          <datnek-ds-icon-text-dropdown
            [icon]="iconEnum.outlineglobe"
            [submenuData]="stateSubmenu"
            [label]="'public'"
          ></datnek-ds-icon-text-dropdown>
        </div>
      </div>
    </div>
    <div class="p-3">
      <div class="w-100">
        <div
          class="editor-icons"
          [ngClass]="Toolbar === true ? 'd-flex' : 'd-none'"
        >
          <img src="assets/icons/src/editor/editor-bold.svg" alt="" />
          <img src="assets/icons/src/editor/editor-italic.svg" alt="" />
          <img src="assets/icons/src/editor/editor-underline.svg" alt="" />
          <img src="assets/icons/src/editor/editor-barre.svg" alt="" />
          <img src="assets/icons/src/editor/editor-code.svg" alt="" />
          <img
            src="assets/icons/src/editor/editor-link.svg"
            alt=""
            (datnekdsClickOrTouch)="addLink()"
          />
        </div>
        <div class="text-content d-flex justify-content-end flex-wrap">
          <div
            class="text-format"
            contenteditable="true"
            aria-label="Que voulez-vous publier aujourd'hui ?"
            data-placeholder="Que voulez-vous publier aujourd'hui ?"
            #editTextDiv
          ></div>
        </div>
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinepencil"
          (datnekdsClickOrTouch)="showToolbar()"
          [size]="sizeEnum.medium1"
          [type]="colorEnum.unstyled"
          class="icon-edit"
        ></datnek-ds-button-icon>
      </div>

      <network-card-publication-box
        [shared]="true"
        [status]="avatarEnum.person"
      ></network-card-publication-box>
    </div>
  </div>
  <div class="datnek-ds-dialog-footer">
    <datnek-ds-button
      [label]="'Publier'"
      [size]="sizeEnum.large"
      [type]="colorEnum.primary"
      (datnekdsClickOrTouch)="dialogRef.close()"
    ></datnek-ds-button>
  </div>
</div>
