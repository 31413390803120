<div class="container">
  <div class="">
    <div class="row no-gutters single-article">
      <div class="col-lg-8 col-12 col-md-12 px-0 px-lg-3">
        <section class="datnek-ds-card" *ngIf="!isLoading">
          <div
            class="
              header
              d-flex
              justify-content-between
              align-items-center align-items-md-center
              p-3
            "
          >
            <div class="display-0 mb-0">Le titre de l'publication</div>
            <div class="d-flex">
              <datnek-ds-button-icon
                [type]="colorEnum.ghost"
                [Icon]="iconEnum.outlinepencil"
                [size]="sizeEnum.large"
                [size]="sizeEnum.medium"
                [round]="true"
                class="d-none d-md-block"
                (datnekdsClickOrTouch)="goToEditArticle($event)"
              ></datnek-ds-button-icon>
              <datnek-ds-button-icon
                [type]="colorEnum.ghost"
                [Icon]="iconEnum.outlinetrash"
                [size]="sizeEnum.large"
                [size]="sizeEnum.medium"
                [round]="true"
                class="d-none d-md-block"
                (datnekdsClickOrTouch)="deleteArticle()"
              ></datnek-ds-button-icon>

              <button
                (datnekdsClickOrTouch)="openDropdown(actionsarticle, target6)"
                #target6
              >
                <datnek-ds-button-icon
                  [Icon]="iconEnum.outlinedotsVertical"
                  [type]="colorEnum.ghost"
                  [size]="sizeEnum.medium"
                  [round]="true"
                ></datnek-ds-button-icon>
              </button>
            </div>
          </div>

          <div class="metadata p-3 d-flex flex-wrap justify-content-between">
            <div class="left gap-3 d-flex flex-wrap mb-3 mb-md-0">
              <div class="d-flex align-items-center">
                <datnek-ds-svg-icon
                  [icon]="iconEnum.outlinecalendar"
                  class="mr-1"
                ></datnek-ds-svg-icon>
                12 février 2023
              </div>
              <div class="d-flex align-items-center">
                <datnek-ds-svg-icon
                  [icon]="iconEnum.outlineclock"
                  class="mr-1"
                ></datnek-ds-svg-icon>
                5mn
              </div>
              <div class="d-flex align-items-center">
                <datnek-ds-svg-icon
                  [icon]="iconEnum.outlineeye"
                  class="mr-1"
                ></datnek-ds-svg-icon>
                124
              </div>
            </div>
            <div class="right gap-3 d-flex justify-content-end">
              <span class="d-flex align-items-center categories flex-wrap">
                <span class="mr-3">Catégories: </span>
                <datnek-ds-link
                  href="javascript:;"
                  (datnekdsClickOrTouch)="goToSavedMessages($event)"
                  label="Catégorie 1"
                ></datnek-ds-link
                >,
                <datnek-ds-link
                  href="javascript:;"
                  (datnekdsClickOrTouch)="goToSavedMessages($event)"
                  class="ml-2"
                  label="Catégorie 2"
                ></datnek-ds-link>
              </span>
            </div>
          </div>

          <div
            class="
              nk-shared-user-infos-small
              d-flex
              align-items-center
              p-3
              border-top border-bottom
              mb-3
            "
          >
            <datnek-ds-avatar
              [size]="sizeEnum.medium1"
              class="mr-3"
            ></datnek-ds-avatar>

            <div
              class="infos pointer"
              (datnekdsClickOrTouch)="goToHome($event)"
            >
              <div class="name d-flex flex-column">
                <div class="d-flex">
                  <div class="display-2 mr-2">
                    {{ name }}
                  </div>
                  <img src="assets/icons/src/check-color.png" alt="" />
                </div>
                <span>{{ professionalTitle }} à {{ society }}</span>
                <span id="relations" class="display-8">
                  {{ relations }} relations en commun
                </span>
              </div>
            </div>
            <datnek-ds-button-icon
              [type]="colorEnum.primary"
              [size]="sizeEnum.medium"
              [Icon]="iconEnum.outlineWhiteuserAdd"
              (datnekdsClickOrTouch)="isFollowed()"
              *ngIf="follow === false"
            ></datnek-ds-button-icon>
            <datnek-ds-button-icon
              [type]="colorEnum.secondary"
              [size]="sizeEnum.medium"
              [Icon]="iconEnum.outlinecheck"
              (datnekdsClickOrTouch)="isFollowed()"
              *ngIf="follow === true"
            ></datnek-ds-button-icon>
            <div
              (datnekdsClickOrTouch)="openDropdown(actionsuser, target5)"
              #target5
              class="ml-3"
            >
              <datnek-ds-button-icon
                [type]="colorEnum.ghost"
                [size]="sizeEnum.medium"
                [Icon]="iconEnum.outlinedotsVertical"
                [round]="true"
              ></datnek-ds-button-icon>
            </div>
          </div>

          <div class="position-relative z-100 d-flex px-0 px-md-3">
            <div class="col-12 col-md-11 pl-md-0">
              <div class="img mb-3 p-md-3">
                <img
                  src="assets/Img/AdobeStock_104857309-1140x650.jpg"
                  alt=""
                  class="img-fluid"
                />
              </div>

              <div class="content-text">
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Mollitia incidunt, libero cum corporis consequatur, sunt
                  tempore nihil veniam eligendi at inventore in, illo quam
                  repellat sit maxime perspiciatis impedit exercitationem. Nihil
                  veniam eligendi at inventore in, illo quam repellat sit maxime
                  perspiciatis impedit exercitationem.
                </p>
                <p>
                  Mollitia incidunt, libero cum corporis consequatur, sunt
                  tempore nihil veniam eligendi at inventore in, illo quam
                  repellat sit maxime perspiciatis impedit exercitationem.Lorem
                  ipsum dolor sit amet consectetur, adipisicing elit. Mollitia
                  incidunt, libero cum corporis consequatur, sunt tempore nihil
                  veniam eligendi at inventore in, illo quam repellat sit maxime
                  perspiciatis impedit exercitationem. Nihil veniam eligendi at
                  inventore in, illo quam repellat sit maxime perspiciatis
                  impedit exercitationem.
                </p>

                <datnek-ds-adaptative-image-gallery></datnek-ds-adaptative-image-gallery>

                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Mollitia incidunt, libero cum corporis consequatur, sunt
                  tempore nihil veniam eligendi at inventore in, illo quam
                  repellat sit maxime perspiciatis impedit exercitationem. Nihil
                  veniam eligendi at inventore in, illo quam repellat sit maxime
                  perspiciatis impedit exercitationem.
                </p>
              </div>
            </div>
            <div class="col-md-1">
              <div class="actions d-none d-md-flex flex-column">
                <div
                  class="like-btn"
                  (datnekdsClickOrTouch)="
                    openDropdown(likesicons, target1); showLikes()
                  "
                  #target1
                >
                  <datnek-ds-button-icon
                    [Icon]="iconEnum.outlineheart"
                    [type]="colorEnum.secondary"
                    [size]="sizeEnum.large"
                    class="mb-3 d-none d-lg-block"
                  ></datnek-ds-button-icon>
                  <datnek-ds-button-icon
                    [Icon]="iconEnum.outlineheart"
                    [type]="colorEnum.secondary"
                    [size]="sizeEnum.medium"
                    class="mb-3 d-lg-none"
                  ></datnek-ds-button-icon>
                  <!--<nk-shared-likes-icons-popbox
                    [likes]="likes"
                    [position]="positionEnum.top"
                  ></nk-shared-likes-icons-popbox>-->
                </div>

                <!--<datnek-ds-button-icon
                  [Icon]="iconEnum.outlinechat"
                  [type]="colorEnum.secondary"
                  [size]="sizeEnum.large"
                  class="mb-3 d-none d-lg-block"
                ></datnek-ds-button-icon>-->
                <datnek-ds-button-icon
                  [Icon]="iconEnum.outlinechat"
                  [type]="colorEnum.secondary"
                  [size]="sizeEnum.large"
                  class="mb-3 d-none d-lg-block"
                  (datnekdsClickOrTouch)="scrollPoint3(); onAddComment()"
                ></datnek-ds-button-icon>
                <datnek-ds-button-icon
                  [Icon]="iconEnum.outlinechat"
                  [type]="colorEnum.secondary"
                  [size]="sizeEnum.medium"
                  class="mb-3 d-lg-none"
                  (datnekdsClickOrTouch)="scrollPoint3(); onAddComment()"
                ></datnek-ds-button-icon>
                <div class="share-post">
                  <div
                    class="pointer"
                    (datnekdsClickOrTouch)="openDropdown(share, target4)"
                    #target4
                  >
                    <datnek-ds-button-icon
                      [size]="sizeEnum.large"
                      [type]="colorEnum.secondary"
                      [Icon]="iconEnum.outlinereplyRight"
                      class="mb-3 d-none d-lg-block"
                    ></datnek-ds-button-icon>
                    <datnek-ds-button-icon
                      [size]="sizeEnum.medium"
                      [type]="colorEnum.secondary"
                      [Icon]="iconEnum.outlinereplyRight"
                      class="mb-3 d-lg-none"
                    ></datnek-ds-button-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="px-0 px-md-3">
            <div class="col-12 px-md-0">
              <!--LIKES-->
              <div
                class="
                  datnek-ds-card-likes
                  d-flex
                  justify-content-between
                  p-md-3
                  mb-3 mb-md-0
                "
              >
                <div class="left d-flex align-items-center">
                  <div class="d-flex">
                    <img
                      src="assets/icons/src/solid/color/heart-active-colonr.svg"
                      alt=""
                      class="mr-2"
                    />
                    <span class="display-6 stat-post pointer">
                      12k
                      <div class="drop-stat-post datnek-ds-card">
                        <ul>
                          <ng-container
                            *ngFor="
                              let item of haveReaction | slice: 1:5;
                              let i = index
                            "
                          >
                            <li>{{ item }}</li>
                          </ng-container>
                          <li>+ {{ rest }} 13 autres personnes</li>
                        </ul>
                      </div>
                    </span>
                  </div>
                  <div class="d-flex">
                    <img
                      src="assets/icons/src/solid/color/comment-active-color.svg"
                      alt=""
                      class="mr-2"
                    />
                    <span class="display-6">234</span>
                  </div>
                  <div class="d-flex">
                    <img
                      src="assets/icons/src/solid/color/share-active-color.svg"
                      alt=""
                      class="mr-2"
                    />
                    <span class="display-6">1k</span>
                  </div>
                </div>
                <span
                  class="text-right display-6 pointer"
                  (datnekdsClickOrTouch)="onShowListComments()"
                  >45 commentaires</span
                >
              </div>

              <!--LIKES BUTTONS-->
              <div
                class="
                  datnek-ds-card-likes-buttons
                  d-flex
                  align-items-center
                  pb-3
                "
                *ngIf="shared === false"
                #comment
              >
                <datnek-ds-avatar-dropdown></datnek-ds-avatar-dropdown>
                <div class="buttons-icons">
                  <div
                    class="like-btn"
                    (datnekdsClickOrTouch)="
                      openDropdown(likesicons, target2); showLikes()
                    "
                    #target2
                  >
                    <datnek-ds-button
                      [label]="'Likes'"
                      [size]="sizeEnum.large"
                      [block]="true"
                      [type]="colorEnum.secondary"
                      [leftIcon]="iconEnum.outlineheart"
                      [isMobile]="true"
                    ></datnek-ds-button>
                  </div>

                  <datnek-ds-button
                    [label]="'Commentaires'"
                    [size]="sizeEnum.large"
                    [block]="true"
                    [type]="colorEnum.secondary"
                    [leftIcon]="iconEnum.outlinechat"
                    (datnekdsClickOrTouch)="onAddComment()"
                    [isMobile]="true"
                  ></datnek-ds-button>

                  <button
                    (datnekdsClickOrTouch)="openDropdown(share, target3)"
                    #target3
                  >
                    <datnek-ds-button
                      [label]="'Partages'"
                      [size]="sizeEnum.large"
                      [block]="true"
                      [type]="colorEnum.secondary"
                      [leftIcon]="iconEnum.outlinereplyRight"
                      [isMobile]="true"
                    ></datnek-ds-button>
                  </button>
                </div>
              </div>

              <!--COMMENTS ADD-->
              <div
                class="add-comments"
                [ngClass]="addComment === true ? 'd-block pb-3' : 'd-none'"
              >
                <nk-shared-comment-add
                  *ngIf="addComment === true"
                  (onClick)="clickListener($event)"
                ></nk-shared-comment-add>
              </div>

              <!--COMMENTS LIST-->
              <div class="list-comments mt-3" *ngIf="showListComments">
                <div
                  class="
                    filter
                    align-items-center
                    d-flex
                    justify-content-end
                    mb-3
                  "
                >
                  <span class="mr-3">Filtrer par </span>
                  <datnek-ds-simple-text-dropdown
                    [submenuData]="subMenu"
                    [label]="label"
                  ></datnek-ds-simple-text-dropdown>
                </div>

                <div class="comments-items">
                  <nk-shared-comment-pushed
                    [commentData]="dataComments"
                  ></nk-shared-comment-pushed>
                  <nk-shared-comment-pushed
                    [commentData]="dataComments"
                    *ngIf="showMore === true"
                    [@moreSuggestion]="showMore"
                  ></nk-shared-comment-pushed>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <datnek-ds-button
                    [type]="colorEnum.link"
                    label="Voir plus de commentaires"
                    *ngIf="showMore === true"
                    (datnekdsClickOrTouch)="onShowMore()"
                  ></datnek-ds-button>
                  <datnek-ds-button
                    [type]="colorEnum.link"
                    label="Voir moins de commentaires"
                    *ngIf="showMore === true"
                    (datnekdsClickOrTouch)="onShowMore()"
                  ></datnek-ds-button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <datnek-skeleton-single-article
          *ngIf="isLoading"
        ></datnek-skeleton-single-article>

        <!--SUGGESTION AUTHOR ARTICLES-->
        <nk-shared-suggestion-box
          titleCard="Plus d'articles de Bassahak Jean Marc"
          [status]="avatarEnum.person"
          [suggestionType]="suggestionEnum.article"
          [isFull]="true"
          [isAuthor]="true"
          class=""
        ></nk-shared-suggestion-box>
      </div>
      <div class="col-lg-4">
        <nk-shared-suggestion-box
          titleCard="Suggestion d'articles"
          [status]="avatarEnum.person"
          [suggestionType]="suggestionEnum.article"
          [isSidebar]="true"
          [isFull]="false"
          class="sticky-sidebar d-none d-md-block"
        ></nk-shared-suggestion-box>
      </div>
    </div>
  </div>
</div>

<!--MOBILE MENU-->
<nk-shared-menu-mobile></nk-shared-menu-mobile>

<nk-shared-chat-list-box></nk-shared-chat-list-box>

<!-- TOASTS MESSAGES -->
<ng-template #rp let-close="close">
  <p class="mr-2">
    L'publication a été partagé
    <a href="javascript:;" (datnekdsClickOrTouch)="goToHome($event)">Voir</a>
  </p>
</ng-template>
<ng-template #sourdine let-close="close">
  Bige Beşikci Yaman a été mis en sourdine. Vous ne verrez plus ses articles sur
  votre page
  <a href="javascript:;" (datnekdsClickOrTouch)="close()"
    >Annuler</a
  ></ng-template
>

<ng-template #reactivate let-close="close">
  Bige Beşikci Yaman a été réactivé. Vous purrez voir de nouveau ses articles
  sur votre page
  <a href="javascript:;" (datnekdsClickOrTouch)="close()"
    >Annuler</a
  ></ng-template
>

<ng-template #blocked let-close="close">
  L'utilisateur Bige Beşikci Yaman a été bloqué</ng-template
>

<ng-template #unblocked let-close="close">
  Vous avez annulé le blcage de l'utilisateur Bige Beşikci Yaman</ng-template
>

<ng-template #unsaved let-close="close">
  L'publication a été retiré de la catégorie</ng-template
>

<ng-template #signal let-close="close">
  <div class="datnek-ds-dialog-body">
    <div class="content-modal">
      <div class="notif-box p-3">
        <p class="">
          Cet utilisateur sera signalé à notre équipe de soutien aux fins
          d’examen.
        </p>
        <div class="py-3">
          <datnek-ds-checkbox-group
            label="Bloquez également cet utilisateur"
            [markLeft]="true"
          ></datnek-ds-checkbox-group>
        </div>
      </div>

      <div class="datnek-ds-dialog-footer">
        <datnek-ds-button
          [label]="'Annuler'"
          [size]="sizeEnum.medium"
          [type]="colorEnum.secondary"
          class="mr-3"
          (datnekdsClickOrTouch)="close(); showToast(reactivate)"
        ></datnek-ds-button>
        <datnek-ds-button
          [label]="'Signaler'"
          [size]="sizeEnum.medium"
          [type]="colorEnum.primary"
          (datnekdsClickOrTouch)="close()"
        ></datnek-ds-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #block let-close="close">
  <div class="datnek-ds-dialog-body">
    <div class="content-modal">
      <div class="notif-box p-3">
        <p class="">Il ne pourra plus vous suivre ou voir votre contenu.</p>
        <a
          href="javascript:;"
          (datnekdsClickOrTouch)="blankPage($event); close()"
          >En savoir plus sur le blocage</a
        >
      </div>

      <div class="datnek-ds-dialog-footer">
        <datnek-ds-button
          [label]="'Annuler'"
          [size]="sizeEnum.medium"
          [type]="colorEnum.secondary"
          class="mr-3"
          (datnekdsClickOrTouch)="showToast(unblocked); close()"
        ></datnek-ds-button>
        <datnek-ds-button
          [label]="'Bloquer'"
          [size]="sizeEnum.medium"
          [type]="colorEnum.danger"
          (datnekdsClickOrTouch)="
            showToast(blocked); close(); gotoListArticles($event)
          "
        ></datnek-ds-button>
      </div>
    </div>
  </div>
</ng-template>

<!-- DROPDOWNS -->
<ng-template #likesicons let-close="close">
  <div class="like-icons-drop">
    <nk-shared-likes-icons-popbox [likes]="likes"></nk-shared-likes-icons-popbox>
  </div>
</ng-template>

<ng-template #actionsuser let-close="close">
  <div class="datnek-ds-card dropdown-card user-action">
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="blockUser(block); close()"
    >
      Bloquer cet auteur
    </button>
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="showToast(sourdine); onMute(); close()"
      *ngIf="!mute"
    >
      Mettre en sourdine cet auteur
    </button>
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="showToast(reactivate); onMute(); close()"
      *ngIf="mute"
    >
      Réactiver cet auteur
    </button>
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="signalUser(signal); close()"
    >
      Signaler cet utilisateur
    </button>
  </div>
</ng-template>

<ng-template #actionsarticle let-close="close">
  <div class="datnek-ds-card dropdown-card user-action">
    <button
      class="dropdown-item d-md-none"
      (datnekdsClickOrTouch)="goToEditArticle($event)"
    >
      <datnek-ds-svg-icon [icon]="iconEnum.outlinepencil"></datnek-ds-svg-icon>
      Modifier l'publication
    </button>
    <button
      class="dropdown-item d-md-none"
      (datnekdsClickOrTouch)="deleteArticle()"
    >
      <datnek-ds-svg-icon [icon]="iconEnum.outlinetrash"></datnek-ds-svg-icon>
      Supprimer l'publication
    </button>
    <button class="dropdown-item" (datnekdsClickOrTouch)="addSignalization()">
      <datnek-ds-svg-icon [icon]="iconEnum.outlineflag"></datnek-ds-svg-icon>
      Signaler l'publication
    </button>
    <button class="dropdown-item" (datnekdsClickOrTouch)="addToCategory()">
      <datnek-ds-svg-icon [icon]="iconEnum.outlinebookmark"></datnek-ds-svg-icon>
      Enregistrer l'publication
    </button>
  </div>
</ng-template>

<ng-template #share let-close="close">
  <div class="datnek-ds-card dropdown-card card-article-share">
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlinereplyRight"
      label="Republier le post"
      description="Publier ce post tel quel sans changement."
      (datnekdsClickOrTouch)="showToast(rp); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.pencilAlt"
      label="Partager avec modification"
      description="Publier ce posten ajoutant votre propre commentaire "
      (datnekdsClickOrTouch)="sharePostWithEdit(); close()"
    ></datnek-ds-icon-submenu>
  </div>
</ng-template>
