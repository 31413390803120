import { Injectable } from '@angular/core';
import { lyl, LyTheme2, StyleRenderer, ThemeVariables } from '@alyle/ui';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(private sRenderer: StyleRenderer, private theme: LyTheme2) {}

  getresponsiveStyle() {
    const styles = (theme: ThemeVariables) => {
      return {
        dialog: lyl`{
          @media ${[
            theme.breakpoints['Small'],
            theme.breakpoints['XSmall'],
          ].join()} {
            border-radius: 0
          }
        }`,
      };
    };
    // RESPONSIVE DIALOG
    return this.sRenderer.renderSheet(styles).dialog;
  }

  getFullScreenStyle(width: number = 100, height: number = 100) {
    const styles = {
      width: `${width}vw`,
      height: `${height}vw`,
      borderRadius: 0,
    };

    return this.theme.style(styles);
  }

  getConfig(width: number = 653) {
    return {
      width: [width, '100vw@XSmall@Small'],
      height: '100vh@XSmall@Small',
      maxWidth: '100vw@XSmall@Small',
      maxHeight: '100vh@XSmall@Small',
      containerClass: this.getresponsiveStyle(),
    };
  }

  getConfigFullScreen() {
    return {
      width: '100vw@XSmall@Small',
      height: '100vh@XSmall@Small',
      maxWidth: '100vw@XSmall@Small',
      maxHeight: '100vh@XSmall@Small',
      containerClass: this.getFullScreenStyle(),
    };
  }
}
