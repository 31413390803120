<div class="homepage">
  <section class="hero d-flex align-items-center p-3">
    <img
      [src]="'Img/homepage/flou1.png'|assetUrl"

      class="flou1 floating"
      style="animation-delay: 0.3s"
      alt=""
    />
    <img
      [src]="'Img/homepage/flou2.png'"

      class="flou2 floating"
      style="animation-delay: 0.6s"
      alt=""
    />
    <img
      [src]="'Img/homepage/flou3.png'|assetUrl"
      class="flou3 floating d-none d-md-block"
      style="animation-delay: 0.5s"
      alt=""
    />
    <div class="container">
      <div class="row no-gutters">
        <div class="col-md-5">
          <h1
            class="mb-4 hero-title text-center text-md-left"

          >
            Le réseau professsionnel qui convient à vos ambitions
          </h1>
          <div class="d-flex justify-content-center justify-content-md-start">
            <datnek-ds-button
              label="Rejoignez-nous"
              [size]="sizeEnum.large"
              [type]="colorEnum.primary"

              (datnekdsClickOrTouch)="register()"
            ></datnek-ds-button>
          </div>
        </div>
        <div class="col-md-7">
          <!--<img
            [src]="'Img/homepage/NetWork.png' | assetUrl"

            class="network floating-lg"
            alt=""
          />-->
          <div class="network">
            <div class="map_line">
              <img
                [src]="'Img/homepage/avatars/avatar-1.jpg'|assetUrl"
                class="rounded-circle a1"
              />
              <img
                [src]="'Img/homepage/avatars/avatar-2.jpg'|assetUrl"
                class="rounded-circle a2"
              />
              <img
                [src]="'Img/homepage/avatars/avatar-3.jpg'|assetUrl"
                class="rounded-circle a3"
              />
              <img
                [src]="'Img/homepage/avatars/avatar-4.jpg'|assetUrl"
                class="rounded-circle a4"
              />
              <img
                [src]="'Img/homepage/avatars/avatar-5.jpg'|assetUrl"
                class="rounded-circle a5"
              />
              <img
                [src]="'Img/homepage/avatars/avatar-6.jpg'|assetUrl"
                class="rounded-circle a6"
              />
              <img
                [src]="'Img/homepage/avatars/avatar-7.jpg'|assetUrl"
                class="rounded-circle a7"
              />
              <img
                [src]="'Img/homepage/avatars/avatar-8.jpg'|assetUrl"
                class="rounded-circle a8"
              />
            </div>

            <svg
              version="1.1"
              id="Calque_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 1039 430.2"
              style="enable-background: new 0 0 1039 430.2"
              xml:space="preserve"
            >
              <g>
                <image
                  style="overflow: visible"
                  width="1039"
                  height="318"
                  [attr.xlink:href]="'Img/homepage/map_line.png'|assetUrl"
                  transform="matrix(1 0 0 1 0 77.0353)"
                ></image>
              </g>
              <path
                class="st0 line_1"
                d="M0,430.2c0,0,82.4-381.4,524.3-381.4c450.5,0,514.7,381.4,514.7,381.4"
              />
              <path
                class="st1 line_2"
                d="M162.4,340.5c0,0,154.9-99.9,404.7-70.3c0,0,153.4,17.7,247,73.6"
              />
              <path
                class="st1 line_3"
                d="M379.3,84.7c0,0-25.3-20.8-69.4,11.1c-37.4,27.1-106.8,108.1-54.7,340.6"
              />
              <path
                class="st1 line_4"
                d="M167.7,284.1c0,0,47.6-169.7,286.7-183.3c0,0,87.5,11,116.3,165.2"
              />
              <path class="st1 line_5" d="M465,94c0,0,224.2-57.8,380.2,81.7" />
              <path
                class="st1 line_6"
                d="M162.4,325.4c0,0,200-216.1,513.4-160.2"
              />
              <path
                class="st1 line_7"
                d="M682.6,160.6c0,0,79.9-14.4,155.8,15.2"
              />
              <path
                class="st1 line_8"
                d="M774.4,232.3c0,0-13.6-29.2-105.4-76S454.5,92.2,454.5,92.2"
              />
              <path
                class="st1 line_9"
                d="M465,416.8C379.3,218,454.4,88,454.4,88s21.9-45.4,60.6-31.5"
              />
              <path
                class="st1 line_10"
                d="M158.9,207.7c0,0,115-115,295.5-129.6c0,0,243.9-32.7,385.9,80.6"
              />
              <path
                class="st1 line_11"
                d="M564,257.3c0,0,95.6-57.3,210.5-32.8"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="avantages">
    <div class="container">
      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="avantage">
            <img
              [src]="'Img/homepage/Profile.png'|assetUrl"
              alt=""

            />
            <h2 class="" >
              Rencontrez des profils exceptionels
            </h2>
          </div>
        </div>
        <div class="col-md-4">
          <div class="avantage">
            <img
              [src]="'Img/homepage/Group.png'|assetUrl"
              alt=""

            />
            <h2 class="" >
              Intégrez des groupes aux grandes opportunités
            </h2>
          </div>
        </div>
        <div class="col-md-4">
          <div class="avantage" >
            <img [src]="'Img/homepage/Trophee.png'|assetUrl" alt="" />
            <h2 class="">Challengez les meilleurs pour rester au top</h2>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="chat d-flex align-items-center">
    <div class="container d-flex">
      <div class="row no-gutters">
        <div class="col-md-5 d-flex align-items-center">
          <div class="infos">
            <img
              [src]="'Img/homepage/chat-alt-2.png'|assetUrl"
              class="icon-top-title"

              alt=""
            />
            <h2 class="mb-3" >
              Saisissez en direct de nouvelles opportunités
            </h2>
            <p >
              Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
              Curabitur aliquet quam id dui posuere blandit. Proin eget tortor
              risus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar
              a. Proin eget tortor risus. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </p>
            <datnek-ds-button
              label="Rejoignez-nous"
              [size]="sizeEnum.large"
              [type]="colorEnum.primary"

              (datnekdsClickOrTouch)="register()"
            ></datnek-ds-button>
          </div>
        </div>
        <div class="col-md-7 chatters">
          <img
            [src]="'Img/homepage/social_network.png'|assetUrl"
            class="users-chat floating-lg img-fluid"

            data-aos-duration="1000"
            alt=""
          />
        </div>
      </div>
    </div>
    <img
      [src]="'Img/homepage/trait-interrompu.png'|assetUrl"
      class="trait"
      alt=""

    />
    <img
      [src]="'Img/homepage/camera.png'|assetUrl"
      data-aos="bounce-in"
      class="camera"
      alt=""
    />
    <img
      [src]="'Img/homepage/chat.png'|assetUrl"
      data-aos="bounce-in"
      class="chat-icon"
      alt=""
    />
    <img
      [src]="'Img/homepage/chat-alt.png'|assetUrl"
      data-aos="bounce-in"
      class="chat-alt"
      alt=""
    />
    <img
      [src]="'Img/homepage/microphone.png'|assetUrl"
      data-aos="bounce-in"
      class="microphone"
      alt=""
    />
    <img
      [src]="'Img/homepage/video-camera.png'|assetUrl"
      data-aos="bounce-in"
      class="video-camera"
      alt=""
    />
    <img
      [src]="'Img/homepage/phone.png'|assetUrl"
      data-aos="bounce-in"
      class="phone d-none d-md-block"
      alt=""
    />
    <img
      [src]="'Img/homepage/photograph.png'|assetUrl"
      data-aos="bounce-in"
      class="photograph"
      alt=""
    />
  </section>

  <section class="network chat d-flex align-items-center p-3">
    <div class="container d-flex">
      <div class="row no-gutters flex-column-reverse flex-md-row align-content-center">
        <div class="col-md-7 d-flex align-items-center">
          <img
            [src]="'Img/homepage/Business%20team.png'|assetUrl"
            class="m-auto floating-lg img-fluid"

            alt=""
          />
        </div>
        <div class="col-md-5 d-flex align-items-center">
          <div class="infos">
            <img
              [src]="'Img/homepage/search.png'|assetUrl"
              class="icon-top-title"

              alt=""
            />
            <h2 class="mb-3" >
              Des milliers de profils qualifiés pour trouvez vos futurs
              collaborateurs
            </h2>
            <p >
              Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
              Curabitur aliquet quam id dui posuere blandit. Proin eget tortor
              risus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar
              a. Proin eget tortor risus. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </p>
            <datnek-ds-button
              label="Rejoignez-nous"
              [size]="sizeEnum.large"
              [type]="colorEnum.primary"

              (datnekdsClickOrTouch)="register()"
            ></datnek-ds-button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="call-4-action d-flex align-items-center p-3">
    <div class="container">
      <div class="row no-gutters">
        <div class="col-md-5">
          <h2 class="mb-3" >
            Trouvez des profils près de chez vous
          </h2>
          <p >
            Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
            Curabitur aliquet quam id dui posuere blandit. Proin eget tortor
            risus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
            Proin eget tortor risus. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit.
          </p>
          <datnek-ds-button
            label="Rejoignez-nous"
            [size]="sizeEnum.large"
            [type]="colorEnum.primary"

            (datnekdsClickOrTouch)="register()"
          ></datnek-ds-button>
        </div>
        <div class="col-md-7">
          <div class="profiles-map">
            <div class="point">
              <div class="point center"></div>
            </div>
            <div class="point1">
              <div class="point1 center"></div>
            </div>
            <div class="point2">
              <div class="point2 center"></div>
            </div>
            <div class="point3">
              <div class="point3 center"></div>
            </div>
            <img
              [src]="'Img/homepage/Worldconnect1.png'|assetUrl"
              class="network floating-lg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="challenge network chat align-items-center">
    <div class="container d-flex">
      <div class="row no-gutters">
        <div class="col-md-8 d-flex align-items-center">
          <img
            [src]="'Img/homepage/Winners.png'|assetUrl"
            class="m-auto floating-lg img-fluid winners"
            alt=""
          />
        </div>
        <div class="col-md-4 d-flex align-items-center">
          <div class="infos">
            <img
              [src]="'Img/homepage/Trophee.png'|assetUrl"
              class="icon-top-title"
              alt=""
            />
            <h2 class="mb-3">
              Positionnez votre profil au top grâce aux Challenges
            </h2>
            <p>
              Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
              Curabitur aliquet quam id dui posuere blandit. Proin eget tortor
              risus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar
              a. Proin eget tortor risus. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </p>
            <datnek-ds-button
              label="Rejoignez-nous"
              [size]="sizeEnum.large"
              [type]="colorEnum.primary"
              data-aos="fade-in"
              (datnekdsClickOrTouch)="register()"
            ></datnek-ds-button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--<section class="testimony">
    <div class="container">
      <div class="col-md-12">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="item m-auto">
              <div class="name">Bassahak Jean Marc</div>
              <div class="fonction">CEO de Digitaille</div>
              <p>
                Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
                Curabitur aliquet quam id dui posuere blandit. Proin eget tortor
                risus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar
                a. Proin eget tortor risus. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit.
              </p>
              <img src="assets/Img/homepage/user1.png" class="user" alt="" />
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="item m-auto">
              <div class="name">YANKAP Arnaud Ghislain</div>
              <div class="fonction">CEO de Delenscio</div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti
                dolores libero illum nulla eveniet sed iure fugiat magni, iste
                esse delectus commodi expedita ad error. Commodi iure similique
                ipsa nobis!
              </p>
              <img src="assets/Img/homepage/user2.png" class="user" alt="" />
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </section>-->

  <section class="top-footer">
    <h2 class="m-auto">
      Boostez vos opportunités maintenant en Rejoignant le réseau Delenscio
    </h2>
    <div class="d-flex justify-content-center mt-5">
      <datnek-ds-button
        label="Rejoignez-nous"
        [size]="sizeEnum.large"
        [type]="colorEnum.primary"
        (datnekdsClickOrTouch)="register()"
      ></datnek-ds-button>
    </div>

    <!--<img
      [src]="'Img/homepage/stock-photo-partnership-and-teamwork-with-businesspeople 1.jpg' | assetUrl"
      class="user-group"
      alt=""
    />-->
  </section>

  <del-shared-footer></del-shared-footer>
</div>
