<div class="mt-5"></div>
<h1>Testing</h1>

<h4>
  Cropper profile image (component display img false and personalise my button)
</h4>
<hr />
<button class="btn btn-sm btn-info" (datnekdsClickOrTouch)="onBrowser($event)">
  <i class="fas fa-image mr-1"></i>
  Select File (custorm button)
</button>
<datnek-ds-profile-cropper
  [key]="keyProfile"
  [custormBrowser]="custormBrowser"
  [cropped]="profile"
></datnek-ds-profile-cropper>
<div *ngIf="profile"><img [src]="profile" [width]="200" [height]="200" /></div>

<h4>Cropper profile image (component display img true)</h4>
<hr />
<datnek-ds-profile-cropper
  [showImg]="true"
  [key]="keyProfile1"
  [cropped]="profile1"
></datnek-ds-profile-cropper>

<h4>
  Cropper banner image (component display img false and personalise my button)
</h4>
<hr />
<button
  class="btn btn-sm btn-info"
  (datnekdsClickOrTouch)="onBrowserBackground($event)"
>
  <i class="fas fa-image mr-1"></i>
  Select background (custorm button)
</button>
<datnek-ds-banner-cropper
  [key]="keyBackground"
  [custormBrowser]="custormBrowserBackground"
  [cropped]="background"
></datnek-ds-banner-cropper>
<div *ngIf="background">
  <img [src]="background" [width]="1000" [height]="350" />
</div>

<h4>Cropper banner image (component display img true)</h4>
<hr />
<datnek-ds-banner-cropper
  [showImg]="true"
  [key]="keyBackground1"
  [cropped]="background1"
></datnek-ds-banner-cropper>

<h4>Demo transaltion</h4>
<hr />
<p>{{ "demo.greeting" | translate: { name: "Andreas" } }}</p>
<p>{{ "demo.text" | translate: { name: "Andreas" } }}</p>

<p [translate]="'demo.greeting'" [translateParams]="{ name: 'Andreas' }"></p>

<p translate [translateParams]="{ name: 'Andreas' }">demo.greeting</p>
<!--ckeditor [(ngModel)]="model" type="inline"></ckeditor-->

<button class="btn btn sm btn-outline-dark" (click)="onChangeLanguage('en')">
  En <i class="flag-icon flag-icon-us"></i>
</button>
<button class="btn btn sm btn-outline-dark" (click)="onChangeLanguage('fr')">
  Fr
</button>
<button class="btn btn sm btn-outline-dark" (click)="onChangeLanguage('nl')">
  nl
</button>

<hr class="my-3" />

<div class="datnek-ds-card p-3">
  <h5>Advanced</h5>
  <p-fileUpload
    name="demo[]"
    [customUpload]="true"
    (onUpload)="onUpload($event)"
    [multiple]="true"
    accept="image/*"
    [maxFileSize]="10000000"
  >
    <ng-template pTemplate="content">
      <ul *ngIf="uploadedFiles.length">
        <li *ngFor="let file of uploadedFiles">
          {{ file.url }} - {{ file.name }} - {{ file.size }} bytes
        </li>
      </ul>
    </ng-template>
  </p-fileUpload>
</div>

<hr class="my-3" />
<h4>Demo show img</h4>
<hr />
<div class="p-5 bg-dark">
  <img [src]="'Img/Professionnel_pattern_1-04.png' | assetUrl" />
</div>
