import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  SuggestionTypeEnum,
} from 'datnek-ds';

interface articles {
  imgSrc: string;
  title: string;
  username: string;
  description: string;
  categories: Array<string>;
}

@Component({
  selector: 'network-list-articles',
  templateUrl: './list-articles.component.html',
  styleUrls: ['./list-articles.component.scss'],
})
export class ListArticlesComponent implements OnInit {
  filters = false;

  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  suggestionEnum = SuggestionTypeEnum;

  showMore = false;

  articesData: articles[] = [
    {
      title: 'ASP.NET 6 Performance Tricks You Haven’t Heard Of',
      username: 'Yankep Arno',
      description:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero nobis temporibus harum est debitis. Unde aut ipsa quisquam ipsum.',
      imgSrc: 'assets/Img/AdobeStock_104857309-1140x650.jpg',
      categories: ['Technologies', 'Vente'],
    },
    {
      title: 'Inheritance Strategies in Entity Framework Core 7',
      username: 'Bassahak Jean Marc',
      description:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero nobis temporibus harum est debitis. Unde aut ipsa quisquam ipsum.',
      imgSrc: 'assets/Img/executives-joking-laughing-office.jpg',
      categories: ['Services'],
    },
    {
      title: 'Upgrade Your Existing Projects to .NET 7',
      username: 'Banick',
      description:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero nobis temporibus harum est debitis. Unde aut ipsa quisquam ipsum.',
      imgSrc: 'assets/Img/AdobeStock_104857309-1140x650.jpg',
      categories: ['Industrie', 'IA'],
    },
    {
      title: 'UI Framework Popularity — 2022',
      username: 'Alper Ebiçoğl',
      description:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero nobis temporibus harum est debitis. Unde aut ipsa quisquam ipsum.',
      imgSrc: 'assets/Img/executives-joking-laughing-office.jpg',
      categories: ['E-commerce', 'ONG'],
    },
  ];
  articesData1: articles[] = [
    {
      title: 'ASP.NET 6 Performance Tricks You Haven’t Heard Of',
      username: 'Yankep Arno',
      description:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero nobis temporibus harum est debitis. Unde aut ipsa quisquam ipsum.',
      imgSrc: 'assets/Img/AdobeStock_104857309-1140x650.jpg',
      categories: ['Technologies', 'Vente'],
    },
    {
      title: 'Inheritance Strategies in Entity Framework Core 7',
      username: 'Bassahak Jean Marc',
      description:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero nobis temporibus harum est debitis. Unde aut ipsa quisquam ipsum.',
      imgSrc: 'assets/Img/executives-joking-laughing-office.jpg',
      categories: ['Services'],
    },
    {
      title: 'Upgrade Your Existing Projects to .NET 7',
      username: 'Banick',
      description:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero nobis temporibus harum est debitis. Unde aut ipsa quisquam ipsum.',
      imgSrc: 'assets/Img/AdobeStock_104857309-1140x650.jpg',
      categories: ['Industrie', 'IA'],
    },
    {
      title: 'UI Framework Popularity — 2022',
      username: 'Alper Ebiçoğl',
      description:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero nobis temporibus harum est debitis. Unde aut ipsa quisquam ipsum.',
      imgSrc: 'assets/Img/executives-joking-laughing-office.jpg',
      categories: ['E-commerce', 'ONG'],
    },
  ];

  isLoading = false;

  iconShow = false;
  categories = [
    {
      name: 'Agro-alimentaire',
    },
    {
      name: 'Technologies',
    },
    {
      name: 'Industrie',
    },
    {
      name: 'Vente',
    },
    {
      name: 'Agro-industriel',
    },
    {
      name: 'Services',
    },
    {
      name: 'Communication',
    },
    {
      name: 'E-commerce',
    },
    {
      name: 'Organisation  à But non lucratif',
    },
    {
      name: 'Santé',
    },
    {
      name: 'Transport',
    },
    {
      name: 'Formation',
    },
    {
      name: 'E-learning',
    },
    {
      name: 'Télécom',
    },
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  goToArticle(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate(['/single-publication']);
  }

  addArticle(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate(['/add-publication']);
  }

  onShowMore() {
    this.showMore = !this.showMore;
  }

  showFilters() {
    this.filters = !this.filters;
  }

  showIcon() {
    this.iconShow = !this.iconShow;
  }
}
