import {Cv} from "datnek-sso";

export namespace ResumeActions {
  export class setCurrentResumeUser {
    static readonly type = '[User Api] Set Current Resume of Connected user';
    constructor(public resume: Cv) {}
  }

  export class fetchCurrentResumeOfConnectedUser {
    static readonly type = '[User Api] Fetch Current Resume of Connected User';
    constructor(public userId: string, public forceRefresh = false) {}
  }

  export class update {
    static readonly type = '[Cv Api] Update Cv';
    constructor(public resume: Cv) {}
  }

  export class create {
    static readonly type = '[Cv Api] Create Cv';
    constructor(public resume: Cv) {}
  }

  export class clone {
    static readonly type = '[Cv Api] Clone Cv';
    constructor(public resumeId: string, public name: string) {}
  }

  export class switchToMaster {
    static readonly type = '[Cv Api] Switch to master';
    constructor(public resume: Cv) {}
  }

  export class remove {
    static readonly type = '[Cv Api] Remove resume';
    constructor(public resume: Cv) {}
  }
}
