
<div
  class="
          d-flex
          align-items-center
          flex-wrap
          border-bottom
          px-3 py-2
        "
>
  <div class="d-flex align-items-center flex-wrap gap-16">
    <div class="">{{props.label}}</div>
    <formly-field *ngFor="let field of this.field.fieldGroup" class="" [field]="field"></formly-field>
  </div>
</div>
