import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef
} from 'datnek-ds';

@Component({
  selector: 'nk-shared-dialog-connect-android-ios',
  templateUrl: './dialog-connect-android-ios.component.html',
  styleUrls: ['./dialog-connect-android-ios.component.scss'],
})
export class DialogConnectAndroidIosComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  constructor(private router: Router, public dialogRef: DialogRef) {}

  ngOnInit(): void {}

  apiGoogleYahoConnect() {
    this.router.navigate(['/api-google-yahoo']);
  }
}
