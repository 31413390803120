import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuComp } from './components/menu-comp';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {DatnekDsAtomModule, DatnekDsGalleryModule, DatnekDsSharedModule, DatnekDsMoleculeModule} from 'datnek-ds';
import {DatnekSkeletonNetworkModule} from "datnek-skeleton";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [...MenuComp],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CarouselModule,
    DatnekDsGalleryModule,
    DatnekDsAtomModule,
    DatnekDsSharedModule,
    DatnekSkeletonNetworkModule,
    DatnekDsMoleculeModule
  ],
  exports: [...MenuComp],
  providers: [],
})
export class MenuShareModule {}

