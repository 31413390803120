import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiComp} from "./ui/ui-component";
import {DatnekDsAtomModule,
  DatnekDsSharedModule,
  DatnekDsUploadFileModule,
  DatnekDsDialogModule,
  DatnekDsDialogUtilisModule,
  DatnekDsCropperModule,
  DatnekDsMoleculeModule} from "datnek-ds";
import {MenuShareModule} from "./ui";
import {FormlyModule} from "@ngx-formly/core";
import {DatnekSsoModule} from "datnek-sso";
import {FormsModule} from "@angular/forms";
import {CarouselModule} from "ngx-owl-carousel-o";
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DialogShowLanguagesComponent } from './ui/dialogs/dialog-show-languages/dialog-show-languages.component';

@NgModule({
  imports: [CommonModule,
    DatnekDsSharedModule,
    DatnekDsAtomModule,
    DatnekDsMoleculeModule,
    DatnekDsUploadFileModule,
    DatnekDsDialogModule,
    DatnekDsDialogUtilisModule,
    DatnekDsCropperModule,
    MenuShareModule,
    DatnekSsoModule,
    FormsModule,
    CarouselModule,
    SlickCarouselModule,
    FormlyModule],
  declarations: [...UiComp, DialogShowLanguagesComponent, ],
  exports:[...UiComp, MenuShareModule, DatnekDsSharedModule]
})
export class SharedModule {}
