<div class="datnek-ds-dialog-body dialog-add-rules">
  <div class="content-modal p-3">
    <div class="grid grid--gap16">
      <div class="grid__col_12">
        <div class="mb-3">
          <datnek-ds-input-text
            label="Titre"
            placeholder="Titre de la règle"
          ></datnek-ds-input-text>
        </div>
        <div class="mb-3">
          <datnek-ds-textarea
            label="Description"
            placeholder="Description de la règle"
          ></datnek-ds-textarea>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="datnek-ds-dialog-footer">
  <datnek-ds-button
    [label]="'Annuler'"
    [size]="sizeEnum.large"
    [type]="colorEnum.secondary"
    class="mr-3"
    (datnekdsClickOrTouch)="close()"
  ></datnek-ds-button>
  <datnek-ds-button
    [label]="'Créer'"
    [size]="sizeEnum.large"
    [type]="colorEnum.primary"
    (datnekdsClickOrTouch)="close()"
  ></datnek-ds-button>
</div>
