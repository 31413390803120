<div class="container px-xl-4 pt-3 pt-lg-4 enterprise-list">
  <section class="datnek-ds-content grid grid--gap16">
    <div
      class="
        d-none d-md-block
        grid__col_12 grid__col_sm4 grid__col_md4 grid__col_xl3
      "
    >
      <div class="datnek-ds-card suggestion-list overflow-hidden">
        <div class="suggestion-header display-1">Catégories d'articles</div>
        <div class="search p-2 border-bottom">
          <datnek-ds-search-input
            placeholder="Rechercher une catégorie..."
          ></datnek-ds-search-input >
        </div>

        <div class="category-body bg-white" *ngIf="!isLoading">
          <p-scrollPanel
            [style]="{ width: '100%', height: 'calc(100vh - 273px)' }"
          >
            <span
              class="pointer category"
              *ngFor="let item of categories"
              (mouseover)="showIcon()"
            >
              {{ item.name }}
              <img src="assets/icons/src/outline/arrow-right.svg" alt="" />
            </span>
          </p-scrollPanel>
        </div>

        <datnek-skeleton-categories
          *ngIf="isLoading"
        ></datnek-skeleton-categories>
      </div>
    </div>
    <div class="grid__col_12 grid__col_sm8 grid__col_md8 grid__col_xl9">
      <div class="">
        <div class="datnek-ds-card overflow-hidden suggestion-groups">
          <div
            class="
              suggestion-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="d-flex align-items-center display-1">
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlinenewspaper"
                class="mr-2"
              ></datnek-ds-svg-icon>
              Liste des articles
            </div>
            <div class="d-none d-md-flex align-items-center">
              <datnek-ds-button
                [leftIcon]="iconEnum.outlinemenu"
                label="Filtrer"
                (datnekdsClickOrTouch)="showFilters()"
                [type]="colorEnum.unstyled"
                class="mr-3 mr-lg-0"
              ></datnek-ds-button>
              <datnek-ds-button
                label="Nouvel publication"
                (datnekdsClickOrTouch)="addArticle($event)"
                [type]="colorEnum.primary"
              ></datnek-ds-button>
            </div>
            <div
              ngbDropdown
              #myDrop="ngbDropdown"
              placement="bottom-start"
              class="network-icon-options-dropdown d-flex d-md-none"
            >
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlinedotsVertical"
                [type]="colorEnum.ghost"
                [round]="true"
                id="dropdownBasic1"
                ngbDropdownToggle
              ></datnek-ds-button-icon>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <datnek-ds-button
                  [leftIcon]="iconEnum.outlinemenu"
                  label="Filtrer"
                  (datnekdsClickOrTouch)="showFilters(); myDrop.close()"
                  [type]="colorEnum.unstyled"
                  [block]="true"
                  class="mr-3 mr-lg-0"
                ></datnek-ds-button>
                <datnek-ds-button
                  label="Nouvel publication"
                  (datnekdsClickOrTouch)="goToArticle($event)"
                  [type]="colorEnum.primary"
                  [block]="true"
                ></datnek-ds-button>
              </div>
            </div>
          </div>
          <div class="suggestion-content">
            <div [ngClass]="{ 'px-3 pt-3': filters === true }">
              <datnek-ds-filter-chip [filters]="filters"></datnek-ds-filter-chip>
            </div>

            <!--SUGGESTION AUTHOR ARTICLES-->
            <nk-shared-suggestion-article-list
              [status]="avatarEnum.person"
              [isSidebar]="false"
              [isFull]="true"
              [articesData]="articesData"
              *ngIf="articesData.length"
            ></nk-shared-suggestion-article-list>
            <nk-shared-suggestion-article-list
              [status]="avatarEnum.person"
              [isSidebar]="false"
              [isFull]="true"
              *ngIf="showMore === true && articesData.length"
              [articesData]="articesData"
            ></nk-shared-suggestion-article-list>

            <!-- NO CONTENT -->
            <datnek-ds-no-content
              *ngIf="!articesData.length"
              imgSrc="assets/icons/src/newspaper-news.svg"
              titleMsg="Aucun publication créé"
              contentDesc="Vous n’avez aucun publication créé pour l’instant"
            ></datnek-ds-no-content>
          </div>
          <div class="suggestion-footer text-center" *ngIf="articesData.length">
            <datnek-ds-link
              label="Voir plus"
              *ngIf="showMore === false"
              (datnekdsClickOrTouch)="onShowMore()"
              href="javascript:;"
            ></datnek-ds-link>
            <datnek-ds-link
              label="Voir moins"
              *ngIf="showMore === true"
              (datnekdsClickOrTouch)="onShowMore()"
              href="javascript:;"
            ></datnek-ds-link>
          </div>
          <!--<div class="suggestion-footer px-3 py-4"></div>-->
        </div>
      </div>
    </div>
  </section>
</div>
