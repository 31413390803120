<div class="datnek-ds-dialog-body dialog-add-calendar">
  <div class="content-text p-3">
    <p class="mb-0">
      Vous pouvez sélectionner jusqu'à 5 adresses emails où partager votre
      contenu. Utilisez le formulaire ci-dessous
    </p>
  </div>
  <div class="form p-3">
    <form [formGroup]="form" class="pb-3">
      <div class="d-flex w-100 gap-3">
        <div class="inputs w-100 d-flex gap-3">
          <datnek-ds-input-email
            [placeholdertext]="'Ajouter une adresse email...'"
            formControlName="email"
          ></datnek-ds-input-email>
        </div>

        <datnek-ds-button-icon
          [Icon]="iconEnum.outlineplus"
          [round]="true"
          [type]="colorEnum.secondary"
          [disabled]="form.invalid"
          (datnekdsClickOrTouch)="onSubmit()"
        ></datnek-ds-button-icon>
      </div>
    </form>

    <div class="email-list">
      <div
        class="email-item d-flex gap-3 mb-3 border-top pt-3"
        *ngFor="let result of emailServices.emailList"
      >
        <div class="input d-flex align-items-center px-3 border rounded w-100">
          {{ result.email }}
        </div>
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinex"
          [type]="colorEnum.secondary"
          [round]="true"
          (datnekdsClickOrTouch)="deleteResult(result)"
        ></datnek-ds-button-icon>
      </div>
    </div>
    <div class="my-0"></div>
  </div>
</div>
<div class="datnek-ds-dialog-footer">
  <datnek-ds-button
    [label]="'Partager'"
    [size]="sizeEnum.medium"
    [type]="colorEnum.primary"
    (onClick)="showToast(sh); close()"
  ></datnek-ds-button>
</div>

<!--NOTIFS MESSAGES-->
<ng-template #sh let-close="close">
  Vos résultats ont bien été partagés dans le(s) email(s) sélectionnés
</ng-template>

<!--NOTIFS MESSAGES-->
<ng-template #sh let-close="close">
  Vos résultats ont bien été partagés dans le(s) groupe(s)
</ng-template>
