import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'del-shared-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  active = 'all';

  constructor(config: NgbModalConfig, private modalService: NgbModal) {}

  ngOnInit(): void {}

  open(newContent: any) {
    this.modalService.open(newContent, {
      centered: false,
      backdropClass: 'dc-modal-backdrop',
      windowClass: 'dc-modal-languages',
      scrollable: true,
      size: 'xl',
    });
  }

  scrollToTop() {
    window.scroll(0, 0);
  }
}
