import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Inject, Injectable} from '@angular/core';
import { State, Action, StateContext, Store } from '@ngxs/store';
import {Environment} from "datnek-core";
import {Cv} from "datnek-sso";
import {ToastService} from "datnek-ds";
import {CvService} from "../../services";
import {ResumeActions} from "./resume.actions";
import {of, tap} from "rxjs";
import {error} from "ng-packagr/lib/utils/log";
import {catchError, finalize, map} from "rxjs/operators";


interface ResumeStateModel {
  resumes: Cv[] | null;
  currentResume: Cv | null;
  isFetchingCurrentResume: boolean;
  loadingSave: boolean;
  loadingRemove: boolean;
  error: any;
}

//const USER_STATE_TOKEN = template StateToken<ResumeStateModel>('user');

@State<ResumeStateModel>({
  name: 'resume',
  defaults: {
    resumes: null,
    currentResume: null,
    isFetchingCurrentResume: false,
    loadingSave: false,
    loadingRemove: false,
    error: null,
  },
})
@Injectable()
export class ResumeState {
  constructor(
    private resumeService: CvService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private store: Store,
    @Inject(Environment.Provider.ENVIRONMENT) private environment: Environment.Env
  ) {}

  @Action(ResumeActions.setCurrentResumeUser)
  async setCurrentResumeUser(
    ctx: StateContext<ResumeStateModel>,
    action: ResumeActions.setCurrentResumeUser
  ) {
    ctx.patchState({ currentResume: action.resume });
  }

  @Action(ResumeActions.fetchCurrentResumeOfConnectedUser)
  fetchCurrentResumeOfConnectedUser(
    ctx: StateContext<ResumeStateModel>,
    action: ResumeActions.fetchCurrentResumeOfConnectedUser
  ) {
    const state = ctx.getState();
    //const user = this.store.selectSnapshot<User>((state) => state.user.currentUser);

    if (state.resumes?.length && !action.forceRefresh) {
      return of(state.currentResume);
    }

    if (state.isFetchingCurrentResume)  return of(state.currentResume);

    ctx.patchState({
      currentResume: null,
      isFetchingCurrentResume: true,
      error: null,
    });

    return  this.resumeService
      .getResumesUser(action.userId as string, 0, 50).pipe(tap(resumes => {
        const resumeMaster = resumes?.Results?.find((elt) => elt.IsMaster);
        ctx.patchState({
          resumes: resumes?.Results,
          currentResume: resumeMaster ? resumeMaster : null,
          error: null,
        });
       }),
        map(res => of(state.currentResume)), catchError(error => {
         ctx.patchState({ error: error });
         return of(state.currentResume);
    }), finalize( () => {
      ctx.patchState({ isFetchingCurrentResume: false });
    }));
  }

  @Action(ResumeActions.update)
  async updateResume(
    ctx: StateContext<ResumeStateModel>,
    action: ResumeActions.update
  ) {
    const _resumes = JSON.parse(
      JSON.stringify(ctx.getState().resumes)
    ) as Cv[];

    ctx.patchState({ loadingSave: true, error: null });

    try {
      const saved = await this.resumeService
        .editData(action.resume, action.resume?.Id as string)
        .toPromise();
      if (!saved) throw new Error('Error');

      const resume = _resumes.find((elt) => elt.Id == saved.Id) as Cv;
      resume.Title = saved.Title;

      ctx.patchState({ resumes: _resumes, currentResume: saved, error: null });

      this.toastService.success('Success', 'Cv a été mis à jour');
    } catch (error) {
      this.toastService.error(
        'Error',
        `Un problème est survenue lors de la mise à jour du Cv`
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingSave: false });
    }
  }

  @Action(ResumeActions.create)
  async createCv(
    ctx: StateContext<ResumeStateModel>,
    action: ResumeActions.create
  ) {
    const _resumes = ctx.getState().resumes;

    ctx.patchState({ loadingSave: true, error: null });

    try {
      const saved = await this.resumeService.createData(action.resume).toPromise();
      if (!saved) throw new Error('Error');

      /* const existingMainLang = _resumes?.find((elt) => elt.IsMaster);
      if (existingMainLang && saved.IsMaster) {
        existingMainLang.IsMaster = false;
        await this.languagesService
          .edit(existingMainLang, existingMainLang.Id)
          .toPromise();
      } */

      ctx.patchState({
        resumes: [..._resumes as Cv[], saved],
        error: null,
      });

      this.toastService.success('Success', 'Cv enregistrée avec succès');
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        `Un problème est survenue lors de l'enregistrement du Cv`
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingSave: false });
    }
  }

  @Action(ResumeActions.clone)
  async cloneCv(
    ctx: StateContext<ResumeStateModel>,
    action: ResumeActions.clone
  ) {
    const _resumes = ctx.getState().resumes;

    ctx.patchState({ loadingSave: true, error: null });

    try {
      const saved = await this.resumeService
        .cloneResume(action.resumeId, action.name)
        .toPromise();
      if (!saved) throw new Error('Error');

      ctx.patchState({
        resumes: [..._resumes as Cv[], saved],
        error: null,
      });

      this.toastService.success('Success', 'Cv cloné avec succès');
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        `Un problème est survenue lors du clonage du Cv`
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingSave: false });
    }
  }

  @Action(ResumeActions.switchToMaster)
  async switchToMaster(
    ctx: StateContext<ResumeStateModel>,
    action: ResumeActions.switchToMaster
  ) {
    const _currentResume = JSON.parse(
      JSON.stringify(ctx.getState().currentResume)
    ) as Cv;
    const _resumes = JSON.parse(
      JSON.stringify(ctx.getState().resumes)
    ) as Cv[];

    ctx.patchState({ loadingSave: true, error: null });

    try {
      const saved = await this.resumeService
        .switchToMaster(action.resume?.Id as string)
        .toPromise();
      if (!saved) throw new Error('Error');

      const prevMaster = _resumes.find((elt) => elt.IsMaster);
      if (prevMaster) {
        prevMaster.IsMaster = false;
      }

      const newMaster = _resumes.find((elt) => elt.Id === saved.Id) as Cv;
      newMaster.IsMaster = true;

      _currentResume.IsMaster =
        !_currentResume.IsMaster && _currentResume?.Id == saved.Id;

      ctx.patchState({
        resumes: _resumes,
        currentResume: _currentResume,
        error: null,
      });

      this.toastService.success(
        'Success',
        `Le Cv ${saved.Title} est desormais master`
      );
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error('Error', `Un problème est survenue`);
      console.log('ERRROOOOOO ====> ', error);
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingSave: false });
    }
  }

  @Action(ResumeActions.remove)
  async removeCertification(
    ctx: StateContext<ResumeStateModel>,
    action: ResumeActions.remove
  ) {
    const _resumes = JSON.parse(
      JSON.stringify(ctx.getState().resumes)
    ) as Cv[];

    ctx.patchState({ loadingRemove: true, error: null });

    try {
      const saved = await this.resumeService
        .deleteData(action.resume?.Id as string)
        .toPromise();
      if (!saved) throw new Error('Error to delete certification');

      const foundIndex = _resumes.findIndex((e) => e.Id === saved.Id);

      _resumes.splice(foundIndex, 1);

      ctx.patchState({ resumes: _resumes });

      this.toastService.success('Success', 'Le Cv a bien été supprimée');
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        'Un problème est survenue lors de la suppression'
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingRemove: false });
    }
  }
}
