import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
} from 'datnek-ds';
import { Overlay } from '@angular/cdk/overlay';

import {
  DialogAddSignalizationComponent,
  DialogDeleteGroupComponent,
  DialogLeaveGroupComponent,
} from '../../../../organisms';
import { DialogNewContentComponent } from '@del-shared';

@Component({
  selector: 'nk-shared-single-group-menu-mobile',
  templateUrl: './single-group-menu-mobile.component.html',
  styleUrls: ['./single-group-menu-mobile.component.scss'],
})
export class SingleGroupMenuMobileComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  @Input()
  menuLeft!: boolean;

  descriptionGroup = `Sed porttitor lectus nibh. Pellentesque in ipsum id orci porta dapibus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Nulla porttitor accumsan tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.`;
  readMore = false;

  /**
   * Optional click handler
   */
  @Output()
  onClick = new EventEmitter<any>();

  constructor(
    private overlay: Overlay,
    private modalService: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  closeLeftSidebar() {
    this.menuLeft = false;
    this.onClick.emit(this.menuLeft);
    console.log('SIDEBAR LEFT STATE = ' + this.menuLeft);
  }

  newContentDialog() {
    const ref = this.modalService.open(DialogNewContentComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Nouvelle publication`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  addSignalization(): void {
    const ref = this.modalService.open(DialogAddSignalizationComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Signaler le groupe`,
        size: DialogSizeEnum.small,
      },
    });
  }

  leaveGroup(): void {
    const ref = this.modalService.open(DialogLeaveGroupComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Quitter le groupe`,
        size: DialogSizeEnum.small,
      },
    });
  }

  deleteGroup(): void {
    const ref = this.modalService.open(DialogDeleteGroupComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Supprimer le groupe`,
        size: DialogSizeEnum.small,
      },
    });
  }

  goToSettings() {
    this.router.navigate([
      '/groups/single-group/group-settings/home-group-settings',
    ]);
  }

  goToStats() {
    this.router.navigate(['/groups/single-group/group-stats/home-group-stats']);
  }
}
