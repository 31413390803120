<div class="datnek-ds-dialog-body dialog-share-screen">
  <div
    class="content-modal flex-column flex-sm-row d-flex p-3"
    style="gap: 15px"
  >
    <div class="full-screen w-100">
      <div class="display-2 mb-3">Partagez l'intégralitéde cotre écran</div>
      <div class="img">
        <img
          src="assets/Img/share-screen/screen-1.jpg"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>
    <div class="part-screen w-100">
      <div class="display-2 mb-3">
        Partagez un onglet ou une fenêtre particulière
      </div>
      <div class="images">
        <img
          src="assets/Img/share-screen/screen-1.jpg"
          alt=""
          class="img-fluid"
        />
        <img
          src="assets/Img/share-screen/screen-2.jpg"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>
  </div>

  <div class="datnek-ds-dialog-footer">
    <datnek-ds-button
      [label]="'Partager'"
      [size]="sizeEnum.large"
      [type]="colorEnum.primary"
      (datnekdsClickOrTouch)="dialogRef.close()"
    ></datnek-ds-button>
  </div>
</div>
