import { Component, OnInit } from '@angular/core';
import {AvatarTypeEnum} from "datnek-ds";

@Component({
  selector: 'nk-shared-suggestions-menu-mobile',
  templateUrl: './suggestions-menu-mobile.component.html',
  styleUrls: ['./suggestions-menu-mobile.component.scss'],
})
export class SuggestionsMenuMobileComponent implements OnInit {
  avatarEnum = AvatarTypeEnum;

  constructor() {}

  ngOnInit(): void {}
}
