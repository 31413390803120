<div class="header d-flex justify-content-between align-items-center">
  <div class="user d-flex align-items-center  w-100">
    <span class="mr-3 ml-2">{{props.label}}</span>
    <formly-field *ngIf="this.field.fieldGroup && this.field.fieldGroup.length > 0"
                  class="d-flex align-items-center" [field]="field.fieldGroup[0]"></formly-field>

  </div>

  <div class="actions d-flex justify-content-end">
    <datnek-ds-button
      [type]="colorEnum.secondary"
      label="Retour"
      [size]="sizeEnum.medium"
      (datnekdsClickOrTouch)="goToHome($event)"
      class="mr-3 d-none d-sm-block"
    ></datnek-ds-button>
    <datnek-ds-button-icon
      [type]="colorEnum.secondary"
      [size]="sizeEnum.medium"
      class="d-sm-none"
      [Icon]="iconEnum.outlinearrowLeft"
      (datnekdsClickOrTouch)="goToHome($event)"
    ></datnek-ds-button-icon>
    <datnek-ds-button
      [type]="colorEnum.primary"
      label="Envoyer"
      [size]="sizeEnum.medium"
      [role]="'submit'"
      class="d-none d-sm-block"
    ></datnek-ds-button>
  </div>
</div>
