import { Component, OnInit } from '@angular/core';

import {
  DialogRef,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogAddLinkComponent
} from 'datnek-ds';


interface stateItems {
  id: number;
  name: string;
  src: IconEnum;
}

interface Items {
  id: number;
  name: string;
  src: string;
  type: string;
}

@Component({
  selector: 'nk-shared-dialog-share-post-with-edit',
  templateUrl: './dialog-share-post-with-edit.component.html',
  styleUrls: ['./dialog-share-post-with-edit.component.scss'],
})
export class DialogSharePostWithEditComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;


  modules = {};
  content = '';

  meSubmenu: Items[] = [
    {
      id: 0,
      name: 'John Doe',
      src: 'assets/Img/user-placeholder-avatar.svg',
      type: 'person',
    },
    {
      id: 0,
      name: 'DIgitaille_CM',
      src: 'assets/Img/profile_img_jobs.png',
      type: 'society',
    },
    {
      id: 0,
      name: 'Graven Inc.',
      src: 'assets/Img/profile_img_jobs.png',
      type: 'society',
    },
  ];

  stateSubmenu: stateItems[] = [
    {
      id: 0,
      name: 'Public',
      src: IconEnum.outlineglobe,
    },
    {
      id: 0,
      name: 'Privé',
      src: IconEnum.outlinelockClosed,
    },
  ];

  constructor(
    public dialogRef: DialogRef,
    private modalService: DialogService
  ) {}

  Toolbar = false;

  ngOnInit(): void {
    // listerner the profile or background when changed
  }

  addLink() {
    const ref = this.modalService.open(DialogAddLinkComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter un lien`,
        size: DialogSizeEnum.small,
      },
    });
  }


  showToolbar() {
    this.Toolbar = !this.Toolbar;
  }
}
