import { Component, OnInit } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef
} from 'datnek-ds';

@Component({
  selector: 'nk-shared-dialog-challenge-accept',
  templateUrl: './dialog-challenge-accept.component.html',
  styleUrls: ['./dialog-challenge-accept.component.scss'],
})
export class DialogChallengeAcceptComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  constructor(public ref: DialogRef) {}

  ngOnInit(): void {}

  close() {
    this.ref.close();
  }
}
