
<div
  class="d-flex align-items-center flex-wrap my-4 border-bottom p-3"
  style="gap: 1rem"
>
  <datnek-ds-button
    (onClick)="functioncall($event)"
    [label]="'Mon bouton'"
    [size]="sizeEnum.large"
    [type]="colorEnum.primary"
    [isLoading]="false"
  ></datnek-ds-button>
  <datnek-ds-button
    (onClick)="functioncall($event)"
    [label]="'Mon bouton'"
    [size]="sizeEnum.large"
    [type]="colorEnum.primary"
    [isLoading]="true"
  ></datnek-ds-button>
  <datnek-ds-button
    (onClick)="functioncall($event)"
    [label]="'Mon bouton'"
    [size]="sizeEnum.medium"
    [type]="colorEnum.primary"
    [leftIcon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button>
  <datnek-ds-button
    (onClick)="functioncall($event)"
    [label]="'Mon bouton'"
    [size]="sizeEnum.small"
    [type]="colorEnum.primary"
    [leftIcon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button>

  <datnek-ds-button
    (onClick)="functioncall($event)"
    [label]="'Mon bouton'"
    [size]="sizeEnum.large"
    [type]="colorEnum.secondary"
    [leftIcon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button>
  <datnek-ds-button
    (onClick)="functioncall($event)"
    [label]="'Mon bouton'"
    [size]="sizeEnum.medium"
    [type]="colorEnum.secondary"
    [leftIcon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button>
  <datnek-ds-button
    (onClick)="functioncall($event)"
    [label]="'Mon bouton'"
    [size]="sizeEnum.small"
    [type]="colorEnum.secondary"
    [leftIcon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button>
  <datnek-ds-button
    (onClick)="functioncall($event)"
    [label]="'Mon bouton'"
    [size]="sizeEnum.large"
    [type]="colorEnum.danger"
    [leftIcon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button>
  <datnek-ds-button
    (onClick)="functioncall($event)"
    [label]="'Mon bouton'"
    [size]="sizeEnum.medium"
    [type]="colorEnum.danger"
    [leftIcon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button>
  <datnek-ds-button
    (onClick)="functioncall($event)"
    [label]="'Mon bouton'"
    [size]="sizeEnum.small"
    [type]="colorEnum.danger"
    [leftIcon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button>
</div>

<!--BUTTONS ICONS-->
<div class="d-flex align-items-center my-4 border-bottom p-3" style="gap: 1rem">
  <datnek-ds-button-icon
    (onClick)="functioncall($event)"
    [size]="sizeEnum.large"
    [type]="colorEnum.primary"
    [Icon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button-icon>

  <datnek-ds-button-icon
    (onClick)="functioncall($event)"
    [size]="sizeEnum.large"
    [type]="colorEnum.secondary"
    [Icon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button-icon>

  <datnek-ds-button-icon
    (onClick)="functioncall($event)"
    [size]="sizeEnum.large"
    [type]="colorEnum.ghost"
    [Icon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button-icon>
  <datnek-ds-button-icon
    (onClick)="functioncall($event)"
    [size]="sizeEnum.medium"
    [type]="colorEnum.primary"
    [Icon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button-icon>

  <datnek-ds-button-icon
    (onClick)="functioncall($event)"
    [size]="sizeEnum.medium"
    [type]="colorEnum.secondary"
    [Icon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button-icon>

  <datnek-ds-button-icon
    (onClick)="functioncall($event)"
    [size]="sizeEnum.medium"
    [type]="colorEnum.ghost"
    [Icon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button-icon>

  <datnek-ds-button-icon
    (onClick)="functioncall($event)"
    [size]="sizeEnum.small"
    [type]="colorEnum.primary"
    [Icon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button-icon>

  <datnek-ds-button-icon
    (onClick)="functioncall($event)"
    [size]="sizeEnum.small"
    [type]="colorEnum.secondary"
    [Icon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button-icon>

  <datnek-ds-button-icon
    (onClick)="functioncall($event)"
    [size]="sizeEnum.small"
    [type]="colorEnum.ghost"
    [Icon]="iconEnum.outlinearchive"
    [isLoading]="false"
  ></datnek-ds-button-icon>
</div>

<datnek-ds-link></datnek-ds-link>

<datnek-ds-label></datnek-ds-label>

<!--INPUTS-->
<div class="d-flex align-items-center my-4 border-bottom p-3" style="gap: 1rem">
  <datnek-ds-input-text
    [placeholdertext]="'Ajouter un texte...'"
  ></datnek-ds-input-text>
  <datnek-ds-input-password></datnek-ds-input-password>
</div>

<!--AVATARS-->
<div class="d-flex align-items-center my-4 border-bottom p-3" style="gap: 1rem">
  <datnek-ds-avatar
    [size]="sizeEnum.large"
    altMsg="L'image de profil"
    imgTitle="Titre de l'image"
  ></datnek-ds-avatar>
  <datnek-ds-avatar
    [size]="sizeEnum.medium"
    altMsg="L'image de profil"
    imgTitle="Titre de l'image"
  ></datnek-ds-avatar>
  <datnek-ds-avatar
    [size]="sizeEnum.small"
    altMsg="L'image de profil"
    imgTitle="Titre de l'image"
  ></datnek-ds-avatar>
  <datnek-ds-avatar
    [size]="sizeEnum.tiny"
    altMsg="L'image de profil"
    imgTitle="Titre de l'image"
  ></datnek-ds-avatar>
</div>

<!--DROPDOWNS-->
<div class="d-flex align-items-center my-4 border-bottom p-3" style="gap: 1rem">
  <datnek-ds-avatar-dropdown></datnek-ds-avatar-dropdown>
</div>

<div class="d-flex align-items-center my-4 border-bottom p-3" style="gap: 1rem">
  <datnek-ds-icon-text-dropdown
    [icon]="iconEnum.outlineglobe"
    label="Vous-même"
  ></datnek-ds-icon-text-dropdown>

  <datnek-ds-icon-text-dropdown
    [icon]="iconEnum.outlineglobe"
    label="Pour moi uniquement"
  ></datnek-ds-icon-text-dropdown>
</div>

<!-- USER-IFOS-SMALL-BOX -->
<div class="d-flex align-items-center mt-4 border-bottom p-3" style="gap: 1rem">
  <nk-shared-user-infos
    name="Bassahak Jean Marc"
    professionalTitle="Designer Graphique Senior"
  ></nk-shared-user-infos>
</div>

<!-- CARD NEW PUBLICATION -->
<div
  class="d-flex align-items-center border-bottom p-4 bg-container"
  style="gap: 1rem"
>
  <nk-shared-card-new-publication-box></nk-shared-card-new-publication-box>
</div>

<!-- CARD USER BOX -->
<div
  class="d-flex align-items-center border-bottom p-4 bg-container"
  style="gap: 1rem"
>
  <nk-shared-card-user-box
    resumeTitle="Penting gak Penting yang oentung Posting"
    name="Bassahak JM"
  ></nk-shared-card-user-box>
</div>
