<div class="datnek-ds-dialog-body dialog-send-message">
  <div class="content-text px-3 py-3">
    <div class="message-to-send mb-3">
      <div class="message">
        <span
          >Comme je te l'avais expliqué la dernière fois, tu cliques
          sim...</span
        >
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinereplyRight"
          [type]="colorEnum.unstyled"
        ></datnek-ds-button-icon>
      </div>
      <input
        type="text"
        name=""
        id=""
        placeholder="Tapez un message facultatif ici"
      />
    </div>
    <datnek-ds-search-input
      placeholder="Trouver des personnes"
    ></datnek-ds-search-input >
  </div>
  <div class="content-modal">
    <div class="member-list">
      <nk-shared-suggestion-list
        [userData]="dataUsers"
        [status]="avatarEnum.person"
        [sendMessage]="true"
      ></nk-shared-suggestion-list>
    </div>
  </div>

  <div class="datnek-ds-dialog-footer">
    <datnek-ds-button
      [label]="'Terminer'"
      [size]="sizeEnum.large"
      [type]="colorEnum.primary"
      (datnekdsClickOrTouch)="dialogRef.close()"
    ></datnek-ds-button>
  </div>
</div>
