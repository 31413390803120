import { Component, OnInit, TemplateRef, Type } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef,
  DialogService,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';
import { Emails } from 'libs/network/network-shared/src/lib/core';
import { EmailListService } from 'libs/network/network-shared/src/lib/core/services/emails/email-list.service';

@Component({
  selector: 'he-shared-dialog-user-event-list',
  templateUrl: './dialog-user-event-list.component.html',
})
export class DialogUserEventListComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  form!: FormGroup;
  emails?: Emails;

  public emailList: Emails[] = [];

  constructor(
    public emailServices: EmailListService,
    private fb: FormBuilder,
    public dialogRef: DialogRef,
    private modalService: DialogService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required]],
    });
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  onSubmit() {
    console.log(this.form.value);
    this.emailServices.addEmail(this.form.value.email);

    this.initForm();
  }

  deleteResult(item: any) {
    this.emails = item;
    this.emailServices.deleteEmail(item);
  }

  close(): void {
    this.dialogRef.close();
  }
}
