import { Company } from '../companies/company.model';
import {TrackModel} from "datnek-core";

export class Schedule extends TrackModel {
  CompanyId?: string;
  Company?: Company;
  StartTime?: string;
  EndTime?: string;
  Day?: number;

  constructor(
    Id?: string,
    CreateAt?: Date,
    UpdateAt?: Date,
    CompanyId?: string,
    StartTime?: string,
    EndTime?: string,
    Day?: number
  ) {
    super(Id, CreateAt, UpdateAt);
    this.CompanyId = CompanyId;
    this.StartTime = StartTime;
    this.EndTime = EndTime;
    this.Day = Day;
  }
}

export enum DayEnumType {}
