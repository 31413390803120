<div class="datnek-ds-dialog-body dialog-event-details">
  <div class="content-modal">
    <div class="content-head d-flex justify-content-between px-3 py-4">
      <div class="left d-flex">
        <datnek-ds-avatar [size]="sizeEnum.large" class="mr-3"></datnek-ds-avatar>
        <div class="infos d-flex flex-column">
          <div class="name display-1 mb-1">
            Etienne Henri Lotin
            <img src="assets/icons/src/check-color.png" alt="" />
          </div>
          <div class="society d-flex align-items-center">
            <datnek-ds-svg-icon
              [icon]="iconEnum.outlineofficeBuilding"
            ></datnek-ds-svg-icon>
            <div class="display-4">Lotin Corp</div>
          </div>
          <div class="d-flex align-items-center">
            <datnek-ds-svg-icon [icon]="iconEnum.outlineinbox"></datnek-ds-svg-icon>
            <div class="display-4">Envoyer unmessage</div>
          </div>
        </div>
      </div>

      <div class="actions d-flex align-items-center justify-content-end">
        <datnek-ds-button
          label="Modifier"
          [size]="sizeEnum.large"
          [type]="colorEnum.primary"
        ></datnek-ds-button>

        <div ngbDropdown class="d-inline-block mr-3">
          <div class="pointer" id="dropdownBasic2" ngbDropdownToggle>
            <datnek-ds-button-icon
              [Icon]="iconEnum.outlinedotsVertical"
              [type]="colorEnum.unstyled"
              [size]="sizeEnum.medium"
            ></datnek-ds-button-icon>
          </div>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
            <button ngbDropdownItem>
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlineeyeOff"
              ></datnek-ds-svg-icon>
              Masquer dans les contacts
            </button>
            <button ngbDropdownItem>
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlinetrash"
              ></datnek-ds-svg-icon>
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="content-body p-3">
      <div class="grid grid--gap16">
        <div class="grid__col_12 grid__col_sm6">
          <div class="datnek-ds-card border coordonnees p-3">
            <div class="display-1 mb-3">Coordonnées</div>
            <div class="d-flex align-items-center mb-2">
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlinemail"
                class="mr-3"
              ></datnek-ds-svg-icon>
              <datnek-ds-link label="contact@lotincorp.biz"></datnek-ds-link>
            </div>
            <div class="d-flex align-items-center mb-2">
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlinelocationMarker"
                class="mr-3"
              ></datnek-ds-svg-icon>
              <datnek-ds-link
                label="403, Rue Drouot, Akwa Douala - Cameroon"
              ></datnek-ds-link>
            </div>
            <div class="d-flex align-items-center mb-2">
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlinelink"
                class="mr-3"
              ></datnek-ds-svg-icon>
              <datnek-ds-link label="https://lotincorp.biz "></datnek-ds-link>
            </div>
          </div>
        </div>
        <div class="grid__col_12 grid__col_sm6">
          <div class="datnek-ds-card border recent-messages p-3">
            <div class="display-1 mb-3">Conversations récentes</div>
            <div class="messages">
              <div class="item py-3">
                <div class="display-4 mb-2"></div>
                <div class="display-7 time">17/08/2019 01:04</div>
              </div>
              <div class="item py-3">
                <div class="display-4 mb-2"></div>
                <div class="display-7 time">17/08/2019 01:04</div>
              </div>
              <div class="item py-3">
                <div class="display-4 mb-2"></div>
                <div class="display-7 time">17/08/2019 01:04</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
