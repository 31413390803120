<!--IF IS CONTACT/CHAT/EVENT PAGES-->
<ng-container *ngIf="isMessages && !isLoading">
  <ul class="sidebar-menu">
    <li class="">
      <a
        [routerLink]="['/chat/home']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #messages="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <datnek-ds-svg-icon
            [icon]="iconEnum.outlineinbox"
            *ngIf="!messages.isActive"
          ></datnek-ds-svg-icon>
          <datnek-ds-svg-icon-color
            [icon]="iconEnum.outlineColorinbox"
            *ngIf="messages.isActive"
          ></datnek-ds-svg-icon-color>
          <span class="display-3 ml-3 mt-1">Messages</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">534</span>
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/events']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #calendar="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <datnek-ds-svg-icon
            [icon]="iconEnum.outlinecalendar"
            *ngIf="!calendar.isActive"
          ></datnek-ds-svg-icon>
          <datnek-ds-svg-icon-color
            [icon]="iconEnum.outlineColorcalendar"
            *ngIf="calendar.isActive"
          ></datnek-ds-svg-icon-color>
          <span class="display-3 ml-3 mt-1">Evènements</span>
        </div>
        <div class="w-25 text-right mt-1">
          <!--<span class="count display-3">534</span>-->
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/contacts']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #contacts="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <datnek-ds-svg-icon
            [icon]="iconEnum.outlineuser"
            *ngIf="!contacts.isActive"
          ></datnek-ds-svg-icon>
          <datnek-ds-svg-icon-color
            [icon]="iconEnum.outlineColoruser"
            *ngIf="contacts.isActive"
          ></datnek-ds-svg-icon-color>
          <span class="display-3 ml-3 mt-1">Contacts</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">534</span>
        </div>
      </a>
    </li>
  </ul>
</ng-container>

<!--IF IS GROUP PAGES-->
<ng-container *ngIf="isGroup && !isLoading">
  <ul class="sidebar-menu mb-0 px-2 pt-3">
    <li class="">
      <a
        [routerLink]="['/groups/home-group']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #feed="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <datnek-ds-svg-icon
            [icon]="iconEnum.outlinenewspaper"
            *ngIf="!feed.isActive"
          ></datnek-ds-svg-icon>
          <datnek-ds-svg-icon-color
            [icon]="iconEnum.outlinecolorNewspaper"
            *ngIf="feed.isActive"
          ></datnek-ds-svg-icon-color>
          <span class="display-3 ml-3 mt-1">Votre Fil d'actualité</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">534</span>
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/groups/suggestion-groups']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #discover="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <datnek-ds-svg-icon
            [icon]="iconEnum.outlinefire"
            *ngIf="!discover.isActive"
          ></datnek-ds-svg-icon>
          <datnek-ds-svg-icon-color
            [icon]="iconEnum.outlinecolorFire"
            *ngIf="discover.isActive"
          ></datnek-ds-svg-icon-color>
          <span class="display-3 ml-3 mt-1">Suggestions</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">534</span>
        </div>
      </a>
    </li>
  </ul>
</ng-container>

<!--IF IS SINGLE GROUP ID PAGES-->
<ng-container *ngIf="isSingleGroupID && !isLoading">
  <ul class="sidebar-menu mb-0 px-2 pt-3">
    <li class="">
      <a
        [routerLink]="['/groups/single-group/group-discussion']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #feed="routerLinkActive"
        (datnekdsClickOrTouch)="closeLeftSidebar()"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Discussions</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">534</span>
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/groups/single-group/group-hashtags/home-hashtags']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #feed="routerLinkActive"
        (datnekdsClickOrTouch)="closeLeftSidebar()"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Hashtags</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">534</span>
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/groups/single-group/group-users']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #feed="routerLinkActive"
        (datnekdsClickOrTouch)="closeLeftSidebar()"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Personnes</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">534</span>
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/groups/single-group/group-multimedia']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #feed="routerLinkActive"
        (datnekdsClickOrTouch)="closeLeftSidebar()"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Multimédia</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">534</span>
        </div>
      </a>
    </li>
  </ul>
</ng-container>

<!--IF IS CHALLENGE PAGES-->
<ng-container *ngIf="isChallenge && !isLoading">
  <ul class="sidebar-menu mb-0 px-2 pt-3">
    <li class="">
      <a
        [routerLink]="['/challenge/list']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #feed="routerLinkActive"
        (datnekClickOrTouch)="closeLeftSidebar()"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Liste des challenges</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">534</span>
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/challenge/challenges-accepted']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #discover="routerLinkActive"
        (datnekClickOrTouch)="closeLeftSidebar()"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1"
            >Challenges auxquels vous avez participé</span
          >
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">4</span>
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/challenge/your-challenges']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #discover="routerLinkActive"
        (datnekClickOrTouch)="closeLeftSidebar()"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Challenges créés par vous</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">4</span>
        </div>
      </a>
    </li>
  </ul>
</ng-container>

<!--IF IS FRIENDS PAGES-->
<ng-container *ngIf="isFriends && !isLoading">
  <ul class="sidebar-menu mb-0 px-2 py-3">
    <li class="">
      <a
        [routerLink]="['/request/friends']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #feed="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Tous les amis</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">34</span>
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/request/invitaions']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #discover="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Invitations</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">4</span>
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/request/suggestions']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #discover="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Suggestions</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">4</span>
        </div>
      </a>
    </li>
  </ul>
</ng-container>

<!--IF IS SUGGESTIONS PAGES-->
<ng-container *ngIf="isSuggestions && !isLoading">
  <ul class="sidebar-menu mb-0 px-2 py-3">
    <li class="">
      <a
        [routerLink]="['/request/friends']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #feed="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Tous les amis</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">34</span>
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/request/invitaions']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #discover="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Invitations</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">4</span>
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/request/suggestions']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #discover="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Suggestions</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">4</span>
        </div>
      </a>
    </li>
  </ul>
</ng-container>

<!--IF IS INVITATION PAGES-->
<ng-container *ngIf="isInvitations && !isLoading">
  <ul class="sidebar-menu mb-0 px-2 py-3">
    <li class="">
      <a
        [routerLink]="['/request/friends']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #feed="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Tous les amis</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">34</span>
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/request/invitaions']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #discover="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Invitations</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">4</span>
        </div>
      </a>
    </li>
    <li class="">
      <a
        [routerLink]="['/request/suggestions']"
        [routerLinkActive]="['is-active']"
        class="d-flex alin-items-center w-100"
        routerLinkActive
        #discover="routerLinkActive"
      >
        <div class="w-75 d-flex alin-items-center">
          <span class="display-3 mt-1">Suggestions</span>
        </div>
        <div class="w-25 text-right mt-1">
          <span class="count display-3">4</span>
        </div>
      </a>
    </li>
  </ul>
</ng-container>

<datnek-skeleton-menu-sidebar
  *ngIf="isLoading"
></datnek-skeleton-menu-sidebar>
