import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'datnek-ds';
import { switchMap} from 'rxjs';
import {Store} from "@ngxs/store";
import {UserSsoActions} from "datnek-sso";
import {AppEnum, ResumeActions, DeviceService,
  TranslationService,} from "@del-shared";
@Component({
  selector: 'network-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
  title = 'DelenscioNetwork';
  loaderTranslator = false;

  isNotSsoPage!: boolean;
  displayChat!: boolean;

  constructor(
    private translationService: TranslationService,
    private translateService: TranslateService,
    private deviceService: DeviceService,
    public router: Router,
    private store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    TranslationService.App = AppEnum.Testing;
    this.translationService.setLanguage();
    const sub2 = this.translateService
      .get('sso.errors.phoneInvalid')
      .subscribe((res) => {
        this.loaderTranslator = true;
      });
    const sub3 = this.deviceService.isNotSsoPage$.subscribe((res) => {
      this.isNotSsoPage = res;
    });
    const sub4 = this.deviceService.displayChat$.subscribe((res) => {
      this.displayChat = res;
    });

    const sub1 = this.store.dispatch(new UserSsoActions.fetchConnectedUser()).pipe(switchMap(state=> {
     return  this.store.dispatch(new ResumeActions.fetchCurrentResumeOfConnectedUser(state.user.currentUser?.Id));
    })).subscribe();

    this.subscribe.add(sub1);
    this.subscribe.add(sub2);
    this.subscribe.add(sub3);
    this.subscribe.add(sub4);
  }
}
