import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  IconEnum,
  AvatarTypeEnum,
  ControlTypeColorEnum,
  ControlSizeEnum,
} from 'datnek-ds';

@Component({
  selector: 'del-shared-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss'],
})
export class MenuSidebarComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = true;

  /**
   * Si c'est une page de messages
   */
  @Input()
  isMessages = false;

  /**
   * Si c'est une page de Groupe (Général)
   */
  @Input()
  isGroup = false;

  /**
   * Si c'est une page d'un Groupe spécifique
   */
  @Input()
  isSingleGroupID = false;

  /**
   * Si c'est une page de ChallengeComp
   */
  @Input()
  isChallenge!: boolean;

  /**
   * Si c'est une page d'amis
   */
  @Input()
  isFriends!: boolean;

  /**
   * Si c'est une page d'invitation
   */
  @Input()
  isInvitations!: boolean;

  /**
   * Si c'est une page de suggestion
   */
  @Input()
  isSuggestions!: boolean;

  @Input()
  menuLeft!: boolean;

  /**
   * Optional click handler
   */
  @Output()
  onClick = new EventEmitter<any>();

  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  closeLeftSidebar() {
    //this.menuLeft = false;
    this.onClick.emit(this.menuLeft);
    console.log('MENU LEFT : ', this.menuLeft);
  }

  homeGroup() {
    this.router.navigate(['/groups/home-groups']);
  }

  goToGroupDiscussion() {
    this.router.navigate(['/groups/single-group/group-discussion']);
  }
  goToGroupHashtag() {
    this.router.navigate(['/groups/single-group/group-hashtags/home-hashtags']);
  }
  goToGroupPerson() {
    this.router.navigate(['/groups/single-group/group-users']);
  }
  goToGroupMedia() {
    this.router.navigate(['/groups/single-group/group-multimedia']);
  }
}
