import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BaseHttpService, Environment, ListResult, LoaderService} from "datnek-core";
import {Cv, SalaryEnum} from "datnek-sso";

@Injectable({
  providedIn: 'root',
})
export class CvService extends BaseHttpService<Cv, Cv> {
  static salaryMap = {
    [SalaryEnum.DAY]: 'Jour',
    [SalaryEnum.WEEK]: 'Semaine',
    [SalaryEnum.MONTH]: 'Mois',
  };

  loadingGetResumeSubject = new BehaviorSubject<boolean>(false);
  currentResumeUserSubject = new Subject<Cv>();
  currentResumeUser!: Cv;
  requestAlreadyMadeToGetCurrentResumeUser = false;

  constructor(http: HttpClient,
              loaderService: LoaderService,
              @Inject(Environment.Provider.API) private api: Environment.Api) {
    super(http, loaderService);
    this.setUrl(`${this.api.cv}/Resumes`);
  }

  getResumesUser(
    userId: string,
    skip: number,
    take: number,
    globalLoading = false
  ): Observable<ListResult<Cv>> {
    return super.getLazyData(take, skip, `${this.rootURL}/ByUser/${userId}/${skip}/${take}`, globalLoading);
  }

  getCurrentResumeUser(userId: string, globalLoading = false): Observable<Cv> {
    return this.getByIdData(userId, `${this.rootURL}/ByUser/Current/${userId}`, globalLoading);
  }

  cloneResume(resumeId: string, title: string, globalLoading = false): Observable<Cv> {
    return this.createData({ ResumeId : resumeId, Title: title }, `${this.rootURL}/Clone`, globalLoading);
  }

  switchToMaster(resumeId: string, globalLoading = false): Observable<Cv> {
    return this.getByIdData(resumeId, `${this.rootURL}/Switch/${resumeId}`, globalLoading);
  }

  //----------------------------------------------------------------------------
  async emitCurrentResumeUserSubject(
    connectedUserId: string,
    forceRefresh = false,
    globalLoading = false
  ) {
    if (this.requestAlreadyMadeToGetCurrentResumeUser && !forceRefresh)
      this.currentResumeUserSubject.next(this.currentResumeUser);
    else {
      this.loadingGetResumeSubject.next(true);
      try {
        this.currentResumeUser = await this.getCurrentResumeUser(
          connectedUserId, globalLoading
        ).toPromise() as Cv;
        console.log('this.currentResumeUser', this.currentResumeUser);
        this.requestAlreadyMadeToGetCurrentResumeUser = true;
        this.currentResumeUserSubject.next(this.currentResumeUser);
      } catch (error){
      } finally {
        this.loadingGetResumeSubject.next(false);
      }
    }
  }

  upadateCurrentResumeUserSubject(newCurrentResumeUser: any) {
    this.currentResumeUser = { ...newCurrentResumeUser };
    this.currentResumeUserSubject.next(this.currentResumeUser);
  }

  getCurrent(userId: string,  globalLoading = false): Observable<Cv> {
    return this.getByIdData(userId, `${this.rootURL}/User/Current/${userId}`, globalLoading);
  }
}
