import { Component, OnInit } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef
} from 'datnek-ds';

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  groupName: string;
  relations: number;
  sector: string;
}

@Component({
  selector: 'nk-shared-dialog-send-message',
  templateUrl: './dialog-send-message.component.html',
  styleUrls: ['./dialog-send-message.component.scss'],
})
export class DialogSendMessageComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  dataUsers: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 4,
      sector: 'Génie civil',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 18,
      sector: 'Technologie',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 0,
      sector: 'Agroalimentaire',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 18,
      sector: 'Technologie',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 0,
      sector: 'Agroalimentaire',
    },
  ];
  constructor(public dialogRef: DialogRef) {}

  ngOnInit(): void {}
}
