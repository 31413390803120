import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
} from 'datnek-ds';
import { Overlay } from '@angular/cdk/overlay';
import { DialogAddChallengeComponent } from '@del-shared';

@Component({
  selector: 'nk-shared-challenge-menu-mobile',
  templateUrl: './challenge-menu-mobile.component.html',
  styleUrls: ['./challenge-menu-mobile.component.scss'],
})
export class ChallengeMenuMobileComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  @Input()
  menuLeft!: boolean;

  /**
   * Optional click handler
   */
  @Output()
  onClick = new EventEmitter<any>();

  constructor(private overlay: Overlay, private modalService: DialogService) {}

  ngOnInit(): void {}

  addNewChallengeDialog() {
    const ref = this.modalService.open(DialogAddChallengeComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Créer un nouveau challenge`,
        size: DialogSizeEnum.small,
      },
    });
  }

  closeLeftSidebar(): void {
    //this.menuLeft = false;
    this.onClick.emit(this.menuLeft);
    console.log('MENU LEFT : ', this.menuLeft);
  }
}
