import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef
} from 'datnek-ds';

@Component({
  selector: 'nk-shared-dialog-private-content',
  templateUrl: './dialog-private-content.component.html',
  styleUrls: ['./dialog-private-content.component.scss'],
})
export class DialogPrivateContentComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  constructor(private router: Router, public dialogRef: DialogRef) {}

  ngOnInit(): void {}

  gotoPrices(e: any) {
    e.preventDefault();
    this.router.navigate(['/pricing-table']);
    this.dialogRef.close();
  }
}
