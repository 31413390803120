import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  FileAcceptEnum,
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  ControlDisplayEnum,
} from 'datnek-ds';
import {Results, ChallengeResultsService} from "../../../../../../core";

@Component({
  selector: 'nk-shared-dialog-publish-challenge',
  templateUrl: './dialog-publish-challenge.component.html',
  styleUrls: ['./dialog-publish-challenge.component.scss'],
})
export class DialogPublishChallengeComponent implements OnInit {
  form!: FormGroup;

  accept = [FileAcceptEnum.image];
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  displayEnum = ControlDisplayEnum;

  step1 = true;
  step2 = false;

  result?: Results;

  public resultList: Results[] = [];

  addResult() {
    this.resultList.push(new Results());
  }

  removeResult(index: number) {
    if (index > -1) {
      this.resultList.splice(index, 1);
    }
  }

  constructor(
    public resultService: ChallengeResultsService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      criteria: ['', [Validators.required]],
      note: ['', [Validators.required]],
    });
  }

  onSubmit() {
    //const {criteria, note} = this.form.value;
    console.log(this.form.value);
    this.resultService.addResult(
      this.form.value.criteria,
      this.form.value.note
    );

    this.initForm();
  }

  deleteResult(item: any) {
    this.result = item;
    this.resultService.deleteResult(item);
  }
}
