import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import {assetUrl, AvatarTypeEnum, BaseComponent, ChallengeStateTypeEnum} from 'datnek-ds';
import {Select, Store} from "@ngxs/store";
import {Cv, DelenUserModel} from "datnek-sso";
import {first} from "rxjs";
import {ResumeState} from "@del-shared";

interface ItemSubmenu {
  name: string;
}

interface ImagesItems {
  imageSrc: string;
  imageAlt: string;
  title: string;
  date: string;
}

interface VideosItems {
  videoSrc: string;
  title: string;
  date: string;
}

interface DocumentItem {
  documentSrc: string;
  docKeyshot: string;
  docName: string;
  docDescription: string;
}

interface ChallengesItems {
  id: string;
  imageSrc: string;
  title: string;
  subtitle: string;
  description: string;
  name_organizer: string;
  date_debut: string;
  date_fin: string;
  status: AvatarTypeEnum;
  progress: ChallengeStateTypeEnum;
}

@Component({
  selector: 'network-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent implements OnInit {
  avatarEnum = AvatarTypeEnum;
  challengeEnum = ChallengeStateTypeEnum;

  label = 'Mon premier bouton';
  showPopup = false;
  menuLeft = false;
  menuRight = false;
  dataImagesGallery: ImagesItems[] = [
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-1.jpg',
      imageAlt: 'Diplome Sorbone',
      title: "Diplome d'Ingénieur en Analyse de Données",
      date: '12 juin 2021',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-2.jpg',
      imageAlt: 'Diplome Sorbone',
      title: "Attestation d'analyse staistique",
      date: '02 mars 2011',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-3.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '13 septembre 2015',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-4.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur backend',
      date: '13 juillet 2016',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-5.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '06 mai 2011',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-6.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '06 novembre 2011',
    },
  ];

  dataVideosGallery: VideosItems[] = [
    {
      videoSrc:
        'https://static.videezy.com/system/resources/previews/000/000/168/original/Record.mp4',
      title: "Diplome d'Ingénieur en Analyse de Données",
      date: '12 juin 2021',
    },
  ];

  dataDocument: DocumentItem[] = [
    {
      documentSrc: '#',
      docKeyshot: 'assets/Img/models_cv/model2.PNG',
      docName: 'Graphic Designer Senior à Digitaille',
      docDescription:
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit voluptatibus qui velit officiis tenetur enim cum, accusantium, repudiandae accusamus, delectus consequatur. Sapiente perspiciatis officia praesentium inventore aliquid! Voluptatum, eaque fugiat.',
    },
  ];

  challengeData: ChallengesItems[] = [
    {
      id: UUID.UUID(),
      imageSrc: 'assets/Img/challenge-placeholder-bg.png',
      title: 'Angular component sprint winning',
      subtitle:
        'Curabitur non nulla sit amet nisl tempus convallis quis ac lectus',
      description:
        'Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Sed porttitor lectus nibh.',
      name_organizer: 'Balnero Allan',
      date_debut: '15 janvier 2022',
      date_fin: '12 févirer 2023',
      status: this.avatarEnum.person,
      progress: ChallengeStateTypeEnum.future,
    },
  ];

  @Input()
  submenuData: ItemSubmenu[] = [
    {
      name: 'Pour moi seulement',
    },
    {
      name: 'Pour tout le monde',
    },
  ];

  imageUrl = '';

  currentUser!: DelenUserModel;

  currentResume!: Cv;

  constructor(
    private titleService: Title,
    private translateService: TranslateService,
    private store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.translateService
      .get('home.title')
      .subscribe((r) => this.titleService.setTitle(r));

    this.imageUrl = assetUrl('img/shortpixel.png');

    const sub =  this.store.select((state) => state.user.currentUser).subscribe(res => {
      this.currentUser = res;
    });

    const sub2 =  this.store.select((state) => state.resume.currentResume).subscribe(res => {
      this.currentResume = res;
    });

    this.subscribe.add(sub);
    this.subscribe.add(sub2);
  }

  getName(): string  | undefined {
    return `${this.currentUser.FirstName?.split(' ').slice(0, 1) } ${this.currentUser.LastName?.split(' ').slice(0, 1)} `
  }

  showmenuLeft() {
    this.menuLeft = !this.menuLeft;
  }

  showmenuRight() {
    this.menuRight = !this.menuRight;
  }

  popupShow() {
    this.showPopup = !this.showPopup;
  }

  functioncall(event: any) {
    console.log('functioncall', event);
  }
}
