import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogService,
  DialogTypeEnum,
  SelectGlobalSearchComponent,
  BaseComponent,
} from 'datnek-ds';
import { Environment } from 'datnek-core';
import { AuthService, UserSsoState } from 'datnek-sso';
import { Store } from '@ngxs/store';
import { User } from '@del-shared';
@Component({
  selector: 'del-shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DialogService],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = true;
  searchList = false;

  currentUser!: User;
  searchbox = false;

  isLogin = false;

  educationUrl = 'user/profile/formation/educations';

  constructor(
    public router: Router,
    private store: Store,
    @Inject(Environment.Provider.ENVIRONMENT)
    public environment: Environment.Env,
    private modalService: DialogService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    const sub = this.store
      .select((state) => state.user.currentUser)
      .subscribe((res) => {
        this.isLogin = !!res;
        this.currentUser = res;
      });

    this.subscribe.add(sub);
    setTimeout(() => (this.isLoading = false), 2500);
    this.educationUrl = `${this.environment.website.websiteDelenscioHome}${this.educationUrl}`;
  }

  openAutocomplete(origin: HTMLElement): void {
    this.modalService.open(SelectGlobalSearchComponent, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  home() {
    this.router.navigate(['/']);
  }

  onFocus() {
    this.searchList = !this.searchList;
  }

  closeSearchlist() {
    this.searchList = false;
  }

  enterprise() {
    this.router.navigate(['/enterprise/list-enterprises']);
  }

  chat() {
    this.router.navigate(['/chat/home']);
  }

  login(e: any) {
    e.preventDefault();
    this.router.navigateByUrl(this.environment.securityRoute.login);
    /* this.isLogin = true;
    localStorage.setItem('partialLogin', 'true');
    this.router.navigate(['/']);
    window.location.reload();  */
    // this.router.navigate(['/register']);
  }

  groups() {
    this.router.navigate(['/groups/home-group']);
  }
  notifications() {
    this.router.navigate(['/notifications']);
  }

  link(url: string) {
    window.open(url, '_blank');
  }

  showSearchbox() {
    this.searchbox = !this.searchbox;
  }
}
