import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LyDialog } from '@alyle/ui/dialog';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
} from 'datnek-ds';
import { Overlay } from '@angular/cdk/overlay';
import {
  DialogDeleteGroupComponent,
  DialogPostInGroupOrUserComponent,
  DialogSharePostWithEditComponent,
  DialogViewParticipantsComponent,
} from '@network-shared';
import { DialogAddCalendarComponent } from '@del-shared';

@Component({
  selector: 'network-single-event',
  templateUrl: './single-event.component.html',
  styleUrls: ['./single-event.component.scss'],
})
export class SingleEventComponent implements OnInit {
  isLoading = false;
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  eventTitle = "Nom complet de l'évènement de l'évènement";
  eventSubtitle = "Sous-titre de l'évènement";

  constructor(
    private _dialog: LyDialog,
    private router: Router,
    private overlay: Overlay,
    private modalService: DialogService
  ) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  editEvent() {
    const ref = this.modalService.open(DialogAddCalendarComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Modofoer l'évènement`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  deleteEvent() {
    const ref = this.modalService.open(DialogDeleteGroupComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Supprimer l'évènement`,
        size: DialogSizeEnum.small,
      },
    });
  }

  sharePostWithEdit() {
    const ref = this.modalService.open(DialogSharePostWithEditComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Partager l'évènement`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  postInGroupOrUser() {
    const ref = this.modalService.open(DialogPostInGroupOrUserComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Partager l'évènement dans un groupe`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  viewParticipants() {
    const ref = this.modalService.open(DialogViewParticipantsComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Voir les partitipants à l'évènement`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  goToEvent(e: any) {
    this.router.navigate(['/events']);
  }
}
