import { Component, OnInit } from '@angular/core';
import {
  FileAcceptEnum,
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  ControlDisplayEnum,
  DialogRef,
  UploadFileFormlyFieldProps,
  IconRadioImageEnum,
  UtilsInterface,
  ImageTextDropdownFormlyFieldProps,
  InputCheckboxFormlyFieldProps,
  InputDateFormlyFieldProps,
  InputTimeFormlyFieldProps,
  SelectListTypeEnum,
  Select2FormlyFieldProps, InputRadioFormlyFieldProps, EditorBaseFormlyFieldProps, InputAddressFormlyFieldProps
} from 'datnek-ds';
import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig, FormlyFormOptions} from "@ngx-formly/core";
import {HelperService} from "datnek-sso";

interface Items {
  id: number;
  name: string;
  src: string;
  type: string;
}

@Component({
  selector: 'del-shared-dialog-add-challenge',
  templateUrl: './dialog-add-challenge.component.html',
  styleUrls: ['./dialog-add-challenge.component.scss'],
})
export class DialogAddChallengeComponent implements OnInit {
  accept = [FileAcceptEnum.image];
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  displayEnum = ControlDisplayEnum;

  Presentiel = false;
  Online = false;

  dataUsers: Items[] = [
    {
      id: 0,
      name: 'John Doe',
      src: 'assets/Img/user-placeholder-avatar.svg',
      type: 'person',
    },
    {
      id: 0,
      name: 'DIgitaille_CM',
      src: 'assets/Img/profile_img_jobs.png',
      type: 'society',
    },
    {
      id: 0,
      name: 'Graven Inc.',
      src: 'assets/Img/profile_img_jobs.png',
      type: 'society',
    },
  ];




  toDay = new Date();

  form = new FormGroup({});
  model = {
    Title:  null,
    Background:  null,
    CreatorId: null,
    Type:  null,
    Subject:  null,
    SubjectUpload:  null,
    SubjectLink:  null,
    TypeOfSubject:  null,
    Category:  null,
    AskParticipation:  null,
    CorrectionCriteria:  null,
    StartDate: null,
    StartTime: null,
    ConditionAnswerChallenge: null,
    ConditionGiveChallenge: null,
    EndDate: null,
    EndTime: null,
    InRemoteLink: null,
    Instruction: null,
    Place: null,
    PlaceAddress: null,
  };
  options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: true,
    },
  };

  isLoading = false;

  fields: FormlyFieldConfig[] = [
    {
      key: 'Background',
      type: 'upload',
      props: {
        required: true,
        smallHeight: true,
        accept: [FileAcceptEnum.image],
        title: "Téléchargez une photo de couverture",
        imgSize: "Largeur minimum de 1280 X 720",
        fileType: "single",
        type: "image",
        multimedia: false,
        placeholder: 'Ajouter une légende'
      } as UploadFileFormlyFieldProps,
    },
    {
      wrappers: ['panel-div'],
      props: {
        label: 'p-3'
      },
      fieldGroup: [
        {
          key: 'Title',
          type: 'name',
          props: {
            label: "Titre du challenge",
            required: true
          },
        },
        {
          key: 'CreatorId',
          type: 'dropdown-image-text',
          props: {
            label: 'Publier le challenge en tant que',
            required: true,
            isFormControl: true,
            icon: IconRadioImageEnum.me,
            options: [
              { label: 'Danick takam', value: 'danick', type: ControlDisplayEnum.inline, icon: IconRadioImageEnum.me },
              { label: 'Datnek Bv', value: 'datnek', type: ControlDisplayEnum.inline, icon: IconRadioImageEnum.enterprise },
              { label: 'Afelio', value: 'afelio', type: ControlDisplayEnum.inline, icon: IconRadioImageEnum.enterprise },
            ] as UtilsInterface.DropdownItem[],
          } as ImageTextDropdownFormlyFieldProps,
        },
        {
          key: 'Type',
          type: 'radio',
          props: {
            label: "Type de challenge",
            position: "flex",
            required: true,
            options: [
              { Label: 'Public', Value: 'Public' },
              { Label: 'Privé', Value: 'Private' }
            ],
          } as InputRadioFormlyFieldProps,
        },
        {
          key: 'AskParticipation',
          type: 'radio',
          props: {
            label: "Faire la demande pour participer au challenge",
            position: "flex",
            required: true,
            options: this.helperService.getOptionYesNo(),
          } as InputRadioFormlyFieldProps,
        },
        {
          key: 'TypeOfSubject',
          type: 'select',
          props: {
            label: "Comment souhaitez-vous renseigner le sujet du challenge?",
            //position: "block",
            required: true,
            options: [
              { Label: "Selectionner un type", Value: '', Checked: true },
              { Label: "Remplir sur l'application", Value: 'ebook' },
              { Label: 'Lien du site', Value: 'Link' },
              { Label: 'Télécharger un fichier', Value: 'File' }
            ] as UtilsInterface.ValueLabel[],
          } , //as InputRadioFormlyFieldProps,
        },
        {
          key: 'Subject',
          type: 'editor',
          props: {
            label: 'Sujet du challenge',
            placeholder: 'Commencez à rédiger...',
            required: true,
            className: ['h-110'],
          } as EditorBaseFormlyFieldProps,
          expressions: {
            hide : (field: FormlyFieldConfig) => {
              return !field.model.TypeOfSubject ||  field.model.TypeOfSubject !== 'ebook'
            }
          }
        },
        {
          key: 'SubjectLink',
          type: 'url',
          props: {
            required: true,
            label: 'Lien vers le meeting en ligne'
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return !field.model.TypeOfSubject ||  field.model.TypeOfSubject !== 'Link'
            }
          }
        },
        {
          key: 'SubjectUpload',
          type: 'upload',
          props: {
            required: true,
            accept: [FileAcceptEnum.zip],
            title:  "Téléchargez le fichier",
            fileType: "single",
            smallHeight: true,
            imgSize: "Le fichier doit être d'extension (zip, rar, tgz)",
            type: "zip",
            multimedia: false,
          } as UploadFileFormlyFieldProps,
          expressions: {
            hide : (field: FormlyFieldConfig) => {
              return !field.model.TypeOfSubject ||  field.model.TypeOfSubject !== 'File'
            }
          }
        },
        {
          key: 'Category',
          type: 'radio',
          props: {
            label: "Catégorie de challlenge",
            position: "flex",
            required: true,
            options: [
              { Label: 'Académique', Value: 'school' },
              { Label: 'Professionnel', Value: 'Professional' }
            ],
          } as InputRadioFormlyFieldProps,
          expressions: {
            hide : (field: FormlyFieldConfig) => {
              return !field.model.TypeOfSubject
            }
          }
        },

        {
          key: 'CorrectionCriteria',
          type: 'task-container',
          props: {
            label: 'Critères de correction du challenge',
            required: true,
            description: "Il s'agit d'un ensemble de critère qui doit être pris en compte lors de la réalisation du challenge",
          },
         /*  expressions: {
            hide : (field: FormlyFieldConfig) => {
              return !field.model.TypeOfSubject
            }
          }, */
          fieldArray: {
            key: 'ConditionTitle',
            type: 'task',
            props: {
              placeholder: 'Saisissez une condition',
              required: true,

            },

          },

        },


        {
          fieldGroupClassName: 'row form-group-custorm',
          fieldGroup: [
            {
              key: 'StartDate',
              type: 'date',
              props: {
                label:  'Date de début du challenge',
                required: true,
                minDate: this.toDay
              } as InputDateFormlyFieldProps,
              expressions: {
                className: (field: FormlyFieldConfig) => {
                  return field.model?.StartDate ? 'col-md-6 col-sm-12' : 'col-12';
                },
                hide : (field: FormlyFieldConfig) => {
                  return !field.model.TypeOfSubject
                }
              }
            },
            {
              className: 'col-md-6 col-sm-12',
              key: 'StartTime',
              type: 'time',
              props: {
                label:  'Heure de début du challenge',
                required: true,
              } as InputTimeFormlyFieldProps,
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(field.model?.StartDate && !isNaN( Date.parse(field.model?.StartDate)));
                },
                'props.minTime': (field: FormlyFieldConfig) => {
                  return field.model.StartDate &&  new Date(field.model.StartDate) > this.toDay ? null :  new Date().toLocaleTimeString().substr(0,5) ;
                },
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'row form-group-custorm',
          fieldGroup: [
            {
              key: 'EndDate',
              type: 'date',
              props: {
                label:  'Date de fin',
                required: true,
                minDate: new Date()
              } as InputDateFormlyFieldProps,
              expressions: {
                className: (field: FormlyFieldConfig) => {
                  return field.model?.StartDate ? 'col-md-6 col-sm-12' : 'col-12';
                },
                hide: (field: FormlyFieldConfig) => {
                  return !(field.model?.StartDate && !isNaN( Date.parse(field.model?.StartDate)));
                },
                'props.minDate': (field: FormlyFieldConfig) => {
                  return new Date(field.model?.StartDate) ;
                },
              }
            },
            {
              className: 'col-md-6 col-sm-12',
              key: 'EndTime',
              type: 'time',
              props: {
                label:  'Heure de fin',
                required: true,
              } as InputTimeFormlyFieldProps,
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(field.model?.StartDate && !isNaN( Date.parse(field.model?.StartDate)));
                },
                'props.minTime': (field: FormlyFieldConfig) => {
                  return field.model.EndDate && field.model?.StartDate && field.model.EndDat > field.model?.StartDate ? null : field.model?.StartTime ;
                },
              },
            },
          ],
        },

        {
          key: 'ConditionAnswerChallenge',
          type: 'editor',
          props: {
            label: 'Critères de correction du challenge',
            placeholder: 'Commencez à rédiger...',
            description: "Il s'agit d'un ensemble de critère qui doit être pris en compte lors de la réalisation du challenge",
            required: true,
            className: ['h-110'],
          } as EditorBaseFormlyFieldProps,
          expressions: {
            hide : (field: FormlyFieldConfig) => {
              return !field.model.TypeOfSubject
            }
          }
        },
        {
          key: 'ConditionGiveChallenge',
          type: 'editor',
          props: {
            label: 'Conditions de rendu du challenge',
            placeholder: 'Commencez à rédiger...',
            required: true,
            className: ['h-110'],
          } as EditorBaseFormlyFieldProps,
          expressions: {
            hide : (field: FormlyFieldConfig) => {
              return !field.model.EndDate
            }
          }
        },
        {
          key: 'Place',
          type: 'checkbox',
          props: {
            label: "Type d\'évènement",
            position: "flex",
            required: true,
            options: [
              { Label: 'En ligne', Value: 'In remote' },
              { Label: 'En Présentiel', Value: 'In office' }
            ],
          } as InputCheckboxFormlyFieldProps,
          expressions: {
            hide : (field: FormlyFieldConfig) => {
              return !field.model.EndDate
            }
          }
        },
        {
          key: 'PlaceAddress',
          type: 'address',
          props: {
            label: "Lieu du challlenge",
            required: true,
            btnLabel: "Soumettre"
          } as InputAddressFormlyFieldProps,
          expressions: {
            'props.required': (field: FormlyFieldConfig) => {
              return field.model?.Place?.includes('office');
            },
            hide: (field: FormlyFieldConfig) => {
              return !field.model?.Place?.includes('office');
            }
          }
        },
        {
          key: 'InRemoteLink',
          type: 'url',
          props: {
            label: 'Lien vers le meeting en ligne'
          },
          expressions: {
            'props.required': (field: FormlyFieldConfig) => {
              return field.model?.Place?.includes('remote');
            },
            hide: (field: FormlyFieldConfig) => {
              return !field.model?.Place?.includes('remote');
            }
          }
        },
        {
          key: 'Instruction',
          type: 'editor',
          props: {
            label: "Instructions d\\'accès au sujet du challenge",
            required: true,
            placeholder: 'Commencez à rédiger...',
            className: ['h-110'],
          } as EditorBaseFormlyFieldProps,
          expressions: {
            hide : (field: FormlyFieldConfig) => {
              return !field.model.EndDate
            }
          }
        }
      ]
    },

  ];

  constructor(public ref: DialogRef, private helperService: HelperService) {}
  ngOnInit(): void {}
  submit(): void {
    if(this.form.valid){
      this.ref.close();
    }
  }
}
