import {Component, OnInit} from '@angular/core';
import {AuthService, StepRegistrationEnum} from "datnek-sso";
import {BaseComponent} from "datnek-ds";

@Component({
  selector: 'network-home-app',
  templateUrl: './home-app.component.html',
  styleUrls: ['./home-app.component.scss']
})
export class HomeAppComponent extends BaseComponent implements OnInit {
  isLogin = false;
  constructor( private authService: AuthService) {
    super();
  }

  ngOnInit(): void {
    const sub =  this.authService.getConnectedUser().subscribe(user => {
      if (user){
        this.isLogin =  user.StepRegistration  === StepRegistrationEnum.STEP3;
      }
      console.log('the services found this user: ', user);
    });

    this.subscribe.add(sub);
  }

}
