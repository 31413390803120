import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
@Injectable({
  providedIn: 'root',
})
export class DeviceService {

  isNotSsoPage$ = new BehaviorSubject(true);
  displayChat$ = new BehaviorSubject(false);

  // constructor(private deviceService: DeviceDetectorService) {}

  // get getInformation(): DeviceInterface {
  //   const deviceInfo = this.deviceService.getDeviceInfo() as DeviceInterface;
  //   deviceInfo.isDesktopDevice = this.deviceService.isDesktop();
  //   deviceInfo.isTablet = this.deviceService.isTablet();
  //   deviceInfo.isMobile = this.deviceService.isMobile();

  //   return deviceInfo;
  // }

  get isMobileApps() {
    return window.matchMedia('(any-pointer:coarse)').matches;
    /*navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i) */
  }



}
