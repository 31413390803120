import { Component, OnInit } from '@angular/core';
import {AvatarTypeEnum} from "datnek-ds";

@Component({
  selector: 'nk-shared-invitations-menu-mobile',
  templateUrl: './invitations-menu-mobile.component.html',
  styleUrls: ['./invitations-menu-mobile.component.scss'],
})
export class InvitationsMenuMobileComponent implements OnInit {
  avatarEnum = AvatarTypeEnum;
  constructor() {}

  ngOnInit(): void {}
}
