<div class="card-challenge-description user-box">
  <div class="datnek-ds-card mb-0 challenge-box">
    <div class="header"></div>
    <div class="content">
      <div class="avatar">
        <img src="/assets/Img/challenge-placeholder-bg.png" alt="" />
      </div>

      <div class="infos challenge-content">
        <div class="display-1">Challenge</div>
        <div class="description text-center">
          <div>
            <p class="px-3">{{ description | truncatetext: 170 }}</p>
            <datnek-ds-link
              href="javascript:;"
              label="En savoir plus"
              (datnekdsClickOrTouch)="goToChallengeDescription()"
              class="d-block m-auto"
            ></datnek-ds-link>
          </div>
        </div>
      </div>

      <div
        class="
          datnek-ds-card-footer
          footer
          d-flex
          align-items-center
          p-3
          border-top
        "
      >
        <div class="icons">
          <datnek-ds-button
            [label]="'Créer un challenge'"
            [size]="sizeEnum.large"
            [block]="true"
            [type]="colorEnum.primary"
            (datnekdsClickOrTouch)="addChallenge()"
          ></datnek-ds-button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<datnek-skeleton-challenge-box
  *ngIf="isLoading"
></datnek-skeleton-challenge-box>-->
