<div class="datnek-ds-dialog-body dialog-challenge-accept">
  <div class="content-modal">
    <div class="notif-box p-3">
      <p>
        Vous allez être redirigés vers la plateforme du déroulement du challenge
      </p>
    </div>

    <div class="datnek-ds-dialog-footer">
      <div class="icons d-flex align-items-center justify-content-end">
        <datnek-ds-button
          [label]="'Pas encore'"
          [size]="sizeEnum.large"
          [type]="colorEnum.secondary"
          class="mr-3"
          (datnekdsClickOrTouch)="close()"
        ></datnek-ds-button>
        <datnek-ds-button
          [label]="'Ok'"
          [size]="sizeEnum.large"
          [type]="colorEnum.primary"
          (datnekdsClickOrTouch)="close()"
        ></datnek-ds-button>
      </div>
    </div>
  </div>
</div>
