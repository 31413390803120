<div class="datnek-ds-dialog-body dialog-post-in-user-profile">
  <div class="head p-3">
    <div class="display-6">Poster en tant que</div>
    <datnek-ds-image-text-dropdown
      [submenuData]="meSubmenu"
      [style]="displayEnum.block"
      [status]="avatarEnum.person"
    ></datnek-ds-image-text-dropdown>
  </div>
  <div class="content-modal">
    <p-scrollPanel [style]="{ width: '100%', height: 'calc(100vh - 504px)' }">
      <div class="member-list">
        <nk-shared-suggestion-list
          [userData]="dataUsers"
          [status]="avatarEnum.person"
          [link]="false"
        ></nk-shared-suggestion-list>
      </div>
    </p-scrollPanel>
  </div>

  <div class="datnek-ds-dialog-footer">
    <datnek-ds-button
      [label]="'Terminer'"
      [size]="sizeEnum.large"
      [type]="colorEnum.primary"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
  </div>
</div>
