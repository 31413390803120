import { Component, OnInit } from '@angular/core';
import {AvatarTypeEnum} from "datnek-ds";

@Component({
  selector: 'nk-shared-home-menu-mobile',
  templateUrl: './home-menu-mobile.component.html',
  styleUrls: ['./home-menu-mobile.component.scss'],
})
export class HomeMenuMobileComponent implements OnInit {
  avatarEnum = AvatarTypeEnum;
  constructor() {}

  ngOnInit(): void {}
}
