import {Event as EventCompany, MediaNetwork} from '@del-shared';

export namespace EventCompanyActions {
  export class setEventCompany {
    static readonly type = '[Network Api] Set EventCompanys of Current user';
    constructor(public count?: number, public data?: EventCompany[]) {}
  }

  export class getLazyByCompany {
    static readonly type = '[Network Api] Get EventCompanys Lazy Resume';
    constructor(
      public companyId: string,
      public skip: number,
      public take: number | null = null
    ) {}
  }

  export class create {
    static readonly type = '[Network Api] Create EventCompany';
    constructor(public model: EventCompany) {}
  }

  export class update {
    static readonly type = '[Network Api] Update EventCompany';
    constructor(public model: EventCompany) {}
  }

  export class remove {
    static readonly type = '[Network Api] Delete EventCompany';
    constructor(public model: EventCompany) {}
  }

  export class removeMedia {
    static readonly type = '[Network Api] Delete Media of EventCompany';
    constructor(public model: MediaNetwork) {}
  }
}
