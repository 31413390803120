<ng-container *ngIf="!isLoading && status === avatarEnum.person">
  <div
    class="datnek-ds-card suggestion-card-mobile pointer"
    *ngFor="let data of userData"
    [ngClass]="
      isInline === true ? 'd-flex flex-row inline-box' : 'd-flex flex-column'
    "
    (datnekdsClickOrTouch)="goToPitch($event)"
  >
    <div class="video" (mouseover)="startPreview()" (mouseout)="stopPreview()">
      <div class="play">
        <img src="assets/Img/icons/play-video.svg" alt="" />
      </div>
      <video class="embed-responsive embed-responsive-16by9" #videoPlayer>
        <source
          src="https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm"
        />
      </video>
    </div>
    <div class="infos p-3 d-flex">
      <datnek-ds-avatar [size]="sizeEnum.small" *ngIf="avatar"></datnek-ds-avatar>
      <div class="w-100 d-flex flex-column pl-3">
        <div class="name">
          <div class="display-2 truncated mr-2">
            {{ data.name }}
            <img src="assets/icons/src/check-color.png" alt="" />
          </div>
        </div>
        <span class="text-left">
          <span class="truncated"> {{ data.professionalTitle }}</span> à
          <span class="truncated">{{ data.society }}</span></span
        >
        <span id="relations" class="display-8 mb-3 text-left">
          {{ data.relations }} relations en commun
        </span>
        <datnek-ds-button
          label="Suivre"
          [type]="colorEnum.primary"
          [block]="true"
          class="mb-3"
          *ngIf="followed === false"
          (datnekdsClickOrTouch)="onFollow()"
        ></datnek-ds-button>
        <datnek-ds-button
          label="Suivi"
          [type]="colorEnum.secondary"
          [block]="true"
          class="mb-3"
          *ngIf="followed === true"
          (datnekdsClickOrTouch)="onFollow()"
        ></datnek-ds-button>
        <div class="stats d-flex justify-content-between border-top pt-3">
          <div class="followers"><span class="">264</span> following</div>
          <div class="followers"><span class="">134</span> folowers</div>
        </div>
      </div>
      <!--<datnek-ds-button iconLeft="user" label=""></datnek-ds-button>-->
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isLoading && status === avatarEnum.society">
  <div
    class="
      datnek-ds-card
      network-user-suggestion-small
      suggestion-single
      pointer
    "
    *ngFor="let data of userData"
    [ngClass]="
      isInline === true ? 'd-flex flex-row inline-box' : 'd-flex flex-column'
    "
    (datnekdsClickOrTouch)="goToSociety()"
  >
    <div class="img"></div>

    <div class="infos d-flex px-3 pt-3">
      <datnek-ds-avatar [size]="sizeEnum.small"></datnek-ds-avatar>
      <div class="name d-flex flex-column">
        <div class="d-flex">
          <div class="display-2 mr-2">
            {{ data.society | truncatetext: 15 }}
          </div>
        </div>
        <span>{{ data.sector | truncatetext: 15 }}</span>
        <span id="relations" class="display-8 mb-3">
          {{ data.relations }} relations en commun
        </span>
        <datnek-ds-button
          label="Suivre"
          [type]="colorEnum.primary"
          [block]="true"
          class="mb-3"
          *ngIf="followed === false"
          (datnekdsClickOrTouch)="onFollow()"
        ></datnek-ds-button>
        <datnek-ds-button
          label="Suivi"
          [type]="colorEnum.secondary"
          [block]="true"
          class="mb-3"
          *ngIf="followed === true"
          (datnekdsClickOrTouch)="onFollow()"
        ></datnek-ds-button>
        <div class="stats d-flex justify-content-between border-top py-3">
          <div class="followers"><span class="">264</span> following</div>
          <div class="followers"><span class="">134</span> folowers</div>
        </div>
      </div>
      <!--<datnek-ds-button iconLeft="user" label=""></datnek-ds-button>-->
    </div>
  </div>
</ng-container>

<div class="network-user-suggestion-small" *ngIf="isLoading">
  <datnek-skeleton-suggestion-single></datnek-skeleton-suggestion-single>
</div>
