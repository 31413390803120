import { ComponentType } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
} from 'datnek-ds';

interface messages {
  content: string;
  date: string;
  hours: string;
  image: string;
  type: string;
}

@Component({
  selector: 'network-saved-category-messages',
  templateUrl: './saved-category-messages.component.html',
  styleUrls: ['./saved-category-messages.component.scss'],
})
export class SavedCategoryMessagesComponent implements OnInit {
  @ViewChild('widgetsContent') widgetsContent!: ElementRef;
  filters = true;
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = false;
  readMore = false;

  iconShow = false;
  categories = [
    {
      name: 'Fait Politiques',
    },
    {
      name: 'Affaires juridiques',
    },
    {
      name: 'International',
    },
    {
      name: 'Faits divers',
    },
    {
      name: 'Technologies',
    },
    {
      name: 'Amour',
    },
    {
      name: 'Cinéma',
    },
    {
      name: 'Entrepreneuriat',
    },
    {
      name: 'Développement personnel',
    },
    {
      name: 'Santé',
    },
    {
      name: 'Matériel informatique',
    },
    {
      name: 'Voyages',
    },
    {
      name: 'Business',
    },
    {
      name: 'Entreprises',
    },
  ];

  messages: messages[] = [];

  listFilter = [
    'Tout',
    'Posts',
    'Articles',
    'Groupes',
    'Chat Messages',
    'Commentaires',
    'Pitch',
  ];

  constructor(private router: Router, private modalService: DialogService) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  unsave(content: TemplateRef<any> | ComponentType<any> | string) {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        size: DialogSizeEnum.small,
        title: "Supprimer (l'publication/ le post) de la liste ?",
      },
    });
  }

  goToArticle(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate(['/single-publication']);
  }

  goToSavedCategery(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate(['/saved-category-messages']);
  }

  goToHome(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate(['/']);
  }

  scrollLeft() {
    this.widgetsContent.nativeElement.scrollLeft -= 150;
  }

  scrollRight() {
    this.widgetsContent.nativeElement.scrollLeft += 150;
  }

  showIcon() {
    this.iconShow = !this.iconShow;
  }
}
