import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-panel',
  template: `
    <div class="{{props.label}}">
      <ng-container #fieldComponent></ng-container>
    </div>
  `,
})
export class PanelWrapperFormlyComponent extends FieldWrapper {
}
