import { Component, OnInit } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef
} from 'datnek-ds';

interface Items {
  label: string;
  description?: string;
}

@Component({
  selector: 'nk-shared-dialog-add-rules-group',
  templateUrl: './dialog-add-rules-group.component.html',
  styleUrls: ['./dialog-add-rules-group.component.scss'],
})
export class DialogAddRulesGroupComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  input = false;
  input1 = false;
  input2 = false;
  options = false;
  options1 = false;
  options2 = false;
  option: Items[] = [
    {
      label: "Valeur de l'option 1",
    },
  ];

  questionType!: 'caseACocher' | 'multiLigne' | 'freeText';

  constructor(public ref: DialogRef) {}

  ngOnInit(): void {}

  addInput() {
    this.input = !this.input;
  }
  addInput1() {
    this.input1 = !this.input1;
  }
  addInput2() {
    this.input2 = !this.input2;
  }

  showOption() {
    this.options = !this.options;
  }
  showOption1() {
    this.options1 = !this.options1;
  }
  showOption2() {
    this.options2 = !this.options2;
  }

  caseACocher() {
    this.questionType = 'caseACocher';
  }

  multiLigne() {
    this.questionType = 'multiLigne';
  }

  freeText() {
    this.questionType = 'freeText';
  }

  close() {
    this.ref.close(null);
  }
}
