import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  Type,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  DialogRedirectToComponent,
  TooltipTypeEnum,
} from 'datnek-ds';
import { ThemeService } from '../../../../../../core';

import {
  DialogPrivateContentComponent,
  DialogAddMemberComponent,
  DialogSharePostWithEditComponent,
  DialogPostInGroupOrUserComponent,
  DialogGroupAdhesionComponent,
  DialogPostInUserProfileComponent,
} from '../../dialogs';

interface Competences {
  name: string;
}

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  groupName: string;
  relations: number;
  sector: string;
}

@Component({
  selector: 'nk-shared-card-user-box',
  templateUrl: './card-user-box.component.html',
  styleUrls: ['./card-user-box.component.scss'],
})
export class CardUserBoxComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  dataUsersChat: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Consultant WebDesign',
      name: 'John Doe',
      society: 'DIGITAILLE',
      groupName: '',
      relations: 12,
      sector: '',
    },
  ];

  /**
   * Le Nom du Profil de la personne ou de l'entreprise
   */
  @Input()
  name: undefined | string = '';

  /**
   * Le Nom donné par delenscio du Profil de la personne ou de l'entreprise
   */
  @Input()
  userName: undefined | string = '';

  /**
   * La profession de la personne ou de l'entreprise
   */
  @Input()
  resumeTitle = '';

  /**
   * Le type de status renvoyé : 'person' | 'society' = 'person'
   *
   * @default = 'person'
   */
  @Input()
  status!: AvatarTypeEnum;

  /**
   * Si c'est le Feed d'une entreprise, Le profil est une proposition de candidature
   *
   * @default = false
   */
  @Input()
  openForJob = false;

  /**
   * Si c'est la page de Stats de ChallengeComp
   *
   * @default = false
   */
  @Input()
  allStats = false;

  /**
   * Si c'est la page de Chat
   *
   * @default = false
   */
  @Input()
  onChat = false;

  /**
   * Si c'est la page de Chat
   *
   * @default = false
   */
  @Input()
  restricted = false;

  /**
   * Liste des compétences du candidat
   * NB: à n'afficher que sur le feed d'une entreprise
   *
   */
  @Input()
  competences = [
    {
      name: 'Destion de la paie',
    },
    {
      name: 'Intégration des données dans la plateforme',
    },
    {
      name: 'Manipiulation des Big Data',
    },
    {
      name: 'Analyse des effets financiers',
    },
    {
      name: 'Harmnisation des candidature',
    },
    {
      name: "Mise en place d'un système de GEPEC",
    },
    {
      name: 'Gestion du recrutement',
    },
    {
      name: 'Rédaction de conventions collectives',
    },
  ];

  readMore = false;
  followed = false;

  isLoading = true;

  constructor(
    private router: Router,
    private themeService: ThemeService,
    private modalService: DialogService
  ) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  toggleReadMore(): void {
    this.readMore = !this.readMore;
  }

  editPicture(): void {
    const ref = this.modalService.open(DialogRedirectToComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Modifier la photo de profil`,
        size: DialogSizeEnum.small,
      },
    });
  }

  showPrivate() {
    const ref = this.modalService.open(DialogPrivateContentComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Contenu privé`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  inviteMember() {
    const ref = this.modalService.open(DialogAddMemberComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter un membre`,
        size: DialogSizeEnum.small,
      },
    });
  }

  sharePostWithEdit(): void {
    const ref = this.modalService.open(DialogSharePostWithEditComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Modifier le post et partager`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  postInGroupOrUser(): void {
    const ref = this.modalService.open(DialogPostInGroupOrUserComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Poster {dans un {groupe}}/{Sur le profil d'un(e) ami(e)}`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  shareOnChatbox(): void {
    const ref = this.modalService.open(DialogPostInGroupOrUserComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Partagez le groupe dans un chat`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  postInUserProfile(): void {
    const ref = this.modalService.open(DialogPostInUserProfileComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Poster sur le profil d'un(e) ami(e)`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  adhesionDemand(): void {
    this.followed = !this.followed;
    const ref = this.modalService.open(DialogGroupAdhesionComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Règles d'adhésion du groupe`,
        size: DialogSizeEnum.small,
      },
    });
  }

  goHomeDialog(): void {
    this.followed = !this.followed;
    const ref = this.modalService.open(DialogRedirectToComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Rediriger vers Delenscio Home`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  goToProfile(e: any): void {
    this.router.navigate(['/']);
  }

  goToFollowingPersons(e: any): void {
    this.router.navigate(['/request/following']);
  }

  goToMyFollowers(e: any): void {
    this.router.navigate(['/request/followers']);
  }

  goToExternalLink(url: string): void {
    window.open(url, '_blank');
  }
}
