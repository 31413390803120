<div class="datnek-ds-dialog-body dialog-private-content">
  <div class="content-modal">
    <div class="notif-box">
      <div
        class="
          content
          d-flex
          flex-column
          justify-content-center
          text-center
          px-md-5
          py-3
        "
      >
        <img
          src="assets/Img/icons/protected-personal-profile-private-user-blocked-user.png"
          alt=""
        />
        <div class="display-2 mb-3">Informations confidentielles</div>
        <p>
          L’accès à cette information de profil est confidentielle et requiert
          un abonnement. Vous aurez accès à des informations sensibles de ce
          profil
        </p>
      </div>
    </div>
  </div>

  <div class="datnek-ds-dialog-footer">
    <div class="icons d-flex flex-column flex-sm-row align-items-center">
      <datnek-ds-button
        [label]="'Ne pas souscrire maintenant'"
        [size]="sizeEnum.large"
        [block]="true"
        [type]="colorEnum.secondary"
        (datnekdsClickOrTouch)="dialogRef.close()"
      ></datnek-ds-button>
      <datnek-ds-button
        [label]="'Souscrire à l’abonnement'"
        [size]="sizeEnum.large"
        [block]="true"
        [type]="colorEnum.primary"
        (datnekdsClickOrTouch)="gotoPrices($event)"
      ></datnek-ds-button>
    </div>
  </div>
</div>
