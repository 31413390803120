import {UserSsoState} from "datnek-sso";
import {ArticleState} from "./users";
import {EventState} from "./companies";
import {PublicationState} from "./users";
import {ResumeState} from "@del-shared";

export const AppNetworkState = [
  UserSsoState,
  ArticleState,
  EventState,
  PublicationState,
  ResumeState
];
