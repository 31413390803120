<div class="datnek-ds-dialog-body dialog-post-in-group-or-user">
  <div class="head p-3">
    <div class="display-6">Poster en tant que</div>
    <datnek-ds-image-text-dropdown
      [submenuData]="meSubmenu"
      [style]="displayEnum.block"
      [status]="avatarEnum.person"
    ></datnek-ds-image-text-dropdown>
  </div>
  <div class="content-modal">
    <div class="content-text px-3 py-3">
      <p>Ajouter une personne à cette conversation pour former un groupe.</p>
      <datnek-ds-search-input
        placeholder="Trouver des personnes"
      ></datnek-ds-search-input >
    </div>

    <div class="add-member p-3">
      <div
        class="member-item"
        *ngFor="let item of items; let i = index"
        (datnekdsClickOrTouch)="remove(i)"
      >
        <datnek-ds-avatar [size]="sizeEnum.medium1"></datnek-ds-avatar>
        <span class="close pointer">x</span>
      </div>
    </div>
    <p-scrollPanel [style]="{ width: '100%', height: 'calc(100vh - 504px)' }">
      <div class="member-list">
        <nk-shared-suggestion-list
          [userData]="dataUsers"
          [status]="avatarEnum.person"
          [link]="false"
        ></nk-shared-suggestion-list>
      </div>
    </p-scrollPanel>
  </div>

  <div class="datnek-ds-dialog-footer">
    <datnek-ds-button
      [label]="'Terminer'"
      [size]="sizeEnum.large"
      [type]="colorEnum.primary"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
  </div>
</div>
