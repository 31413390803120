<div class="datnek-ds-dialog-body dialog-challenge-rejected">
  <div class="content-modal">
    <div class="notif-box p-3">
      <p class="mb-0">
        125 contacts ont été trouvés dans votre compte
        bjm.graphicdesigner@gmail.com
      </p>
      <div class="p-3">
        <datnek-ds-checkbox-group
          label="Supprimer les contacts en double"
          [markLeft]="true"
        ></datnek-ds-checkbox-group>
      </div>
    </div>

    <div class="datnek-ds-dialog-footer">
      <datnek-ds-button
        [label]="'Annuler'"
        [size]="sizeEnum.large"
        [type]="colorEnum.secondary"
        class="mr-3"
        (datnekdsClickOrTouch)="dialogRef.close()"
      ></datnek-ds-button>
      <datnek-ds-button
        [label]="'Importer'"
        [size]="sizeEnum.large"
        [type]="colorEnum.primary"
        (datnekdsClickOrTouch)="dialogRef.close()"
      ></datnek-ds-button>
    </div>
  </div>
</div>
