<div class="datnek-ds-dialog-body dialog-challenge-no-results">
  <div class="content-modal">
    <div class="notif-box p-3">
      <p>
        Vous ne pouvez accéder au challlenge qu’à partir du 03 janvier 2023. Le
        lien du challenge sera disponible lors du début effectif du challenge.
      </p>
    </div>

    <div class="datnek-ds-dialog-footer">
      <datnek-ds-button
        [label]="'Ok'"
        [size]="sizeEnum.large"
        [type]="colorEnum.primary"
        (datnekdsClickOrTouch)="close()"
      ></datnek-ds-button>
    </div>
  </div>
</div>
