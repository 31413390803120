import { Component, Input, OnInit, TemplateRef, Type } from '@angular/core';
import { Router } from '@angular/router';
import {
  SuggestionTypeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogService,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  groupName: string;
  relations: number;
  sector: string;
}

@Component({
  selector: 'nk-shared-suggestion-box',
  templateUrl: './suggestion-box.component.html',
  styleUrls: ['./suggestion-box.component.scss'],
})
export class SuggestionBoxComponent implements OnInit {
  avatarEnum = AvatarTypeEnum;
  colorEnum = ControlTypeColorEnum;

  folowed = false;

  @Input()
  status!: AvatarTypeEnum;

  /**
   * S'il s'agit des suggestions d'un auteur'
   *
   * @default = 'false'
   */
  @Input()
  isAuthor = false;

  /**
   * Si la suggestion de l'publication est dans un Sidebar
   *
   * @default = 'false'
   */
  @Input()
  isSidebar = false;

  /**
   * Si la suggestion de l'publication est en full
   *
   * @default = 'true'
   */
  @Input()
  isFull = true;

  dataUsers: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Jobs, emplois et concours',
      relations: 4,
      sector: 'Génie civil',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Jobs, emplois et concours',
      relations: 18,
      sector: 'Technologie',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Jobs, emplois et concours',
      relations: 0,
      sector: 'Agroalimentaire',
    },
  ];

  /**
   * Les Titre de la card
   */
  @Input()
  titleCard = '';

  /**
   * Les type de suggestion
   */
  @Input()
  suggestionType: SuggestionTypeEnum = SuggestionTypeEnum.profile;

  isLoading = true;

  constructor(private router: Router, private modalService: DialogService) {}

  ngOnInit(): void {
    console.log(this.suggestionType[0]);
    setTimeout(() => (this.isLoading = false), 2500);
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  gotoSuggestion(e: any) {
    e.preventDefault();
    this.router.navigate(['/request/suggestions']);
  }

  gotoListArticles(e: any) {
    e.preventDefault();
    this.router.navigate(['/list-articles']);
  }
}
