import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  AvatarTypeEnum,
  ControlTypeColorEnum,
  IconEnum,
  PopupInterface,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  ControlSizeEnum,
} from 'datnek-ds';

import { DialogAddMemberComponent } from '../../dialogs';

interface UsersItems {
  imageSrc: string;
  time: string;
  name: string;
  writer: string;
  message: string;
}

@Component({
  selector: 'nk-shared-user-chat-list',
  templateUrl: './user-chat-list.component.html',
  styleUrls: ['./user-chat-list.component.scss'],
})
export class UserChatListComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = true;

  /***
   * Pour les cnversations épinglées
   */
  @Input() pinned = false;

  /***
   * All users
   */
  @Input() userData: PopupInterface[] = [];

  /***
   * Listener when click in user
   */
  @Output() openPopupEvent = new EventEmitter<PopupInterface>();

  /**
   * Affiche ou non la barre de recherche
   */
  @Input()
  searchbar = false;

  /**
   * Le Nom du Profil de la personne ou de l'entreprise
   */
  @Input()
  name = '';

  /**
   * La personne qui a envoyé le dernier message
   */
  @Input()
  writer = '';

  /**
   * Le dernier message envoyé
   */
  @Input()
  message = '';

  /**
   * Le date d'envoi du dernier message
   */
  @Input()
  time = '';

  /**
   * Le type de status renvoyé : 'person' | 'society' = 'person'
   *
   * @default = 'person'
   */
  @Input()
  status!: AvatarTypeEnum;

  /**
   *
   */
  @Input()
  leftSidebarMobile = false;

  /**
   *
   */
  @Input()
  isChatActive = false;

  /**
   * If user want to create a new group conversation
   */
  @Input()
  toCreateGroupConversation = true;

  /**
   * If user want to create a new individual conversation
   *
   * @boolean
   */
  @Input()
  toCreateSingleConversation = false;

  /**
   *
   */
  @Input()
  onChatBox = false;

  /**
   * Optional click handler
   */
  @Output()
  onClick = new EventEmitter<any>();

  @Input() showPopup = false;

  constructor(private router: Router, private modalService: DialogService) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  showLeftSidebarMobile(e: any): void {
    this.onClick.emit((e = this.leftSidebarMobile));
  }

  showActiveChat(e: any): void {
    this.onClick.emit((e = this.isChatActive));
  }

  counter(i: number): Array<number> {
    return new Array(i);
  }

  goToChatMessage(e: any): void {
    e.preventDefault();
    this.onClick.emit(this.router.navigate(['/chat/home']));
    this.router.navigate(['/chat/home']);
  }

  addMember(): void {
    const ref = this.modalService.open(DialogAddMemberComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter un nouveau membre`,
        size: DialogSizeEnum.small,
      },
    });
  }

  addSingleMember(): void {
    const ref = this.modalService.open(DialogAddMemberComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter un nouveau membre`,
        size: DialogSizeEnum.small,
      },
    });
  }

  // openPopup() {
  //   this.onClick.emit(this.showPopup);
  //   console.log('SHOWPOPUP CHILD : ' + this.showPopup);
  // }

  openPopup(userPop: PopupInterface): void {
    this.openPopupEvent.emit(userPop);
  }
}
