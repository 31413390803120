import { Component, OnInit } from '@angular/core';
import {AvatarTypeEnum} from "datnek-ds";

@Component({
  selector: 'nk-shared-followers-menu-mobile',
  templateUrl: './followers-menu-mobile.component.html',
  styleUrls: ['./followers-menu-mobile.component.scss'],
})
export class FollowersMenuMobileComponent implements OnInit {
  avatarEnum = AvatarTypeEnum;
  constructor() {}

  ngOnInit(): void {}
}
