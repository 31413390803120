import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ControlSizeEnum,
  IconEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
} from 'datnek-ds';

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  relations: number;
}

@Component({
  selector: 'nk-shared-invitation-list',
  templateUrl: './invitation-list.component.html',
  styleUrls: ['./invitation-list.component.scss'],
})
export class InvitationListComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  @Input() userData: UsersItems[] = [];
  currentIndex = 0;

  /**
   * Le type de suggestion renvoyé : 'invitation' | 'suggestion'
   *
   * @default = 'suggestion'
   */
  @Input()
  type: 'invitation' | 'suggestion' = 'suggestion';

  /**
   * Design de type block. A afficher sur la page des suggestions
   */
  @Input()
  isBlock = false;

  @Input()
  follow = false;

  isLoading = true;

  show = true;

  constructor(private router: Router) {}

  ngOnInit(): void {
    console.log(typeof this.type);
    setTimeout(() => (this.isLoading = false), 2500);
  }

  counter(i: number) {
    return new Array(i);
  }

  isFollowed(index: number): void {
    this.follow = !this.follow;
    this.currentIndex = index;
  }

  onShowed(index: number): void {
    this.show = !this.show;
    this.currentIndex = index;
  }

  goToProfile(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate(['/']);
  }
}
