<form class="container add-article" (ngSubmit)="submit()">
  <section class="datnek-ds-card">
    <formly-form [model]="model" [fields]="fields"
                 [options]="options" [form]="form"></formly-form>

    <button type="submit" id="formAddArticle" hidden></button>
  </section>

</form>


<ng-template #afterDelete let-close="close">
  Le contact a été ajouté/supprimé vos favoris
  <datnek-ds-link label="Voir"></datnek-ds-link
></ng-template>

<!-- TOASTS MESSAGE -->
<ng-template #afterPublish let-close="close">
  L'publication a été publié
  <datnek-ds-button
    [type]="colorEnum.link"
    label="Voir"
    (datnekdsClickOrTouch)="openArticle($event)"></datnek-ds-button>
</ng-template>
