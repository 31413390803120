import { Component, OnInit } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  DialogRef
} from 'datnek-ds';

@Component({
  selector: 'nk-shared-dialog-leave-group',
  templateUrl: './dialog-leave-group.component.html',
  styleUrls: ['./dialog-leave-group.component.scss'],
})
export class DialogLeaveGroupComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;

  constructor(public ref: DialogRef) {}
  ngOnInit(): void {}

  close() {
    this.ref.close(null);
  }
}
