import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import {
  AvatarTypeEnum,
  ControlDisplayEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';
import { Router } from '@angular/router';

import {
  DialogAddSignalizationComponent,
  DialogAddCategoryComponent,
  DialogSendMessageComponent,
} from '../../dialogs';

@Component({
  selector: 'nk-shared-chat-message-left',
  templateUrl: './chat-message-left.component.html',
  styleUrls: ['./chat-message-left.component.scss'],
})
export class ChatMessageLeftComponent implements OnInit {
  isLoading = true;
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  fullIcons = false;

  reaction = true;

  /**
   * Le contenu du message
   */
  @Input()
  message = '';

  /**
   * Le contenu de la réponse
   */
  @Input()
  response = '';

  /**
   * Le contenu du message
   */
  @Input()
  time = '2:33 am';

  /**
   * Si le message est une gallerie d'images
   */
  @Input()
  imageGallery = false;

  /**
   * Si le message est une gallerie de vidéos
   */
  @Input()
  videoGallery = false;

  /**
   * Si le message est une gallerie de vidéos
   */
  @Input()
  documentGallery = false;

  /**
   * Si le message est audio
   */
  @Input()
  audio = false;

  /**
   * La taille de l'avatar : 'small' | 'medium' | 'large'
   *
   * @string
   */
  @Input()
  size!: ControlSizeEnum;

  likes = false;
  Emoji = false;
  options = false;

  /**
   * Si l'on a paartagé un publication
   *
   * @boolean
   */
  @Input()
  isArticle = false;

  /**
   * Si l'on a paartagé un groupe
   *
   * @boolean
   */
  @Input()
  isGroup = false;

  /**
   * Si C'est un popbox
   *
   * @boolean
   */
  @Input()
  isPopbox = false;

  /**
   * Si l'on a paartagé un groupe
   *
   * @boolean
   */
  @Input()
  isEvent = false;

  userData = [
    {
      name: 'John Doe',
      img: 'assets/icons/src/likes/like-color.svg',
    },
    { name: 'Arno Y', img: 'assets/icons/src/likes/like-color.svg' },
    {
      name: 'Danick Takam',
      img: 'assets/icons/src/likes/clapping-hands-color.svg',
    },
  ];
  userData1 = [
    {
      name: 'John Doe',
      img: 'assets/icons/src/likes/like-color.svg',
    },
    { name: 'Arno Y', img: 'assets/icons/src/likes/like-color.svg' },
  ];
  userData2 = [
    {
      name: 'Danick Takam',
      img: 'assets/icons/src/likes/clapping-hands-color.svg',
    },
  ];

  galleryItems = [
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-3.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '13 septembre 2015',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-4.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur backend',
      date: '13 juillet 2016',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-5.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '06 mai 2011',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-3.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '13 septembre 2015',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-4.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur backend',
      date: '13 juillet 2016',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-5.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '06 mai 2011',
    },
  ];
  videoItems = [
    {
      videoSrc:
        'https://mdn.github.io/learning-area/html/multimedia-and-embedding/video-and-audio-content/rabbit320.webm',
      title: 'Diplome de Développeur front-end',
    },
    {
      videoSrc:
        'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm  ',
      title: 'Titre de la deuxième vidéo',
    },
  ];
  documentsItems = [
    {
      docSrc: 'assets/Img/gallery-publication/gallery-3.jpg',
      title: "Photos de l'académie",
      type: 'zip',
    },
    {
      docSrc: 'assets/Img/gallery-publication/gallery-4.jpg',
      title: 'Titre de la deuxième vidéo',
      type: 'doc',
    },
    {
      docSrc: 'assets/Img/gallery-publication/gallery-3.jpg',
      title: 'Manuel de procédures RH',
      type: 'pdf',
    },
    {
      docSrc: 'assets/Img/gallery-publication/gallery-4.jpg',
      title: 'Titre de la deuxième vidéo',
      type: 'zip',
    },
    {
      docSrc: 'assets/Img/gallery-publication/gallery-3.jpg',
      title: 'Manuel de procédures RH',
      type: 'pdf',
    },
    {
      docSrc: 'assets/Img/gallery-publication/gallery-4.jpg',
      title: 'Titre de la deuxième vidéo',
      type: 'zip',
    },
  ];

  groupName = 'Group des ambassadeurs';
  articleTitle = "Titre de l'publication";
  articleDesc = `Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur velit accusamus nobis atque sint alias quia dolore mollitia architecto`;

  menuDots!: boolean;
  menuLikeIcons!: boolean;

  visibleMenu = true;

  /**
   * Le style d'affichage des documents
   */
  @Input()
  styleDoc: ControlDisplayEnum = ControlDisplayEnum.inline;

  @Input() showMessageToRespond = false;

  /**
   * Optional click handler
   */
  @Output()
  respond = new EventEmitter<any>();

  @ViewChild('toggleButton') toggleButton!: ElementRef;
  @ViewChild('iconLikes') iconLikes!: ElementRef;

  constructor(private modalService: DialogService, private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  onHide(): void {
    this.visibleMenu = false;
  }

  deleteReaction(): void {
    this.reaction = false;
  }

  showReaction(): void {
    this.reaction = true;
  }

  openArticle(e: any): void {
    this.router.navigate(['/single-publication']);
  }

  openGroup(e: any): void {
    this.router.navigate(['/groups/single-group/group-discussion']);
  }

  onFullIcons(): void {
    this.fullIcons = !this.fullIcons;
  }

  addSignalization(): void {
    const ref = this.modalService.open(DialogAddSignalizationComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Signaler ce message`,
        size: DialogSizeEnum.small,
      },
    });
  }

  addToCategory() {
    const ref = this.modalService.open(DialogAddCategoryComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter le message dans une catégorie`,
        size: DialogSizeEnum.small,
      },
    });
  }

  sendMessage() {
    const ref = this.modalService.open(DialogSendMessageComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Envoyer le message`,
        size: DialogSizeEnum.small,
      },
    });
  }

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  openMessageToRespond(): void {
    this.respond.emit(this.showMessageToRespond);
  }

  showLikes(): void {
    this.likes = !this.likes;
    console.log('LIKES : ' + this.likes);
  }

  showEmoji(): void {
    this.Emoji = true;
    this.options = !this.options;
  }

  hideEmoji(): void {
    if (this.likes === true) {
      this.Emoji = true;
    } else {
      this.Emoji = false;
    }
    this.options = !this.options;
  }

  showOptions(): void {
    this.options = !this.options;
  }

  outsideClick(hasClickedOutside: any): void {
    if (this.likes === true) {
      this.likes = false;
    }
    console.log(this.likes);
  }

  // @HostListener('window:mouseup', ['$event'])
  // onClick(event: any): void {
  //   const idIcons = document.getElementById('showIcons');
  //   if (event.target != idIcons && event.target.parentNode != idIcons) {
  //     this.likes = false;
  //   }
  // }
}
