import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogAddChallengeComponent } from '@del-shared';

import {
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
} from 'datnek-ds';


@Component({
  selector: 'nk-shared-card-challenge-description',
  templateUrl: './card-challenge-description.component.html',
  styleUrls: ['./card-challenge-description.component.scss'],
})
export class CardChallengeDescriptionComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  description = `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Natus fugiat aut, cumque pariatur odio voluptatem placeat! Aspernatur et sequi ullam ducimus?
                  Saepe nesciunt vitae, magnam ex sint earum provident obcaecati?`;

  isLoading = true;

  constructor(private router: Router, private modalService: DialogService) {}

  ngOnInit(): void {}

  goToChallengeDescription() {
    this.router.navigate(['/challenge/description']);
  }

  addChallenge() {
    const ref = this.modalService.open(DialogAddChallengeComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Créer un nouveau challenge`,
        size: DialogSizeEnum.small,
      },
    });
  }
}
