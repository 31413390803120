import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nk-shared-notifications-menu-mobile',
  templateUrl: './notifications-menu-mobile.component.html',
  styleUrls: ['./notifications-menu-mobile.component.scss']
})
export class NotificationsMenuMobileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
