<div class="suggestion-article-list" *ngIf="!isLoading">
  <ng-container *ngIf="isSidebar">
    <div
      class="d-flex infos full sidebar p-3 pointer"
      *ngFor="let data of articesDataAuthor; let i = index"
    >
      <div class="left-infos d-flex flex-column align-items-center">
        <div class="w-100">
          <div class="d-flex justify-content-between align-items-start">
            <div class="d-flex align-items-center w-100 mb-2">
              <div
                class="d-inline-block"
                (mouseenter)="openDropdown(userinfos, target2)"
                (mouseleave)="close()"
                #target2
              >
                <datnek-ds-avatar
                  [size]="sizeEnum.small"
                  class="mr-2"
                  [type]="status"
                ></datnek-ds-avatar>
              </div>
              <div class="d-flex">
                <div class="d-flex flex-column">
                  <span>{{ data.username }}</span>
                  <!--<span class="point d-none d-sm-block">.</span>-->
                  <span class="date">Analyste programmeur</span>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlinebookmark"
                [type]="colorEnum.unstyled"
                [round]="true"
                (datnekdsClickOrTouch)="onBookmark(); addToCategory()"
                *ngIf="!bookmark"
              ></datnek-ds-button-icon>
              <datnek-ds-button-icon
                [Icon]="iconEnum.solidbookmark"
                [type]="colorEnum.unstyled"
                [round]="true"
                (datnekdsClickOrTouch)="onBookmark(); showToast(unsaved)"
                *ngIf="bookmark"
              ></datnek-ds-button-icon>
              <div
                class="network-icon-options-dropdown"
                (datnekdsClickOrTouch)="openDropdown(actions, target3)"
                #target3
              >
                <datnek-ds-button-icon
                  [Icon]="iconEnum.outlinedotsHorizontal"
                  [type]="colorEnum.ghost"
                  [round]="true"
                ></datnek-ds-button-icon>
              </div>
            </div>
          </div>

          <div class="w-100 d-flex">
            <div class="w-100">
              <div class="d-flex align-items-center gap-3">
                <div class="icon-info">
                  <datnek-ds-svg-icon
                    [icon]="iconEnum.outlinecalendar"
                    [scale]="scaleIconEnum.small"
                    class="mr-0"
                  ></datnek-ds-svg-icon>
                  <span>23 février 2023</span>
                </div>
                <div class="icon-info">
                  <datnek-ds-svg-icon
                    [icon]="iconEnum.outlineclock"
                    [scale]="scaleIconEnum.small"
                    class="mr-0"
                  ></datnek-ds-svg-icon>
                  <span>5mn</span>
                </div>
                <div class="icon-info">
                  <datnek-ds-svg-icon
                    [icon]="iconEnum.outlineeye"
                    [scale]="scaleIconEnum.small"
                    class="mr-0"
                  ></datnek-ds-svg-icon>
                  <span>125</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="w-75 flex-grow-1">
                  <div
                    (datnekdsClickOrTouch)="goToArticle($event)"
                    class="display-2 w-100 mb-1 truncated py-1"
                  >
                    {{ data.title }}
                  </div>
                  <p (datnekdsClickOrTouch)="goToArticle($event)">
                    {{ data.description | truncatetext: 100 }}
                  </p>
                </div>
                <img
                  src="{{ data.imgSrc }}"
                  alt=""
                  class=""
                  (datnekdsClickOrTouch)="goToArticle($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isFull">
    <div
      class="d-flex infos full p-3 pointer"
      *ngFor="let data of articesDataAuthor; let i = index"
    >
      <div class="left-infos d-flex flex-column align-items-center">
        <div class="w-100">
          <div class="d-flex justify-content-between align-items-start">
            <div class="d-flex align-items-center w-100 mb-2">
              <div
                class="d-inline-block"
                (mouseenter)="openDropdown(userinfos, target2)"
                (mouseleave)="close()"
                #target2
              >
                <datnek-ds-avatar
                  [size]="sizeEnum.small"
                  class="mr-2"
                  [type]="status"
                ></datnek-ds-avatar>
              </div>
              <div class="d-flex">
                <div class="d-flex flex-column">
                  <span>{{ data.username }}</span>
                  <!--<span class="point d-none d-sm-block">.</span>-->
                  <span class="date">Analyste programmeur</span>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlinebookmark"
                [type]="colorEnum.unstyled"
                [round]="true"
                (datnekdsClickOrTouch)="onBookmark(); addToCategory()"
                *ngIf="!bookmark"
              ></datnek-ds-button-icon>
              <datnek-ds-button-icon
                [Icon]="iconEnum.solidbookmark"
                [type]="colorEnum.unstyled"
                [round]="true"
                (datnekdsClickOrTouch)="onBookmark(); showToast(unsaved)"
                *ngIf="bookmark"
              ></datnek-ds-button-icon>
              <div
                class="network-icon-options-dropdown"
                (datnekdsClickOrTouch)="openDropdown(actions, target3)"
                #target3
              >
                <datnek-ds-button-icon
                  [Icon]="iconEnum.outlinedotsHorizontal"
                  [type]="colorEnum.ghost"
                  [round]="true"
                ></datnek-ds-button-icon>
              </div>
            </div>
          </div>

          <div class="w-100 d-flex">
            <div class="w-100">
              <div class="d-flex align-items-center gap-3">
                <div class="icon-info">
                  <datnek-ds-svg-icon
                    [icon]="iconEnum.outlinecalendar"
                    [scale]="scaleIconEnum.small"
                    class="mr-0"
                  ></datnek-ds-svg-icon>
                  <span>23 février 2023</span>
                </div>
                <div class="icon-info">
                  <datnek-ds-svg-icon
                    [icon]="iconEnum.outlineclock"
                    [scale]="scaleIconEnum.small"
                    class="mr-0"
                  ></datnek-ds-svg-icon>
                  <span>5mn</span>
                </div>
                <div class="icon-info">
                  <datnek-ds-svg-icon
                    [icon]="iconEnum.outlineeye"
                    [scale]="scaleIconEnum.small"
                    class="mr-0"
                  ></datnek-ds-svg-icon>
                  <span>125</span>
                </div>
              </div>
              <div
                class="display-2 w-100 mb-3 truncated py-1"
                (datnekdsClickOrTouch)="goToArticle($event)"
              >
                {{ data.title }}
              </div>
              <p (datnekdsClickOrTouch)="goToArticle($event)">
                {{ data.description | truncatetext: 145 }}
              </p>
              <div
                class="
                  actions
                  d-flex
                  align-items-start
                  justify-content-between
                  w-100
                "
              >
                <div class="d-flex align-items-center flex-wrap">
                  <div
                    class="tag"
                    (datnekdsClickOrTouch)="gotoListArticles($event)"
                    *ngFor="let filter of listFilter; let i = index"
                  >
                    {{ filter }}
                  </div>
                </div>
                <!--<div class="d-none d-sm-flex align-items-center">
                  <datnek-ds-button-icon
                    [Icon]="iconEnum.outlinebookmark"
                    [type]="colorEnum.unstyled"
                    [round]="true"
                    (datnekdsClickOrTouch)="onBookmark(); addToCategory()"
                    *ngIf="!bookmark"
                  ></datnek-ds-button-icon>
                  <datnek-ds-button-icon
                    [Icon]="iconEnum.solidbookmark"
                    [type]="colorEnum.unstyled"
                    [round]="true"
                    (datnekdsClickOrTouch)="onBookmark(); showToast(unsaved)"
                    *ngIf="bookmark"
                  ></datnek-ds-button-icon>
                  <div
                    class="network-icon-options-dropdown-formly"
                    (datnekdsClickOrTouch)="openDropdown(actions, target1)"
                    #target1
                  >
                    <datnek-ds-button-icon
                      [Icon]="iconEnum.outlinedotsHorizontal"
                      [type]="colorEnum.ghost"
                      [round]="true"
                    ></datnek-ds-button-icon>
                  </div>
                </div>-->
              </div>
            </div>
            <img
              src="{{ data.imgSrc }}"
              alt=""
              class="d-none d-sm-block"
              (datnekdsClickOrTouch)="goToArticle($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-container *ngFor="let in of counter(3); let i = index">
  <datnek-skeleton-invitation-list
    *ngIf="isLoading"
  ></datnek-skeleton-invitation-list>
</ng-container>

<!--MESSAGES-->
<ng-template #sourdine let-close="close">
  Bige Beşikci Yaman a été mis en sourdine. Vous ne verrez plus ses articles sur
  votre page
  <a href="javascript:;" (datnekdsClickOrTouch)="dialogRef.close()"
    >Annuler</a
  ></ng-template
>

<ng-template #reactivate let-close="close">
  Bige Beşikci Yaman a été réactivé. Vous purrez voir de nouveau ses articles
  sur votre page
  <a href="javascript:;" (datnekdsClickOrTouch)="dialogRef.close()"
    >Annuler</a
  ></ng-template
>

<ng-template #blocked let-close="close">
  L'utilisateur Bige Beşikci Yaman a été bloqué</ng-template
>

<ng-template #unblocked let-close="close">
  Vous avez annulé le blcage de l'utilisateur Bige Beşikci Yaman</ng-template
>

<ng-template #unsaved let-close="close">
  L'publication a été retiré de la catégorie</ng-template
>

<ng-template #signal let-close="close">
  <div class="datnek-ds-dialog-body">
    <div class="content-modal">
      <div class="notif-box p-3">
        <p class="">
          Cet utilisateur sera signalé à notre équipe de soutien aux fins
          d’examen.
        </p>
        <div class="py-3">
          <datnek-ds-checkbox-group
            label="Bloquez également cet utilisateur"
            [markLeft]="true"
          ></datnek-ds-checkbox-group>
        </div>
      </div>

      <div class="datnek-ds-dialog-footer">
        <datnek-ds-button
          [label]="'Annuler'"
          [size]="sizeEnum.medium"
          [type]="colorEnum.secondary"
          class="mr-3"
          (datnekdsClickOrTouch)="close(); showToast(reactivate)"
        ></datnek-ds-button>
        <datnek-ds-button
          [label]="'Signaler'"
          [size]="sizeEnum.medium"
          [type]="colorEnum.primary"
          (datnekdsClickOrTouch)="close()"
        ></datnek-ds-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #block let-close="close">
  <div class="datnek-ds-dialog-body">
    <div class="content-modal">
      <div class="notif-box p-3">
        <p class="">Il ne pourra plus vous suivre ou voir votre contenu.</p>
        <a
          href="javascript:;"
          (datnekdsClickOrTouch)="blankPage($event); close()"
          >En savoir plus sur le blocage</a
        >
      </div>

      <div class="datnek-ds-dialog-footer">
        <datnek-ds-button
          [label]="'Annuler'"
          [size]="sizeEnum.medium"
          [type]="colorEnum.secondary"
          class="mr-3"
          (datnekdsClickOrTouch)="showToast(unblocked); close()"
        ></datnek-ds-button>
        <datnek-ds-button
          [label]="'Bloquer'"
          [size]="sizeEnum.medium"
          [type]="colorEnum.danger"
          (datnekdsClickOrTouch)="
            showToast(blocked); close(); gotoListArticles($event)
          "
        ></datnek-ds-button>
      </div>
    </div>
  </div>
</ng-template>

<!-- DROPDOWN -->
<ng-template #actions let-close="close">
  <div class="datnek-ds-card dropdown-card user-action">
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="blockUser(block); close()"
    >
      Bloquer cet auteur
    </button>
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="showToast(sourdine); onMute(); close()"
      *ngIf="!mute"
    >
      Mettre en sourdine cet auteur
    </button>
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="showToast(reactivate); onMute(); close()"
      *ngIf="mute"
    >
      Réactiver cet auteur
    </button>
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="signalUser(signal); close()"
    >
      Signaler cet utilisateur
    </button>
  </div>
</ng-template>

<ng-template #userinfos let-close="close">
  <div class="datnek-ds-card dropdown-card user-infos-dropdown">
    <nk-shared-comment-user-infos
      [commentUserInfo]="false"
    ></nk-shared-comment-user-infos>
  </div>
</ng-template>
