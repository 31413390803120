<div
  ngbDropdown
  class="d-inline-block d-none d-md-block app-header"
  #myDrop="ngbDropdown"
>
  <div class="icon-apps" id="dropdownBasic2" ngbDropdownToggle>
    <datnek-ds-button-icon
      [size]="sizeEnum.medium"
      [type]="colorEnum.unstyled"
      [round]="true"
      [Icon]="iconEnum.outlineApps"
    ></datnek-ds-button-icon>
  </div>

  <div
    class="dropdown-menu-block dropdown-menu dropdown-menu-right mt-md-1 p-md-3"
    ngbDropdownMenu
    aria-labelledby="dropdownBasic2"
  >
    <datnek-ds-button-icon
      [Icon]="iconEnum.outlinex"
      [round]="true"
      [type]="colorEnum.secondary"
      (datnekdsClickOrTouch)="myDrop.close()"
      class="close d-block d-md-none"
    ></datnek-ds-button-icon>
    <p-scrollPanel [style]="{ width: '100%', height: '400px' }">
      <div class="container pt-0 app-menu">
        <div class="row flex-md-nowrap mx-0 gap-16">
          <div class="col-12 col-md-8 pr-md-0">
            <div class="card datnek-ds-card mb-0">
              <div class="header border-bottom p-3">
                <h3 class="display-1">Menu</h3>
              </div>
              <div class="contenu-menu p-3">
                <div
                  class="item-social d-flex flex-row align-items-start mb-4"
                  (datnekdsClickOrTouch)="goToEvent($event); myDrop.close()"
                >
                  <span class="img">
                    <img [src]="'Img/icons/colors/calendar.svg' | assetUrl" />
                  </span>
                  <div class="infos">
                    <span class="display-3">Evènement</span>
                    <div class="contenu">
                      Trouvez des activités et des évènements auxquels vous
                      pouvez participer en ligne ou en personne près de chez
                      vous, ou organisez-en.
                    </div>
                  </div>
                </div>
                <div
                  class="item-social d-flex flex-row align-items-start mb-4"
                  (datnekdsClickOrTouch)="goToPeaple($event); myDrop.close()"
                >
                  <span class="img">
                    <img [src]="'Img/icons/colors/users-young.svg' | assetUrl" />
                  </span>
                  <div class="infos">
                    <span class="display-3">Amis</span>
                    <div class="contenu">
                      Trouvez des amis ou des personnes que vous connaissez.
                    </div>
                  </div>
                </div>
                <div
                  class="item-social d-flex flex-row align-items-start mb-4"
                  (datnekdsClickOrTouch)="goToContacts($event); myDrop.close()"
                >
                  <span class="img">
                    <img [src]="'icons/src/contacts.svg' | assetUrl" />
                  </span>
                  <div class="infos">
                    <span class="display-3">Contacts</span>
                    <div class="contenu">
                      Gérez la liste de vos contacts avec des infrmations plus
                      détaillées
                    </div>
                  </div>
                </div>
                <div
                  class="item-social d-flex flex-row align-items-start mb-4"
                  (datnekdsClickOrTouch)="goToGroups($event); myDrop.close()"
                >
                  <span class="img">
                    <img [src]="'Img/icons/colors/group.svg' | assetUrl" />
                  </span>
                  <div class="infos">
                    <span class="display-3">Groupes</span>
                    <div class="contenu">
                      Entrez en contact avec des personnes qui partagent vos
                      centres d’intérêt.
                    </div>
                  </div>
                </div>
                <div
                  class="item-social d-flex flex-row align-items-start mb-4"
                  (datnekdsClickOrTouch)="
                    goToChallenges($event); myDrop.close()
                  "
                >
                  <span class="img">
                    <img
                      [src]="'icons/src/outline/color/competition-champion-color.svg' | assetUrl"
                    />
                  </span>
                  <div class="infos">
                    <span class="display-3">Challenges</span>
                    <div class="contenu">
                      Consultez la liste des challenges disponibles ou magagez
                      les votres
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 flex-1">
            <div class="card datnek-ds-card dc-menu-vertical">
              <div class="header border-bottom p-3">
                <h3 class="display-1">Créer</h3>
              </div>

              <datnek-ds-button
                [label]="'Publication'"
                [size]="sizeEnum.large"
                [type]="colorEnum.ghost"
                [lBlock]="true"
                [isMobile]="true"
                [colorIconLeft]="iconEnum.outlineColorphotographColor"
                (datnekdsClickOrTouch)="newContentDialog(); myDrop.close()"
              ></datnek-ds-button>
              <datnek-ds-button
                [label]="'Article'"
                [size]="sizeEnum.large"
                [type]="colorEnum.ghost"
                [lBlock]="true"
                [colorIconLeft]="iconEnum.outlineColorviewListColor"
                (datnekdsClickOrTouch)="addArticlePage($event); myDrop.close()"
              ></datnek-ds-button>
              <datnek-ds-button
                [label]="'ChallengeComp'"
                [size]="sizeEnum.large"
                [type]="colorEnum.ghost"
                [lBlock]="true"
                [colorIconLeft]="iconEnum.outlineColorcompetitionChampionColor"
                (datnekdsClickOrTouch)="addChallenge(); myDrop.close()"
              ></datnek-ds-button>
              <datnek-ds-button
                [label]="'Groupe'"
                [size]="sizeEnum.large"
                [type]="colorEnum.ghost"
                [lBlock]="true"
                [colorIconLeft]="iconEnum.outlineuserGroup"
                (datnekdsClickOrTouch)="addNewGroupDialog(); myDrop.close()"
              ></datnek-ds-button>
              <datnek-ds-button
                [label]="'Evènement'"
                [size]="sizeEnum.large"
                [type]="colorEnum.ghost"
                [lBlock]="true"
                [colorIconLeft]="iconEnum.outlineColorcalendarColor"
                (datnekdsClickOrTouch)="addCalendar(); myDrop.close()"
              ></datnek-ds-button>
              <datnek-ds-button
                [label]="'Publicité'"
                [size]="sizeEnum.large"
                [type]="colorEnum.ghost"
                [lBlock]="true"
                [colorIconLeft]="iconEnum.outlinespeakerphone"
                (datnekdsClickOrTouch)="newAds($event); myDrop.close()"
              ></datnek-ds-button>
              <datnek-ds-button
                [label]="'Déconnexion'"
                [size]="sizeEnum.large"
                [type]="colorEnum.ghost"
                [lBlock]="true"
                (datnekdsClickOrTouch)="onLogout($event)"
                [colorIconLeft]="iconEnum.outlinelogout"
              ></datnek-ds-button>
            </div>
          </div>
        </div>
      </div>
    </p-scrollPanel>
  </div>
</div>
