<div class="px-3">
  <span class="display-5 title-menu text-uppercase py-3 d-flex"
    >Application</span
  >
  <div class="">
    <del-shared-menu-sidebar [isMessages]="true"></del-shared-menu-sidebar>
  </div>

  <!-- LES EVENTS -->
  <span class="display-5 title-menu model2 text-uppercase py-3 d-flex"
    >Evènements
  </span>
  <div class="sidebar-users">
    <p-tabView>
      <p-tabPanel header="Vos évènement">
        <div class="sidebar-event">
          <nk-shared-events-list [EventData]="EventData"></nk-shared-events-list>
        </div>
      </p-tabPanel>
      <p-tabPanel header="A suivre">
        <div class="sidebar-event">
          <nk-shared-events-list></nk-shared-events-list>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>

  <div class="sidebar-right-files">
    <p-tabView>
      <p-tabPanel header="Images">
        <div class="sidebar-media-chat pt-2">
          <datnek-ds-image-gallery></datnek-ds-image-gallery>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Vidéos">
        <div class="sidebar-media-chat pt-2">
          <datnek-ds-video-gallery
            [galleryVideoItems]="videoItems"
          ></datnek-ds-video-gallery>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Fichiers">
        <div class="sidebar-media-chat pt-2">
          <datnek-ds-document-gallery
            [styleDoc]="displayEnum.inline"
          ></datnek-ds-document-gallery>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

<div class="py-4"></div>
