import { Component, OnInit } from '@angular/core';
import {
  IconEnum,
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
} from 'datnek-ds';

@Component({
  selector: 'network-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = false;

  filters = true;

  listFilter = ['Tout', 'Non lus', 'Lus'];

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }
}
