import { Component, OnInit } from '@angular/core';
import {AvatarTypeEnum, PopupInterface} from "datnek-ds";

interface UsersItems {
  imageSrc: string;
  time: string;
  name: string;
  writer: string;
  message: string;
}

@Component({
  selector: 'nk-shared-chat-menu-mobile',
  templateUrl: './chat-menu-mobile.component.html',
  styleUrls: ['./chat-menu-mobile.component.scss'],
})
export class ChatMenuMobileComponent implements OnInit {
  avatarEnum = AvatarTypeEnum;

  dataUsersChat: PopupInterface[] = [
    {
      id: '1',
      name: 'Danick takam',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '2',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Jean-Marc',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '3',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Arno yankam',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '4',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Kevin Sokodjou',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '5',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Jordan Wildes',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '6',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Jospin Chimi',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '7',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Lionel Messi',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '8',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Christiane',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
  ];
  data1UsersChat: PopupInterface[] = [
    {
      id: '0',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Lionel Messi',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '1',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Christiane',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
