import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuComp } from './components/menu-comp';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ChallengeStatsMenuMobileComponent } from './components';
import { MoleculeModule } from '../molecules';
import { OrganismSharedModule } from '../organisms';
import { NetworkSharedModule } from '../../shared';
import { DatnekDsGalleryModule } from 'datnek-ds';
import { SharedModule } from '@del-shared';

@NgModule({
  declarations: [...MenuComp, ChallengeStatsMenuMobileComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NetworkSharedModule,
    MoleculeModule,
    OrganismSharedModule,
    SharedModule,
    CarouselModule,
    DatnekDsGalleryModule,
  ],
  exports: [...MenuComp],
  providers: [],
})
export class MenuModule {}
