/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import {
  AccountTypeEnum,
  PageTypeEnum,
  StepEnum,
  UserTypeEnum,
  UserSsoActions,
} from 'datnek-sso';
import { BaseComponent, getImage } from 'datnek-ds';
import { Environment } from 'datnek-core';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { DeviceService } from 'libs/shared/src/lib/core';


interface Slide {
  Image: string;
  Title: string;
  Description: string;
}

@Component({
  selector: 'del-shared-security',
  templateUrl: './security-shared.component.html',
  styleUrls: ['./security-shared.component.scss'],
})
export class SecuritySharedComponent extends BaseComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    items: 1,
    nav: false,
  };

  @Input()
  slides: Slide[] = [];

  @Input()
  logo: string = '';


  loading = false;
  currentPage!: PageTypeEnum;

  registrationStep!: StepEnum;
  userType!: UserTypeEnum;
  registrationAccountType!: AccountTypeEnum;

  constructor(
    private store: Store,
    @Inject(Environment.Provider.ENVIRONMENT)
    private environment: Environment.Env,
    private router: Router,
    private deviceService: DeviceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.deviceService.isNotSsoPage$.next(false);
  }

  onSucess(user: any): void {
    this.store
      .dispatch(new UserSsoActions.setConnectedUser(user.Data))
      .subscribe(() => {
        this.loading = false;
      });
  }

  onError(error: any): void {
    this.loading = false;
  }

  getImg(img: string): string{
    return getImage(img);
  }
  home(e: any): void {
    e.preventDefault();
    // alert("HELLO :::::::")
    this.router.navigate(['/']);
    this.environment.securityRoute.forgetPassword
  }
}
