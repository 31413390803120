<div
  class="
    datnek-ds-card
    suggestion-list
    enterprise-list-mobile-menu
    overflow-hidden
  "
>
  <div class="suggestion-header display-1">Catégories d'entreprises</div>
  <div class="search p-2 border-bottom">
    <datnek-ds-search-input
      placeholder="Rechercher une catégorie..."
    ></datnek-ds-search-input >
  </div>

  <div class="category-body bg-white" *ngIf="!isLoading">
    <span class="pointer category" *ngFor="let item of categories">
      {{ item.name }}
    </span>
  </div>

  <datnek-skeleton-categories *ngIf="isLoading"></datnek-skeleton-categories>
</div>
