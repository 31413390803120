import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nk-shared-enterprise-list-menu-mobile',
  templateUrl: './enterprise-list-menu-mobile.component.html',
  styleUrls: ['./enterprise-list-menu-mobile.component.scss'],
})
export class EnterpriseListMenuMobileComponent implements OnInit {
  isLoading = true;

  categories = [
    {
      name: 'Agro-alimentaire',
    },
    {
      name: 'Technologies',
    },
    {
      name: 'Industrie',
    },
    {
      name: 'Vente',
    },
    {
      name: 'Agro-industriel',
    },
    {
      name: 'Services',
    },
    {
      name: 'Communication',
    },
    {
      name: 'E-commerce',
    },
    {
      name: 'Organisation  à But non lucratif',
    },
    {
      name: 'Santé',
    },
    {
      name: 'Transport',
    },
    {
      name: 'Formation',
    },
    {
      name: 'E-learning',
    },
    {
      name: 'Télécom',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
