import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {AvatarTypeEnum, ControlSizeEnum, IconEnum, ControlTypeColorEnum, assetUrl} from 'datnek-ds';

interface Items {
  name: string;
}

@Component({
  selector: 'network-ui-box',
  templateUrl: './ui-box.component.html',
  styleUrls: ['./ui-box.component.scss'],
})
export class UiBoxComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  label = 'Mon premier bouton';

  @Input()
  submenuData: Items[] = [
    {
      name: 'Pour moi seulement',
    },
    {
      name: 'Pour tout le monde',
    },
  ];

  imageUrl = '';
  constructor(
    private titleService: Title,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.translateService
      .get('home.title')
      .subscribe((r) => this.titleService.setTitle(r));

    this.imageUrl = assetUrl('img/shortpixel.png');
  }

  functioncall(event: any) {
    console.log('functioncall', event);
  }
}
