import { Router } from '@angular/router';
import { Component, OnInit, TemplateRef, Type } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef,
  DialogService,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';


interface Items {
  label: string;
  description?: string;
}

@Component({
  selector: 'nk-shared-dialog-add-category',
  templateUrl: './dialog-add-category.component.html',
  styleUrls: ['./dialog-add-category.component.scss'],
})
export class DialogAddCategoryComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  addCat = false;
  nonOk = false;
  items: Items[] = [
    {
      label: 'Catégorie une',
    },
    {
      label: 'Une autre Catégorie ici',
    },
    {
      label: 'Troisième Catégorie',
    },
  ];
  constructor(
    public ref: DialogRef,
    private router: Router,
    private modalService: DialogService
  ) {}

  ngOnInit(): void {}

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  addCategory() {
    this.addCat = !this.addCat;
  }

  goToSavedMessages(e: any) {
    this.router.navigate(['/saved-messages']);
  }
}
