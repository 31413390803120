import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {Article} from "../../../models";
import {BaseHttpService, Environment, ListResult, LoaderService} from "datnek-core";

@Injectable({
  providedIn: 'root',
})
export class ArticleService extends BaseHttpService<Article, Article> {
  saveSubject!: Subject<Article>;
  getListSubject!: Subject<Article[]>;

  constructor(http: HttpClient,
              loaderService: LoaderService,
              @Inject(Environment.Provider.API) private api: Environment.Api) {
    super(http, loaderService);
    this.setUrl(`${this.api.network}/articles`);

    this.saveSubject = new Subject<Article>();
    this.getListSubject = new Subject<Article[]>();
  }

  getByResumeLazy(
    resumeId: string,
    skip: number,
    take: number,
    globalLoading = false
  ): Observable<ListResult<Article>> {
    return super.getLazyData(take, skip, `${this.rootURL}/Resume/${resumeId}`, globalLoading);
  }
}
