import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {BaseHttpService, Environment, ListResult, LoaderService} from "datnek-core";
import {Publication} from "../../../models";

@Injectable({
  providedIn: 'root',
})
export class PublicationService extends BaseHttpService<Publication, Publication> {

  saveSubject: Subject<Publication>;
  getListSubject: Subject<Publication[]>;

  constructor(http: HttpClient,
              loaderService: LoaderService,
              @Inject(Environment.Provider.API) private api: Environment.Api) {
    super(http, loaderService);
    this.setUrl(`${this.api.network}/Publications`);

    this.saveSubject = new Subject<Publication>();
    this.getListSubject = new Subject<Publication[]>();
  }

  getByResume(
    resumeId: string,
    skip: number,
    take: number,
    globalLoading = false
  ): Observable<ListResult<Publication>> {
    return this.getLazyData(take, skip,`${this.rootURL}/Resume/${resumeId}`, globalLoading);
  }

  getByUser(
    userId: string,
    skip: number,
    take: number,
    globalLoading = false
  ): Observable<ListResult<Publication>> {
    return this.getLazyData(take, skip,`${this.rootURL}/ByUser/${userId}`, globalLoading);
  }

  getByCompany(
    companyId: string,
    skip: number,
    take: number,
    globalLoading = false
  ): Observable<ListResult<Publication>> {
    return this.getLazyData(take, skip,`${this.rootURL}/Enterprise/${companyId}`, globalLoading);
  }
}
