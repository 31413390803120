import { Component, OnInit } from '@angular/core';
import {IconEnum} from "datnek-ds";

@Component({
  selector: 'nk-shared-saved-messages-menu-mobile',
  templateUrl: './saved-messages-menu-mobile.component.html',
  styleUrls: ['./saved-messages-menu-mobile.component.scss'],
})
export class SavedMessagesMenuMobileComponent implements OnInit {
  iconEnum = IconEnum;

  iconShow = false;
  categories = [
    {
      name: 'Fait Politiques',
    },
    {
      name: 'Affaires juridiques',
    },
    {
      name: 'International',
    },
    {
      name: 'Faits divers',
    },
    {
      name: 'Technologies',
    },
    {
      name: 'Amour',
    },
    {
      name: 'Cinéma',
    },
    {
      name: 'Entrepreneuriat',
    },
    {
      name: 'Développement personnel',
    },
    {
      name: 'Santé',
    },
    {
      name: 'Matériel informatique',
    },
    {
      name: 'Voyages',
    },
    {
      name: 'Business',
    },
    {
      name: 'Entreprises',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
  showIcon() {
    this.iconShow = !this.iconShow;
  }
}
