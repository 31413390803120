export class BaseModel {
  Id?: string;
  CreateAt?: Date;
  UpdateAt?: Date;

  constructor(
    Id?: string,
    CreatedAt: Date = new Date(),
    UpdatedAt?: Date
  ) {
    this.Id = Id;
    this.CreateAt = CreatedAt;
    this.UpdateAt = UpdatedAt;
  }
}
