<div class="datnek-ds-card suggestion-list">
  <div class="suggestion-header display-1">Groupes</div>
  <div class="search p-2 border-bottom">
    <datnek-ds-search-input
      placeholder="Rechercher dans les groupes"
    ></datnek-ds-search-input>
  </div>
  <del-shared-menu-sidebar [isGroup]="true"></del-shared-menu-sidebar>
  <div class="p-3">
    <datnek-ds-button
      [block]="true"
      label="Créer un nouveau groupe"
      [type]="colorEnum.primary"
      [size]="sizeEnum.large"
      (onClick)="addNewGroupDialog()"
    ></datnek-ds-button>
  </div>
</div>

<div class="datnek-ds-card suggestion-list">
  <div class="suggestion-header display-1">Vos Groupes</div>
  <div class="search p-2 border-bottom">
    <datnek-ds-search-input
      placeholder="Rechercher un groupe"
    ></datnek-ds-search-input >
  </div>
  <div class="groups-list">
    <nk-shared-suggestion-list
      [userData]="dataUsers"
      [status]="avatarEnum.group"
    ></nk-shared-suggestion-list>
  </div>
</div>

<div class="datnek-ds-card suggestion-list">
  <div class="suggestion-header display-1">Groupes dont vous êtes membre</div>
  <div class="search p-2 border-bottom">
    <datnek-ds-search-input
      placeholder="Rechercher un groupe"
    ></datnek-ds-search-input >
  </div>
  <div class="groups-list">
    <nk-shared-suggestion-list
      [userData]="dataUsers"
      [type]="suggestionType[1]"
      [status]="avatarEnum.group"
    ></nk-shared-suggestion-list>
  </div>
</div>
