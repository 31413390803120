import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrganismComp } from './components/organism-comp';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NetworkSharedModule } from '../../shared';
import { DatnekDsGalleryModule,
  DatnekDsUploadFileModule,
  DatnekDsAtomModule,
  DatnekDsMoleculeModule } from 'datnek-ds';
import { MoleculeModule } from '../molecules';
import { FormlyModule } from '@ngx-formly/core';
import {SharedModule} from "@del-shared";

@NgModule({
  declarations: [...OrganismComp],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    //  PdfViewerModule,
    CarouselModule,
    NetworkSharedModule,
    DatnekDsGalleryModule,
    MoleculeModule,
    DatnekDsAtomModule,
    DatnekDsMoleculeModule,
    DatnekDsUploadFileModule,
    FormlyModule,
    SharedModule
  ],
  exports: [...OrganismComp],
})
export class OrganismSharedModule {}
