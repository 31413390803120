<div class="grid grid--gap16 enterprise-mobile-menu">
  <div class="grid__col_12">
    <owl-carousel-o [options]="customOptions1">
      <ng-template carouselSlide>
        <nk-shared-card-user-box
          [openForJob]="true"
          name="John Doe"
          [status]="avatarEnum.person"
          resumeTitle="Web Designer senior"
        ></nk-shared-card-user-box>
      </ng-template>

      <ng-template carouselSlide>
        <nk-shared-card-user-box
          [openForJob]="true"
          name="Yankep Arno Ghislain"
          resumeTitle="Analyste Web"
          [status]="avatarEnum.person"
        ></nk-shared-card-user-box>
      </ng-template>
    </owl-carousel-o>
  </div>
</div>

<nk-shared-suggestion-box
  titleCard="Top profils"
  [status]="avatarEnum.person"
></nk-shared-suggestion-box>

<div class="py-4"></div>
