import { Overlay } from '@angular/cdk/overlay';
import { Component, OnInit, TemplateRef, Type } from '@angular/core';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';
import {DialogDeleteGroupComponent} from "@network-shared";

interface ImagesItems {
  imageSrc: string;
  imageAlt: string;
  title: string;
  date: string;
}

@Component({
  selector: 'network-single-post-page',
  templateUrl: './single-post-page.component.html',
  styleUrls: ['./single-post-page.component.scss'],
})
export class SinglePostPageComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  dataImagesGallery: ImagesItems[] = [
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-1.jpg',
      imageAlt: 'Diplome Sorbone',
      title: "Diplome d'Ingénieur en Analyse de Données",
      date: '12 juin 2021',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-2.jpg',
      imageAlt: 'Diplome Sorbone',
      title: "Attestation d'analyse staistique",
      date: '02 mars 2011',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-3.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '13 septembre 2015',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-4.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur backend',
      date: '13 juillet 2016',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-5.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '06 mai 2011',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-6.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '06 novembre 2011',
    },
  ];
  posts = [
    {
      libelle: `Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae`,
      type: IconEnum.outlinephotograph,
    },
    {
      libelle: `#Nulla #porttitor accumsan tincidunt. Nulla porttitor accumsan tincidunt. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Nulla quis lorem ut libero malesuada feugiat. Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.`,
      type: IconEnum.outlinedocumentText,
    },
    {
      libelle: `Pellentesque in ipsum id orci porta dapibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;`,
      type: IconEnum.outlinephotograph,
    },
    {
      libelle: `Nulla porttitor accumsan tincidunt. Nulla porttitor accumsan tincidunt. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Nulla quis lorem ut libero malesuada feugiat. Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.`,
      type: IconEnum.outlineplay,
    },
    {
      libelle: `Nulla porttitor accumsan tincidunt. Nulla porttitor accumsan tincidunt. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Nulla quis lorem ut libero malesuada feugiat. Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.`,
      type: IconEnum.outlinephotograph,
    },
    {
      libelle: `#Nulla #porttitor accumsan tincidunt. Nulla porttitor accumsan tincidunt. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Nulla quis lorem ut libero malesuada feugiat. Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.`,
      type: IconEnum.outlinedocumentText,
    },
    {
      libelle: `Pellentesque in ipsum id orci porta dapibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;`,
      type: IconEnum.outlinephotograph,
    },
  ];
  notifs = [
    {
      avatar: this.avatarEnum.society,
      icon: this.iconEnum.outlineWhiteuserGroup,
      message: `Vos amis <span>Bassahak JM, Hervé Bass</span> et <span>2 autres personnes</span> ont publié dans le groupe <span>BeBallsy</span>`,
      time: '3m',
    },
    {
      avatar: this.avatarEnum.society,
      icon: this.iconEnum.outlineWhiteuserGroup,
      message: `Un admin a approuvé votre publication dans <span>LES EXPERTS NODE.JS</span>`,
      time: '4h',
    },
    {
      avatar: this.avatarEnum.society,
      icon: this.iconEnum.outlineWhiteuserGroup,
      message: `<span>DIGITAILLE</span> a publié un nouveau challenge : <span>Titre du Challenge</span>`,
      time: '1 sem',
    },
    {
      avatar: this.avatarEnum.society,
      icon: this.iconEnum.outlineWhiteuserGroup,
      message: `<span>Dieudonnée Ambang</span> a commenté une pulication de <span>Atn Henri Lotin</span>`,
      time: '3m',
    },
    {
      avatar: this.avatarEnum.society,
      icon: this.iconEnum.outlineWhiteuserGroup,
      message: `Vous avez une nouvelle suggestion de profil:
      <span>Yankap Arno</span>`,
      time: '3m',
    },
    {
      avatar: this.avatarEnum.society,
      icon: this.iconEnum.outlineWhiteuserGroup,
      message: `<span>Danick tchaffo</span> a visité <span>DIGITAILLE</span> :
      "Les deux pilliers du mangement participatif"`,
      time: '2h',
    },
    {
      avatar: this.avatarEnum.society,
      icon: this.iconEnum.outlineWhiteuserGroup,
      message: ` <span>Mboning Elvis</span> vous a envoyé un message : "Stp
      n'oublie pas de ..."`,
      time: '3h',
    },
    {
      avatar: this.avatarEnum.society,
      icon: this.iconEnum.outlineWhiteuserGroup,
      message: `Vos amis <span>Bassahak JM, Hervé Bass</span> et <span>2 autres personnes</span> ont publié dans le groupe <span>BeBallsy</span>`,
      time: '3m',
    },
  ];
  notifList = false;

  constructor(private overlay: Overlay, private modalService: DialogService) {}

  ngOnInit(): void {}

  deleteNotifs() {
    const ref = this.modalService.open(DialogDeleteGroupComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Supprimer la notification`,
        size: DialogSizeEnum.small,
      },
    });
  }

  showNotifList() {
    this.notifList = !this.notifList;
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }
}
