import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import {
  IconEnum,
  AvatarTypeEnum,
  ControlTypeColorEnum,
  ControlSizeEnum,
} from 'datnek-ds';

interface UsersItems {
  id: string;
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  relations: number;
  sector: string;
}

@Component({
  selector: 'nk-shared-suggestion-card-mobile',
  templateUrl: './suggestion-card-mobile.component.html',
  styleUrls: ['./suggestion-card-mobile.component.scss'],
})
export class SuggestionCardMobileComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = true;

  @Input()
  userData: UsersItems[] = [
    {
      id: UUID.UUID(),
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      relations: 4,
      sector: 'Génie civil',
    },
    {
      id: UUID.UUID(),
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      relations: 18,
      sector: 'Technologie',
    },
    {
      id: UUID.UUID(),
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      relations: 0,
      sector: 'Agroalimentaire',
    },
  ];

  @Input()
  status: AvatarTypeEnum = this.avatarEnum.person;

  @Input()
  isInline = false;

  @Input()
  avatar = true;

  @ViewChild('videoPlayer')
  videoplayer!: ElementRef<HTMLVideoElement>;

  followed = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  counter(i: number): Array<number> {
    return new Array(i);
  }

  startPreview(): void {
    this.videoplayer.nativeElement.muted = true;
    this.videoplayer.nativeElement.currentTime = 1;
    this.videoplayer.nativeElement.playbackRate = 0.5;
    this.videoplayer.nativeElement.play();
  }

  stopPreview(): void {
    this.videoplayer.nativeElement.currentTime = 0;
    this.videoplayer.nativeElement.playbackRate = 1;
    this.videoplayer.nativeElement.pause();
  }

  goToPitch(e: any): void {
    this.router.navigate(['/request/suggestion-pitch']);
  }

  goToSociety(): void {
    this.router.navigate(['/enterprise']);
  }

  onFollow(): void {
    this.followed = !this.followed;
  }
}
