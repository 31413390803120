import { User } from '../users';
import { Event } from '../events/event.model';
import {TrackModel} from "datnek-core";

export class EventUser extends TrackModel {
  UserId?: string;
  User?: User;

  EventId?: string;
  Event?: Event;

  Status?: ParticipationStatusEnum;

  constructor(
    Id?: string,
    CreatedAt?: Date,
    UpdatedAt?: Date,
    userId?: string,
    eventId?: string,
    status?: ParticipationStatusEnum
  ) {
    super(Id, CreatedAt, UpdatedAt);
    this.UserId = userId;
    this.EventId = eventId;
    this.Status = status;
  }
}

export enum ParticipationStatusEnum {}
