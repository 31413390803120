<div class="datnek-ds-dialog-body dialog-add-signalization">
  <div class="content-text px-3 py-3">
    <p>
      Si une personne est en danger immédiat, demandez de l’aide sans attendre
      avant de le signaler à Delescio Network.
    </p>
    <datnek-ds-search-input
      placeholder="Recherche les raisons"
      class="mb-3"
    ></datnek-ds-search-input >
  </div>

  <p-scrollPanel [style]="{ width: '100%', height: '300px' }">
    <div class="reasons-list">
      <network-accordion>
        <nk-shared-accordion-group title="Nudité">
          <datnek-ds-checkbox-group
            [markLeft]="true"
            [items]="nudite"
          ></datnek-ds-checkbox-group>
          <div class="mb-3 border-top pt-3">
            <datnek-ds-checkbox-group
              label="Autre"
              [markLeft]="true"
              (click)="otherReason()"
              class="mb-3"
            ></datnek-ds-checkbox-group>
          </div>

          <datnek-ds-textarea
            placeholdertext="Ajouter une autre raison de signalisation"
            *ngIf="!other"
          ></datnek-ds-textarea>
        </nk-shared-accordion-group>
        <nk-shared-accordion-group title="Violence">
          <datnek-ds-checkbox-group
            [markLeft]="true"
            [items]="violence"
          ></datnek-ds-checkbox-group>
          <div class="mb-3 border-top pt-3">
            <datnek-ds-checkbox-group
              label="Autre"
              [markLeft]="true"
              (click)="otherReason()"
              class="mb-3"
            ></datnek-ds-checkbox-group>
          </div>

          <datnek-ds-textarea
            placeholdertext="Ajouter une autre raison de signalisation"
            *ngIf="!other"
          ></datnek-ds-textarea>
        </nk-shared-accordion-group>
        <nk-shared-accordion-group title="Harcèlement">
          <datnek-ds-checkbox-group
            [markLeft]="true"
            [items]="harcelement"
          ></datnek-ds-checkbox-group>
          <div class="mb-3 border-top pt-3">
            <datnek-ds-checkbox-group
              label="Autre"
              [markLeft]="true"
              (click)="otherReason()"
              class="mb-3"
            ></datnek-ds-checkbox-group>
          </div>

          <datnek-ds-textarea
            placeholdertext="Ajouter une autre raison de signalisation"
            *ngIf="!other"
          ></datnek-ds-textarea>
        </nk-shared-accordion-group>
        <nk-shared-accordion-group title="Fausses informations">
          <datnek-ds-checkbox-group
            [markLeft]="true"
            [items]="fausses_infos"
          ></datnek-ds-checkbox-group>
          <div class="mb-3 border-top pt-3">
            <datnek-ds-checkbox-group
              label="Autre"
              [markLeft]="true"
              (click)="otherReason()"
              class="mb-3"
            ></datnek-ds-checkbox-group>
          </div>

          <datnek-ds-textarea
            placeholdertext="Ajouter une autre raison de signalisation"
            *ngIf="!other"
          ></datnek-ds-textarea>
        </nk-shared-accordion-group>
        <nk-shared-accordion-group title="Contenu indésirable">
          <datnek-ds-checkbox-group
            [markLeft]="true"
            [items]="indesirable"
          ></datnek-ds-checkbox-group>
          <div class="mb-3 border-top pt-3">
            <datnek-ds-checkbox-group
              label="Autre"
              [markLeft]="true"
              (click)="otherReason()"
              class="mb-3"
            ></datnek-ds-checkbox-group>
          </div>

          <datnek-ds-textarea
            placeholdertext="Ajouter une autre raison de signalisation"
            *ngIf="!other"
          ></datnek-ds-textarea>
        </nk-shared-accordion-group>
      </network-accordion>
    </div>
  </p-scrollPanel>
</div>

<!-- FOOTER MODAL -->
<div class="datnek-ds-dialog-footer">
  <datnek-ds-button
    [label]="'Annuler'"
    [size]="sizeEnum.large"
    [type]="colorEnum.secondary"
    class="mr-3"
  ></datnek-ds-button>
  <datnek-ds-button
    [label]="'Créer'"
    [size]="sizeEnum.large"
    [type]="colorEnum.primary"
    (datnekdsClickOrTouch)="close()"
  ></datnek-ds-button>
</div>
