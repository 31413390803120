import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
} from 'datnek-ds';

interface UsersItems {
  id: string;
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  relations: number;
  sector: string;
}

@Component({
  selector: 'nk-shared-suggestion-full-list',
  templateUrl: './suggestion-full-list.component.html',
  styleUrls: ['./suggestion-full-list.component.scss'],
})
export class SuggestionFullListComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  @Input()
  userData: UsersItems[] = [
    {
      id: UUID.UUID(),
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      relations: 4,
      sector: 'Génie civil',
    },
    {
      id: UUID.UUID(),
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      relations: 18,
      sector: 'Technologie',
    },
    {
      id: UUID.UUID(),
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      relations: 0,
      sector: 'Agroalimentaire',
    },
  ];

  currentIndex = 0;

  video!: HTMLVideoElement;

  previewTimeout: null | ReturnType<typeof setTimeout> = null;

  /**
   * Le type de suggestion renvoyé : 'invitation' | 'suggestion'
   *
   * @default = 'suggestion'
   */
  @Input()
  type: 'invitation' | 'suggestion' = 'suggestion';

  /**
   * Le type de status renvoyé : 'person' | 'society' = 'person'
   *
   * @default = 'person'
   */
  @Input()
  status: AvatarTypeEnum = this.avatarEnum.person;

  follow = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    console.log(typeof this.type);

    // this.video = document.querySelector('#' + this.idVideo) as HTMLVideoElement;
  }

  startPreview(id: string) {
    const video = document.querySelector('#' + id) as HTMLVideoElement;
    if (video) {
      video.muted = true;
      video.currentTime = 1;
      video.playbackRate = 0.5;
      video.play();
    }
    // this.videoplayer.nativeElement.muted = true;
    // this.videoplayer.nativeElement.currentTime = 1;
    // this.videoplayer.nativeElement.playbackRate = 0.5;
    // this.videoplayer.nativeElement.play();
  }

  stopPreview() {
    // this.videoplayer.nativeElement.currentTime = 0;
    // this.videoplayer.nativeElement.playbackRate = 1;
    // this.videoplayer.nativeElement.pause();
  }

  // @HostListener('mouseover')
  // onMouseOver() {
  //   this.startPreview();
  //   this.previewTimeout = setTimeout(() => {
  //     this.stopPreview();
  //   }, 4000);
  // }

  // @HostListener('mouseout')
  // onMouseOut() {
  //   clearTimeout();
  //   this.stopPreview();
  // }

  isFollowed(index: number): void {
    this.follow = !this.follow;
    this.currentIndex = index;
  }

  goToPitch(e: any) {
    this.router.navigate(['/request/suggestion-pitch']);
  }
}
