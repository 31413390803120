import { Router } from '@angular/router';
import { Component, Input, OnInit, TemplateRef, Type } from '@angular/core';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  ScaleIconEnum,
  SuggestionTypeEnum,
  DialogRef,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';

import { ComponentType } from '@angular/cdk/overlay';
import { DialogAddCategoryComponent } from '../../dialogs';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nk-shared-suggestion-article-list',
  templateUrl: './suggestion-article-list.component.html',
  styleUrls: ['./suggestion-article-list.component.scss'],
  providers: [NgbDropdown],
})
export class SuggestionArticleListComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  scaleIconEnum = ScaleIconEnum;

  mute = false;
  bookmark = false;

  @Input() articesData = [
    {
      title: 'ASP.NET 6 Performance Tricks You Haven’t Heard Of',
      username: 'Yankep Arno',
      imgSrc: 'assets/Img/AdobeStock_104857309-1140x650.jpg',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sunt, veritatis libero hic itaque dignissimos doloremque molestiae aut quasi qui nemo facere perspiciatis impedit fugiat officia enim assumenda quisquam, tempora modi.',
    },
    {
      title: 'Inheritance Strategies in Entity Framework Core 7',
      username: 'John Doe',
      imgSrc: 'assets/Img/executives-joking-laughing-office.jpg',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sunt, veritatis libero hic itaque dignissimos doloremque molestiae aut quasi qui nemo facere perspiciatis impedit fugiat officia enim assumenda quisquam, tempora modi.',
    },
    {
      title: 'Upgrade Your Existing Projects to .NET 7',
      username: 'Banick',
      imgSrc: 'assets/Img/AdobeStock_104857309-1140x650.jpg',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sunt, veritatis libero hic itaque dignissimos doloremque molestiae aut quasi qui nemo facere perspiciatis impedit fugiat officia enim assumenda quisquam, tempora modi.',
    },
    {
      title: 'UI Framework Popularity — 2022',
      username: 'Alper Ebiçoğl',
      imgSrc: 'assets/Img/executives-joking-laughing-office.jpg',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sunt, veritatis libero hic itaque dignissimos doloremque molestiae aut quasi qui nemo facere perspiciatis impedit fugiat officia enim assumenda quisquam, tempora modi.',
    },
  ];

  @Input() articesDataAuthor = [
    {
      title: 'ASP.NET 6 Performance Tricks You Haven’t Heard Of',
      username: 'John Doe',
      imgSrc: 'assets/Img/AdobeStock_104857309-1140x650.jpg',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sunt, veritatis libero hic itaque dignissimos doloremque molestiae aut quasi qui nemo facere perspiciatis impedit fugiat officia enim assumenda quisquam, tempora modi.',
    },
    {
      title: 'Inheritance Strategies in Entity Framework Core 7',
      username: 'John Doe',
      imgSrc: 'assets/Img/executives-joking-laughing-office.jpg',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sunt, veritatis libero hic itaque dignissimos doloremque molestiae aut quasi qui nemo facere perspiciatis impedit fugiat officia enim assumenda quisquam, tempora modi.',
    },
    {
      title: 'Upgrade Your Existing Projects to .NET 7',
      username: 'John Doe',
      imgSrc: 'assets/Img/AdobeStock_104857309-1140x650.jpg',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sunt, veritatis libero hic itaque dignissimos doloremque molestiae aut quasi qui nemo facere perspiciatis impedit fugiat officia enim assumenda quisquam, tempora modi.',
    },
    {
      title: 'UI Framework Popularity — 2022',
      username: 'John Doe',
      imgSrc: 'assets/Img/executives-joking-laughing-office.jpg',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sunt, veritatis libero hic itaque dignissimos doloremque molestiae aut quasi qui nemo facere perspiciatis impedit fugiat officia enim assumenda quisquam, tempora modi.',
    },
  ];

  listFilter = ['Développement Web', 'c#', 'IA'];

  /**
   * Le type de status renvoyé : 'person' | 'society' = 'person'
   *
   * @default = 'person'
   */
  @Input()
  status!: AvatarTypeEnum;

  /**
   * Si la suggestion de l'publication est dans un Sidebar
   *
   * @default = 'false'
   */
  @Input()
  isSidebar = false;

  /**
   * Si la suggestion de l'publication est en full
   *
   * @default = 'true'
   */
  @Input()
  isFull = true;

  /**
   * Le type de suggestion renvoyé : 'invitation' | 'suggestion'
   *
   * @default = 'suggestion'
   */
  @Input()
  type: SuggestionTypeEnum = SuggestionTypeEnum.suggestion;

  linkDescription = `Lorem ipsum dolor sit amet consectetur, adipisicing elit.
  Cum, cupiditate similique! Exercitationem modi dicta animi,
  voluptates repellat excepturi reiciendis`;
  descriptionPage = `Lorem ipsum dolor sit amet consectetur, adipisicing elit.
  Cum, cupiditate similique! Exercitationem modi dicta animi,
  voluptates repellat excepturi reiciendis`;
  follow = false;

  isLoading = true;

  constructor(
    private router: Router,
    private modalService: DialogService,
    public dialogRef: DialogRef,
    private myDropDown: NgbDropdown
  ) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  addToCategory() {
    const ref = this.modalService.open(DialogAddCategoryComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Sauvegarder dans une catégorie`,
        size: DialogSizeEnum.small,
      },
    });
  }

  blockUser(content: TemplateRef<any> | ComponentType<any> | string) {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        size: DialogSizeEnum.small,
        title: 'Bloquer cet utilisateur ?',
      },
    });
  }

  signalUser(content: TemplateRef<any> | ComponentType<any> | string) {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        size: DialogSizeEnum.small,
        title: 'Signaler cet utilisateur ?',
      },
    });
  }

  onHover(event: any): void {
    this.myDropDown.toggle();
  }

  onMute() {
    this.mute = !this.mute;
  }

  onBookmark() {
    this.bookmark = !this.bookmark;
  }

  goToArticle(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate(['/single-publication']);
  }

  blankPage(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate(['/blank']);
  }

  gotoListArticles(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate(['/list-articles']);
  }

  goToSavedMessages(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate(['/saved-messages']);
  }

  close(): void {
    this.dialogRef.close(null);
  }

  counter(i: number): Array<number> {
    return new Array(i);
  }
}
