import { Component, OnInit } from '@angular/core';
import {AvatarTypeEnum} from "datnek-ds";

@Component({
  selector: 'nk-shared-challenge-stats-menu-mobile',
  templateUrl: './challenge-stats-menu-mobile.component.html',
  styleUrls: ['./challenge-stats-menu-mobile.component.scss'],
})
export class ChallengeStatsMenuMobileComponent implements OnInit {
  avatarEnum = AvatarTypeEnum;
  constructor() {}

  ngOnInit(): void {}
}
