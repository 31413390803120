<div
  class="datnek-ds-card user-box card-challenger-box mb-3"
  *ngIf="!isLoading"
>
  <div class="header">
    <img src="/assets/Img/bg/bg-placeholder.svg" alt="" />
  </div>
  <div class="content">
    <div class="avatar" [ngClass]="{ versus: versus }">
      <datnek-ds-avatar [size]="sizeEnum.medium" [type]="status"></datnek-ds-avatar>
    </div>
    <div class="infos">
      <div
        class="display-1 d-flex justify-content-center title position-relative"
      >
        <span class="username d-flex">
          <span class="truncated">{{ userName }}</span>

          <img
            *ngIf="challenger === true"
            src="/assets/icons/src/outline/color/competition-champion-simple.svg"
            alt=""
          />
        </span>
      </div>

      <p class="mb-0">{{ userDesc }}</p>
    </div>

    <div class="competences text-left" [class.open]="readMore === true">
      <!--<span class="" *ngFor="let item of competences" style="color: #b9b9b9">
        {{ readMore ? item.name : (item.name | slice: 0:50) }}
      </span>-->
      <span class="" *ngFor="let item of competences" style="color: #b9b9b9">
        {{ item.name }}
      </span>
    </div>
    <div
      class="more pointer mx-3 mb-3"
      (datnekdsClickOrTouch)="toggleReadMore()"
    >
      <datnek-ds-svg-icon
        [icon]="iconEnum.outlinechevronDown"
        *ngIf="!readMore"
      ></datnek-ds-svg-icon>
      <datnek-ds-svg-icon
        [icon]="iconEnum.outlinechevronUp"
        *ngIf="readMore"
      ></datnek-ds-svg-icon>
    </div>
    <!--<datnek-ds-link
      href="javascript:;"
      label="Voir plus"
      *ngIf="!readMore && openForJob === true && onChat === false"
      (datnekdsClickOrTouch)="readMore = true"
      class="d-block m-auto p-3"
    ></datnek-ds-link>
    <datnek-ds-link
      href="javascript:;"
      label="Voir moins"
      *ngIf="readMore"
      (datnekdsClickOrTouch)="
        readMore = false && openForJob === true && onChat === false
      "
      class="d-block m-auto p-3"
    ></datnek-ds-link>-->

    <div
      class="challenge-desc text-left p-3 border-top border-bottom"
      *ngIf="acceptedChallenge === false"
    >
      <span *ngIf="readMore1 === false">{{
        challengeDescription | truncatetext: 60
      }}</span>
      <span *ngIf="readMore1 === true">{{ challengeDescription }}</span>
      <span
        class="link"
        (datnekdsClickOrTouch)="readMore1 = true"
        *ngIf="!readMore1"
        >Voir plus</span
      >
      <span
        class="link"
        (datnekdsClickOrTouch)="readMore1 = false"
        *ngIf="readMore1"
        >Voir moins</span
      >
    </div>

    <div
      class="challenge-footer align-items-center d-flex justify-content-between"
      *ngIf="acceptedChallenge === false"
    >
      <datnek-ds-button
        label="Accepter"
        [size]="sizeEnum.large"
        [block]="true"
        [type]="colorEnum.primary"
        (datnekdsClickOrTouch)="acceptChallenge(); confirmChallenge()"
      ></datnek-ds-button>
      <datnek-ds-button
        [label]="'Rejeter'"
        [size]="sizeEnum.large"
        [block]="true"
        [type]="colorEnum.secondary"
        (datnekdsClickOrTouch)="showToast(no)"
      ></datnek-ds-button>
    </div>
  </div>
</div>

<datnek-skeleton-card-user-box
  *ngIf="isLoading"
></datnek-skeleton-card-user-box>

<!--NOTIFS MESSAGES-->
<ng-template #yes let-close="close">
  Vous avez accepté le challenge. Vous recevrez des notifications pour la suite
</ng-template>
<ng-template #no let-close="close">
  Vous avez rejeté le challenge.
</ng-template>
