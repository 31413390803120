import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nk-shared-single-article-mobile',
  templateUrl: './single-article-mobile.component.html',
  styleUrls: ['./single-article-mobile.component.scss'],
})
export class SingleArticleMobileComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
