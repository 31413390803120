import { Overlay } from '@angular/cdk/overlay';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  Type,
} from '@angular/core';
import { Router } from '@angular/router';
import { DialogAddChallengeComponent } from '@del-shared';

import {
  DialogService,
  AvatarTypeEnum,
  ControlSizeEnum,
  IconEnum,
  ControlTypeColorEnum,
  ChallengeStateTypeEnum,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';

import {
  DialogAcceptChallengeModalComponent,
  DialogChallengeAcceptComponent,
  DialogChallengeImpossibleAccessComponent,
  DialogChallengeNoResultsComponent,
  DialogChallengeShowWinnerComponent,
  DialogDeleteGroupComponent,
} from '../../dialogs';

interface ChallengesItems {
  id: string;
  imageSrc: string;
  title: string;
  subtitle: string;
  description: string;
  name_organizer: string;
  date_debut: string;
  date_fin: string;
  status: AvatarTypeEnum;
  progress: ChallengeStateTypeEnum;
}

@Component({
  selector: 'nk-shared-card-challenge-box',
  templateUrl: './card-challenge-box.component.html',
  styleUrls: ['./card-challenge-box.component.scss'],
})
export class CardChallengeBoxComponent implements OnInit {
  constructor(
    private router: Router,
    private overlay: Overlay,
    private modalService: DialogService
  ) {}
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  challengeName = 'Le nom ou titre du challenge';

  @Input()
  challengeEnum!: 'future' | 'inProgress' | 'finished';

  @Input()
  acceptedChallenges = false;

  @Input()
  fullChallenges = false;

  @Input()
  listChallenges = false;

  @Input()
  isSidebar = false;

  @Input()
  results = false;

  @Input()
  timer = true;

  isLoading = true;
  hide = false;

  @Input()
  challengeData: ChallengesItems[] = [];

  days = 194;
  hours = 22;
  mins = 14;
  secs = 5;

  x = setInterval(() => {
    const futureDate = new Date('Jan 01, 2023 17:54:00').getTime();
    const today = new Date().getTime();
    const distance = futureDate - today;
    this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    this.hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    this.mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    this.secs = Math.floor((distance % (1000 * 60)) / 1000);
    if (distance < 0) {
      clearInterval(this.x);
      this.timer = false;
    }
  }, 1000);

  /**
   * Optional click handler
   */
  @Output()
  onClick = new EventEmitter<any>();

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  publishResults(): void {
    this.router.navigate(['/challenge/publish-my-challenge']);
  }

  onHide(): void {
    this.hide = true;
  }

  noPublishResults(): void {
    const ref = this.modalService.open(DialogChallengeNoResultsComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Aucun résultat pour le moment`,
        size: DialogSizeEnum.small,
      },
    });
  }

  accessToChallenge(): void {
    const ref = this.modalService.open(DialogAcceptChallengeModalComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Accepter le challenge`,
        size: DialogSizeEnum.small,
      },
    });
  }

  resultsDetails(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    const ref = this.modalService.open(DialogChallengeShowWinnerComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Impossible d'accéder au challenge`,
        size: DialogSizeEnum.large,
      },
    });
  }

  impossibleAccess(): void {
    const ref = this.modalService.open(
      DialogChallengeImpossibleAccessComponent,
      {
        dialogType: DialogTypeEnum.Dialog,
        data: {
          title: `Impossible d'accéder au challenge`,
          size: DialogSizeEnum.small,
        },
      }
    );
  }

  confirmChallenge(): void {
    const ref = this.modalService.open(DialogChallengeAcceptComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Conformer l'adhésion au Challenge`,
        size: DialogSizeEnum.small,
      },
    });
  }

  deleteChallenge(): void {
    const ref = this.modalService.open(DialogDeleteGroupComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Supprimer le Challenge ?`,
        size: DialogSizeEnum.small,
      },
    });
  }
  editChallenge(): void {
    const ref = this.modalService.open(DialogAddChallengeComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Modifier le challenge`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  link(url: string): void {
    window.open(url, '_blank');
  }

  goToChallenge(): void {
    this.router.navigate(['/challenge/challenge-name']);
  }

  goToChallengeAccepted(): void {
    this.router.navigate(['/challenge/accepted-challenge-name']);
  }

  goToChallengeAcceptedInProgress(): void {
    this.router.navigate(['/challenge/challenge-name-in-progress']);
  }

  goToChallengeAcceptedFinished(): void {
    this.router.navigate(['/challenge/challenge-name-finished']);
  }

  goToYourChallenges(): void {
    this.router.navigate(['/challenge/my-challenge-name']);
  }

  goToYourChallengesInProgress(): void {
    this.router.navigate(['/challenge/my-challenge-name-in-progress']);
  }

  goToYourChallengesFinished(): void {
    this.router.navigate(['/challenge/my-challenge-name-finished']);
  }

  goToResults(): void {
    this.router.navigate(['/challenge/my-results']);
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }
}
