<div class="datnek-ds-dialog-body dialog-group-adhesion">
  <div class="top-info">
    <nk-shared-suggestion-list
      [userData]="dataUser"
      [status]="avatarEnum.group"
    ></nk-shared-suggestion-list>
  </div>

  <div class="content-text px-3 py-3">
    <div class="display-3">Votre adhésion est en cours d’approbation</div>
    <p>
      Répondez à ces questions des administrateurs du groupe pour les aider à
      examiner votre demande d’adhésion. Seuls les administrateurs et les
      modérateurs verront vos réponses.
    </p>
  </div>
  <div class="content-modal">
    <div class="questions p-3">
      <div class="question-item mb-3">
        <div
          class="
            head
            d-flex
            align-items-center
            mb-3
            justify-content-between
            w-100
          "
        >
          <div class="question display-4">
            Vous engagez-vous à respecter fidèlement nos règles et règlements?
          </div>
          <button
            (datnekdsClickOrTouch)="openDropdown(signal, origin1)"
            #origin1
          >
            <datnek-ds-button-icon
              [Icon]="iconEnum.outlinedotsHorizontal"
              [type]="colorEnum.ghost"
              [round]="true"
              id="dropdownBasic1"
            ></datnek-ds-button-icon>
          </button>
        </div>
        <datnek-ds-checkbox-group
          [items]="quizz"
          [markLeft]="true"
        ></datnek-ds-checkbox-group>
      </div>
      <div class="question-item mb-3">
        <div
          class="
            head
            d-flex
            align-items-center
            mb-3
            justify-content-between
            w-100
          "
        >
          <div class="question display-4">
            Pourquoi voulez-vous rejoindre Amoureux de Cuisine Camerounaise?
          </div>
          <button
            (datnekdsClickOrTouch)="openDropdown(signal, origin2)"
            #origin2
          >
            <datnek-ds-button-icon
              [Icon]="iconEnum.outlinedotsHorizontal"
              [type]="colorEnum.ghost"
              [round]="true"
              id="dropdownBasic1"
            ></datnek-ds-button-icon>
          </button>
        </div>
        <datnek-ds-checkbox-group
          [items]="quizz1"
          [markLeft]="true"
        ></datnek-ds-checkbox-group>
      </div>
      <div class="question-item mb-3">
        <div
          class="
            head
            d-flex
            align-items-center
            mb-3
            justify-content-between
            w-100
          "
        >
          <div class="question display-4">
            Votre ville et pays de résidence?
          </div>
          <button
            (datnekdsClickOrTouch)="openDropdown(signal, origin3)"
            #origin3
          >
            <datnek-ds-button-icon
              [Icon]="iconEnum.outlinedotsHorizontal"
              [type]="colorEnum.ghost"
              [round]="true"
              id="dropdownBasic1"
            ></datnek-ds-button-icon>
          </button>
        </div>
        <datnek-ds-textarea
          placeholder="Ecrivez une réponse..."
        ></datnek-ds-textarea>
      </div>
    </div>
    <div class="rules p-3">
      <div class="display-2">Règles du groupe</div>
      <datnek-ds-checkbox-group [items]="acceptRules"></datnek-ds-checkbox-group>
      <div class="rules-content m-2">
        <div class="list">
          <div class="d-flex">
            <span class="content flex-column">
              <span class="display-3 d-block mb-2">Objectif</span>
              <span
                >L’objectif principal de ce groupe est de communiquer, partager,
                et valoriser la culture du Cameroun en général et de l’art
                culinaire camerounais en particulier</span
              >
            </span>
          </div>
          <div class="d-flex">
            <span class="content flex-column">
              <span class="display-3 d-block mb-2">Cibles</span>
              <span
                >Ce groupe s'adresse à tout le monde, camerounais et non
                camerounais qui partage la même passion, à savoir LA CUISINE
                CAMEROUNAISE, richesse gastronomique de nos dix région</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="datnek-ds-dialog-footer">
    <datnek-ds-button
      [label]="'Annuler'"
      [size]="sizeEnum.large"
      [type]="colorEnum.secondary"
      class="mr-3"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
    <datnek-ds-button
      [label]="'Envoyer'"
      [size]="sizeEnum.large"
      [type]="colorEnum.primary"
      (datnekdsClickOrTouch)="showToast(rp); close()"
    ></datnek-ds-button>
  </div>
</div>

<!-- TOAST MESSAGES-->
<ng-template #rp let-close="close">
  Les administrateurs ont reçu vos réponses. Vous recevrez une notification si
  votre demande d’adhésion est approuvée.</ng-template
>

<!-- DROPDOWN -->
<ng-template #signal let-close="close">
  <div class="datnek-ds-card dropdown-card group-adhesion">
    <datnek-ds-icon-submenu
      ngbDropdownItem
      [icon]="iconEnum.outlineflag"
      label="Signaler ce contenu"
      description="Ce contenu contient du contenu offansant ou compte piraté"
      (datnekdsClickOrTouch)="addSignalization()"
    ></datnek-ds-icon-submenu>
  </div>
</ng-template>
