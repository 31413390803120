<div class="datnek-ds-dialog-body dialog-add-group">
  <div class="content-modal">
    <div class="content-text">
      <datnek-ds-upload-files
        title="Téléchargez une photo de couverture"
        imgSize="Largeur minimum de 1280 X 720"
        fileType="single"
        [accept]="accept"
        type="image"
      ></datnek-ds-upload-files>
    </div>
    <div class="content-profile">
      <input type="file" name="" id="" />
      <img [src]="'icons/src/photograph-camera.svg' | assetUrl" alt="" />
      <div class="add-img-profile">Ajouter une image de profil</div>
    </div>
    <div class="form p-3">
      <div class="grid grid--gap16">
        <div class="grid__col_12 mb-3">
          <datnek-ds-input-text
            type="text"
            label="Nom du groupe"
            placeholder="Nom du groupe"
          ></datnek-ds-input-text>
        </div>
      </div>
      <div class="grid grid--gap16">
        <div class="grid__col_12 mb-3">
          <div class="display-6 mb-1">Confidentialité du groupe</div>
          <div ngbDropdown class="question">
            <div
              class="
                pointer
                p-3
                question-choice
                d-flex
                justify-content-between
                align-items-center
              "
              id="dropdownBasic2"
              ngbDropdownToggle
            >
              <span>Confidentialité du groupe</span>
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlinechevronDown"
                [type]="colorEnum.unstyled"
                [size]="sizeEnum.small"
              ></datnek-ds-button-icon>
            </div>

            <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
              <datnek-ds-icon-submenu
                ngbDropdownItem
                [icon]="iconEnum.outlineglobe"
                label="Publique"
                (datnekdsClickOrTouch)="isPublic()"
              ></datnek-ds-icon-submenu>
              <datnek-ds-icon-submenu
                ngbDropdownItem
                [icon]="iconEnum.outlinelockClosed"
                label="Privé"
                (datnekdsClickOrTouch)="isPrivate()"
              ></datnek-ds-icon-submenu>
            </div>
          </div>
          <span class="mt-3 d-block" *ngIf="public === true"
            >Les membres et les visiteurs peuvent publier dans le groupe. Les
            admins peuvent examiner les personnes qui participent pour la
            première fois.</span
          >
        </div>
      </div>
      <div class="grid grid--gap16" *ngIf="private === true">
        <div class="grid__col_12 mb-3">
          <div class="display-6 mb-1">Masquer le groupe</div>
          <div ngbDropdown class="question">
            <div
              class="
                pointer
                p-3
                question-choice
                d-flex
                justify-content-between
                align-items-center
              "
              id="dropdownBasic2"
              ngbDropdownToggle
            >
              <span>Masquer le groupe ?</span>
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlinechevronDown"
                [type]="colorEnum.unstyled"
                [size]="sizeEnum.small"
              ></datnek-ds-button-icon>
            </div>

            <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
              <datnek-ds-icon-submenu
                ngbDropdownItem
                [icon]="iconEnum.outlineeye"
                label="Visible"
              ></datnek-ds-icon-submenu>
              <datnek-ds-icon-submenu
                ngbDropdownItem
                [icon]="iconEnum.outlineeyeOff"
                label="Masqué"
              ></datnek-ds-icon-submenu>
            </div>
          </div>
          <span *ngIf=""
            >Les membres et les visiteurs peuvent publier dans le groupe. Les
            admins peuvent examiner les personnes qui participent pour la
            première fois.</span
          >
        </div>
      </div>
      <div class="grid grid--gap16">
        <div class="grid__col_12 mb-3">
          <datnek-ds-textarea
            [label]="'Description du groupe*'"
          ></datnek-ds-textarea>
        </div>
      </div>
      <div class="grid grid--gap16">
        <div class="grid__col_12 mb-3">
          <div class="list-users-input">
            <datnek-ds-select2 [selectType]="selectType.users"></datnek-ds-select2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="datnek-ds-dialog-footer">
  <datnek-ds-button
    [label]="'Publier'"
    [size]="sizeEnum.medium"
    [type]="colorEnum.primary"
  ></datnek-ds-button>
</div>

<ng-template #tpl let-close="close">
  <div class="modal-card">
    <section class="modal-card-body">A yes no dialog, using template</section>
    <footer class="modal-card-foot">
      <div class="buttons is-right">
        <button (click)="close('yes')" type="button" class="btn btn-primary">
          Yes
        </button>
        <button (click)="close('no')" type="button" class="btn btn-danger">
          No
        </button>
      </div>
    </footer>
  </div>
</ng-template>
