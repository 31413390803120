import { Component, OnInit } from '@angular/core';
import {
  FileAcceptEnum,
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef
} from 'datnek-ds';

@Component({
  selector: 'nk-shared-dialog-edit-contact',
  templateUrl: './dialog-edit-contact.component.html',
  styleUrls: ['./dialog-edit-contact.component.scss'],
})
export class DialogEditContactComponent implements OnInit {
  accept = [FileAcceptEnum.image];
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  input = false;
  input1 = false;
  input2 = false;

  constructor(public ref: DialogRef) {}

  ngOnInit(): void {}

  addInput() {
    this.input = !this.input;
  }
  addInput1() {
    this.input1 = !this.input1;
  }
  addInput2() {
    this.input2 = !this.input2;
  }

  close() {
    this.ref.close(null);
  }
}
