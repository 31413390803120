<div
  [id]="userChat.id"
  [hidden]="!userChat.display"
  [ngStyle]="{ right: userChat.right + 'px' }"
  class="chat-popup-box shadow-sm"
  [class.show]="status === true"
  [ngClass]="isSidebar === true ? 'sidebar' : ''"
>
  <div
    class="
      header-chat-list
      d-flex
      align-items-center
      justify-content-between
      px-3
    "
    *ngIf="isSidebar === false"
  >
    <div triggers="click:blur">
      <datnek-ds-avatar
        [size]="sizeEnum.small"
        [border]="false"
        class="mr-2"
      ></datnek-ds-avatar>
      <span class="display-4" triggers="click:blur">{{
        userChat.name | truncatetext: 16
      }}</span>
    </div>
    <div class="text-right d-flex align-items-center">
      <datnek-ds-button-icon
        [round]="true"
        [type]="colorEnum.primary"
        [Icon]="iconEnum.outlineWhitevideoCamera"
        [size]="sizeEnum.medium"
        class=""
        (datnekdsClickOrTouch)="openVideoCall()"
      ></datnek-ds-button-icon>
      <datnek-ds-button-icon
        [round]="true"
        [type]="colorEnum.primary"
        [Icon]="iconEnum.outlineWhitephone"
        [size]="sizeEnum.medium"
        class=""
        (datnekdsClickOrTouch)="openVideoCall()"
      ></datnek-ds-button-icon>
      <datnek-ds-button-icon
        [round]="true"
        [type]="colorEnum.primary"
        [Icon]="iconEnum.outlineWhiteminus"
        [size]="sizeEnum.medium"
        class="pointer"
        (datnekdsClickOrTouch)="showStatus()"
      ></datnek-ds-button-icon>
      <datnek-ds-button-icon
        [round]="true"
        [type]="colorEnum.primary"
        [Icon]="iconEnum.outlineWhiteclose"
        [size]="sizeEnum.medium"
        class="pointer"
        (datnekdsClickOrTouch)="closePopup()"
      ></datnek-ds-button-icon>
    </div>
  </div>
  <div class="body-chat-list w-100">
    <div class="position-relative w-100">
      <!--CHAT MESSAGE-->
      <div class="chat-messages w-100">
        <ng-container #date>
          <div class="date-message">
            <span class="display-8">Aujourd'hui</span>
          </div>

          <nk-shared-chat-message-right
            message="Cras ultricies ligula sed magna dictum porta. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem."
            time="17/08/2019 01:04"
            [size]="sizeEnum.small"
            [isPopbox]="true"
            (respond)="openMessageToRespond()"
          ></nk-shared-chat-message-right>
          <nk-shared-chat-message-left
            message="Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem."
            time="17/08/2019 04:12"
            [size]="sizeEnum.small"
            [isPopbox]="true"
            (respond)="openMessageToRespond()"
          ></nk-shared-chat-message-left>
          <nk-shared-chat-message-right
            message="Proin eget tortor risus."
            [size]="sizeEnum.small"
            [isPopbox]="true"
            (respond)="openMessageToRespond()"
          ></nk-shared-chat-message-right>
          <nk-shared-chat-message-left
            [imageGallery]="true"
            [size]="sizeEnum.small"
            [isPopbox]="true"
            (respond)="openMessageToRespond()"
          ></nk-shared-chat-message-left>
        </ng-container>

        <ng-container #date>
          <div class="date-message">
            <span class="display-8">Vendredi 21 juillet</span>
          </div>
          <nk-shared-chat-message-right
            message="Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem."
            [size]="sizeEnum.small"
            [isPopbox]="true"
            (respond)="openMessageToRespond()"
          ></nk-shared-chat-message-right>
          <nk-shared-chat-message-left
            message="Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula."
            [size]="sizeEnum.small"
            [isPopbox]="true"
            (respond)="openMessageToRespond()"
          ></nk-shared-chat-message-left>
          <nk-shared-chat-message-right
            message="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            [size]="sizeEnum.small"
            [isPopbox]="true"
            (respond)="openMessageToRespond()"
          ></nk-shared-chat-message-right>
          <nk-shared-chat-message-left
            [videoGallery]="true"
            [size]="sizeEnum.small"
            [isPopbox]="true"
            (respond)="openMessageToRespond()"
          ></nk-shared-chat-message-left>
          <nk-shared-chat-message-left
            [audio]="true"
            [size]="sizeEnum.small"
            [isPopbox]="true"
            (respond)="openMessageToRespond()"
          ></nk-shared-chat-message-left>
          <nk-shared-chat-message-right
            message="
              Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula."
            [size]="sizeEnum.small"
            [isPopbox]="true"
            [isPopbox]="true"
            (respond)="openMessageToRespond()"
          ></nk-shared-chat-message-right>
        </ng-container>

        <ng-container #date>
          <div class="date-message">
            <span class="display-8">Jeudi 13 juiller</span>
          </div>
          <nk-shared-chat-message-left
            message="OK"
            [size]="sizeEnum.small"
            [isPopbox]="true"
            (respond)="openMessageToRespond()"
          ></nk-shared-chat-message-left>
          <nk-shared-chat-message-right
            message="Proin eget tortor risus."
            [size]="sizeEnum.small"
            [isPopbox]="true"
            (respond)="openMessageToRespond()"
          ></nk-shared-chat-message-right>
        </ng-container>
      </div>

      <!--MESSSAGE INPUT-->
      <div
        class="
          chat-form
          d-flex
          flex-grow-0
          justify-content-between
          py-1
          border-top
        "
      >
        <!--<div
          class="
            icons
            d-flex
            align-items-center
            justify-content-between
            flex-grow-1
            mr-3
          "
        >
          <datnek-ds-button-icon
            [type]="colorEnum.ghost"
            [Icon]="iconEnum.outlinepaperClip"
            (datnekdsClickOrTouch)="showIcons()"
          >
          </datnek-ds-button-icon>
          <div class="all-icons" *ngIf="icons === true">
            <datnek-ds-button-icon
              [type]="colorEnum.ghost"
              [Icon]="iconEnum.outlinephotograph"
              (datnekdsClickOrTouch)="addPicture()"
            ></datnek-ds-button-icon>
            <datnek-ds-button-icon
              [type]="colorEnum.ghost"
              [Icon]="iconEnum.outlinefilm"
              (datnekdsClickOrTouch)="addVideo()"
            ></datnek-ds-button-icon>
            <datnek-ds-button-icon
              [type]="colorEnum.ghost"
              [Icon]="iconEnum.outlinemicrophone"
              (datnekdsClickOrTouch)="addVoice()"
            ></datnek-ds-button-icon>
            <datnek-ds-button-icon
              [type]="colorEnum.ghost"
              [Icon]="iconEnum.outlinedocumentText"
              (datnekdsClickOrTouch)="addDocument()"
            ></datnek-ds-button-icon>
          </div>
        </div>-->
        <div class="input d-flex flex-column align-items-center">
          <div
            class="respond-message mx-2"
            *ngIf="showMessageToRespond === true"
          >
            <div class="message py-2 px-3">
              <div class="name-poster display-3">Vous</div>
              <span>{{ respond_message | truncatetext: 95 }}</span>
              <datnek-ds-button-icon
                [Icon]="iconEnum.solidinboxclose"
                [type]="colorEnum.unstyled"
                [size]="sizeEnum.small"
                (datnekdsClickOrTouch)="openMessageToRespond()"
                class="close"
              ></datnek-ds-button-icon>
            </div>
          </div>
          <datnek-ds-editor-chat-popup-box></datnek-ds-editor-chat-popup-box>
        </div>
      </div>
    </div>
  </div>
</div>
