import { Router } from '@angular/router';
import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  FileAcceptEnum,
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
} from 'datnek-ds';
import { ComponentType, Overlay } from '@angular/cdk/overlay';


import { DialogConnectAndroidIosComponent } from '../dialog-connect-android-ios/dialog-connect-android-ios.component';
import { DialogConnectApiGoogleYahooComponent } from '../dialog-connect-api-google-yahoo/dialog-connect-api-google-yahoo.component';

@Component({
  selector: 'nk-shared-dialog-add-contact',
  templateUrl: './dialog-add-contact.component.html',
  styleUrls: ['./dialog-add-contact.component.scss'],
})
export class DialogAddContactComponent implements OnInit {
  accept = [FileAcceptEnum.image];
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  input = false;
  input1 = false;
  input2 = false;

  imported = false;
  import = false;
  value!: number;
  progress = 0;

  constructor(
    public dialogRef: DialogRef,
    private router: Router,
    private overlay: Overlay,
    private modalService: DialogService
  ) {
    setInterval(() => {
      if (this.progress < 100) {
        Math.round((this.progress = this.progress + 1));
      } else {
        this.imported = true;
      }
    }, 100);
  }

  ngOnInit(): void {}

  importContacts() {
    this.import = !this.import;
  }

  addInput() {
    this.input = !this.input;
  }
  addInput1() {
    this.input1 = !this.input1;
  }
  addInput2() {
    this.input2 = !this.input2;
  }

  initProgress() {
    //this.subscription = this.source.subscribe((valor) => (this.value = valor));
  }

  importContactApiGoogle(
    content: TemplateRef<any> | ComponentType<any> | string
  ) {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        size: DialogSizeEnum.small,
        title: 'Importer des contacts Google',
      },
    });
  }

  importContactApiYahoo(
    content: TemplateRef<any> | ComponentType<any> | string
  ) {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        size: DialogSizeEnum.small,
        title: 'Importer des contacts Yahoo',
      },
    });
  }

  importContactApiOutlook(
    content: TemplateRef<any> | ComponentType<any> | string
  ) {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        size: DialogSizeEnum.small,
        title: 'Importer des contacts Outlook',
      },
    });
  }

  importContactApiAndroidIos() {
    const ref = this.modalService.open(DialogConnectAndroidIosComponent, {
      dialogType: DialogTypeEnum.Dialog,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      data: {
        title: `Importer des contacts depuis votre téléphone`,
        size: DialogSizeEnum.small,
      },
    });
  }

  importContactApi() {
    const ref = this.modalService.open(DialogConnectApiGoogleYahooComponent, {
      dialogType: DialogTypeEnum.Dialog,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      data: {
        title: `Importer des contacts`,
        size: DialogSizeEnum.small,
      },
    });
  }

  apiGoogleYahoConnect() {
    this.router.navigate(['/api-google-yahoo']);
  }
}
