import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Inject, Injectable} from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';

import { PublicationActions } from './publications.actions';
import {Environment, ListData, ListDataInterface, ListResult} from "datnek-core";
import { MediaNetwork, Publication, MediaNetworksService, PublicationService} from '@del-shared';
import {ToastService} from "datnek-ds";

interface PublicationStateModel {
  publications:  ListDataInterface<Publication>;
  publicationsCompany: ListDataInterface<Publication>;
  loadingPublicationsByLazy: boolean;
  loadingSave: boolean;
  loadingRemove: boolean;
  error: any;
}

//const USER_STATE_TOKEN = template StateToken<UserStateModel>('user');

@State<PublicationStateModel>({
  name: 'publication',
  defaults: {
    publications: new ListData<Publication>(),
    publicationsCompany: new ListData<Publication>(),
    loadingPublicationsByLazy: false,
    loadingSave: false,
    loadingRemove: false,
    error: null,
  },
})
@Injectable()
export class PublicationState {
  constructor(
    private publicationService: PublicationService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private mediaNetworkService: MediaNetworksService,
    @Inject(Environment.Provider.ENVIRONMENT) private environment: Environment.Env
  ) {}

  @Action(PublicationActions.setPublicationOfCurrentUser)
  async setPublicationOfCurrentUser(
    ctx: StateContext<PublicationStateModel>,
    action: PublicationActions.setPublicationOfCurrentUser
  ) {
    if (action.type === 'user') {
      ctx.patchState({
        publications: new ListData(action.count as number, action.data),
      });
    }

    if (action.type === 'company') {
      ctx.patchState({
        publicationsCompany: new ListData(action.count as number, action.data),
      });
    }
  }

  @Action(PublicationActions.getLazyByUserOrCompany)
  async getPublicationsLazyByResume(
    ctx: StateContext<PublicationStateModel>,
    action: PublicationActions.getLazyByUserOrCompany
  ) {
    const state = ctx.getState();

    ctx.patchState({ loadingPublicationsByLazy: true, error: null });

    try {
      let publicationListResponse: ListResult<Publication> | null = null;

      if (action.type === 'user') {
        publicationListResponse = await this.publicationService
          .getByUser(action.userOrCompanyId, action.skip, action.take ?? this.environment.paginationTake.publications )
          .toPromise() as  ListResult<Publication>;
      }

      if (action.type === 'company') {
        publicationListResponse = await this.publicationService
          .getByCompany(action.userOrCompanyId, action.skip, action.take ?? this.environment.paginationTake.publications )
          .toPromise() as  ListResult<Publication>;
      }

      const publications = publicationListResponse?.Results?.sort(
        (a: Publication, b: Publication) => {
          return (
            new Date(b.UpdateAt as Date).getTime() - new Date(a.UpdateAt as Date).getTime()
          );
        }
      ) as Publication[];

      let publications1: Publication[] = [];

      if (action.type === 'user') {
        publications1 = state.publications.Data?.length
          ? [...state.publications.Data, ...publications]
          : publications;
      }

      if (action.type === 'company') {
        publications1 = state.publicationsCompany.Data?.length
          ? [...state.publicationsCompany.Data, ...publications]
          : publications;
      }

      ctx.dispatch(
        new PublicationActions.setPublicationOfCurrentUser(
          publicationListResponse?.Count as number,
          publications1,
          action.type
        )
      );
    } catch (error) {
      this.toastService.error(
        'Error',
        'Un problème est survenue lors  de la récupération la liste de publications'
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingPublicationsByLazy: false });
    }
  }

  @Action(PublicationActions.create)
  async createPublication(
    ctx: StateContext<PublicationStateModel>,
    action: PublicationActions.create
  ) {
    const state = ctx.getState();

    ctx.patchState({ loadingSave: true, error: null });

    try {
      const saved = await this.publicationService
        .createData(action.publication)
        .toPromise();

      let publications1: Publication[] = [];
      let count = 0;

      if (action.type === 'user') {
        publications1 = [saved, ...state.publications.Data as Publication[]];
        count = state.publications.Count as number;
      }

      if (action.type === 'company') {
        publications1 = [saved, ...state.publicationsCompany.Data as Publication[]];
        count = state.publicationsCompany.Count as number;
      }

      ctx.dispatch(
        new PublicationActions.setPublicationOfCurrentUser(
          count + 1,
          publications1,
          action.type
        )
      );

      this.toastService.success(
        'Success',
        'publication enregistrée avec succès'
      );
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        `Un problème est survenue lors de l'enregistrement de la publication`
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingSave: false });
    }
  }

  @Action(PublicationActions.update)
  async updatePublication(
    ctx: StateContext<PublicationStateModel>,
    action: PublicationActions.update
  ) {
    let _publications: { Data: Publication[]; Count: number } | null = null;

    if (action.type === 'user') {
      _publications = JSON.parse(
        JSON.stringify(ctx.getState().publications)
      ) as { Data: Publication[]; Count: number };
    }

    if (action.type === 'company') {
      _publications = JSON.parse(
        JSON.stringify(ctx.getState().publicationsCompany)
      ) as { Data: Publication[]; Count: number };
    }

    ctx.patchState({ loadingSave: true, error: null });

    try {
      const saved = await this.publicationService
        .editData(action.publication, action.publication.Id as string)
        .toPromise();

      const foundIndex = _publications?.Data?.findIndex((e) => e.Id === saved.Id) as number;

      const oldDocuments = _publications?.Data[foundIndex]?.Documents?.length
        ? _publications.Data[foundIndex].Documents
        : [];
      const newDocuments = saved.Documents.length
        ? [...saved.Documents, ...oldDocuments as MediaNetwork[]]
        : oldDocuments;

      _publications?.Data?.splice(foundIndex, 1, {
        ...saved,
        Documents: newDocuments,
      });

      ctx.dispatch(
        new PublicationActions.setPublicationOfCurrentUser(
          _publications?.Count as number,
          _publications?.Data as Publication[],
          action.type
        )
      );

      this.toastService.success(
        'Success',
        'publication enregistrée avec succès'
      );
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        `Un problème est survenue lors de la mise à jour de la publication`
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingSave: false });
    }
  }

  @Action(PublicationActions.remove)
  async removePublication(
    ctx: StateContext<PublicationStateModel>,
    action: PublicationActions.remove
  ) {
    let _publications: { Data: Publication[]; Count: number } | null = null;

    if (action.type === 'user') {
      _publications = JSON.parse(
        JSON.stringify(ctx.getState().publications)
      ) as { Data: Publication[]; Count: number };
    }

    if (action.type === 'company') {
      _publications = JSON.parse(
        JSON.stringify(ctx.getState().publicationsCompany)
      ) as { Data: Publication[]; Count: number };
    }

    ctx.patchState({ loadingRemove: true, error: null });

    try {
      const saved = await this.publicationService
        .deleteData(action.publication.Id as string)
        .toPromise();
      if (!saved) throw new Error('Error to delete publication');

      const foundIndex = _publications?.Data.findIndex((e) => e.Id === saved.Id) as number;

      _publications?.Data?.splice(foundIndex, 1);
      ctx.dispatch(
        new PublicationActions.setPublicationOfCurrentUser(
          _publications?.Count as number - 1,
          _publications?.Data as Publication[],
          action.type
        )
      );

      this.toastService.success(
        'Success',
        'La publication a bien été supprimée'
      );
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        'Un problème est survenue lors de la suppression'
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingRemove: false });
    }
  }

  @Action(PublicationActions.removeMedia)
  async removePublicationMedia(
    ctx: StateContext<PublicationStateModel>,
    action: PublicationActions.removeMedia
  ) {
    let _publications: { Data: Publication[]; Count: number } | null = null

    if (action.type === 'user') {
      _publications = JSON.parse(
        JSON.stringify(ctx.getState().publications)
      ) as { Data: Publication[]; Count: number };
    }

    if (action.type === 'company') {
      _publications = JSON.parse(
        JSON.stringify(ctx.getState().publicationsCompany)
      ) as { Data: Publication[]; Count: number };
    }

    ctx.patchState({ loadingRemove: true, error: null });

    try {
      const result = await this.mediaNetworkService
        .delete(action.mediaNetwork.Id as string)
        .toPromise();
      if (!result)
        throw new Error(
          'la suppression du media a rencontré une erreur, le resulat du save = ' +
            result
        );

      const foundIndex = _publications?.Data?.findIndex(
        (e) => e.Id === action.mediaNetwork.PublicationId
      ) as number;
      const indexMediaDeleted = _publications?.Data[
        foundIndex
      ]?.Documents?.findIndex((elt) => elt.Id === action.mediaNetwork.Id) as number;

      _publications?.Data[foundIndex]?.Documents?.splice(indexMediaDeleted, 1);
      _publications?.Data?.splice(foundIndex, 1, _publications?.Data[foundIndex]);

      ctx.dispatch(
        new PublicationActions.setPublicationOfCurrentUser(
          _publications?.Count as number,
          _publications?.Data as Publication[],
          action.type
        )
      );

      this.toastService.success('Success', 'Le media a bien été supprimée');
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        'Un problème est survenue lors de la suppression du media'
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingRemove: false });
    }
  }
}
