<main class="sso-login">
  <!-- Logo Icon Delenscio -->
  <div class="logo-top p-3 d-none d-md-block">
    <a routerLink="/"
      ><img
        alt="logo Delenscio CV Resume - White"
        class="logo Delenscio"
        [src]="getImg(logo)"
    /></a>
  </div>

  <!-- End Logo Icon Delenscio -->

  <!-- Login Container -->
  <div
    class="container-fluid"
    style="
      overflow: hidden;
      height: 100vh;
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      background: white;
    "
  >
    <div class="row px-0">
      <!-- Side Bloc -->
      <div class="col-md-5 px-0 d-none d-md-block login-left">
        <!-- CAROUSEL SLIDER -->
        <!--<p-carousel
          [autoplayInterval]="6000"
          [circular]="true"
          [numScroll]="1"
          [numVisible]="1"
          [value]="slides"
          styleClass="customCarousel"
        >
          <ng-template let-slide pTemplate="item">

          </ng-template>
        </p-carousel>-->
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let slide of slides">
            <ng-template carouselSlide>
              <div class="slide-item">
                <div class="p-mb-3">
                  <img
                    [src]="getImg(slide.Image)"
                    alt="login image"
                    class="login-img"
                  />
                </div>
                <div class="infos-perso w-100 p-5">
                  <div
                    class="slide-infos d-flex flex-column justify-content-left"
                  >
                    <div class="slide-title mb-3">
                      {{ slide.Title }}
                    </div>
                    <div class="slide-description w-75">
                      {{ slide.Description }}
                    </div>
                    <div class="entreprise"></div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <!-- END CAROUSEL SLIDER -->
      </div>
      <!-- End Slide Bloc -->

      <!-- Login Wrapper -->
      <div
        class="col-12 col-md-7 login-right d-flex flex-column align-items-center p-0 form-login"
      >
        <div class="logo-top p-3 d-md-none">
          <a routerLink="/"
            ><img
              alt="logo Delenscio CV Resume"
              class="logo Delenscio"
              [src]="getImg(logo)"
          /></a>
        </div>
        <datnek-sso
          [currentPage]="currentPage"
          [userType]="userType"
          [registrationAccountType]="registrationAccountType"
          [registrationStep]="registrationStep"
          (onSuccess)="onSucess($event)"
          (onError)="onError($event)"
        >
        </datnek-sso>
      </div>
      <!-- Login Wrapper -->
    </div>
  </div>
</main>
