import { Component, OnInit, TemplateRef, Type } from '@angular/core';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogRef,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';
import { Overlay } from '@angular/cdk/overlay';
import { DialogDeleteGroupComponent } from '../dialog-delete-group/dialog-delete-group.component';
import { DialogEditContactComponent } from '../dialog-edit-contact/dialog-edit-contact.component';


@Component({
  selector: 'nk-shared-dialog-contact-details',
  templateUrl: './dialog-contact-details.component.html',
  styleUrls: ['./dialog-contact-details.component.scss'],
})
export class DialogContactDetailsComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  favorite = true;

  message1 = 'Merci beaucoup';
  message2 = 'Il y a vraiment beaucoup à faire dan la semaine de la jeunesse';
  message3 = 'Ok. Thanks';

  constructor(
    public ref: DialogRef,
    private overlay: Overlay,
    private modalService: DialogService
  ) {}

  ngOnInit(): void {}
  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  editContact() {
    const ref = this.modalService.open(DialogEditContactComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Modifier un contact`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  deleteContact() {
    const ref = this.modalService.open(DialogDeleteGroupComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Supprimer le contact`,
        size: DialogSizeEnum.small,
      },
    });
  }

  addFavorite() {
    this.favorite = !this.favorite;
  }

  close() {
    this.ref.close(null);
  }

  link(url: string) {
    window.open(url, '_blank');
  }
}
