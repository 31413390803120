import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  Type,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogRedirectToComponent,
} from 'datnek-ds';
import { DialogAcceptChallengeModalComponent } from '../../dialogs';

interface Competences {
  name: string;
}

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  groupName: string;
  relations: number;
  sector: string;
}

@Component({
  selector: 'nk-shared-card-challenger-box',
  templateUrl: './card-challenger-box.component.html',
  styleUrls: ['./card-challenger-box.component.scss'],
})
export class CardChallengerBoxComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  dataUsersChat: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Consultant WebDesign',
      name: 'John Doe',
      society: 'DIGITAILLE',
      groupName: '',
      relations: 12,
      sector: '',
    },
  ];

  /**
   * Le Nom du Profil de la personne ou de l'entreprise
   */
  @Input()
  userName = '';

  /**
   * La profession de la personne ou de l'entreprise
   */
  @Input()
  userDesc = '';

  /**
   * La description du challenge proposé
   */
  @Input()
  challengeDescription = '';

  /**
   * Le type de status renvoyé : 'person' | 'society' = 'person'
   *
   * @default = 'person'
   */
  @Input()
  status!: AvatarTypeEnum;

  /**
   * Si c'est le Feed d'une entreprise, Le profil est une proposition de candidature
   *
   * @default = false
   */
  @Input()
  openForJob = false;

  /**
   * Si c'est la page de Stats de ChallengeComp
   *
   * @default = false
   */
  @Input()
  allStats = false;

  /**
   * Si c'est la page de Chat
   *
   * @default = false
   */
  @Input()
  onChat = false;

  /**
   * Si c'est la page de Chat
   *
   * @default = false
   */
  @Input()
  restricted = false;

  /**
   * Liste des compétences du candidat
   * NB: à n'afficher que sur le feed d'une entreprise
   *
   */
  @Input()
  competences = [
    {
      name: 'Gestion de la paie',
    },
    {
      name: 'Intégration des données dans la plateforme',
    },
    {
      name: 'Manipiulation des Big Data',
    },
    {
      name: 'Analyse des effets financiers',
    },
    {
      name: 'Harmnisation des candidature',
    },
    {
      name: "Mise en place d'un système de GEPEC",
    },
    {
      name: 'Gestion du recrutement',
    },
    {
      name: 'Rédaction de conventions collectives',
    },
  ];

  /**
   *
   */
  @Input()
  versus = false;

  readMore = false;
  readMore1 = false;
  followed = false;

  /**
   * Show if a challenge has benn accepted
   */
  @Input()
  acceptedChallenge = false;

  /**
   * Show if it is person who ask a challenge
   */
  @Input()
  challenger = false;

  rejectedChallenge = true;

  isLoading = true;

  /**
   * Optional click handler
   */
  @Output()
  onClick = new EventEmitter<any>();

  constructor(private router: Router, private modalService: DialogService) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  toggleReadMore(): void {
    this.readMore = !this.readMore;
  }

  acceptChallenge(): void {
    this.onClick.emit(this.acceptedChallenge);
    this.acceptedChallenge = !this.acceptedChallenge;
    console.log('CHALLENGE STATE = ' + this.acceptedChallenge);
  }

  confirmChallenge(): void {
    const ref = this.modalService.open(DialogAcceptChallengeModalComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Confirmation du Challenge`,
        size: DialogSizeEnum.small,
      },
    });
  }

  goHomeDialog(): void {
    this.followed = !this.followed;
    const ref = this.modalService.open(DialogRedirectToComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Rediriger vers Delenscio Home`,
        size: DialogSizeEnum.small,
      },
    });
  }

  goToProfile(e: any): void {
    this.router.navigate(['/']);
  }

  goToFollowingPersons(e: any): void {
    this.router.navigate(['/request/following']);
  }

  goToMyFollowers(e: any): void {
    this.router.navigate(['/request/followers']);
  }

  goToExternalLink(url: string): void {
    window.open(url, '_blank');
  }
}
