<div class="datnek-ds-dialog-body dialog-leave-group">
  <div class="content-modal">
    <div class="notif-box p-3">
      <p>
        Voulez-vous vraiment quitter LE CLUB DES EXPERTS ANGULARJS ? Vous pouvez
        également désactiver les notifications des nouvelles publications ou
        signaler ce groupe.
      </p>
    </div>
  </div>
  <div class="datnek-ds-dialog-footer">
    <datnek-ds-button
      [label]="'Annuler'"
      [size]="sizeEnum.large"
      [type]="colorEnum.secondary"
      class="mr-3"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
    <datnek-ds-button
      [label]="'Quitter le groupe'"
      [size]="sizeEnum.large"
      [type]="colorEnum.primary"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
  </div>
</div>
