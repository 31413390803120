import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  FileAcceptEnum,
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  SelectListTypeEnum,
  DialogService,
  DialogTypeEnum
} from 'datnek-ds';

@Component({
  selector: 'del-shared-dialog-add-group',
  templateUrl: './dialog-add-group.component.html',
  styleUrls: ['./dialog-add-group.component.scss'],
})
export class DialogAddGroupComponent implements OnInit {
  accept = [FileAcceptEnum.image];
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  selectType = SelectListTypeEnum;
  values1!: string[];
  list = false;

  private = false;
  public = false;

  items = ['Bassahka Jean Marc', 'Yankep Arno Gislain', 'Danick Takam'];

  constructor(private modalService: DialogService) {}

  ngOnInit(): void {}

  openTemplate(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  isPrivate() {
    this.private = !this.private;
  }

  isPublic() {
    this.public = !this.public;
  }

  onFocus() {
    this.list = !this.list;
    console.log('LIST : ' + this.list);
  }
}
