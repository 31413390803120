import {
  Component
} from '@angular/core';

import {FieldArrayType} from "@ngx-formly/core";

import {
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum, InputBaseFormlyFieldProps
} from "datnek-ds";
import {FieldArrayTypeConfig} from "@ngx-formly/core/lib/templates/field-array.type";

@Component({
  selector: 'network-add-article-header-formly',
  templateUrl: './add-article-header-formly.component.html',
  styleUrls: ['./add-article-header-formly.component.scss'],
})
export class AddArticleHeaderFormlyComponent extends  FieldArrayType<FieldArrayTypeConfig<AddArticleHeaderFormlyFieldProps>> {
  colorEnum = ControlTypeColorEnum;
  sizeEnum = ControlSizeEnum;
  iconEnum = IconEnum;

  ngOnInit(){
    if (!this.field.fieldGroup?.length){
      this.add();
    }
    console.log('the form: ', this.field.fieldArray, this.field.fieldGroup);
  }
  goToHome(e: any) {
    if (this.props['goToHome']){
      this.props.goToHome(e);
    }
  }
}


export interface AddArticleHeaderFormlyFieldProps extends InputBaseFormlyFieldProps{
  goToHome(value: any): void;
}
