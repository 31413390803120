<div class="datnek-ds-dialog-body dialog-add-quizz-group">
  <div class="content-modal">
    <!--QUESTION CHOICE-->
    <div ngbDropdown class="question p-3">
      <div
        class="
          pointer
          p-3
          question-choice
          d-flex
          justify-content-between
          align-items-center
        "
        id="dropdownBasic2"
        ngbDropdownToggle
      >
        <span>Choisir un type de question</span>
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinechevronDown"
          [type]="colorEnum.unstyled"
          [size]="sizeEnum.small"
        ></datnek-ds-button-icon>
      </div>

      <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
        <datnek-ds-icon-submenu
          ngbDropdownItem
          [icon]="iconEnum.checkCircle"
          label="Cases à cocher"
          (datnekdsClickOrTouch)="caseACocher()"
        ></datnek-ds-icon-submenu>
        <datnek-ds-icon-submenu
          ngbDropdownItem
          [icon]="iconEnum.outlinecheck"
          label="Choix multiple"
          (datnekdsClickOrTouch)="multiLigne()"
        ></datnek-ds-icon-submenu>
        <datnek-ds-icon-submenu
          ngbDropdownItem
          [icon]="iconEnum.pencilAlt"
          label="Réponse libre"
          (datnekdsClickOrTouch)="freeText()"
        ></datnek-ds-icon-submenu>
      </div>
    </div>

    <ng-container *ngIf="questionType === 'caseACocher'">
      <div class="question-list p-3">
        <div class="display-2 mb-3">Réponse unique (Case à cocher)</div>
        <div class="grid grid--gap16">
          <div class="grid__col_12">
            <div class="input-plus mb-3">
              <datnek-ds-input-text
                [label]="'Ajouter une question'"
                type="text"
                placeholder="Ajouter une question"
              ></datnek-ds-input-text>
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlineplusCircle"
                [type]="colorEnum.ghost"
                [round]="true"
                (datnekdsClickOrTouch)="addInput()"
              ></datnek-ds-button-icon>
            </div>
            <div class="question-items" *ngIf="input === true">
              <datnek-ds-input-text
                type="text"
                placeholder="le libellé de la question 1"
              ></datnek-ds-input-text>
              <div class="input-plus mb-3" *ngIf="options === true">
                <datnek-ds-svg-icon
                  [icon]="iconEnum.checkCircle"
                  class="mr-3"
                ></datnek-ds-svg-icon>
                <datnek-ds-input-text
                  type="text"
                  placeholder="Valeur de l'option 1"
                ></datnek-ds-input-text>
                <datnek-ds-button-icon
                  [Icon]="iconEnum.solidinboxclose"
                  [type]="colorEnum.ghost"
                  [round]="true"
                ></datnek-ds-button-icon>
              </div>
              <div class="mt-3">
                <datnek-ds-link
                  label="Ajouter une option"
                  href="javascript:;"
                  (datnekdsClickOrTouch)="showOption()"
                  class="mt-3"
                ></datnek-ds-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="questionType === 'multiLigne'">
      <div class="question-list p-3">
        <div class="display-2 mb-3">Réponses multiples</div>
        <div class="grid grid--gap16">
          <div class="grid__col_12">
            <div class="input-plus mb-3">
              <datnek-ds-input-text
                [label]="'Ajouter une question'"
                type="text"
                placeholder="Ajouter une question"
              ></datnek-ds-input-text>
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlineplusCircle"
                [type]="colorEnum.ghost"
                [round]="true"
                (datnekdsClickOrTouch)="addInput1()"
              ></datnek-ds-button-icon>
            </div>
            <div class="question-items" *ngIf="input1 === true">
              <datnek-ds-input-text
                type="text"
                placeholder="le libellé de la question 1"
              ></datnek-ds-input-text>
              <div class="input-plus mb-3" *ngIf="options1 === true">
                <datnek-ds-svg-icon
                  [icon]="iconEnum.checkCircle"
                  class="mr-3"
                ></datnek-ds-svg-icon>
                <datnek-ds-input-text
                  type="text"
                  placeholder="Valeur de l'option 1"
                ></datnek-ds-input-text>
                <datnek-ds-button-icon
                  [Icon]="iconEnum.solidinboxclose"
                  [type]="colorEnum.ghost"
                  [round]="true"
                ></datnek-ds-button-icon>
              </div>
              <datnek-ds-link
                label="Ajouter une option"
                href="javascript:;"
                (datnekdsClickOrTouch)="showOption1()"
                class="mt-3"
              ></datnek-ds-link>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="questionType === 'freeText'">
      <div class="question-list p-3">
        <div class="display-2 mb-3">Réponse libre</div>
        <div class="grid grid--gap16">
          <div class="grid__col_12">
            <div class="input-plus mb-3">
              <datnek-ds-input-text
                [label]="'Ajouter une question'"
                type="text"
                placeholder="Ajouter une question"
              ></datnek-ds-input-text>
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlineplusCircle"
                [type]="colorEnum.ghost"
                [round]="true"
                (datnekdsClickOrTouch)="addInput2()"
              ></datnek-ds-button-icon>
            </div>
            <div class="question-items" *ngIf="input2 === true">
              <datnek-ds-input-text
                type="text"
                placeholder="le libellé de la question 1"
              ></datnek-ds-input-text>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="datnek-ds-dialog-footer">
  <datnek-ds-button
    [label]="'Annuler'"
    [size]="sizeEnum.large"
    [type]="colorEnum.secondary"
    class="mr-3"
    (datnekdsClickOrTouch)="dialogRef.close()"
  ></datnek-ds-button>
  <datnek-ds-button
    [label]="'Créer'"
    [size]="sizeEnum.large"
    [type]="colorEnum.primary"
    (datnekdsClickOrTouch)="addQuizz(); dialogRef.close()"
  ></datnek-ds-button>
</div>
