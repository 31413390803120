import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogAddCalendarComponent } from '@del-shared';
import {
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
} from 'datnek-ds';

import {
  DialogEventDetailsComponent,
} from '../../dialogs';

interface EventsItems {
  picture: string;
  date: string;
  name: string;
}

@Component({
  selector: 'nk-shared-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = true;

  /**
   * Affiche ou non la barre de recherche
   */
  @Input()
  searchbar = false;

  @Input() EventData: EventsItems[] = [
    {
      picture: 'assets/Img/bg/bg-placeholder.svg',
      date: '13/10/2022',
      name: 'Meet-Up sur les formations en ligne',
    },
    {
      picture: 'assets/Img/bg/bg-placeholder.svg',
      date: '13/10/2022',
      name: 'Meet-Up sur les formations en ligne',
    },
    {
      picture: 'assets/Img/bg/bg-placeholder.svg',
      date: '13/10/2022',
      name: 'Meet-Up sur les formations en ligne',
    },
    {
      picture: 'assets/Img/bg/bg-placeholder.svg',
      date: '13/10/2022',
      name: 'Meet-Up sur les formations en ligne',
    },
    {
      picture: 'assets/Img/bg/bg-placeholder.svg',
      date: '13/10/2022',
      name: 'Meet-Up sur les formations en ligne',
    },
    {
      picture: 'assets/Img/bg/bg-placeholder.svg',
      date: '13/10/2022',
      name: 'Meet-Up sur les formations en ligne',
    },
    {
      picture: 'assets/Img/bg/bg-placeholder.svg',
      date: '13/10/2022',
      name: 'Meet-Up sur les formations en ligne',
    },
    {
      picture: 'assets/Img/bg/bg-placeholder.svg',
      date: '13/10/2022',
      name: 'Meet-Up sur les formations en ligne',
    },
  ];

  constructor(private router: Router, private modalService: DialogService) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  showEvent() {
    const ref = this.modalService.open(DialogEventDetailsComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Détails de l'évènement`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  addEvent() {
    const ref = this.modalService.open(DialogAddCalendarComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter un évènement`,
        size: DialogSizeEnum.small,
      },
    });
  }

  goToEventDetails(e: any) {
    this.router.navigate(['/events/single-events']);
  }

  counter(i: number): Array<number> {
    return new Array(i);
  }
}
