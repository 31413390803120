import { UserInformationCompany } from '../users';
import { Link } from '../links/link.model';
import { MediaNetwork } from '../mediaNetwork/media-network.model';
import { User } from '../users';
import {TrackModel} from "datnek-core";

export class Publication extends TrackModel {
  Title?: string;
  Content?: string;
  Links?: Link[];
  StartDate?: Date;
  EndDate?: Date;

  Documents?: MediaNetwork[];
  UserId?: string;
  User?: User;

  CompanyId?: string;
  Company?: UserInformationCompany;

  constructor(
    Id?: string,
    CreateAt?: Date,
    UpdateAt?: Date,
    Title?: string,
    Content?: string,
    Links?: Link[],
    StartDate?: Date,
    EndDate?: Date,
    Documents?: MediaNetwork[],
    UserId?: string,
    // tslint:disable-next-line:no-shadowed-variable
    User?: User
  ) {
    super(Id, CreateAt, UpdateAt);
    this.Title = Title;
    this.Content = Content;
    this.Links = Links;
    this.StartDate = StartDate;
    this.EndDate = EndDate;
    this.Documents = Documents;
    (this.UserId = UserId), (this.User = User);
  }
}
