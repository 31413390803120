<main class="content">
  <div class="container">
    <div
      class="
        single-post-page
        col-12 col-xl-10
        offset-md-1
        col-md-10
        offset-md-1
      "
    >
      <div class="row">
        <div class="d-none d-xl-block col-xl-5">
          <div class="datnek-ds-card bg-white">
            <div class="header p-3 d-flex justify-content-between">
              <div class="display-1">Notifications</div>
              <div ngbDropdown class="d-inline-block">
                <div class="pointer" id="dropdownBasic2" ngbDropdownToggle>
                  <datnek-ds-button-icon
                    [Icon]="iconEnum.outlinedotsVertical"
                    [type]="colorEnum.unstyled"
                    [size]="sizeEnum.medium"
                  ></datnek-ds-button-icon>
                </div>

                <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                  <datnek-ds-icon-submenu
                    ngbDropdownItem
                    [icon]="iconEnum.checkCircle"
                    label="Tout marquer comme lu"
                    (datnekdsClickOrTouch)="showToast(pin)"
                  ></datnek-ds-icon-submenu>
                  <datnek-ds-icon-submenu
                    ngbDropdownItem
                    [icon]="iconEnum.outlinetrash"
                    label="Tout supprimer"
                    (datnekdsClickOrTouch)="deleteNotifs()"
                  ></datnek-ds-icon-submenu>
                </div>
              </div>
            </div>

            <p-scrollPanel [style]="{ width: '100%', height: '500px' }">
              <div class="notification-list">
                <div
                  class="
                    message-item
                    d-flex
                    align-items-center
                    justify-content-between
                    p-3
                    border-top border-bottom
                  "
                  routerLink="/page-post-id"
                  *ngFor="let item of notifs"
                >
                  <div class="avatar mr-3">
                    <datnek-ds-avatar
                      [size]="sizeEnum.medium1"
                      [type]="item.avatar"
                      class="mr-3"
                    ></datnek-ds-avatar>
                    <div class="icon">
                      <img src="{{ item.icon }}" alt="" />
                    </div>
                  </div>
                  <div class="infos">
                    <div
                      class="display-2 mb-1"
                      [innerHtml]="item.message | truncatetext: 85"
                    ></div>
                    <div class="display-5">Il ya une {{ item.time }}</div>
                  </div>
                </div>
              </div>
            </p-scrollPanel>
          </div>
        </div>
        <div class="col-12 px-0 px-md-3 col-lg-12 col-xl-7">
          <div class="allnotifs mb-3 d-lg-none">
            <div class="notif-list">
              <datnek-ds-button
                label="Liste des notifications"
                [type]="colorEnum.primary"
                [size]="sizeEnum.large"
                class="w-50"
                (datnekdsClickOrTouch)="showNotifList()"
              ></datnek-ds-button>
              <div class="datnek-ds-card" *ngIf="notifList === true">
                <div
                  class="
                    suggestion-header
                    d-flex
                    justify-content-between
                    align-items-center
                    display-1
                    p-3
                    border-bottom
                  "
                >
                  Liste des notifications en attente de lecture
                  <div
                    ngbDropdown
                    placement="bottom-start"
                    class="network-icon-options-dropdown"
                  >
                    <datnek-ds-button-icon
                      [Icon]="iconEnum.outlinedotsHorizontal"
                      [type]="colorEnum.ghost"
                      [round]="true"
                      id="dropdownBasic1"
                      ngbDropdownToggle
                    ></datnek-ds-button-icon>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <datnek-ds-icon-submenu
                        ngbDropdownItem
                        [icon]="iconEnum.checkCircle"
                        label="Tout marquer comme lu"
                        (datnekdsClickOrTouch)="showToast(pin)"
                      ></datnek-ds-icon-submenu>
                      <datnek-ds-icon-submenu
                        ngbDropdownItem
                        [icon]="iconEnum.outlinetrash"
                        label="Tout supprimer"
                        (datnekdsClickOrTouch)="deleteNotifs()"
                      ></datnek-ds-icon-submenu>
                    </div>
                  </div>
                </div>
                <p-scrollPanel
                  [style]="{ width: '100%', height: 'calc(100vh - 260px)' }"
                >
                  <div class="notification-list">
                    <div
                      class="
                        message-item
                        d-flex
                        align-items-center
                        justify-content-between
                        p-3
                        border-top border-bottom
                      "
                      routerLink="/page-post-id"
                      *ngFor="let item of notifs"
                    >
                      <div class="avatar mr-3">
                        <datnek-ds-avatar
                          [size]="sizeEnum.medium1"
                          [type]="item.avatar"
                          class="mr-3"
                        ></datnek-ds-avatar>
                        <div class="icon">
                          <img src="{{ item.icon }}" alt="" />
                        </div>
                      </div>
                      <div class="infos">
                        <div
                          class="display-2 mb-1"
                          [innerHtml]="item.message | truncatetext: 85"
                        ></div>
                        <div class="display-5">Il ya une {{ item.time }}</div>
                      </div>
                    </div>
                  </div>
                </p-scrollPanel>
              </div>
            </div>
          </div>
          <network-card-publication-box
            [init]="dataImagesGallery"
            publicationType="images"
            [status]="avatarEnum.person"
          ></network-card-publication-box>
        </div>
      </div>
    </div>
  </div>
</main>

<!--NOTIFS MESSAGES-->
<ng-template #pin let-close="close">
  Tous les messages ont été marqués comme lus
</ng-template>
