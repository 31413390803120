import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MoleculeComp } from './components/molecule-comp';
import {NetworkSharedModule} from "../../shared";

@NgModule({
  declarations: [...MoleculeComp],
  exports: [...MoleculeComp],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NetworkSharedModule
  ],
})
export class MoleculeModule {}
