import { Component, OnInit } from '@angular/core';
import {ControlDisplayEnum} from "datnek-ds";
interface EventsItems {
  picture: string;
  date: string;
  name: string;
}

@Component({
  selector: 'nk-shared-event-menu-mobile',
  templateUrl: './event-menu-mobile.component.html',
  styleUrls: ['./event-menu-mobile.component.scss'],
})
export class EventMenuMobileComponent implements OnInit {
  displayEnum = ControlDisplayEnum;

  EventData: EventsItems[] = [
    {
      picture: 'assets/Img/bg/bg-placeholder.svg',
      date: '13/10/2022',
      name: 'Meet-Up sur les formations en ligne',
    },
    {
      picture: 'assets/Img/bg/bg-placeholder.svg',
      date: '13/10/2022',
      name: 'Meet-Up sur les formations en ligne',
    },
  ];

  videoItems = [
    {
      videoSrc:
        'https://mdn.github.io/learning-area/html/multimedia-and-embedding/video-and-audio-content/rabbit320.webm',
      title: 'Diplome de Développeur front-end',
    },
    {
      videoSrc:
        'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm  ',
      title: 'Titre de la deuxième vidéo',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
