import { AppComponent } from './ui/container';
import {Inject, LOCALE_ID, NgModule} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
// Gestures
import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';

/** Import Alyle UI */
import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,
  LyHammerGestureConfig,
} from '@alyle/ui';

/** Import themes */
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MessageService } from 'primeng/api';
import { RouterModule } from '@angular/router';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
  MenuModule, NETWORK_LOCALES,
  NetworkSharedModule,
  OrganismSharedModule,
} from '@network-shared';
import { Constant, DatnekSsoModule, TokenInterceptorService } from 'datnek-sso';
import { JwtModule } from '@auth0/angular-jwt';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { Environment } from 'datnek-core';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { DsFormlyType, dsRegisterTranslateExtension } from 'datnek-ds';
import {
  AppEnum,
  DialogFormlyType,
  SharedModule,
  TranslateCustomHttpLoaderFactory,
  TranslateCustomHttpLoaderFactoryService,
  wrappers
} from '@del-shared';
import { HomeFormlyType } from './ui/modules/home';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';

registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DatnekSsoModule,
    RouterModule.forRoot([], {
      scrollPositionRestoration: 'enabled',
    }),
    NetworkSharedModule,
    FormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateCustomHttpLoaderFactory,
        deps: [HttpClient, TranslateCustomHttpLoaderFactoryService],
      },
    }),
    FormlyBootstrapModule,
    FormlyModule.forRoot({
      types: [...DsFormlyType, ...HomeFormlyType, ...DialogFormlyType],
      wrappers: [...wrappers],
    }),
    // MenuShareModule,

    // Gestures
    HammerModule,
    NgbModule,
    NgxFileDropModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxsModule.forRoot([], {developmentMode: !environment.production}),
    NgxsReduxDevtoolsPluginModule.forRoot({disabled: environment.production}),
    NgxsRouterPluginModule.forRoot(),
    BsDropdownModule.forRoot(),
    OrganismSharedModule,
    MenuModule,
    SharedModule,
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: AppEnum.FactoryName,
      useValue: NETWORK_LOCALES,
    },
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    Title,
    [LyTheme2],
    [StyleRenderer],
    // Theme that will be applied to this module
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
    { provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
    // Gestures
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
    StyleRenderer,
    LyTheme2,
    {
      provide: Environment.Provider.API,
      useValue: {
        cv: environment.api.cv,
        user: environment.api.home,
        network: environment.api.network,
        learning: environment.api.learning,
        testing: environment.api.testing,
        jobs: environment.api.jobs,
        medias: environment.api.medias,
      },
    },
    {
      provide: Environment.Provider.SECURITYROUTE,
      useValue: {
        register: environment.securityRoute.register,

        registerStep1: environment.securityRoute.registerStep1,
        registerStep2: environment.securityRoute.registerStep2,
        registerStep3: environment.securityRoute.registerStep3,
        registerStep3SMS: environment.securityRoute.registerStep3SMS,

        registerMeStudentStep4:
          environment.securityRoute.registerMeStudentStep4,
        registerMeEmployeeStep4:
          environment.securityRoute.registerMeEmployeeStep4,
        registerMeSelfContractorStep4:
          environment.securityRoute.registerMeSelfContractorStep4,
        registerMeUnemployedStep4:
          environment.securityRoute.registerMeUnemployedStep4,

        registerCompanyStep3: environment.securityRoute.registerCompanyStep3,

        registerStep5EmailVerify:
          environment.securityRoute.registerStep5EmailVerify,

        login: environment.securityRoute.login,
        forgetPassword: environment.securityRoute.forgetPassword,
        forgetPasswordStep2Email:
          environment.securityRoute.forgetPasswordStep2Email,
        forgetPasswordStep3Email:
          environment.securityRoute.forgetPasswordStep3Email,
        forgetPasswordStep2SMS:
          environment.securityRoute.forgetPasswordStep2SMS,
        forgetPasswordStep3SMS:
          environment.securityRoute.forgetPasswordStep3SMS,
        forgetPasswordNew: environment.securityRoute.forgetPasswordNew,

        logout: environment.securityRoute.logout,

        webSite: environment.securityRoute.webSite,
      },
    },
    {
      provide: Environment.Provider.ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: Environment.Provider.WEBSITE,
      useValue: environment.website,
    },
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: dsRegisterTranslateExtension,
      deps: [TranslateService],
    },
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
// tslint:disable-next-line:typedef
export function getToken() {
  const token = localStorage.getItem(Constant.TOKEN);
  return token;
}
