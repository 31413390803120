import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { APP_BASE_HREF } from '@angular/common';
import {environment} from "../environments/environment";
import {AuthGuardService} from "datnek-sso";

const routes: Routes = [
  // Network
  {
    path: '',
    loadChildren: () =>
      import('./ui/modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: environment.securityRoute.home,
    loadChildren: () =>
      import('./ui/modules/security/security.module').then((m) => m.SecurityModule),
  },
  {
    path: 'enterprise',
    loadChildren: () =>
      import('@network-company').then(
        (m) => m.CompanyModule
      ),
    canLoad: [AuthGuardService]
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('@network-chat').then(
        (m) => m.ChatModule
      ),
    canLoad: [AuthGuardService]
  },
  {
    path: 'request',
    loadChildren: () =>
      import('@network-request').then(
        (m) => m.RequestModule
      ),
    canLoad: [AuthGuardService]
  },
  {
    path: 'groups',
    loadChildren: () =>
      import('@network-group').then(
        (m) => m.GroupModule
      ),
    canLoad: [AuthGuardService]
  },


  // home applications
  {
    path: 'private',
    children: [
      {
        path: 'company',
        loadChildren: () =>
          import('@home-company').then(
            (m) => m.HomeCompanyModule
          ),
      },
      {
        path: 'user',
        loadChildren: () =>
          import('@home-user').then(
            (m) => m.HomeUserModule
          ),
      }
    ],
    canLoad: [AuthGuardService]
  },
  {
    path: 'public/:guid',
    children: [
      {
        path: 'company',
        loadChildren: () =>
          import('@home-company').then(
            (m) => m.HomeCompanyModule
          ),
      },
      {
        path: 'user',
        loadChildren: () =>
          import('@home-user').then(
            (m) => m.HomeUserModule
          ),
      }
    ]
  },
 // { path: '**', component: EmptyRouteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})
export class AppRoutingModule {}
