<main class="content notifications">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
        <div class="datnek-ds-card bg-white">
          <div
            class="header p-3 d-flex justify-content-between align-items-center"
            *ngIf="!isLoading"
          >
            <div class="display-1">Notifications</div>
            <div ngbDropdown class="d-inline-block">
              <div class="pointer" id="dropdownBasic2" ngbDropdownToggle>
                <datnek-ds-button-icon
                  [Icon]="iconEnum.outlinedotsVertical"
                  [type]="colorEnum.ghost"
                  [size]="sizeEnum.medium"
                ></datnek-ds-button-icon>
              </div>

              <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                <button ngbDropdownItem>
                  <datnek-ds-svg-icon
                    [icon]="iconEnum.outlinecheck"
                  ></datnek-ds-svg-icon>
                  Tout marquer comme lu
                </button>
                <!--<button ngbDropdownItem>
                  <datnek-ds-svg-icon
                    [icon]="iconEnum.outlinecog"
                  ></datnek-ds-svg-icon>
                  Paramètres de notifications
                </button>-->
              </div>
            </div>
          </div>
          <div
            class="
              skeleton
              header
              d-flex
              p-3
              align-items-center
              justify-content-between
              border-bottom
            "
            *ngIf="isLoading"
          >
            <div class="w-75 d-flex align-items-center">
              <div class="line w-25 h15"></div>
            </div>
            <div class="d-flex gap-3 w-25 justify-content-end">
              <div class="circle medium-c"></div>
            </div>
          </div>
          <div class="px-3 pt-3">
            <datnek-ds-filter-chip
              [filters]="filters"
              [listFilter]="listFilter"
              [direction]="false"
            ></datnek-ds-filter-chip>
          </div>

          <!--<div class="filter p-3">
            <span class="chip active">Tout</span>
            <span class="chip">Non lus</span>
            <span class="chip">lus</span>
          </div>-->

          <datnek-ds-no-content
            titleMsg="Aucun contenu signalé par un membre"
            contentDesc="Si des membres signalent du contenu aux administrateurs du groupe, vous pouvez l’examiner ici."
            imgSrc="assets/Img/icons/colors/notification-bell.svg"
          ></datnek-ds-no-content>

          <div class="notification-list" *ngIf="!isLoading">
            <div class="mb-0 d-block p-3 display-2">Nouveau</div>
            <div
              class="
                message-item
                d-flex
                align-items-center
                justify-content-between
                p-3
                border-top border-bottom
              "
              routerLink="/page-post-id"
            >
              <div class="avatar mr-3">
                <datnek-ds-avatar
                  [size]="sizeEnum.medium1"
                  [type]="avatarEnum.person"
                  class="mr-3"
                ></datnek-ds-avatar>
                <div class="icon">
                  <img
                    src="assets/icons/src/outline/white/user-group.svg"
                    alt=""
                  />
                </div>
              </div>
              <div class="infos">
                <div class="display-2 mb-1">
                  Vos amis <span>Bassahak JM, Hervé Bass</span> et
                  <span>2 autres personnes</span> ont publié dans...
                </div>
                <div class="display-5">Il ya une 3m</div>
              </div>
              <datnek-ds-svg-icon [icon]="iconEnum.outlinex"></datnek-ds-svg-icon>
            </div>
            <div
              class="
                message-item
                d-flex
                align-items-center
                justify-content-between
                p-3
                border-top border-bottom
              "
              routerLink="/page-post-id"
            >
              <div class="avatar mr-3">
                <datnek-ds-avatar
                  [size]="sizeEnum.medium1"
                  [type]="avatarEnum.society"
                  class="mr-3"
                ></datnek-ds-avatar>
                <div class="icon">
                  <img
                    src="assets/icons/src/outline/white/user-group.svg"
                    alt=""
                  />
                </div>
              </div>
              <div class="infos">
                <div class="display-2 mb-1">
                  Un admin a approuvé votre publication dans
                  <span>LES EXPERTS NODE.JS</span>
                </div>
                <div class="display-5">Il ya une 4h</div>
              </div>
              <datnek-ds-svg-icon [icon]="iconEnum.outlinex"></datnek-ds-svg-icon>
            </div>
            <div
              class="
                message-item
                read
                d-flex
                align-items-center
                justify-content-between
                p-3
                border-top border-bottom
              "
              routerLink="/page-post-id"
            >
              <div class="avatar mr-3">
                <datnek-ds-avatar
                  [size]="sizeEnum.medium1"
                  [type]="avatarEnum.society"
                  class="mr-3"
                ></datnek-ds-avatar>
                <div class="icon">
                  <img src="assets/icons/src/outline/white/star.svg" alt="" />
                </div>
              </div>
              <div class="infos">
                <div class="display-2 mb-1">
                  <span>DIGITAILLE</span> a publié un nouveau challenge :

                  <span>Titre du Challenge</span>
                </div>
                <div class="display-5">Il ya une semaine</div>
              </div>
              <datnek-ds-svg-icon [icon]="iconEnum.outlinex"></datnek-ds-svg-icon>
            </div>
            <div
              class="
                message-item
                read
                d-flex
                align-items-center
                justify-content-between
                p-3
                border-top border-bottom
              "
              routerLink="/page-post-id"
            >
              <div class="avatar mr-3">
                <datnek-ds-avatar
                  [size]="sizeEnum.medium1"
                  class="mr-3"
                ></datnek-ds-avatar>
                <div class="icon">
                  <img src="assets/icons/src/outline/white/chat.svg" alt="" />
                </div>
              </div>
              <div class="infos">
                <div class="display-2 mb-1">
                  <span>Dieudonnée Ambang</span> a commenté une pulication de
                  <span>Atn Henri Lotin</span>
                </div>
                <div class="display-5">Il ya une semaine</div>
              </div>
              <datnek-ds-svg-icon [icon]="iconEnum.outlinex"></datnek-ds-svg-icon>
            </div>
            <div
              class="
                message-item
                d-flex
                align-items-center
                justify-content-between
                p-3
                border-top border-bottom
              "
              routerLink="/page-post-id"
            >
              <div class="avatar mr-3">
                <datnek-ds-avatar
                  [size]="sizeEnum.medium1"
                  class="mr-3"
                ></datnek-ds-avatar>
                <div class="icon">
                  <img src="assets/icons/src/outline/white/user.svg" alt="" />
                </div>
              </div>
              <div class="infos">
                <div class="display-2 mb-1">
                  Vous avez une nouvelle suggestion de profil:
                  <span>Yankap Arno</span>
                </div>
                <div class="display-5">Il ya 1 heure</div>
              </div>
              <datnek-ds-svg-icon [icon]="iconEnum.outlinex"></datnek-ds-svg-icon>
            </div>
            <div class="mb-0 d-block p-3 display-2">Plus tôt</div>
            <div
              class="
                message-item
                d-flex
                align-items-center
                justify-content-between
                p-3
                border-top border-bottom
              "
              routerLink="/page-post-id"
            >
              <div class="avatar mr-3">
                <datnek-ds-avatar
                  [size]="sizeEnum.medium1"
                  class="mr-3"
                ></datnek-ds-avatar>
                <div class="icon">
                  <img
                    src="assets/icons/src/outline/white/office-building.svg"
                    alt=""
                  />
                </div>
              </div>

              <div class="infos">
                <div class="display-2 mb-1">
                  <span>Danick tchaffo</span> a visité <span>DIGITAILLE</span> :
                  "Les deux pilliers..."
                </div>
                <div class="display-5">Il ya 2 heure</div>
              </div>
              <datnek-ds-svg-icon [icon]="iconEnum.outlinex"></datnek-ds-svg-icon>
            </div>
            <div
              class="
                message-item
                d-flex
                align-items-center
                justify-content-between
                p-3
                border-top border-bottom
              "
              routerLink="/page-post-id"
            >
              <div class="avatar mr-3">
                <datnek-ds-avatar
                  [size]="sizeEnum.medium1"
                  class="mr-3"
                ></datnek-ds-avatar>
                <div class="icon">
                  <img
                    src="assets/icons/src/outline/white/chat-alt.svg"
                    alt=""
                  />
                </div>
              </div>
              <div class="infos">
                <div class="display-2 mb-1">
                  <span>Mboning Elvis</span> vous a envoyé un message : "Stp
                  n'oublie pas de ..."
                </div>
                <div class="display-5">Il ya 3 heure</div>
              </div>
              <datnek-ds-svg-icon [icon]="iconEnum.outlinex"></datnek-ds-svg-icon>
            </div>
          </div>

          <datnek-skeleton-notifications
            *ngIf="isLoading"
          ></datnek-skeleton-notifications>
        </div>
      </div>
    </div>
  </div>
</main>
