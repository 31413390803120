<div class="datnek-ds-card notif-box" *ngIf="show === true && !isLoading">
  <div
    class="header d-flex justify-between align-items-center"
    *ngIf="restricted === false"
  >
    <img src="/assets/Img/Icon-logo-delenscio.png" alt="" />
    <datnek-ds-button-icon
      [Icon]="iconEnum.solidinboxclose"
      [type]="colorEnum.ghost"
      [round]="true"
      class="icon-close"
      (datnekdsClickOrTouch)="onShow()"
    ></datnek-ds-button-icon>
  </div>

  <div
    class="
      content
      d-flex
      flex-column
      justify-content-center
      text-center
      px-5
      py-3
    "
  >
    <img src="{{ imgSrc }}" alt="" class="mb-3" />
    <div class="display-2 mb-3">{{ title }}</div>
    <p *ngIf="description">{{ description }}</p>
  </div>

  <div
    class="datnek-ds-card-footer d-flex align-items-center"
    *ngIf="restricted === false"
  >
    <div class="icons">
      <datnek-ds-button
        [label]="'Plus tard'"
        [size]="sizeEnum.large"
        [block]="true"
        [type]="colorEnum.secondary"
        (datnekdsClickOrTouch)="onShow()"
      ></datnek-ds-button>
      <datnek-ds-button
        [label]="'D\'accord'"
        [size]="sizeEnum.large"
        [block]="true"
        [type]="colorEnum.primary"
      ></datnek-ds-button>
    </div>
  </div>

  <ng-content *ngIf="restricted === true"></ng-content>
</div>

<datnek-skeleton-notification-box
  *ngIf="isLoading"
></datnek-skeleton-notification-box>
