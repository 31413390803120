import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Overlay } from '@angular/cdk/overlay';

import {
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  BaseComponent,
  DialogAddVideoNoTypeComponent,
  DialogAddPublicationLegendNoTypeComponent,
  DialogAddPicturesNoTypeComponent,
} from 'datnek-ds';

import {
  DialogAddCalendarComponent,
  DialogAddChallengeComponent,
  DialogNewContentComponent,
} from '@del-shared';
import { Article, blobToBase64, MediaNetwork, Publication } from '@del-shared';
import { Cv, DelenUserModel } from 'datnek-sso';
import { Store } from '@ngxs/store';

@Component({
  selector: 'nk-shared-card-new-publication-box',
  templateUrl: './card-new-publication-box.component.html',
  styleUrls: ['./card-new-publication-box.component.scss'],
})
export class CardNewPublicationBoxComponent
  extends BaseComponent
  implements OnInit
{
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = true;

  publication: Publication | null = null;

  /**
   * Le type de status renvoyé : 'person' | 'society' = 'person'
   *
   * @default = 'person'
   */
  @Input()
  status!: AvatarTypeEnum;

  /**
   * La source de l'image
   */
  @Input()
  imgSrc!: IconEnum | string;

  files: File[] = [];

  currentUser!: DelenUserModel;

  currentResume!: Cv;

  onlyIcon = false;

  public innerWidth = window.innerWidth;

  constructor(
    private router: Router,
    private overlay: Overlay,
    private modalService: DialogService,
    private store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.innerWidth <= 560) {
      this.onlyIcon = true;
      // console.log('WINDOWS WIDTH : ' + this.innerWidth);
    }
    setTimeout(() => (this.isLoading = false), 2500);

    const sub = this.store
      .select((state) => state.user.currentUser)
      .subscribe((res) => {
        this.currentUser = res;
      });

    const sub2 = this.store
      .select((state) => state.resume.currentResume)
      .subscribe((res) => {
        this.currentResume = res;
      });

    this.subscribe.add(sub);
    this.subscribe.add(sub2);
  }

  newContentDialog(): void {
    const ref = this.modalService.open(DialogNewContentComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Créez un nouveau contenu`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  addPicture(): void {
    this.publication = null;
    const ref = this.modalService.open(DialogAddPicturesNoTypeComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter une image`,
        size: DialogSizeEnum.small,
        coverTitle: 'Téléchargez une photo de couverture',
        imgSize: 'Largeur minimum de 1280 X 720',
        fileType: 'multiple',
        maxFiles: 10,
        btnLabel: 'Soumettre',
      },
    });

    const sub = ref.afterClosed$.subscribe(async (res) => {
      console.log('data image :', res);
      if (res.data) {
        await this.buildArticle(res.data.Medias);
        this.addLegend();
      }
    });

    this.subscribe.add(sub);
  }

  private async buildArticle(
    medias: { file: File; title: string; type: string; url: string }[]
  ): Promise<void> {
    this.publication = new Publication();
    this.publication.Documents = [];
    for (let media of medias) {
      let hashname = await blobToBase64(media.file);
      hashname = hashname?.substr(hashname.indexOf(',') + 1);
      const mn = new MediaNetwork(
        null,
        new Date(),
        new Date(),
        media.file.name,
        hashname
      );
      mn.SubDir = `NetworkApi/${this.currentUser.Id}/Publications`;
      mn.Description = media.title;
      this.publication.Documents.push(mn);
    }
  }

  addLegend(): void {
    const ref = this.modalService.open(
      DialogAddPublicationLegendNoTypeComponent,
      {
        dialogType: DialogTypeEnum.Dialog,
        data: {
          title: `Ajouter une legende`,
          size: DialogSizeEnum.small,
          titleLabel: 'Dites quelque chose par rapport à votre publication',
          placeholder: 'Rediger votre texte...',
          btnLabel: 'Soumettre',
          isRequired: true,
          className: ['h-200'],
        },
      }
    );

    const sub = ref.afterClosed$.subscribe((res) => {
      console.log('data legend:', res);
      if (this.publication) {
        this.publication.Content = res.data.Content;
      }

      console.log('publication :', this.publication);
    });

    this.subscribe.add(sub);
  }

  addVideo() {
    this.publication = null;
    const ref = this.modalService.open(DialogAddVideoNoTypeComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter une vidéo`,
        size: DialogSizeEnum.small,
        coverTitle: 'Téléchargez une video de présentation',
        imgSize: 'La video doit être de très bonne qualité',
        fileType: 'multiple',
        maxFiles: 10,
        btnLabel: 'Soumettre',
      },
    });

    const sub = ref.afterClosed$.subscribe(async (res) => {
      console.log('data video :', res);
      if (res.data) {
        await this.buildArticle(res.data.Medias);
        this.addLegend();
      }
    });

    this.subscribe.add(sub);
  }

  addCalendar() {
    const ref = this.modalService.open(DialogAddCalendarComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Créer un évènemnent`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  addChallenge() {
    const ref = this.modalService.open(DialogAddChallengeComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Créer un nouveau challenge`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  // ROUTES !!!
  addArticlePage(e: any): void {
    e.preventDefault();
    this.router.navigate(['/add-publication']);
  }
}
