<main class="content paiement-checkout">
  <div class="container p-0">
    <div class="row g-0">
      <div class="col-12 col-sm-12 col-md-8 offset-md-2">
        <div class="datnek-ds-card mb-4">
          <div class="content-header">
            <h1 class="display-1">Types de facturation</h1>
          </div>
          <div class="options p-3">
            <p-tabView>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <datnek-ds-checkbox-group
                    label="Mensuel"
                    description="Essai gratuit de 1 mois, puis vous payez 55.99$ mensuel"
                  ></datnek-ds-checkbox-group>
                </ng-template>
                <ng-template pTemplate="content">
                  <div class="datnek-ds-card price-details p-3">
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        py-3
                        border-bottom
                      "
                    >
                      <div class="element">
                        <p class="mb-0">
                          Total après l’essai gratuit (79,99 USD x 12 mois)
                        </p>
                      </div>
                      <div class="price text-right">
                        <p class="mb-0">55,88 USD</p>
                      </div>
                    </div>
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        py-3
                        border-bottom
                      "
                    >
                      <div class="element total">
                        <p class="font-weight-bold mb-0">Total du jour</p>
                      </div>
                      <div class="price text-right">
                        <p class="font-weight-bold mb-0">55,88 USD</p>
                      </div>
                    </div>
                    <div class="description pt-3">
                      <p>
                        Votre essai gratuit débute le 5 juillet 2022 et prend
                        fin le 5 août 2022. Vous pouvez annuler à tout moment
                        avant le 5 août 2022 pour ne pas être débité. Nous vous
                        enverrons un rappel par e-mail sept jours avant la fin
                        de votre essai gratuit.
                      </p>
                    </div>
                  </div>
                </ng-template>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <datnek-ds-checkbox-group
                    label="Trimestriel"
                    description="Essai gratuit de 1 mois, puis vous payez 55.99$ mensuel"
                  ></datnek-ds-checkbox-group>
                </ng-template>
                <ng-template pTemplate="content">
                  <div class="datnek-ds-card price-details p-3">
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        py-3
                        border-bottom
                      "
                    >
                      <div class="element">
                        <p class="mb-0">
                          Total après l’essai gratuit (79,99 USD x 12 mois)
                        </p>
                      </div>
                      <div class="price text-right">
                        <p class="mb-0">159,88 USD</p>
                      </div>
                    </div>
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        py-3
                        border-bottom
                      "
                    >
                      <div class="element total">
                        <p class="font-weight-bold mb-0">Total du jour</p>
                      </div>
                      <div class="price text-right">
                        <p class="font-weight-bold mb-0">159,88 USD</p>
                      </div>
                    </div>
                    <div class="description pt-3">
                      <p>
                        Votre essai gratuit débute le 5 juillet 2022 et prend
                        fin le 5 août 2022. Vous pouvez annuler à tout moment
                        avant le 5 août 2022 pour ne pas être débité. Nous vous
                        enverrons un rappel par e-mail sept jours avant la fin
                        de votre essai gratuit.
                      </p>
                    </div>
                  </div>
                </ng-template>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <datnek-ds-checkbox-group
                    label="Annuel"
                    description="Essai gratuit de 1 mois, puis vous payez 55.99$ mensuel"
                  ></datnek-ds-checkbox-group>
                </ng-template>
                <ng-template pTemplate="content">
                  <div class="datnek-ds-card price-details p-3">
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        py-3
                        border-bottom
                      "
                    >
                      <div class="element">
                        <p class="mb-0">
                          Total après l’essai gratuit (79,99 USD x 12 mois)
                        </p>
                      </div>
                      <div class="price text-right">
                        <p class="mb-0">959,88 USD</p>
                      </div>
                    </div>
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        py-3
                        border-bottom
                      "
                    >
                      <div class="element total">
                        <p class="font-weight-bold mb-0">Total du jour</p>
                      </div>
                      <div class="price text-right">
                        <p class="font-weight-bold mb-0">959,88 USD</p>
                      </div>
                    </div>
                    <div class="description pt-3">
                      <p>
                        Votre essai gratuit débute le 5 juillet 2022 et prend
                        fin le 5 août 2022. Vous pouvez annuler à tout moment
                        avant le 5 août 2022 pour ne pas être débité. Nous vous
                        enverrons un rappel par e-mail sept jours avant la fin
                        de votre essai gratuit.
                      </p>
                    </div>
                  </div>
                </ng-template>
              </p-tabPanel>
            </p-tabView>
          </div>
        </div>

        <!--MODES DE REGLEMENT-->
        <div class="datnek-ds-card body-content">
          <div class="content-header">
            <h1 class="display-1">Modes de règlement</h1>
          </div>
          <div class="options p-3">
            <p-tabView>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <div class="img-method m-auto">
                    <img src="assets/Img/paiement-methods.png" alt="" />
                  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <div class="form p-3">
                    <div class="grid grid--gap16">
                      <div class="grid__col_12 grid__col_sm6 mb-3">
                        <datnek-ds-input-text
                          [label]="'Noms *'"
                          type="text"
                          placeholder="Nom du contact"
                        ></datnek-ds-input-text>
                      </div>
                      <div class="grid__col_12 grid__col_sm6 mb-3">
                        <datnek-ds-input-text
                          [label]="'Prénom(s) *'"
                          type="text"
                          placeholder="Prénom du contact"
                        ></datnek-ds-input-text>
                      </div>
                    </div>
                    <div class="grid grid--gap16">
                      <div class="grid__col_12 mb-3">
                        <datnek-ds-input-text
                          [label]="'Numéro de la Carte *'"
                          type="text"
                          placeholder="0000-0000-0000-0000-0000"
                        ></datnek-ds-input-text>
                      </div>
                    </div>
                    <div class="grid grid--gap16">
                      <div class="grid__col_12 grid__col_sm6 mb-3">
                        <datnek-ds-input-text
                          [label]="'Date d’expiration *'"
                          type="date"
                          placeholder="MM/AA"
                        ></datnek-ds-input-text>
                      </div>
                      <div class="grid__col_12 grid__col_sm6 mb-3">
                        <datnek-ds-input-text
                          [label]="'CVV *'"
                          type="text"
                          placeholder="007"
                        ></datnek-ds-input-text>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <datnek-ds-button
                        label="Valider la commande"
                        [type]="colorEnum.primary"
                        [size]="sizeEnum.large"
                        [block]="true"
                        class="m-auto"
                        (datnekdsClickOrTouch)="checkoutStatus()"
                      ></datnek-ds-button>
                    </div>
                  </div>
                </ng-template>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <div class="img-method m-auto">
                    <img src="assets/Img/Paypal.png" alt="" />
                  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <div class="paypal-details text-center m-auto p-5">
                    <p>
                      Cliquez sur Continuer vers PayPal pour vous identifier
                      sous PayPal et confirmer votre achat. Nous vous renverrons
                      sur cette page pour terminer.
                    </p>
                    <div class="d-flex justify-content-center">
                      <datnek-ds-button
                        label="Continuer vers paypal"
                        [type]="colorEnum.primary"
                        [size]="sizeEnum.large"
                        class="m-auto"
                      ></datnek-ds-button>
                    </div>
                  </div>
                </ng-template>
              </p-tabPanel>
            </p-tabView>
          </div>
        </div>

        <!--FAQ-->
        <div class="datnek-ds-card body-content">
          <div class="content-header">
            <h1 class="display-1">Question fréquentes</h1>
          </div>
          <div class="options p-3">
            <p-accordion [multiple]="true">
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <div
                    class="
                      d-flex
                      w-100
                      align-items-center
                      justify-content-between
                    "
                  >
                    <div class="display-2">Question 1</div>
                    <datnek-ds-button-icon
                      [type]="colorEnum.unstyled"
                      [size]="sizeEnum.large"
                      [Icon]="iconEnum.outlinechevronDown"
                    ></datnek-ds-button-icon>
                  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Possimus amet molestiae velit culpa officiis at, repudiandae
                    nisi temporibus repellat, quidem eligendi nobis qui
                    distinctio. Deleniti atque animi voluptatum! Aspernatur,
                    sunt. Lorem ipsum dolor sit amet consectetur adipisicing
                    elit. Possimus amet molestiae velit culpa officiis at,
                    repudiandae nisi temporibus repellat, quidem eligendi nobis
                    qui distinctio. Deleniti atque animi voluptatum! Aspernatur,
                    sunt.
                  </p>
                </ng-template>
              </p-accordionTab>
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <div
                    class="
                      d-flex
                      w-100
                      align-items-center
                      justify-content-between
                    "
                  >
                    <div class="display-2">Question 2</div>
                    <datnek-ds-button-icon
                      [type]="colorEnum.unstyled"
                      [size]="sizeEnum.large"
                      [Icon]="iconEnum.outlinechevronDown"
                    ></datnek-ds-button-icon>
                  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Possimus amet molestiae velit culpa officiis at, repudiandae
                    nisi temporibus repellat, quidem eligendi nobis qui
                    distinctio. Deleniti atque animi voluptatum! Aspernatur,
                    sunt. Lorem ipsum dolor sit amet consectetur adipisicing
                    elit. Possimus amet molestiae velit culpa officiis at,
                    repudiandae nisi temporibus repellat, quidem eligendi nobis
                    qui distinctio. Deleniti atque animi voluptatum! Aspernatur,
                    sunt.
                  </p>
                </ng-template>
              </p-accordionTab>
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <div
                    class="
                      d-flex
                      w-100
                      align-items-center
                      justify-content-between
                    "
                  >
                    <div class="display-2">Question 3</div>
                    <datnek-ds-button-icon
                      [type]="colorEnum.unstyled"
                      [size]="sizeEnum.large"
                      [Icon]="iconEnum.outlinechevronDown"
                    ></datnek-ds-button-icon>
                  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Possimus amet molestiae velit culpa officiis at, repudiandae
                    nisi temporibus repellat, quidem eligendi nobis qui
                    distinctio. Deleniti atque animi voluptatum! Aspernatur,
                    sunt. Lorem ipsum dolor sit amet consectetur adipisicing
                    elit. Possimus amet molestiae velit culpa officiis at,
                    repudiandae nisi temporibus repellat, quidem eligendi nobis
                    qui distinctio. Deleniti atque animi voluptatum! Aspernatur,
                    sunt.
                  </p>
                </ng-template>
              </p-accordionTab>
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <div
                    class="
                      d-flex
                      w-100
                      align-items-center
                      justify-content-between
                    "
                  >
                    <div class="display-2">Question 4</div>
                    <datnek-ds-button-icon
                      [type]="colorEnum.unstyled"
                      [size]="sizeEnum.large"
                      [Icon]="iconEnum.outlinechevronDown"
                    ></datnek-ds-button-icon>
                  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Possimus amet molestiae velit culpa officiis at, repudiandae
                    nisi temporibus repellat, quidem eligendi nobis qui
                    distinctio. Deleniti atque animi voluptatum! Aspernatur,
                    sunt. Lorem ipsum dolor sit amet consectetur adipisicing
                    elit. Possimus amet molestiae velit culpa officiis at,
                    repudiandae nisi temporibus repellat, quidem eligendi nobis
                    qui distinctio. Deleniti atque animi voluptatum! Aspernatur,
                    sunt.
                  </p>
                </ng-template>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>

        <div class="d-md-none py-4"></div>
      </div>
    </div>
  </div>

  <!--MOBILE MENU-->
  <nk-shared-menu-mobile></nk-shared-menu-mobile>
</main>
