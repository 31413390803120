<div class="follow-box" [ngClass]="{ comment_user: commentUserInfo }">
  <div class="d-flex mb-3">
    <div class="avatar">
      <datnek-ds-avatar
        [size]="sizeEnum.medium"
        [type]="avatarEnum.person"
        class="mr-md-3"
      ></datnek-ds-avatar>
    </div>
    <div class="infos">
      <div class="display-1 mb-3">Display name</div>
      <div class="d-flex mb-3" *ngIf="commentUserInfo">
        <datnek-ds-svg-icon
          [icon]="iconEnum.outlineusers"
          class="mr-2"
        ></datnek-ds-svg-icon>
        <span
          >34 amis communs, dont
          <a href="javescript:;" (datnekdsClickOrTouch)="link('http://localhost:9101/public/company/home')"
            >Emel Atsena</a
          >
          et
          <a href="javescript:;" (datnekdsClickOrTouch)="link('http://localhost:9101/public/company/home')"
            >Christian Essame</a
          ></span
        >
      </div>
      <div class="d-flex mb-3" *ngIf="commentUserInfo">
        <datnek-ds-svg-icon
          [icon]="iconEnum.outlinehome"
          class="mr-2"
        ></datnek-ds-svg-icon>
        <span>Habite à Bruxelle</span>
      </div>
      <div class="d-flex mb-3" *ngIf="commentUserInfo">
        <datnek-ds-svg-icon
          [icon]="iconEnum.outlineinformationCircle"
          class="mr-2"
        ></datnek-ds-svg-icon>
        <span>{{ descriptionPage | truncatetext: 55 }}</span>
      </div>
      <div class="d-flex mb-3" *ngIf="commentUserInfo">
        <datnek-ds-svg-icon
          [icon]="iconEnum.outlinelink"
          class="mr-2"
        ></datnek-ds-svg-icon>
        <a href="twitter.com/PMEXCHANGE1" target="_blank"
          >twitter.com/PMEXCHANGE1</a
        >
      </div>
    </div>
  </div>
  <div class="buttons d-flex align-items-center gap-3">
    <datnek-ds-button
      [label]="'En savoir plus'"
      [size]="sizeEnum.medium"
      [block]="true"
      [type]="colorEnum.border"
      class="datnek-ds-button"
      (datnekdsClickOrTouch)="
        link(
          'https://dev-home.delenscio.be/user/enterprise/private/about/general-informations'
        );
        ref.close()
      "
    ></datnek-ds-button>
    <datnek-ds-button
      [label]="'Message'"
      [size]="sizeEnum.medium"
      [block]="true"
      [type]="colorEnum.secondary"
      class="datnek-ds-button"
      (datnekdsClickOrTouch)="goToChat($event); ref.close()"
    ></datnek-ds-button>
    <datnek-ds-button-icon
      [type]="colorEnum.primary"
      [size]="sizeEnum.medium"
      [Icon]="iconEnum.outlineWhiteuserAdd"
      (datnekdsClickOrTouch)="follow = true"
      class="mr-3"
      *ngIf="!follow"
    ></datnek-ds-button-icon>
    <datnek-ds-button-icon
      [type]="colorEnum.secondary"
      [size]="sizeEnum.medium"
      [Icon]="iconEnum.outlinecheck"
      (datnekdsClickOrTouch)="follow = false"
      class="mr-3"
      *ngIf="follow"
    ></datnek-ds-button-icon>
  </div>
</div>
