import { Component, OnInit } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
} from 'datnek-ds';

@Component({
  selector: 'nk-shared-dialog-event-details',
  templateUrl: './dialog-event-details.component.html',
  styleUrls: ['./dialog-event-details.component.scss'],
})
export class DialogEventDetailsComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  constructor() {}

  ngOnInit(): void {}
}
