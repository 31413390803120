<div
  class="datnek-ds-card likes-icons"
  [ngClass]="{
    'full-grids': fullIcons === true,
    'grid-icons': likes === true
  }"
  #iconLikes
  id="showIcons"
>
  <img src="/assets/icons/src/likes/like-color.svg" />
  <img src="/assets/icons/src/likes/clapping-hands-color.svg" />
  <img src="/assets/icons/src/likes/happy-color.svg" />
  <img src="/assets/icons/src/likes/heart-color.svg" />
  <datnek-ds-button-icon
    [round]="true"
    [type]="colorEnum.secondary"
    [Icon]="iconEnum.outlineplus"
    [size]="sizeEnum.medium"
    (datnekdsClickOrTouch)="onFullIcons()"
    *ngIf="fullIcons === false"
  ></datnek-ds-button-icon>
  <img
    src="/assets/icons/src/likes/idea-color.svg"
    *ngIf="fullIcons === true"
  />
  <img
    src="/assets/icons/src/likes/like-color.svg"
    *ngIf="fullIcons === true"
  />
  <img
    src="/assets/icons/src/likes/clapping-hands-color.svg"
    *ngIf="fullIcons === true"
  />
  <img
    src="/assets/icons/src/likes/happy-color.svg"
    *ngIf="fullIcons === true"
  />
  <img
    src="/assets/icons/src/likes/heart-color.svg"
    *ngIf="fullIcons === true"
  />
  <img
    src="/assets/icons/src/likes/idea-color.svg"
    *ngIf="fullIcons === true"
  />
  <datnek-ds-button-icon
    [round]="true"
    [type]="colorEnum.secondary"
    [Icon]="iconEnum.outlinex"
    [size]="sizeEnum.medium"
    (datnekdsClickOrTouch)="onFullIcons()"
    *ngIf="fullIcons === true"
  ></datnek-ds-button-icon>
</div>
