<div class="datnek-ds-dialog-body dialog-add-calendar"></div>
<div class="datnek-ds-dialog-footer">
  <datnek-ds-button
    [label]="'Partager'"
    [size]="sizeEnum.medium"
    [type]="colorEnum.primary"
    (onClick)="showToast(sh); close()"
  ></datnek-ds-button>
</div>

<!--NOTIFS MESSAGES-->
<ng-template #sh let-close="close">
  Vos résultats ont bien été partagés dans le(s) email(s) sélectionnés
</ng-template>

<!--NOTIFS MESSAGES-->
<ng-template #sh let-close="close">
  Vos résultats ont bien été partagés dans le(s) groupe(s)
</ng-template>
