<div class="datnek-ds-dialog-body dialog-add-challenge">
  <div class="content-modal"></div>
</div>
<div class="datnek-ds-dialog-footer">
  <datnek-ds-button
    [label]="'Ok'"
    [size]="sizeEnum.medium"
    [type]="colorEnum.primary"
    (onClick)="close()"
  ></datnek-ds-button>
</div>
