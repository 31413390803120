<div class="datnek-ds-card suggestion-list">
  <div class="suggestion-header display-1">Menu challenge</div>
  <del-shared-menu-sidebar
    [isChallenge]="true"
    (datnekClickOrTouch)="closeLeftSidebar()"
  ></del-shared-menu-sidebar>
  <div class="p-3">
    <datnek-ds-button
      [block]="true"
      label="Créer un nouveau challenge"
      [type]="colorEnum.primary"
      [size]="sizeEnum.large"
      (datnekdsClickOrTouch)="addNewChallengeDialog()"
    ></datnek-ds-button>
  </div>
</div>
