import {
  Component
} from '@angular/core';

import {FieldArrayType} from "@ngx-formly/core";
@Component({
  selector: 'del-shared-dialog-new-content-header-formly',
  templateUrl: './dialog-new-content-header-formly.component.html',
  styleUrls: ['./dialog-new-content-header-formly.component.scss'],
})
export class DialogNewContentHeaderFormlyComponent extends  FieldArrayType {
  ngOnInit(){
    if (!this.field.fieldGroup?.length){
      this.add();
    }
    console.log('the form: ', this.field.fieldArray, this.field.fieldGroup);
  }
}
