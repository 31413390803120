<div class="datnek-ds-card suggestion-list saved-message-menu-mobile">
  <div class="suggestion-header display-1">Catégories</div>
  <div class="search p-2 border-bottom">
    <datnek-ds-search-input
      placeholder="Rechercher une catégorie..."
    ></datnek-ds-search-input >
  </div>
  <div class="category-body bg-white">
    <span
      class="display-3 pointer category"
      *ngFor="let item of categories"
      (mouseover)="showIcon()"
    >
      {{ item.name }}
      <datnek-ds-svg-icon [icon]="iconEnum.outlinearrowRight"></datnek-ds-svg-icon>
    </span>
  </div>
</div>

<div class="py-4"></div>
