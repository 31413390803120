<div class="datnek-ds-dialog-body dialog-add-calendar">
  <div class="content-text p-3">
    <p class="mb-0">
      Vous pouvez sélectionner jusqu'à 5 utilisateurs à qui partager votre
      contenu. Utilisez le formulaire ci-dessous
    </p>
  </div>
  <div class="form p-3">
    <!-- INTERVENANTS -->
    <div class="display-6 mb-2">Sélectionnez un ou plusieurs utilisateurs</div>
    <div class="mb-3">
      <datnek-ds-select2 [selectType]="selectType.users"></datnek-ds-select2>
    </div>
    <div class="my-0"></div>
  </div>
</div>
<div class="datnek-ds-dialog-footer">
  <datnek-ds-button
    [label]="'Partager'"
    [size]="sizeEnum.medium"
    [type]="colorEnum.primary"
    (onClick)="showToast(sh); close()"
  ></datnek-ds-button>
</div>

<!--NOTIFS MESSAGES-->
<ng-template #sh let-close="close">
  Vos résultats ont bien été partagés dans le(s) groupe(s)
</ng-template>
