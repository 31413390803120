<ng-container *ngIf="userData.length">
  <div class="suggestion-container suggestion-full-list">
    <nk-shared-suggestion-single
      *ngFor="let data of userData"
      [data]="data"
    ></nk-shared-suggestion-single>
  </div>
  <!--<div class="suggestion-container">
    <div
      class="network-user-suggestion-small d-flex align-items-center p-3"
      *ngFor="let data of userData; let i = index"
    >
      <div class="image">
        <figure
          class="d-flex align-items-center justify-content-center"
          *ngFor="let item of userData"
        >
          <div class="play">
            <img src="assets/Img/icons/play-video.svg" alt="" />
          </div>
          <img [src]="item.imageSrc" alt="item.name" title="item.name" />
        </figure>
      </div>
    </div>
  </div>-->
</ng-container>
