import { Router } from '@angular/router';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  LikeBoxPositionEnum,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
  DialogService,
  SuggestionTypeEnum,
} from 'datnek-ds';
import { ComponentType } from '@angular/cdk/overlay';
import {
  DialogAddCategoryComponent,
  DialogAddSignalizationComponent,
  DialogDeleteGroupComponent,
  DialogSharePostWithEditComponent,
} from '@network-shared';

interface CommentsItems {
  imageSrc: string;
  name: string;
  since: string;
  desc: string;
  readMoreComment: boolean;
  multimedia: string;
}
interface stateItems {
  id: number;
  name: string;
}

@Component({
  selector: 'network-single-publication',
  templateUrl: './single-article.component.html',
  styleUrls: ['./single-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleArticleComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  positionEnum = LikeBoxPositionEnum;
  suggestionEnum = SuggestionTypeEnum;

  name = 'Bassahak Jean Marc';
  professionalTitle = 'Analyste Programmeur';
  society = 'DIGITAILLE';
  relations = 4;

  isLoading = false;

  /**
   * Défini si la publication est une publication partagée ou non. Si elle est partagée,
   * alors elle s'affiche dans une autre publication et certains elements disparaissent: le bouton des options et deux des likes et des partages
   *
   * @booblean
   */
  @Input()
  shared = false;

  likes = false;
  likes1 = false;
  showMore = false;
  follow = false;
  mute = false;

  dataComments: CommentsItems[] = [
    {
      imageSrc: 'assets/Img/gallery-publication/comment-4.png',
      name: 'Ulrich Guagang 2',
      since: '3m',
      desc: `Nulla quis lorem ut libero malesuada feugiat.`,
      readMoreComment: false,
      multimedia: '',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/comment-2.jpg',
      name: 'Ebenyst',
      since: '3m',
      desc: `Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec rutrum congue leo eget malesuada.`,
      readMoreComment: false,
      multimedia: 'images',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/comment-3.jpg',
      name: 'Marie Alavert',
      since: '3m',
      desc: `Malesuada !!!.`,
      readMoreComment: false,
      multimedia: 'video',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-1.jpg',
      name: 'Damien Frost',
      since: '3m',
      desc: `Nulla quis lorem ut libero malesuada feugiat. <strong>Nulla porttitor accumsan tincidunt</strong>. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec rutrum congue leo eget malesuada. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec rutrum congue leo eget malesuada. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec rutrum congue leo eget malesuada.`,
      readMoreComment: false,
      multimedia: 'link',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-1.jpg',
      name: 'Damien Frost',
      since: '3m',
      desc: `Nulla quis lorem ut libero malesuada feugiat. Nulla porttitor accumsan tincidunt. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec rutrum congue leo eget malesuada.`,
      readMoreComment: false,
      multimedia: 'voice',
    },
  ];

  dataSubComments: CommentsItems[] = [
    {
      imageSrc: 'assets/Img/gallery-publication/comment-4.png',
      name: 'Ulrich Guagang 2',
      since: '3m',
      desc: `Nulla quis lorem ut libero malesuada feugiat.`,
      readMoreComment: false,
      multimedia: 'link',
    },
  ];

  label = 'Les plus récents';

  subMenu: stateItems[] = [
    {
      id: 0,
      name: 'Les plus pertinents',
    },
    {
      id: 1,
      name: 'Les plus récents',
    },
  ];

  haveReaction = [
    'Bassahak JM',
    'Yankep Arno',
    'Danick Takam',
    'Escalator',
    'Predatro Rhymes',
    'Siblings',
  ];

  rest = this.haveReaction.slice(5, -1);

  addComment = false;
  showListComments = false;

  @ViewChild('myElem') MyProp!: ElementRef;

  constructor(private router: Router, private modalService: DialogService) {
    console.log('RESTE : ' + this.rest);
  }

  ngOnInit(): void {
    if (this.haveReaction.length > 4) {
      //return this.haveReaction.slice(1, 5)
    }
    setTimeout(() => (this.isLoading = false), 2500);
  }

  clickListener(e: any) {
    if (e.keyCode === 13) {
      console.log(e);
      this.showListComments = true;
    }
  }

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  addToCategory() {
    const ref = this.modalService.open(DialogAddCategoryComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter dans une catégorie`,
        size: DialogSizeEnum.small,
      },
    });
  }

  addSignalization() {
    const ref = this.modalService.open(DialogAddSignalizationComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Signaler l'article`,
        size: DialogSizeEnum.small,
      },
    });
  }

  deleteArticle() {
    const ref = this.modalService.open(DialogDeleteGroupComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Supprimer l'article`,
        size: DialogSizeEnum.small,
      },
    });
  }

  onAddComment() {
    this.addComment = !this.addComment;
  }

  onShowMore() {
    this.showMore = !this.showMore;
  }

  @ViewChild('comment') private my_point_3!: ElementRef;
  scrollPoint3() {
    // this.my_point_3.nativeElement.scrollIntoView({behavior: "smooth"});
    this.my_point_3.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  gotoListArticles(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate(['/list-articles']);
  }

  blankPage(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate(['/blank']);
  }

  sharePostWithEdit() {
    const ref = this.modalService.open(DialogSharePostWithEditComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Partager l'article`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  blockUser(content: TemplateRef<any> | ComponentType<any> | string) {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        size: DialogSizeEnum.small,
        title: 'Bloquer cet utilisateur ?',
      },
    });
  }

  signalUser(content: TemplateRef<any> | ComponentType<any> | string) {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        size: DialogSizeEnum.small,
        title: 'Signaler cet utilisateur ?',
      },
    });
  }

  onMute() {
    this.mute = !this.mute;
  }

  isFollowed(): void {
    this.follow = !this.follow;
  }

  showLikes(): void {
    this.likes = true;
  }

  showLikes1(): void {
    this.likes1 = !this.likes1;
  }

  goToEditArticle(e: any) {
    this.router.navigate(['/add-publication']);
  }

  goToSavedMessages(e: any) {
    this.router.navigate(['/saved-messages']);
  }

  goToHome(e: any) {
    this.router.navigate(['/']);
  }

  onShowListComments() {
    this.showListComments = !this.showListComments;
  }
}
