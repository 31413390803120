<div class="user-chat-box" [ngClass]="{ pinned: pinned }">
  <ng-container *ngIf="userData.length && !isLoading">
    <datnek-ds-search-input
      *ngIf="searchbar === true"
      placeholder="Rechercher personne/groupe"
    ></datnek-ds-search-input >
    <!-- ON CHAT POPUP-->
    <ng-container *ngIf="onChatBox === false">
      <div
        class="user-chat-list d-flex p-2"
        *ngFor="let data of userData"
        (datnekdsClickOrTouch)="openPopup(data)"
      >
        <div class="d-flex align-items-center w-75">
          <datnek-ds-avatar
            [size]="sizeEnum.small"
            [border]="false"
            imgSrc="{{ data.imageSrc }}"
            class="mr-2"
            [type]="status"
          ></datnek-ds-avatar>
          <div class="infos">
            <div class="display-4 name mr-2">
              {{ data.name | truncatetext: 18 }}
            </div>
            <span class="display-6"
              >{{ data.writer }} : {{ data.message }}</span
            >
          </div>
        </div>

        <small class="time display-6 w-25 text-right">{{ data.time }}</small>

        <datnek-ds-notification-point number="3"></datnek-ds-notification-point>
      </div>
    </ng-container>

    <!-- ON CHATBOX-->
    <ng-container *ngIf="onChatBox === true">
      <div
        class="user-chat-list d-flex p-2"
        *ngFor="let data of userData"
        (onClick)="showLeftSidebarMobile($event); showActiveChat($event)"
      >
        <div class="d-flex align-items-center w-75">
          <datnek-ds-avatar
            [size]="sizeEnum.small"
            [border]="false"
            imgSrc="{{ data.imageSrc }}"
            class="mr-2"
            [type]="status"
          ></datnek-ds-avatar>
          <div class="infos">
            <div class="display-4 name mr-2">
              {{ data.name | truncatetext: 18 }}
            </div>
            <span class="display-6"
              >{{ data.writer }} : {{ data.message }}</span
            >
          </div>
        </div>

        <small class="time display-6 w-25 text-right">{{ data.time }}</small>

        <datnek-ds-notification-point number="3"></datnek-ds-notification-point>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="isLoading && userData.length > 1">
  <datnek-skeleton-user-chat-list
    *ngFor="let in of counter(5); let i = index"
  ></datnek-skeleton-user-chat-list>
</ng-container>

<ng-container *ngIf="isLoading && userData.length === 1">
  <datnek-skeleton-user-chat-list
    *ngFor="let in of counter(1); let i = index"
  ></datnek-skeleton-user-chat-list>
</ng-container>

<ng-container *ngIf="isLoading && userData.length < 3">
  <datnek-skeleton-user-chat-list
    *ngFor="let in of counter(2); let i = index"
  ></datnek-skeleton-user-chat-list>
</ng-container>

<!-- IF NO USERS CONTENT -->
<ng-container *ngIf="!userData.length && toCreateGroupConversation === true">
  <datnek-ds-button
    label="Créer un groupe"
    [size]="sizeEnum.large"
    [block]="true"
    [type]="colorEnum.primary"
    (datnekdsClickOrTouch)="addMember()"
  ></datnek-ds-button>
  <datnek-ds-no-content
    imgSrc="assets/Img/icons/colors/group.svg"
    titleMsg="Aucun groupe créé"
    contentDesc="Vous n’avez aucune conversation de groupe pour l’instant"
  ></datnek-ds-no-content>
</ng-container>

<ng-container *ngIf="!userData.length && toCreateSingleConversation === true">
  <datnek-ds-button
    label="Créer une conversation"
    [size]="sizeEnum.large"
    [block]="true"
    [type]="colorEnum.primary"
    (datnekdsClickOrTouch)="addSingleMember()"
  ></datnek-ds-button>
  <datnek-ds-no-content
    imgSrc="assets/Img/icons/colors/group.svg"
    titleMsg="Aucune conversation créée"
    contentDesc="Vous n’avez aucune conversation individuelle pour l’instant"
  ></datnek-ds-no-content>
</ng-container>
