import { Component, Input, OnInit } from '@angular/core';
import { AvatarTypeEnum } from 'datnek-ds';
interface JobsItems {
  imageSrc: string;
  jobTitle: string;
  society: string;
  time: string;
}

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  groupName: string;
  relations: number;
  sector: string;
}

@Component({
  selector: 'nk-shared-top-jobs',
  templateUrl: './top-jobs.component.html',
  styleUrls: ['./top-jobs.component.scss'],
})
export class TopJobsComponent implements OnInit {
  avatarEnum = AvatarTypeEnum;

  /**
   * Affiche les blocks des Challenges
   *
   * @default = true
   *
   */
  @Input()
  showChallenges = true;

  dataJobs: JobsItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      jobTitle: 'Analyste Programmeur',
      society: 'DIGITAILLE',
      time: 'Il ya 4 mn',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      jobTitle: 'Agent de recouvrement de fonds',
      society: 'AVAST',
      time: 'Il ya 30mn',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      jobTitle: 'Développeur front-end',
      society: 'Delenscio',
      time: 'Il ya 2 jour',
    },
    // {
    //   imageSrc: 'assets/Img/users/1.png',
    //   jobTitle: 'Dévrloppeur Java Senior',
    //   society: 'UBS Europa',
    //   time: '1m',
    // },
  ];

  dataUsers: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 4,
      sector: 'Génie civil',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 18,
      sector: 'Technologie',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 0,
      sector: 'Agroalimentaire',
    },
  ];

  isLoading = true;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }
}
