import {Challenge, MediaNetwork} from '@del-shared';

export namespace ChallengeActions {

  export class setChallengeOfCurrentUser { 
    static readonly type = '[Challenge Api] Set Challenges of Current user';
    constructor(public count?: number, public data?: Challenge[]) {}
  }

  export class getLazyByResume {
    static readonly type = '[Challenge Api] Get Challenges Lazy Resume';
    constructor(
      public resumeId: string,
      public skip: number,
      public take: number| null = null
    ) {}
  }

  export class create {
    static readonly type = '[Challenge Api] Create Challenge';
    constructor(public Challenge: Challenge) {}
  }

  export class update {
    static readonly type = '[Challenge Api] Update Challenge';
    constructor(public Challenge: Challenge) {}
  }

  export class remove {
    static readonly type = '[Challenge Api] Delete Challenge';
    constructor(public Challenge: Challenge) {}
  }

  export class removeMedia {
    static readonly type = '[Challenge Api] Delete Media of Challenge';
    constructor(public mediaChallenge: MediaNetwork) {}
  }
}
