<div class="datnek-ds-dialog-body dialog-challenge-impossible-access">
  <div class="content-modal p-3">
    <div class="content-text">
      <p class="">
        Voulez-vous vraiment annuler votre participation au challenge ? Cette
        action est irréversible. Vous ne pourrez plus à nouveau prendre part à
        ce challenge.
      </p>
    </div>
  </div>
  <div class="datnek-ds-dialog-footer">
    <datnek-ds-button
      [label]="'Non je continue'"
      [size]="sizeEnum.large"
      [type]="colorEnum.secondary"
      class="mr-3"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
    <datnek-ds-button
      [label]="'Oui je veux annuler'"
      [size]="sizeEnum.large"
      [type]="colorEnum.primary"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
  </div>
</div>
