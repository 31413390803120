<div class="datnek-ds-dialog-body dialog-challenge-rejected">
  <div class="content-modal">
    <div class="notif-box p-3">
      <p class="mb-0">Contenu ici</p>
    </div>

    <div class="datnek-ds-dialog-footer">
      <datnek-ds-button
        [label]="'Annuler'"
        [size]="sizeEnum.large"
        [type]="colorEnum.secondary"
        class="mr-3"
        (datnekdsClickOrTouch)="dialogRef.close()"
      ></datnek-ds-button>
      <datnek-ds-button
        [label]="'Importer'"
        [size]="sizeEnum.large"
        [type]="colorEnum.primary"
        (datnekdsClickOrTouch)="dialogRef.close()"
      ></datnek-ds-button>
    </div>
  </div>
</div>
