import { MediaNetwork, Publication} from '@del-shared';

export namespace PublicationActions {
  export type PublicationType = 'user' | 'company';

  export class setPublicationOfCurrentUser {
    static readonly type = '[Network Api] Set Publications of Current user';
    constructor(
      public count: number | null,
      public data: Publication[] | null,
      public type: PublicationType
    ) {}
  }

  export class getLazyByUserOrCompany {
    static readonly type = '[Network Api] Get Publications Lazy Resume';
    constructor(
      public userOrCompanyId: string,
      public type: PublicationType,
      public skip: number,
      public take: number | null = null
    ) {}
  }

  export class create {
    static readonly type = '[Network Api] Create Publication';
    constructor(
      public publication: Publication,
      public type: PublicationType
    ) {}
  }

  export class update {
    static readonly type = '[Network Api] Update Publication';
    constructor(
      public publication: Publication,
      public type: PublicationType
    ) {}
  }

  export class remove {
    static readonly type = '[Network Api] Delete Publication';
    constructor(
      public publication: Publication,
      public type: PublicationType
    ) {}
  }

  export class removeMedia {
    static readonly type = '[Network Api] Delete Media of Publication';
    constructor(
      public mediaNetwork: MediaNetwork,
      public type: PublicationType
    ) {}
  }
}
