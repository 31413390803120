import { Component, Inject, OnInit, PLATFORM_ID  } from '@angular/core';
import { Router } from '@angular/router';
import Aos from 'aos'; //AOS - 1
import { OwlOptions } from 'ngx-owl-carousel-o';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
} from 'datnek-ds';
import { Environment } from 'datnek-core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'del-shared-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<img src="assets/icons/src/outline/white/chevron-left.svg" class="arrow-slide">',
      '<img src="assets/icons/src/outline/white/chevron-right.svg" class="arrow-slide">',
    ],
    items: 1,
    nav: true,
  };

  constructor(
    public router: Router,
    @Inject(Environment.Provider.ENVIRONMENT)
    private environment: Environment.Env,
  ) {}

  ngOnInit(): void {
    Aos.init({ disable: 'mobile' }); //AOS - 2
    Aos.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
  }

  register() {
    this.router.navigateByUrl(this.environment.securityRoute.login);
  }
}
