import { BaseModel } from '../base.model';

export class Media extends BaseModel {
  Name?: string;
  Hashname?: string;
  Extension?: string;
  Size?: number;
  SubDir?: string;


  constructor(
    Id?: string,
    CreatedAt?: Date,
    UpdatedAt?: Date,
    Name?: string,
    Hashname?: string,
    Extension?: string,
    Size?: number,
    SubDir?: string,

  ) {
    super(Id, CreatedAt, UpdatedAt);
    this.Name = Name;
    this.Hashname = Hashname;
    this.Extension = Extension;
    this.Size = Size;
    this.SubDir = SubDir;
  }
}
