import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import {GeneralStepEnum} from "datnek-sso";
import { AppEnum } from '../../enum';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private translations: any;
  public static App: AppEnum

  public languageName = GeneralStepEnum.DELENSCIOLANG;
  constructor(private http: HttpClient, private translate: TranslateService) {}

  setLanguage(): void {
    let lang = localStorage.getItem(this.languageName);
    lang = lang ? lang : 'en';
    this.translate.setDefaultLang(lang);
    this.useLanguage(lang);
  }

  useLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem(this.languageName, language);
  }

  getLanguage(): string {
    return localStorage.getItem(this.languageName) as string;
  }
}
