<div
  class="datnek-ds-card challenge-box challenge-battle-box"
  *ngIf="hide === false && !isLoading"
>
  <div
    class="
      challenge-header
      d-flex
      justify-content-between
      align-items-center
      border-bottom
    "
  >
    <div class="left mr-3 d-flex align-items-center" *ngIf="!isLoading">
      <img
        src="/assets/icons/src/outline/color/competition-champion-color.svg"
        alt=""
        class="mr-3"
      />
      <div class="display-1" *ngIf="!acceptedChallenge">
        Nouvelle demande de challenge
      </div>
      <div class="display-1" *ngIf="acceptedChallenge">Nouveau challenge</div>
    </div>
    <div class="p-3 skeleton d-flex align-items-center gap-3" *ngIf="isLoading">
      <div class="circle medium-c"></div>
      <div class="line h15 w-50"></div>
    </div>

    <!--<datnek-ds-button-icon
      [Icon]="iconEnum.solidinboxclose"
      [type]="colorEnum.secondary"
      [size]="sizeEnum.medium"
      [round]="true"
      (datnekdsClickOrTouch)="onHide()"
    ></datnek-ds-button-icon>-->
  </div>
  <div class="challenge-content d-flex flex-column justify-content-center p-3">
    <ng-container *ngIf="acceptedChallenge">
      <div
        class="
          challengers
          d-flex
          justify-content-between
          align-items-start
          gap-2
        "
      >
        <div class="item left d-flex align-items-start justify-content-end">
          <nk-shared-card-challenger-box
            userDesc="Designer graphique Senior"
            userName="John Doe"
            challengeDescription="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error atque expedita ullam. Repudiandae ratione perspiciatis veniam rerum totam reprehenderit nulla distinctio ipsam minus quod aspernatur qui amet architecto, enim molestiae."
            [status]="avatarEnum.person"
            [competences]="competencesList"
            [acceptedChallenge]="true"
            [challenger]="true"
            [versus]="true"
            *ngIf="!isLoading"
          ></nk-shared-card-challenger-box>
          <datnek-skeleton-card-user-box
            *ngIf="isLoading"
          ></datnek-skeleton-card-user-box>
        </div>
        <div class="vs" *ngIf="!isLoading">
          <img src="/assets/icons/src/vs.svg" alt="" />
        </div>
        <div class="vs skeleton" *ngIf="isLoading">
          <div class="circle medium-c"></div>
        </div>
        <div class="item right d-flex align-items-start">
          <nk-shared-card-challenger-box
            userDesc="UI/UX Designer Senior"
            userName="Yarno Ghislain"
            challengeDescription="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error atque expedita ullam. Repudiandae ratione perspiciatis veniam rerum totam reprehenderit nulla distinctio ipsam minus quod aspernatur qui amet architecto, enim molestiae."
            [status]="avatarEnum.person"
            [competences]="competencesList"
            [acceptedChallenge]="true"
            [versus]="true"
            *ngIf="!isLoading"
          ></nk-shared-card-challenger-box>
          <datnek-skeleton-card-user-box
            *ngIf="isLoading"
          ></datnek-skeleton-card-user-box>
        </div>
      </div>

      <p>Il reste 2 semaines pour le lancement du challenge</p>
    </ng-container>

    <ng-container *ngIf="!acceptedChallenge">
      <div class="challenger d-none d-sm-flex">
        <nk-shared-card-challenger-box
          userDesc="Penting gak Penting yang oentung Posting"
          userName="John Doe"
          challengeDescription="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error atque expedita ullam. Repudiandae ratione perspiciatis veniam rerum totam reprehenderit nulla distinctio ipsam minus quod aspernatur qui amet architecto, enim molestiae."
          [status]="avatarEnum.person"
          [competences]="competencesList"
          [acceptedChallenge]="acceptedChallenge"
          (onClick)="acceptedChallenge = true"
        ></nk-shared-card-challenger-box>
        <nk-shared-card-challenge-description></nk-shared-card-challenge-description>
      </div>

      <!-- ONLY ON MOBILE DEVICE -->
      <div class="challenger px-3 d-sm-none">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <nk-shared-card-challenger-box
              userDesc="Penting gak Penting yang oentung Posting"
              userName="John Doe"
              challengeDescription="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error atque expedita ullam. Repudiandae ratione perspiciatis veniam rerum totam reprehenderit nulla distinctio ipsam minus quod aspernatur qui amet architecto, enim molestiae."
              [status]="avatarEnum.person"
              [competences]="competencesList"
              [acceptedChallenge]="acceptedChallenge"
              (onClick)="acceptedChallenge = true"
            ></nk-shared-card-challenger-box>
          </ng-template>

          <ng-template carouselSlide>
            <nk-shared-card-challenge-description></nk-shared-card-challenge-description>
          </ng-template>
        </owl-carousel-o>
      </div>
      <!--
      <span class="mb-3">
        Challengez <span class="challenge-name">@Yarno</span> pour la comptence
        <span class="challenge-name">#Nom de la compétence</span>
      </span>

      <div class="organizer rounded">
        <div class="d-flex align-items-center p-3">
          <datnek-ds-avatar
            [size]="sizeEnum.medium1"
            class="mr-3"
            [type]="avatarEnum.person"
          ></datnek-ds-avatar>
          <a
            class="infos pointer d-block"
            target="_blank"
            href="https://dev-home.delenscio.be/user/enterprise/private/about/general-informations"
          >
            <div class="d-flex flex-column">
              <div class="d-flex">
                <div class="name display-2 d-inline mr-2">
                  {{ society | truncatetext: 35 }}
                  <img src="assets/icons/src/check-color.png" alt="" />
                </div>
              </div>
              <span>{{ sector | truncatetext: 35 }}</span>
            </div>
          </a>
        </div>
      </div>
      -->
    </ng-container>
  </div>
</div>

<datnek-skeleton-new-challenge
  *ngIf="isLoading"
></datnek-skeleton-new-challenge>

<!--NOTIFS MESSAGES-->
<ng-template #yes let-close="close">
  Vous avez accepté le challenge. Vous recevrez des notifications pour la suite
</ng-template>
<ng-template #no let-close="close">
  Vous avez rejeté le challenge.
</ng-template>
