<main class="content contacts">
  <div class="container p-0">
    <div class="datnek-ds-card px-3">
      <div class="row g-0">
        <div
          class="d-none col-lg-3 col-xl-3 px-4"
          [ngClass]="
            leftSidebar === true ? 'd-md-block col-md-4' : 'd-lg-block'
          "
        >
          <span
            class="display-5 title-menu text-uppercase py-3 d-flex"
            *ngIf="!isLoading"
            >Application</span
          >
          <div class="p-3 skeleton" *ngIf="isLoading">
            <div class="line h15 w-50"></div>
          </div>
          <div class="d-none d-md-block">
            <del-shared-menu-sidebar [isMessages]="true"></del-shared-menu-sidebar>
          </div>
        </div>

        <div
          class="col-12 col-lg-9 col-xl-9 px-0"
          [ngClass]="leftSidebar === true ? 'col-md-8' : 'col-md-12'"
        >
          <div
            class="header d-flex justify-content-between px-3 px-md-0"
            *ngIf="!isLoading"
          >
            <div class="title d-flex align-items-center">
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlinemenu"
                [type]="colorEnum.unstyled"
                (datnekdsClickOrTouch)="showLeftSidebar()"
                class="d-none d-md-block d-lg-none"
              ></datnek-ds-button-icon>
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlineuser"
                class="mr-1"
              ></datnek-ds-svg-icon>
              <div class="display-1">Contacts</div>
            </div>
            <div class="actions d-flex align-items-center justify-content-end">
              <div class="d-inline-block mr-3">
                <div
                  (datnekdsClickOrTouch)="openDropdown(actions, target1)"
                  #target1
                >
                  <span>Les plus pertinents</span>
                  <datnek-ds-svg-icon
                    [icon]="iconEnum.outlinechevronDown"
                    class="ml-2"
                  ></datnek-ds-svg-icon>
                </div>
              </div>
              <datnek-ds-button
                label="Importez des contacts"
                [leftIcon]="iconEnum.outlineWhiteplus"
                [type]="colorEnum.primary"
                [size]="sizeEnum.large"
                (datnekdsClickOrTouch)="addContact()"
                class="mr-0 mr-md-3 d-none d-md-block"
              ></datnek-ds-button>
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlineWhiteplus"
                [type]="colorEnum.primary"
                [size]="sizeEnum.medium"
                (datnekdsClickOrTouch)="addContact()"
                class="d-md-none"
              ></datnek-ds-button-icon>
            </div>
          </div>
          <div
            class="
              skeleton
              suggestion-header
              d-flex
              align-items-center
              justify-content-between
            "
            *ngIf="isLoading"
          >
            <div class="w-75 d-flex align-items-center">
              <div class="skeleton-left mr-3">
                <div class="circle medium-c"></div>
              </div>
              <div class="skeleton-right w-25">
                <div class="line w-100 h15"></div>
              </div>
            </div>
            <div class="d-flex gap-3 w-50 justify-content-end">
              <div class="button w-50 medium-h"></div>
              <div class="button w-75 medium-h"></div>
            </div>
          </div>
          <div class="body-content">
            <!--No Content-->
            <datnek-ds-no-content
              titleMsg="Aucun contact"
              contentDesc="Vous
              n’avez encore créé aucun contact pour l’instant. Cliquez sur le
              bouton de création de nouveau contact plus haut"
              imgSrc="assets/icons/src/contacts.svg"
            ></datnek-ds-no-content>

            <!-- coNTACT LIST -->
            <div class="table-responsive" *ngIf="!isLoading">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">
                      <label class="checkbox">
                        <input type="checkbox" />
                        <span class="checkmark"></span>
                      </label>
                    </th>
                    <th scope="col"><div class="display-3">Tttre</div></th>
                    <th scope="col"><div class="display-3">Email</div></th>
                    <th scope="col">
                      <div class="display-3">Téléphone</div>
                    </th>
                    <th scope="col">
                      <div class="display-3">Fonctions</div>
                    </th>
                    <th scope="col" class="text-center">
                      <div class="display-3">Actions</div>
                    </th>
                  </tr>
                </thead>
                <tbody *ngIf="onlyFavorite === false">
                  <tr *ngFor="let user of users; let i = index">
                    <td scope="row" class="pointer">
                      <label class="checkbox">
                        <input type="checkbox" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td
                      (datnekdsClickOrTouch)="contactDetails($event)"
                      class="pointer"
                    >
                      <div
                        class="user d-flex align-items-center"
                        style="width: 150px"
                      >
                        <datnek-ds-avatar
                          [size]="sizeEnum.small"
                        ></datnek-ds-avatar>
                        <span class="truncated ml-2">{{ user.name }}</span>
                      </div>
                    </td>
                    <td
                      (datnekdsClickOrTouch)="contactDetails($event)"
                      class="pointer"
                    >
                      <span class="truncated">{{ user.email }}</span>
                    </td>
                    <td
                      (datnekdsClickOrTouch)="contactDetails($event)"
                      class="pointer"
                    >
                      <span class="truncated">
                        {{ user.phone }}
                      </span>
                    </td>
                    <td
                      (datnekdsClickOrTouch)="contactDetails($event)"
                      class="pointer"
                    >
                      <span class="truncated">{{ user.function }}</span>
                    </td>
                    <td>
                      <div
                        class="d-flex align-items-center justify-content-end"
                      >
                        <datnek-ds-svg-icon
                          [icon]="iconEnum.outlinestar"
                          class="mr-2"
                          *ngIf="favorite === false"
                          (datnekdsClickOrTouch)="
                            addFavorite(i); showToast(fav)
                          "
                        ></datnek-ds-svg-icon>
                        <datnek-ds-svg-icon-solid
                          [icon]="iconEnum.solidstar"
                          class="mr-2"
                          *ngIf="favorite === true"
                          (datnekdsClickOrTouch)="
                            addFavorite(i); showToast(fav)
                          "
                        ></datnek-ds-svg-icon-solid>
                        <datnek-ds-button-icon
                          [Icon]="iconEnum.outlinepencil"
                          [type]="colorEnum.unstyled"
                          [size]="sizeEnum.small"
                          (datnekdsClickOrTouch)="editContact()"
                        ></datnek-ds-button-icon>
                        <div class="d-inline-block mr-3">
                          <div
                            class="pointer"
                            (datnekdsClickOrTouch)="
                              openDropdown(actions1, target2)
                            "
                            #target2
                          >
                            <datnek-ds-button-icon
                              [Icon]="iconEnum.outlinedotsVertical"
                              [type]="colorEnum.unstyled"
                              [size]="sizeEnum.medium"
                            ></datnek-ds-button-icon>
                          </div>

                          <ng-template #actions1 let-close="close">
                            <div
                              class="
                                datnek-ds-card
                                dropdown-card
                                actions-single-groups
                              "
                            >
                              <button
                                class="dropdown-item"
                                (datnekdsClickOrTouch)="
                                  showToast(mask); maskContact(i); close()
                                "
                              >
                                <datnek-ds-svg-icon
                                  [icon]="iconEnum.outlineeyeOff"
                                ></datnek-ds-svg-icon>
                                Masquer dans les contacts
                              </button>
                              <button
                                class="dropdown-item"
                                (datnekdsClickOrTouch)="
                                  deleteContact(); close()
                                "
                              >
                                <datnek-ds-svg-icon
                                  [icon]="iconEnum.outlinetrash"
                                ></datnek-ds-svg-icon>
                                Supprimer
                              </button>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="onlyFavorite === true">
                  <tr *ngFor="let user of users1; let i = index">
                    <td scope="row" class="pointer">
                      <label class="checkbox">
                        <input type="checkbox" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td
                      (datnekdsClickOrTouch)="contactDetails($event)"
                      class="pointer"
                    >
                      <div
                        class="user d-flex align-items-center"
                        style="width: 150px"
                      >
                        <datnek-ds-avatar
                          [size]="sizeEnum.small"
                        ></datnek-ds-avatar>
                        <span class="truncated ml-2">{{ user.name }}</span>
                      </div>
                    </td>
                    <td
                      (datnekdsClickOrTouch)="contactDetails($event)"
                      class="pointer"
                    >
                      <span class="truncated">{{ user.email }}</span>
                    </td>
                    <td
                      (datnekdsClickOrTouch)="contactDetails($event)"
                      class="pointer"
                    >
                      <span class="truncated">
                        {{ user.phone }}
                      </span>
                    </td>
                    <td
                      (datnekdsClickOrTouch)="contactDetails($event)"
                      class="pointer"
                    >
                      <span class="truncated">{{ user.function }}</span>
                    </td>
                    <td>
                      <div
                        class="d-flex align-items-center justify-content-end"
                      >
                        <datnek-ds-svg-icon
                          [icon]="iconEnum.outlinestar"
                          class="mr-2"
                          *ngIf="favorite === true"
                          (datnekdsClickOrTouch)="
                            addFavorite(i); showToast(fav)
                          "
                        ></datnek-ds-svg-icon>
                        <datnek-ds-svg-icon-solid
                          [icon]="iconEnum.solidstar"
                          class="mr-2"
                          *ngIf="favorite === false"
                          (datnekdsClickOrTouch)="
                            addFavorite(i); showToast(fav)
                          "
                        ></datnek-ds-svg-icon-solid>
                        <datnek-ds-button-icon
                          [Icon]="iconEnum.outlinepencil"
                          [type]="colorEnum.unstyled"
                          [size]="sizeEnum.small"
                          (datnekdsClickOrTouch)="editContact()"
                        ></datnek-ds-button-icon>
                        <div class="d-inline-block mr-3">
                          <div
                            class="pointer"
                            (datnekdsClickOrTouch)="
                              openDropdown(actions1, target3)
                            "
                            #target3
                          >
                            <datnek-ds-button-icon
                              [Icon]="iconEnum.outlinedotsVertical"
                              [type]="colorEnum.unstyled"
                              [size]="sizeEnum.medium"
                            ></datnek-ds-button-icon>
                          </div>

                          <ng-template #actions1 let-close="close">
                            <div
                              class="
                                datnek-ds-card
                                dropdown-card
                                actions-single-groups
                              "
                            >
                              <button
                                class="dropdown-item"
                                (datnekdsClickOrTouch)="
                                  showToast(mask); maskContact(i); close()
                                "
                              >
                                <datnek-ds-svg-icon
                                  [icon]="iconEnum.outlineeyeOff"
                                ></datnek-ds-svg-icon>
                                Masquer dans les contacts
                              </button>
                              <button
                                class="dropdown-item"
                                (datnekdsClickOrTouch)="
                                  deleteContact(); close()
                                "
                              >
                                <datnek-ds-svg-icon
                                  [icon]="iconEnum.outlinetrash"
                                ></datnek-ds-svg-icon>
                                Supprimer
                              </button>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <datnek-skeleton-contact-list
              *ngIf="isLoading"
            ></datnek-skeleton-contact-list>

            <div
              class="
                skeleton
                footer
                d-flex
                align-items-center
                justify-content-end
                p-3
              "
            >
              <!--<ngb-pagination
                  [collectionSize]="collectionSize"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  (pageChange)="refreshUsers()"
                >
                </ngb-pagination>-->

              <select
                *ngIf="!isLoading"
                class="form-select"
                style="width: auto"
                [(ngModel)]="pageSize"
                (ngModelChange)="refreshUsers()"
              >
                <option [ngValue]="2">2 items per page</option>
                <option [ngValue]="4">4 items per page</option>
                <option [ngValue]="6">6 items per page</option>
              </select>
              <div
                class="button medium-h"
                style="width: 20%"
                *ngIf="isLoading"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<ng-template #mask let-close="close">
  <p class="mb-0">
    Le contact a bien été masqué de la liste
    <!--<a class="ml-2">Voir</a>-->
  </p>
</ng-template>
<ng-template #fav let-close="close">
  <p class="mb-0">
    Le contact a été ajouté/supprimé vos favoris
    <!--<a class="ml-2">Voir</a>-->
  </p>
</ng-template>

<!-- DROPDOWNS -->
<ng-template #actions let-close="close">
  <div class="datnek-ds-card dropdown-card actions-single-groups">
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="showOnlyFavorites(); close()"
    >
      <datnek-ds-svg-icon [icon]="iconEnum.outlineusers"></datnek-ds-svg-icon>
      Tous les contacts
    </button>
    <button
      class="dropdown-item"
      (datnekdsClickOrTouch)="showOnlyFavorites(); close()"
    >
      <datnek-ds-svg-icon [icon]="iconEnum.outlinestar"></datnek-ds-svg-icon>
      Contacts favoris
    </button>
  </div>
</ng-template>
