import {assetUrl} from "datnek-ds";
export const SHARED_LOCALES = [
  {
    prefix: assetUrl('locales/home/'),
    suffix: '.json',
  },
  {
    prefix: assetUrl('locales/external-lib/sso/'),
    suffix: '.json',
  },
  {
    prefix: assetUrl('locales/external-lib/ds/'),
    suffix: '.json',
  }
];
