<div class="datnek-ds-card new-publication" *ngIf="!isLoading">
  <div class="d-flex">
    <div class="left">
      <datnek-ds-avatar
        [size]="sizeEnum.medium1"
        [type]="status"
        [imgSrc]="iconEnum.avatarSociety"
      ></datnek-ds-avatar>
    </div>
    <div class="right">
      <div class="message mb-3">
        <div
          class="text-box d-flex align-items-center"
          (datnekdsClickOrTouch)="newContentDialog()"
        >
          Quoi de neuf ?
        </div>
      </div>
    </div>
  </div>

  <div class="datnek-ds-card-footer d-flex align-items-center">
    <div class="icons">
      <datnek-ds-button
        [label]="'Photos'"
        [size]="sizeEnum.large"
        [block]="true"
        [type]="colorEnum.secondary"
        [colorIconLeft]="iconEnum.outlineColorphotographColor"
        (datnekdsClickOrTouch)="addPicture()"
        [isMobile]="true"
        pTooltip="Photos"
        tooltipPosition="top"
      ></datnek-ds-button>
      <datnek-ds-button
        [label]="'Vidéos'"
        [size]="sizeEnum.large"
        [block]="true"
        [type]="colorEnum.secondary"
        [colorIconLeft]="iconEnum.outlineColorfilmColor"
        (datnekdsClickOrTouch)="addVideo()"
        [isMobile]="true"
        pTooltip="Vidéos"
        tooltipPosition="top"
      ></datnek-ds-button>
      <datnek-ds-button
        [label]="'Articles'"
        [size]="sizeEnum.large"
        [block]="true"
        [type]="colorEnum.secondary"
        [colorIconLeft]="iconEnum.outlineColorviewListColor"
        (datnekdsClickOrTouch)="addArticlePage($event)"
        [isMobile]="true"
        pTooltip="Articles"
        tooltipPosition="top"
      ></datnek-ds-button>
      <datnek-ds-button
        [label]="'Evènement'"
        [size]="sizeEnum.large"
        [block]="true"
        [type]="colorEnum.secondary"
        [colorIconLeft]="iconEnum.outlineColorcalendarColor"
        (datnekdsClickOrTouch)="addCalendar()"
        [isMobile]="true"
        pTooltip="Evènement"
        tooltipPosition="top"
      ></datnek-ds-button>
      <datnek-ds-button
        [label]="'ChallengeComp'"
        [size]="sizeEnum.large"
        [block]="true"
        [type]="colorEnum.secondary"
        [colorIconLeft]="iconEnum.outlineColorcompetitionChampionColor"
        (datnekdsClickOrTouch)="addChallenge()"
        [isMobile]="true"
        pTooltip="Challenge"
        tooltipPosition="top"
      ></datnek-ds-button>
    </div>
  </div>
</div>

<datnek-skeleton-new-publication
  *ngIf="isLoading"
></datnek-skeleton-new-publication>
