<div class="p-3">
  <span class="display-5 title-menu text-uppercase py-3 d-flex"
    >Application</span
  >
  <div class="">
    <del-shared-menu-sidebar [isMessages]="true"></del-shared-menu-sidebar>
  </div>
  <span class="display-5 title-menu model2 text-uppercase py-3 d-flex"
    >Conversations
  </span>
  <div class="sidebar-users">
    <p-tabView>
      <p-tabPanel header="Individuelles">
        <div class="sidebar-chat">
          <nk-shared-user-chat-list
            [userData]="dataUsersChat"
            [status]="avatarEnum.person"
            [searchbar]="true"
          ></nk-shared-user-chat-list>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Groupes">
        <div class="sidebar-chat">
          <nk-shared-user-chat-list
            [userData]="data1UsersChat"
            [status]="avatarEnum.group"
            [searchbar]="true"
          ></nk-shared-user-chat-list>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>

  <hr class="d-block d-lg-none mt-1 mb-0" />
</div>

<div class="py-4"></div>
