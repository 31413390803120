<div class="container px-xl-4 pt-3 pt-lg-4">
  <section class="saved-messages-page datnek-ds-content grid grid--gap16">
    <div
      class="
        d-none d-md-block
        grid__col_12 grid__col_sm4 grid__col_md4 grid__col_xl3
      "
    >
      <div class="datnek-ds-card suggestion-list overflow-hidden">
        <div class="suggestion-header display-1">Catégories</div>
        <div class="search p-2 border-bottom">
          <datnek-ds-search-input
            placeholder="Rechercher une catégorie..."
          ></datnek-ds-search-input >
        </div>

        <div class="category-body bg-white" *ngIf="!isLoading">
          <p-scrollPanel
            [style]="{ width: '100%', height: 'calc(100vh - 273px)' }"
          >
            <span
              class="display-3 pointer category"
              *ngFor="let item of categories"
              (mouseover)="showIcon()"
              (datnekdsClickOrTouch)="goToSavedCategery($event)"
            >
              {{ item.name }}
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlinearrowRight"
              ></datnek-ds-svg-icon>
            </span>
          </p-scrollPanel>
        </div>

        <datnek-skeleton-categories
          *ngIf="isLoading"
        ></datnek-skeleton-categories>
      </div>
    </div>
    <div class="grid__col_12 grid__col_sm8 grid__col_md8 grid__col_xl9">
      <div class="">
        <div class="suggestion-header">
          <div
            class="
              display-1
              d-flex
              align-items-center
              justify-content-between
              w-100
            "
          >
            <div class="d-flex">
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlinebookmark"
                class="mr-2"
              ></datnek-ds-svg-icon>
              Enregistré dans
            </div>
            <datnek-ds-button
              [leftIcon]="iconEnum.outlinechevronLeft"
              [label]="'Retour'"
              [size]="sizeEnum.medium"
              [type]="colorEnum.secondary"
              (datnekdsClickOrTouch)="goToHome($event)"
            ></datnek-ds-button>
          </div>
        </div>
        <div class="p-3 bg-white">
          <datnek-ds-filter-chip
            [filters]="filters"
            [listFilter]="listFilter"
          ></datnek-ds-filter-chip>
        </div>

        <div class="suggestion-content">
          <div
            class="
              tri
              bg-white
              d-flex
              align-items-center
              justify-content-end
              p-2
            "
          >
            <span class="display-4 mr-3">Filtrer par : </span>
            <div ngbDropdown class="d-inline-block">
              <div class="pointer" id="dropdownBasic2" ngbDropdownToggle>
                <span>Date d'ajout(plus récente)</span>
                <datnek-ds-svg-icon
                  [icon]="iconEnum.outlinechevronDown"
                  class="ml-2"
                ></datnek-ds-svg-icon>
              </div>

              <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                <button ngbDropdownItem>Date d'ajout (plus récente)</button>
                <button ngbDropdownItem>Date d'ajout (plus ancienne)</button>
                <button ngbDropdownItem>Plus populaire</button>
                <button ngbDropdownItem>
                  Date de publication (plus ancienne)
                </button>
                <button ngbDropdownItem>
                  Date de publication (plus ancienne)
                </button>
              </div>
            </div>
          </div>
          <div class="saved-messages" *ngIf="!isLoading && messages.length">
            <div
              class="
                message-item
                d-flex
                align-items-center
                justify-content-between
                p-3
                border-top border-bottom
              "
              *ngFor="let item of messages"
            >
              <div
                class="pointer d-flex align-items-center"
                (datnekdsClickOrTouch)="goToArticle($event)"
              >
                <img
                  [src]="item.image"
                  alt=""
                  class="mr-3"
                  *ngIf="item.image"
                />
                <div class="infos">
                  <div class="display-2 mb-2">
                    {{ item.content | truncatetext: 60 }}
                  </div>
                  <div class="display-5">
                    Enregistré le {{ item.date }} à {{ item.hours }}
                  </div>
                </div>
              </div>

              <datnek-ds-button-icon
                [Icon]="iconEnum.solidbookmark"
                [size]="sizeEnum.medium"
                [type]="colorEnum.ghost"
                [round]="true"
                (datnekdsClickOrTouch)="unsave(publication)"
              ></datnek-ds-button-icon>
            </div>

            <!-- IF READ MORE -->
            <ng-container *ngIf="readMore">
              <div
                class="
                  message-item
                  d-flex
                  align-items-center
                  justify-content-between
                  p-3
                  border-top border-bottom
                "
                *ngFor="let item of messages"
                (datnekdsClickOrTouch)="goToArticle($event)"
              >
                <img
                  [src]="item.image"
                  alt=""
                  class="mr-3"
                  *ngIf="item.image"
                />
                <div class="infos">
                  <div class="display-2 mb-2">
                    {{ item.content | truncatetext: 60 }}
                  </div>
                  <div class="display-5">
                    Enregistré le {{ item.date }} à {{ item.hours }}
                  </div>
                </div>
                <datnek-ds-button-icon
                  [Icon]="iconEnum.solidbookmark"
                  [size]="sizeEnum.medium"
                  [type]="colorEnum.ghost"
                  [round]="true"
                  (datnekdsClickOrTouch)="unsave(publication)"
                ></datnek-ds-button-icon>
              </div>
            </ng-container>
          </div>

          <datnek-ds-no-content
            *ngIf="!messages.length"
            imgSrc="assets/icons/src/newspaper-journal.svg"
            titleMsg="Aucun contenu enregistré"
            contentDesc="Vous n’avez aucun contenu enregistré pour l’instant"
          ></datnek-ds-no-content>

          <datnek-skeleton-notifications
            *ngIf="isLoading"
          ></datnek-skeleton-notifications>
        </div>
        <div
          class="suggestion-footer d-flex justify-content-center p-3"
          *ngIf="messages.length"
        >
          <datnek-ds-link
            href="javascript:;"
            label="Voir plus"
            (datnekdsClickOrTouch)="readMore = true"
            class="d-block m-auto"
          ></datnek-ds-link>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #publication let-close="close">
  <div class="datnek-ds-dialog-body">
    <div class="content-modal">
      <div class="notif-box p-3">
        <p class="">
          Voulez-vous vraiment retirer l'publication de la liste des messages
          sauvegardés ?
        </p>
      </div>

      <div class="datnek-ds-dialog-footer">
        <datnek-ds-button
          [label]="'Annuler'"
          [size]="sizeEnum.large"
          [type]="colorEnum.secondary"
          class="mr-3"
          (datnekdsClickOrTouch)="close()"
        ></datnek-ds-button>
        <datnek-ds-button
          [label]="'supprimer'"
          [size]="sizeEnum.large"
          [type]="colorEnum.primary"
          (datnekdsClickOrTouch)="close(); showToast(unsaved)"
        ></datnek-ds-button>
      </div>
    </div>
  </div>
</ng-template>

<!--NOTIFS MESSAGES-->
<ng-template #unsaved let-close="close">
  L'publication a été retiré de la liste
</ng-template>
