<main class="bg-container app" *ngIf="loaderTranslator; else available">
  <del-shared-header
    [ngClass]="router.url === '/chat/home' || !isNotSsoPage ? 'd-none' : ''"
  ></del-shared-header>

  <router-outlet></router-outlet>

  <div class="d-md-none py-4"></div>
  <!--MOBILE MENU-->
  <ng-container *ngIf="isNotSsoPage">
    <nk-shared-menu-mobile
      [class.d-none]="
        router.url === '/homepage' ||
        router.url === '/register' ||
        router.url === '/chat/home'
      "
    ></nk-shared-menu-mobile>

    <nk-shared-chat-list-box
      *ngIf="
        displayChat &&
        router.url !== '/chat/home' &&
        router.url !== '/chat/chat-call' &&
        router.url !== '/homepage'
      "
    ></nk-shared-chat-list-box>
  </ng-container>
</main>

<ng-template #available> Loading... </ng-template>
