<div class="datnek-ds-dialog-body dialog-add-contacts">
  <div class="content-modal">
    <div class="content-text">
      <p-tabView>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="display-2">Synchroniser</div>
          </ng-template>
          <ng-template pTemplate="content">
            <div class="d-flex justify-content-around icon-synchro">
              <div
                class="google text-center m-auto pointer"
                (datnekdsClickOrTouch)="importContactApiGoogle(google)"
              >
                <img
                  src="assets/Img/icons/colors/google.svg"
                  alt=""
                  class="mb-3"
                />
                <span class="display-3">Synchronisez vos contacts Google</span>
              </div>
              <div
                class="yahoo text-center m-auto pointer"
                (datnekdsClickOrTouch)="importContactApiYahoo(yahoo)"
              >
                <img
                  src="assets/Img/icons/colors/yahoo.svg"
                  alt=""
                  class="mb-3"
                />
                <span class="display-3">Synchronisez vos contacts Yahoo</span>
              </div>
              <div
                class="outlook text-center m-auto pointer"
                (datnekdsClickOrTouch)="
                  apiGoogleYahoConnect(); dialogRef.close()
                "
              >
                <img
                  src="assets/Img/icons/colors/outlook.svg"
                  alt=""
                  class="mb-3"
                />
                <span class="display-3">Synchronisez vos contacts Outlook</span>
              </div>
              <div
                class="outlook text-center m-auto pointer"
                (datnekdsClickOrTouch)="importContactApiAndroidIos()"
              >
                <img
                  src="assets/Img/icons/colors/smartphone.svg"
                  alt=""
                  class="mb-3"
                />
                <span class="display-3"
                  >Synchronisez vos contacts depuis votre téléphone</span
                >
              </div>
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="display-2">Importer</div>
          </ng-template>
          <ng-template pTemplate="content">
            <div
              class="
                import-contact
                d-flex
                align-items-center
                justify-content-center
                flex-column
              "
              (datnekdsClickOrTouch)="importContacts()"
            >
              <input type="file" name="" id="" />
              <img
                src="assets/Img/icons/colors/file.svg"
                alt=""
                class="m-auto d-block"
              />
              <span class="display-3 mt-3 text-center"
                >Importez les contacts depuis un fichier CSV</span
              >
            </div>

            <!--<nk-shared-progress-bar
              [value]="value"
              (start)="(initProgress)"
            ></nk-shared-progress-bar>-->
            <p class="p-3 border-top mb-0" *ngIf="import === true">
              <ngb-progressbar [value]="progress"
                >{{ progress }}%</ngb-progressbar
              >
            </p>

            <div class="text-center p-3" *ngIf="import === true">
              Les contacts ont bien été importés dans votre liste
            </div>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
<div class="datnek-ds-dialog-footer">
  <datnek-ds-button
    [label]="'Annuler'"
    [size]="sizeEnum.medium"
    [type]="colorEnum.secondary"
    class="mr-3"
    (datnekdsClickOrTouch)="dialogRef.close()"
  ></datnek-ds-button>
  <datnek-ds-button
    [label]="'Ajouter le contact'"
    [size]="sizeEnum.medium"
    [type]="colorEnum.primary"
    (datnekdsClickOrTouch)="dialogRef.close()"
  ></datnek-ds-button>
</div>

<ng-template #google let-close="close">
  <div class="datnek-ds-dialog-body">
    <div class="content-modal">
      <div class="notif-box p-3">
        <p class="">
          409 contacts ont été trouvés dans votre compte
          bjm.graphicdesigner@gmail.com
        </p>
        <div class="py-3">
          <datnek-ds-checkbox-group
            label="Supprimer les contacts en double"
            [markLeft]="true"
          ></datnek-ds-checkbox-group>
        </div>
      </div>

      <div class="datnek-ds-dialog-footer">
        <datnek-ds-button
          [label]="'Annuler'"
          [size]="sizeEnum.large"
          [type]="colorEnum.secondary"
          class="mr-3"
          (datnekdsClickOrTouch)="dialogRef.close()"
        ></datnek-ds-button>
        <datnek-ds-button
          [label]="'Importer'"
          [size]="sizeEnum.large"
          [type]="colorEnum.primary"
          (datnekdsClickOrTouch)="dialogRef.close()"
        ></datnek-ds-button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #yahoo let-close="close">
  <div class="datnek-ds-dialog-body">
    <div class="content-modal">
      <div class="notif-box p-3">
        <p class="">
          28 contacts ont été trouvés dans votre compte bassakirou@yahoo.com
        </p>
        <div class="py-3">
          <datnek-ds-checkbox-group
            label="Supprimer les contacts en double"
            [markLeft]="true"
          ></datnek-ds-checkbox-group>
        </div>
      </div>

      <div class="datnek-ds-dialog-footer">
        <datnek-ds-button
          [label]="'Annuler'"
          [size]="sizeEnum.large"
          [type]="colorEnum.secondary"
          class="mr-3"
          (datnekdsClickOrTouch)="dialogRef.close()"
        ></datnek-ds-button>
        <datnek-ds-button
          [label]="'Importer'"
          [size]="sizeEnum.large"
          [type]="colorEnum.primary"
          (datnekdsClickOrTouch)="dialogRef.close()"
        ></datnek-ds-button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #outlook let-close="close">
  <div class="datnek-ds-dialog-body">
    <div class="content-modal">
      <div class="notif-box p-3">
        <p class="">125 contacts ont été trouvés dans votre compte outlook</p>
        <div class="py-3">
          <datnek-ds-checkbox-group
            label="Supprimer les contacts en double"
            [markLeft]="true"
          ></datnek-ds-checkbox-group>
        </div>
      </div>

      <div class="datnek-ds-dialog-footer">
        <datnek-ds-button
          [label]="'Annuler'"
          [size]="sizeEnum.large"
          [type]="colorEnum.secondary"
          class="mr-3"
          (datnekdsClickOrTouch)="dialogRef.close()"
        ></datnek-ds-button>
        <datnek-ds-button
          [label]="'Importer'"
          [size]="sizeEnum.large"
          [type]="colorEnum.primary"
          (datnekdsClickOrTouch)="dialogRef.close()"
        ></datnek-ds-button>
      </div>
    </div>
  </div>
</ng-template>
